
interface PreComputed {
  ask: number;
  mrr: number;
  growth: number;
  volatility: number;
  pd: number;
  tenure: number;
  pledge: number;
  trapped: number;
}

const data = `
11.666666666666668,50,50,50,48.88,81.01,63.01,94.1,79.99,107.81,80.0,128.62,3.0,15
163.33333333333331,700,35,50,71.65,60.11,73.24,62.07,74.72,63.23,77.34,65.74,0.0,15
163.33333333333331,700,25,50,70.99,60.4,72.64,62.0,74.26,63.4,77.52,65.2,0.0,15
175.0,750,45,50,71.09,60.97,72.63,61.92,73.91,63.5,74.73,64.53,0.0,15
116.66666666666667,500,30,50,70.89,61.69,72.54,63.19,74.82,64.93,79.24,66.94,0.0,15
151.66666666666669,650,25,50,70.92,60.0,72.73,61.91,74.3,63.42,75.86,65.23,0.0,15
198.33333333333331,850,45,50,71.75,60.12,72.94,61.22,73.99,62.41,75.04,64.12,0.0,15
128.33333333333331,550,20,50,70.79,61.24,72.85,62.84,74.46,64.48,77.52,66.64,0.0,15
140.0,600,50,50,70.24,60.87,72.82,62.73,74.49,64.57,76.1,67.77,0.0,15
93.33333333333334,400,35,50,69.83,61.62,73.8,64.85,75.95,67.06,79.99,68.99,0.0,15
128.33333333333331,550,25,50,71.1,60.4,73.54,62.84,75.16,64.5,76.76,66.95,0.0,15
163.33333333333331,700,50,50,70.94,61.08,72.69,62.76,73.97,64.33,76.68,65.63,0.0,15
46.66666666666667,200,50,50,65.88,64.94,71.5,69.81,76.72,74.12,79.99,81.49,0.0,15
186.66666666666669,800,50,50,70.73,60.33,72.57,61.95,74.09,63.85,75.62,65.86,0.0,15
116.66666666666667,500,45,50,69.99,61.4,72.86,63.75,75.25,65.41,79.99,67.4,0.0,15
93.33333333333334,400,45,50,68.95,62.25,72.37,64.54,73.95,66.22,77.35,69.65,0.0,15
151.66666666666669,650,50,50,70.75,60.47,72.61,62.56,74.04,64.53,76.69,67.87,0.0,15
46.66666666666667,200,30,50,67.37,64.15,73.56,68.95,78.19,72.24,80.0,78.06,0.0,15
151.66666666666669,650,35,50,70.61,61.12,73.33,62.25,75.05,63.26,79.86,66.27,0.0,15
93.33333333333334,400,40,50,70.1,61.99,73.51,64.55,76.44,67.32,79.8,69.21,0.0,15
128.33333333333331,550,30,50,70.32,60.55,72.42,62.21,75.14,63.88,77.83,67.73,0.0,15
105.0,450,45,50,69.9,61.89,71.58,64.45,74.04,66.61,78.92,69.46,0.0,15
210.0,900,45,50,71.26,60.03,72.73,61.59,74.36,62.6,76.84,64.13,0.0,15
116.66666666666667,500,20,50,70.49,60.67,73.66,63.59,76.17,64.85,79.73,68.18,0.0,15
93.33333333333334,400,50,50,68.62,63.21,72.06,66.16,74.28,67.98,78.62,69.91,0.0,15
93.33333333333334,400,30,50,70.77,61.33,73.94,64.17,76.08,65.73,80.0,69.29,0.0,15
140.0,600,30,50,70.27,61.08,71.87,62.29,73.14,63.43,74.19,65.63,0.0,15
233.33333333333334,1000,45,50,71.51,60.03,72.85,61.28,73.91,62.13,75.87,63.23,0.0,15
116.66666666666667,500,50,50,69.53,61.78,72.24,63.71,74.4,65.14,78.44,67.99,0.0,15
151.66666666666669,650,20,50,71.23,60.36,72.95,61.85,75.31,63.44,78.23,65.09,0.0,15
93.33333333333334,400,20,50,69.49,61.19,71.73,64.0,74.96,66.77,78.94,68.68,0.0,15
105.0,450,20,50,70.88,61.42,73.43,63.55,76.34,65.13,79.99,67.93,0.0,15
175.0,750,35,50,71.09,60.52,72.6,61.66,74.44,62.51,78.55,64.37,0.0,15
233.33333333333334,1000,25,50,71.7,60.02,72.9,61.0,73.74,61.75,74.94,62.73,0.0,15
186.66666666666669,800,20,50,71.26,59.66,73.11,61.54,74.43,62.68,76.46,63.45,0.0,15
198.33333333333331,850,50,50,71.28,60.18,72.52,61.07,73.51,62.2,75.83,64.46,0.0,15
58.333333333333336,250,40,50,68.13,64.6,73.03,68.77,78.03,71.67,80.0,74.36,0.0,15
128.33333333333331,550,50,50,70.73,60.97,73.25,62.73,74.7,65.09,77.45,66.49,0.0,15
81.66666666666666,350,25,50,69.52,62.21,72.62,65.41,76.84,68.09,80.0,69.87,0.0,15
70.0,300,20,50,69.27,62.17,74.15,65.33,77.1,68.49,80.0,72.52,0.0,15
140.0,600,45,50,70.92,60.79,72.66,62.99,74.57,64.19,79.48,66.05,0.0,15
198.33333333333331,850,40,50,72.04,59.68,73.21,61.25,74.15,62.74,75.81,64.05,0.0,15
105.0,450,25,50,69.06,62.38,72.05,64.56,74.8,66.8,79.29,69.23,0.0,15
35.0,150,40,50,64.43,65.1,72.05,71.01,79.98,77.73,80.0,85.9,0.0,15
128.33333333333331,550,35,50,70.29,61.21,72.74,63.34,74.56,65.07,79.98,66.66,0.0,15
198.33333333333331,850,35,50,71.49,60.19,72.84,61.24,73.55,62.21,75.37,65.54,0.0,15
186.66666666666669,800,45,50,70.87,60.29,72.61,62.05,73.69,63.03,76.65,66.34,0.0,15
163.33333333333331,700,20,50,71.31,60.69,72.89,61.7,74.97,63.47,77.41,64.76,0.0,15
140.0,600,25,50,70.59,60.0,73.12,62.41,74.79,64.08,77.72,65.81,0.0,15
175.0,750,30,50,71.14,59.97,72.81,61.54,74.82,63.25,76.65,65.79,0.0,15
140.0,600,40,50,70.76,61.66,72.35,63.11,73.97,65.0,75.41,66.23,0.0,15
116.66666666666667,500,40,50,70.33,61.49,72.08,63.95,74.42,65.51,78.48,67.64,0.0,15
198.33333333333331,850,25,50,71.89,59.79,73.36,60.9,74.44,62.48,75.92,63.68,0.0,15
105.0,450,40,50,69.14,62.18,71.6,64.48,73.92,66.88,76.99,70.83,0.0,15
186.66666666666669,800,25,50,71.29,60.22,73.14,61.34,74.33,62.44,76.69,64.76,0.0,15
151.66666666666669,650,45,50,70.77,60.95,72.84,62.62,74.94,64.14,79.16,66.47,0.0,15
198.33333333333331,850,20,50,71.84,59.67,73.15,60.73,74.52,62.48,76.81,64.08,0.0,15
210.0,900,25,50,71.6,59.68,72.95,61.08,74.86,62.17,76.57,63.47,0.0,15
11.666666666666668,50,20,50,57.35,75.27,78.54,88.53,79.99,106.66,80.0,122.15,9.0,15
105.0,450,50,50,69.88,61.89,72.87,64.33,75.07,65.17,78.64,69.4,0.0,15
221.66666666666669,950,40,50,71.32,60.33,72.52,61.68,73.93,62.5,77.11,63.61,0.0,15
70.0,300,35,50,68.82,62.41,73.69,65.73,76.82,70.61,79.98,75.62,0.0,15
221.66666666666669,950,30,50,71.86,59.92,72.75,61.42,74.3,62.45,77.05,63.3,0.0,15
233.33333333333334,1000,50,50,71.67,60.04,72.86,61.08,74.09,61.64,75.04,63.96,0.0,15
151.66666666666669,650,30,50,70.46,60.46,72.22,62.66,73.71,63.98,77.8,65.85,0.0,15
116.66666666666667,500,25,50,70.44,61.81,72.67,63.94,75.0,65.1,79.99,68.42,0.0,15
140.0,600,35,50,70.69,61.15,72.79,62.79,74.91,64.36,78.12,66.52,0.0,15
233.33333333333334,1000,35,50,71.18,60.07,72.52,61.11,73.45,62.04,75.41,63.35,0.0,15
93.33333333333334,400,25,50,69.6,61.62,73.33,64.73,76.25,65.99,80.0,70.86,0.0,15
23.333333333333336,100,40,50,60.85,73.4,70.79,85.53,79.99,90.38,80.0,97.7,1.0,15
221.66666666666669,950,25,50,72.12,59.61,73.74,60.66,75.29,61.59,76.96,63.46,0.0,15
198.33333333333331,850,30,50,71.52,60.34,72.98,61.58,73.8,62.71,75.94,64.77,0.0,15
105.0,450,30,50,70.65,61.64,73.44,64.24,75.47,65.87,78.87,67.99,0.0,15
186.66666666666669,800,30,50,71.16,60.36,72.41,61.31,74.29,62.9,75.57,64.71,0.0,15
175.0,750,20,50,71.11,60.26,73.19,61.13,74.71,62.3,77.27,65.56,0.0,15
58.333333333333336,250,35,50,69.16,62.0,72.97,67.2,77.35,69.93,80.0,75.26,0.0,15
70.0,300,50,50,69.11,63.79,72.98,67.07,75.93,70.34,80.0,76.58,0.0,15
58.333333333333336,250,30,50,69.06,62.71,74.41,68.82,79.3,71.2,80.0,74.92,0.0,15
11.666666666666668,50,40,50,52.78,77.37,74.16,90.33,79.99,101.11,80.0,116.39,7.0,15
186.66666666666669,800,40,50,71.94,60.27,73.36,61.59,74.82,62.54,77.16,64.86,0.0,15
46.66666666666667,200,40,50,67.23,65.9,73.7,71.37,77.3,74.26,80.0,79.17,0.0,15
11.666666666666668,50,45,50,50.09,80.19,64.88,94.17,79.99,100.74,80.0,119.33,8.0,15
11.666666666666668,50,35,50,53.81,75.78,70.03,89.87,79.99,104.35,80.0,134.19,5.0,15
23.333333333333336,100,45,50,58.34,71.24,68.17,81.54,79.99,88.2,80.0,99.65,1.0,15
70.0,300,40,50,68.27,63.33,71.36,66.71,75.94,68.79,80.0,77.35,0.0,15
35.0,150,50,50,62.09,70.09,68.96,75.32,76.75,80.55,79.99,93.61,0.0,15
221.66666666666669,950,20,50,71.79,59.77,73.08,60.87,74.4,61.46,76.02,63.14,0.0,15
81.66666666666666,350,45,50,68.81,63.19,72.15,66.38,76.45,69.11,79.99,73.54,0.0,15
210.0,900,40,50,71.73,59.93,72.87,60.88,73.87,62.09,77.14,64.15,0.0,15
186.66666666666669,800,35,50,71.32,59.98,72.93,61.13,74.65,62.59,77.39,64.35,0.0,15
81.66666666666666,350,20,50,68.96,62.77,72.62,65.22,77.12,68.43,80.0,72.35,0.0,15
175.0,750,25,50,71.66,60.08,73.25,61.63,74.68,63.05,76.7,64.61,0.0,15
23.333333333333336,100,50,50,62.47,67.89,72.17,79.5,79.98,88.19,80.0,105.83,4.0,15
23.333333333333336,100,30,50,62.89,68.77,74.08,77.72,79.99,86.15,80.0,98.0,0.0,15
46.66666666666667,200,25,50,66.13,64.74,72.96,70.92,79.97,73.3,80.0,80.26,0.0,15
221.66666666666669,950,50,50,71.31,60.04,73.02,61.47,74.08,62.18,76.21,63.64,0.0,15
35.0,150,25,50,65.11,67.29,71.57,74.22,79.98,79.01,80.0,84.87,2.0,15
70.0,300,45,50,69.54,63.42,72.26,66.88,76.06,69.16,79.56,72.79,0.0,15
58.333333333333336,250,45,50,67.98,63.95,73.22,68.33,76.37,73.04,80.0,78.58,0.0,15
233.33333333333334,1000,20,50,71.76,59.42,73.31,61.05,74.03,62.47,76.37,63.58,0.0,15
58.333333333333336,250,20,50,69.47,63.65,74.56,68.3,79.11,71.52,80.0,76.21,0.0,15
81.66666666666666,350,35,50,67.51,62.66,72.98,65.82,75.04,68.32,79.98,71.54,0.0,15
221.66666666666669,950,45,50,71.78,59.95,72.93,61.0,73.46,62.22,75.48,63.89,0.0,15
70.0,300,30,50,69.48,62.39,73.34,66.68,76.79,70.69,80.0,74.09,0.0,15
210.0,900,20,50,71.37,59.73,73.42,61.03,74.92,62.03,76.28,65.07,0.0,15
23.333333333333336,100,35,50,59.97,71.19,70.72,81.35,78.55,89.03,80.0,98.95,1.0,15
70.0,300,25,50,68.03,64.2,71.89,66.67,75.78,69.92,80.0,74.34,0.0,15
23.333333333333336,100,25,50,63.93,68.62,76.9,81.96,79.99,89.88,80.0,99.49,4.0,15
46.66666666666667,200,35,50,68.51,64.62,76.35,70.02,79.98,73.59,80.0,81.12,0.0,15
58.333333333333336,250,50,50,67.26,64.72,73.08,68.22,78.07,71.98,79.99,75.95,0.0,15
175.0,750,40,50,71.22,60.7,72.26,62.22,73.57,63.83,75.86,65.16,0.0,15
58.333333333333336,250,25,50,69.6,62.34,73.65,66.95,78.5,69.33,79.99,74.92,0.0,15
210.0,900,30,50,71.78,59.79,73.49,61.03,75.04,61.74,76.24,63.19,0.0,15
210.0,900,50,50,71.54,59.75,72.76,61.0,73.69,62.23,76.11,63.65,0.0,15
210.0,900,35,50,71.36,60.12,72.96,61.21,73.95,62.4,75.37,63.69,0.0,15
175.0,750,50,50,70.88,60.88,72.63,62.04,73.65,62.79,76.87,64.69,0.0,15
11.666666666666668,50,30,50,54.6,81.49,73.12,97.14,79.99,107.93,80.0,128.38,7.0,15
35.0,150,45,50,62.93,68.93,72.22,74.69,78.07,78.69,80.0,84.42,0.0,15
151.66666666666669,650,40,50,70.31,60.93,72.15,62.14,73.81,64.16,76.09,65.67,0.0,15
81.66666666666666,350,40,50,69.85,62.77,72.92,64.85,75.08,66.84,79.05,71.98,0.0,15
233.33333333333334,1000,30,50,71.68,60.09,72.87,61.18,73.71,62.32,76.07,63.22,0.0,15
233.33333333333334,1000,40,50,71.59,59.97,72.82,61.22,73.95,62.26,75.49,65.85,0.0,15
163.33333333333331,700,40,50,71.06,60.68,72.54,61.85,74.26,62.95,77.76,64.63,0.0,15
116.66666666666667,500,35,50,70.44,61.42,72.16,63.21,74.59,65.03,78.4,68.83,0.0,15
140.0,600,20,50,70.76,60.69,72.82,62.37,74.57,64.44,78.1,66.87,0.0,15
46.66666666666667,200,45,50,66.05,65.4,72.01,70.93,77.74,75.08,80.0,79.34,0.0,15
163.33333333333331,700,30,50,71.56,59.75,73.4,61.66,75.61,63.32,79.96,65.57,0.0,15
128.33333333333331,550,45,50,70.78,60.82,72.9,62.8,75.5,64.1,78.98,67.36,0.0,15
105.0,450,35,50,70.0,61.48,73.08,63.82,75.34,66.72,79.99,70.01,0.0,15
221.66666666666669,950,35,50,71.3,60.33,72.45,61.35,73.4,62.24,75.2,63.42,0.0,15
35.0,150,30,50,65.68,64.35,74.65,70.22,79.97,75.52,80.0,81.4,0.0,15
128.33333333333331,550,40,50,70.26,62.05,72.31,63.75,74.62,65.73,77.61,67.85,0.0,15
81.66666666666666,350,30,50,69.01,62.43,71.84,65.8,75.66,68.81,80.0,71.66,0.0,15
81.66666666666666,350,50,50,68.65,62.58,73.3,65.08,75.74,68.59,79.98,71.14,0.0,15
35.0,150,35,50,63.77,69.59,72.94,74.34,79.97,79.18,80.0,88.88,0.0,15
35.0,150,20,50,67.25,65.24,73.56,69.74,79.98,76.02,80.0,80.88,1.0,15
23.333333333333336,100,20,50,63.61,66.72,74.38,79.85,79.99,87.85,80.0,98.43,4.0,15
163.33333333333331,700,45,50,70.77,60.18,72.86,62.06,74.5,63.34,78.61,65.26,0.0,15
46.66666666666667,200,20,50,67.38,64.98,72.08,69.16,78.08,77.31,80.0,81.75,0.0,15
11.666666666666668,50,25,50,52.64,75.6,64.83,89.85,79.99,101.05,80.0,121.98,6.0,15
11.666666666666668,50,50,50,22.65,105.48,29.29,122.05,42.82,138.77,79.99,171.98,1.0,30
140.0,600,50,50,35.82,68.46,36.77,71.31,38.11,73.77,40.06,76.52,0.0,30
151.66666666666669,650,25,50,36.39,67.21,37.7,69.91,38.54,72.31,41.49,75.78,0.0,30
163.33333333333331,700,25,50,36.24,67.67,37.34,69.53,38.15,70.79,40.01,73.76,0.0,30
151.66666666666669,650,50,50,35.46,68.59,36.96,70.33,38.66,71.95,40.47,75.64,0.0,30
151.66666666666669,650,35,50,36.13,67.86,37.4,70.72,38.78,72.84,40.67,76.29,0.0,30
46.66666666666667,200,30,50,33.65,77.4,37.31,83.8,40.93,88.42,50.49,97.54,0.0,30
175.0,750,35,50,36.34,66.99,37.79,69.24,39.47,71.51,42.39,74.4,0.0,30
175.0,750,45,50,36.11,67.56,37.19,69.44,37.91,71.81,39.71,73.36,0.0,30
163.33333333333331,700,35,50,36.26,66.92,37.4,68.86,38.63,72.23,40.87,74.75,0.0,30
198.33333333333331,850,25,50,36.34,66.11,37.4,68.35,38.58,69.28,40.56,73.36,0.0,30
105.0,450,25,50,35.54,69.75,37.46,73.16,40.24,77.37,42.77,80.18,0.0,30
93.33333333333334,400,30,50,35.56,70.25,37.52,74.01,39.22,76.69,41.25,84.53,0.0,30
128.33333333333331,550,20,50,36.17,68.15,37.88,71.17,39.52,73.31,42.85,76.54,0.0,30
198.33333333333331,850,35,50,36.29,67.03,37.75,68.8,38.75,70.34,39.67,72.61,0.0,30
116.66666666666667,500,30,50,35.38,69.47,37.28,72.31,38.64,74.82,41.06,80.02,0.0,30
93.33333333333334,400,35,50,34.69,71.15,37.48,74.61,39.88,77.42,42.27,85.38,0.0,30
186.66666666666669,800,50,50,36.45,67.11,37.13,68.82,38.23,70.72,39.14,72.32,0.0,30
116.66666666666667,500,45,50,35.45,70.02,37.22,72.8,39.27,75.24,40.39,79.72,0.0,30
198.33333333333331,850,40,50,36.68,66.63,38.01,68.75,38.96,70.28,39.87,73.05,0.0,30
198.33333333333331,850,45,50,36.39,66.97,37.33,69.24,38.1,71.08,39.35,73.54,0.0,30
163.33333333333331,700,50,50,35.99,67.99,37.33,69.61,38.26,71.84,39.86,75.16,0.0,30
46.66666666666667,200,50,50,32.36,80.24,35.78,88.3,38.4,93.2,43.22,100.36,0.0,30
140.0,600,45,50,35.99,68.41,37.63,71.21,39.23,73.4,41.68,78.91,0.0,30
128.33333333333331,550,50,50,35.15,69.48,36.23,71.52,37.63,74.41,39.72,78.39,0.0,30
93.33333333333334,400,45,50,34.73,70.26,36.99,75.9,38.46,78.67,40.28,81.41,0.0,30
116.66666666666667,500,20,50,35.52,67.94,37.05,70.97,38.62,74.01,40.93,77.9,0.0,30
105.0,450,20,50,35.6,68.27,37.33,72.68,38.99,74.89,44.18,80.45,0.0,30
116.66666666666667,500,25,50,35.68,68.5,37.07,71.19,38.58,74.38,41.35,77.74,0.0,30
70.0,300,20,50,33.9,73.25,37.33,77.04,39.34,81.49,45.06,86.07,0.0,30
151.66666666666669,650,20,50,36.57,65.81,38.09,68.95,39.37,71.13,40.46,73.96,0.0,30
151.66666666666669,650,30,50,36.13,68.02,37.46,70.43,38.32,72.17,39.88,74.56,0.0,30
105.0,450,45,50,34.87,70.61,36.36,72.99,37.79,76.65,43.49,79.95,0.0,30
128.33333333333331,550,25,50,35.91,67.3,37.8,69.33,39.2,72.32,41.54,77.53,0.0,30
128.33333333333331,550,30,50,35.93,68.62,37.47,71.35,38.62,74.18,40.78,76.53,0.0,30
210.0,900,45,50,36.55,66.59,37.57,69.14,38.44,71.19,40.74,73.38,0.0,30
116.66666666666667,500,50,50,35.72,69.61,36.88,72.84,38.27,76.2,41.58,81.03,0.0,30
93.33333333333334,400,40,50,34.59,70.56,37.1,73.37,39.26,76.86,42.76,82.12,0.0,30
221.66666666666669,950,25,50,37.04,65.78,37.86,67.81,38.72,69.22,39.88,71.32,0.0,30
128.33333333333331,550,35,50,35.51,69.57,36.61,72.73,38.01,74.57,41.1,77.61,0.0,30
233.33333333333334,1000,45,50,36.55,66.09,37.23,67.85,37.99,69.53,39.51,71.43,0.0,30
93.33333333333334,400,50,50,34.87,72.21,36.51,75.33,37.79,78.9,44.16,82.84,0.0,30
93.33333333333334,400,20,50,34.91,69.87,36.86,73.94,38.78,76.96,41.38,80.92,0.0,30
198.33333333333331,850,20,50,36.79,65.66,37.6,67.95,38.56,69.38,39.44,72.77,0.0,30
140.0,600,25,50,36.25,67.9,37.46,70.21,39.23,71.94,40.88,75.01,0.0,30
175.0,750,30,50,36.27,66.46,37.86,68.52,38.6,70.44,40.33,72.93,0.0,30
11.666666666666668,50,20,50,23.46,100.92,36.14,115.27,59.69,134.05,80.0,174.33,2.0,30
186.66666666666669,800,20,50,36.27,66.02,37.59,68.73,39.16,69.9,41.72,73.15,0.0,30
221.66666666666669,950,40,50,36.43,66.71,37.34,68.97,38.52,70.41,39.75,72.14,0.0,30
151.66666666666669,650,45,50,35.86,67.65,36.97,70.23,38.34,72.5,39.31,74.89,0.0,30
58.333333333333336,250,30,50,33.08,73.82,35.71,79.44,38.27,86.05,45.04,94.16,0.0,30
233.33333333333334,1000,25,50,36.88,65.53,37.9,67.65,38.7,69.12,40.02,71.1,0.0,30
140.0,600,30,50,36.46,66.86,37.74,69.77,38.71,72.78,40.6,76.23,0.0,30
198.33333333333331,850,50,50,36.67,66.43,37.69,68.27,38.19,70.16,39.06,71.88,0.0,30
140.0,600,40,50,35.85,68.58,37.53,71.85,38.02,74.17,40.24,79.06,0.0,30
116.66666666666667,500,40,50,35.09,69.76,36.55,73.46,38.6,75.82,42.01,78.01,0.0,30
23.333333333333336,100,30,50,30.03,86.29,35.5,99.34,45.16,108.46,61.57,119.94,1.0,30
140.0,600,35,50,35.74,68.49,37.09,71.53,38.44,73.62,39.29,76.96,0.0,30
210.0,900,25,50,36.62,66.52,37.56,67.97,38.71,69.79,40.2,72.33,0.0,30
221.66666666666669,950,30,50,36.67,66.9,37.44,68.27,38.45,69.44,40.43,72.29,0.0,30
186.66666666666669,800,45,50,36.22,66.86,37.09,68.92,38.4,70.81,40.29,74.33,0.0,30
58.333333333333336,250,50,50,33.34,74.13,36.29,79.45,39.46,85.39,43.25,93.12,0.0,30
163.33333333333331,700,20,50,36.66,66.08,38.34,68.51,39.76,72.49,41.02,75.02,0.0,30
35.0,150,40,50,31.69,82.74,35.71,88.16,39.75,94.6,59.93,118.4,0.0,30
175.0,750,25,50,36.81,66.66,38.27,68.51,39.24,70.73,41.24,73.19,0.0,30
93.33333333333334,400,25,50,35.77,68.99,37.94,72.94,40.38,75.41,45.04,81.29,0.0,30
105.0,450,40,50,34.99,69.86,36.78,74.45,39.59,76.7,42.45,80.34,0.0,30
58.333333333333336,250,40,50,33.08,75.09,37.15,81.61,40.34,85.51,43.92,90.23,0.0,30
70.0,300,35,50,33.79,73.9,36.11,78.58,37.96,82.37,44.24,86.16,0.0,30
233.33333333333334,1000,35,50,36.87,65.27,37.92,67.37,38.54,69.0,39.92,70.95,0.0,30
186.66666666666669,800,40,50,36.5,66.87,37.76,69.36,38.4,71.65,39.38,75.42,0.0,30
186.66666666666669,800,25,50,36.29,66.89,37.47,69.51,38.19,70.93,40.32,75.65,0.0,30
81.66666666666666,350,25,50,35.13,73.5,37.48,76.74,40.92,79.94,43.46,84.95,0.0,30
105.0,450,50,50,35.37,69.23,36.86,74.29,38.43,77.57,42.22,82.5,0.0,30
11.666666666666668,50,40,50,22.86,94.68,31.78,115.29,49.48,126.45,79.99,154.67,4.0,30
70.0,300,40,50,33.51,72.39,36.98,77.38,39.62,82.85,44.04,88.64,0.0,30
221.66666666666669,950,45,50,36.52,66.5,37.18,69.04,38.35,70.37,39.42,72.85,0.0,30
105.0,450,30,50,34.86,70.24,37.15,73.68,38.84,76.83,42.17,82.14,0.0,30
233.33333333333334,1000,50,50,36.47,66.21,37.59,67.97,38.27,69.63,39.46,71.52,0.0,30
198.33333333333331,850,30,50,36.59,66.11,37.38,67.92,38.15,70.23,40.77,72.82,0.0,30
58.333333333333336,250,25,50,34.25,73.03,36.9,79.26,39.34,85.12,45.63,95.79,0.0,30
81.66666666666666,350,40,50,34.46,71.78,36.28,76.07,38.49,79.41,44.03,83.59,0.0,30
70.0,300,45,50,34.41,74.11,36.56,77.9,39.23,83.92,43.94,90.48,0.0,30
46.66666666666667,200,25,50,33.44,74.9,36.18,82.4,38.5,88.12,45.52,97.74,0.0,30
23.333333333333336,100,40,50,29.35,90.53,36.52,102.22,46.84,112.59,79.98,127.95,1.0,30
58.333333333333336,250,45,50,32.19,76.5,36.88,81.68,40.11,86.74,43.67,96.44,0.0,30
70.0,300,25,50,33.74,73.13,35.71,78.01,39.27,82.24,43.95,85.64,0.0,30
81.66666666666666,350,35,50,34.45,70.88,36.84,75.39,38.31,79.95,40.86,87.4,0.0,30
46.66666666666667,200,40,50,32.19,75.43,36.18,81.25,39.38,89.03,42.41,97.32,0.0,30
81.66666666666666,350,45,50,34.35,71.69,36.24,77.91,37.82,81.76,40.49,87.33,0.0,30
210.0,900,40,50,36.41,66.82,37.62,68.06,38.54,70.39,39.67,72.55,0.0,30
221.66666666666669,950,50,50,36.47,66.67,37.22,68.75,38.35,70.33,39.27,72.54,0.0,30
186.66666666666669,800,30,50,36.52,66.71,37.81,68.37,38.5,69.55,39.68,73.09,0.0,30
175.0,750,20,50,36.57,66.62,37.94,68.56,38.8,70.77,41.2,71.9,0.0,30
233.33333333333334,1000,20,50,37.05,65.67,37.7,67.54,38.33,68.67,39.64,70.4,0.0,30
175.0,750,40,50,36.18,67.08,37.52,69.51,38.56,71.58,40.07,75.73,0.0,30
58.333333333333336,250,20,50,33.16,73.63,37.2,80.07,40.92,83.86,51.97,91.37,0.0,30
11.666666666666668,50,45,50,23.06,102.34,30.12,119.58,39.98,143.04,79.96,172.23,0.0,30
175.0,750,50,50,36.37,67.72,37.27,70.07,38.38,72.57,39.49,75.03,0.0,30
23.333333333333336,100,50,50,27.57,90.44,33.28,101.46,39.95,109.44,52.92,123.21,0.0,30
81.66666666666666,350,20,50,34.85,71.31,37.17,75.68,39.36,79.42,42.75,83.06,0.0,30
35.0,150,50,50,30.27,82.52,34.69,92.19,39.16,100.05,54.69,110.03,0.0,30
210.0,900,30,50,36.92,65.74,37.79,68.07,38.67,68.89,39.91,71.37,0.0,30
221.66666666666669,950,20,50,36.68,65.6,37.68,67.39,38.44,68.59,39.65,71.26,0.0,30
70.0,300,30,50,34.0,73.53,37.42,78.1,41.12,82.21,45.21,87.52,0.0,30
186.66666666666669,800,35,50,35.98,67.02,37.41,69.04,38.64,71.38,40.82,74.39,0.0,30
23.333333333333336,100,45,50,28.22,90.43,35.0,102.1,39.2,115.08,56.34,130.12,0.0,30
210.0,900,20,50,37.09,65.24,37.9,67.75,38.91,69.51,40.01,71.09,0.0,30
11.666666666666668,50,35,50,22.26,99.25,28.74,117.62,42.34,140.11,79.99,166.63,2.0,30
35.0,150,25,50,32.05,77.88,35.84,86.82,41.52,95.14,53.19,105.2,0.0,30
23.333333333333336,100,25,50,28.8,87.9,33.44,99.52,43.28,110.36,53.9,126.69,1.0,30
35.0,150,45,50,29.21,83.35,33.94,93.0,39.23,98.06,49.8,106.13,0.0,30
23.333333333333336,100,35,50,28.85,86.06,33.46,101.68,45.78,110.91,57.79,134.19,0.0,30
210.0,900,50,50,36.42,67.06,37.01,68.84,37.88,70.58,39.06,73.55,0.0,30
46.66666666666667,200,35,50,31.51,77.46,34.24,85.81,38.01,91.03,46.59,96.12,0.0,30
233.33333333333334,1000,30,50,37.06,66.09,37.89,67.59,38.59,68.72,40.2,71.02,0.0,30
11.666666666666668,50,30,50,25.67,103.37,35.11,121.18,70.2,140.06,80.0,195.51,3.0,30
210.0,900,35,50,36.97,65.92,37.98,67.93,38.67,69.47,40.98,71.83,0.0,30
58.333333333333336,250,35,50,34.01,72.82,37.05,80.3,40.73,85.16,48.39,91.67,0.0,30
70.0,300,50,50,34.01,75.21,36.27,80.34,38.6,84.74,41.05,93.82,0.0,30
233.33333333333334,1000,40,50,36.87,65.78,37.66,67.29,38.81,68.47,39.69,71.01,0.0,30
46.66666666666667,200,45,50,32.72,77.18,36.9,83.6,41.97,89.2,57.12,97.07,0.0,30
221.66666666666669,950,35,50,36.8,66.12,37.65,67.91,38.72,69.16,40.12,71.52,0.0,30
140.0,600,20,50,36.06,68.31,37.37,70.98,39.1,72.86,40.59,78.77,0.0,30
163.33333333333331,700,30,50,36.51,67.45,37.5,70.71,38.36,71.76,39.64,74.71,0.0,30
151.66666666666669,650,40,50,36.19,68.27,37.46,69.84,38.56,73.07,40.88,76.53,0.0,30
81.66666666666666,350,50,50,34.13,73.38,35.99,77.05,38.51,80.56,42.48,86.83,0.0,30
116.66666666666667,500,35,50,35.44,69.29,37.22,72.17,39.03,75.31,40.45,80.52,0.0,30
128.33333333333331,550,45,50,35.31,69.42,36.72,71.76,38.53,75.04,40.75,77.3,0.0,30
163.33333333333331,700,40,50,36.44,67.74,37.66,69.87,38.78,71.93,41.33,75.55,0.0,30
105.0,450,35,50,34.72,69.73,36.52,72.67,39.03,77.06,41.1,83.24,0.0,30
128.33333333333331,550,40,50,35.41,68.69,36.69,72.93,38.15,75.69,41.15,79.63,0.0,30
35.0,150,30,50,30.48,78.01,35.4,88.51,40.65,96.04,50.46,110.69,0.0,30
81.66666666666666,350,30,50,35.06,71.46,37.41,75.36,39.77,80.29,43.94,84.11,0.0,30
35.0,150,35,50,30.99,82.27,35.66,89.57,42.28,98.13,51.73,116.11,0.0,30
163.33333333333331,700,45,50,36.07,67.7,37.04,69.7,39.08,72.16,40.47,75.28,0.0,30
23.333333333333336,100,20,50,29.4,84.48,34.34,98.99,43.46,110.69,77.43,138.36,1.0,30
35.0,150,20,50,32.63,76.81,35.73,88.06,39.68,93.67,48.22,105.34,0.0,30
46.66666666666667,200,20,50,32.51,75.11,36.77,82.11,40.15,87.2,42.85,95.4,0.0,30
11.666666666666668,50,25,50,22.12,98.14,35.06,116.94,51.13,131.72,79.99,193.4,2.0,30
11.666666666666668,50,50,50,11.18,123.26,15.35,147.38,21.92,166.14,42.76,222.1,1.0,45
163.33333333333331,700,25,50,24.19,71.8,25.01,74.96,26.06,77.41,27.78,79.67,0.0,45
46.66666666666667,200,50,50,20.09,88.01,23.47,95.54,26.62,104.27,32.7,118.45,0.0,45
46.66666666666667,200,30,50,20.35,85.83,23.18,93.74,26.76,98.73,32.7,109.55,0.0,45
116.66666666666667,500,30,50,23.48,75.37,24.53,78.64,26.0,81.6,29.22,87.19,0.0,45
175.0,750,35,50,24.28,71.14,25.2,73.77,25.68,76.23,27.22,78.42,0.0,45
151.66666666666669,650,25,50,23.95,72.15,24.46,75.13,25.51,77.47,27.62,79.56,0.0,45
151.66666666666669,650,35,50,24.12,72.97,24.87,75.69,25.64,78.16,27.51,82.05,0.0,45
198.33333333333331,850,35,50,24.53,70.57,25.54,72.82,26.28,74.8,26.98,79.4,0.0,45
105.0,450,45,50,22.97,76.73,24.5,80.74,25.64,83.94,27.62,90.54,0.0,45
140.0,600,50,50,23.67,74.58,24.88,77.97,25.56,80.66,26.78,83.91,0.0,45
151.66666666666669,650,50,50,23.67,72.89,24.56,77.25,25.71,78.48,27.16,80.67,0.0,45
163.33333333333331,700,35,50,23.98,71.67,24.98,74.19,26.25,76.67,27.03,81.16,0.0,45
128.33333333333331,550,20,50,23.81,73.68,24.87,76.6,25.73,80.67,27.23,86.79,0.0,45
105.0,450,25,50,23.24,75.42,24.6,79.57,25.65,83.64,30.71,86.22,0.0,45
163.33333333333331,700,50,50,23.9,73.61,24.67,76.65,25.52,78.67,26.64,83.21,0.0,45
116.66666666666667,500,20,50,24.09,73.11,25.59,74.88,26.55,78.6,27.79,86.14,0.0,45
198.33333333333331,850,25,50,24.78,68.8,25.38,71.72,25.83,75.32,27.11,77.63,0.0,45
198.33333333333331,850,40,50,24.38,71.23,24.99,73.73,26.15,75.22,27.62,79.75,0.0,45
70.0,300,20,50,22.81,78.32,24.95,84.76,27.23,90.55,32.34,97.48,0.0,45
128.33333333333331,550,30,50,23.5,74.44,24.92,77.84,26.19,80.96,28.28,85.32,0.0,45
93.33333333333334,400,30,50,22.57,75.87,24.17,81.49,25.91,85.78,27.63,94.32,0.0,45
175.0,750,45,50,24.07,71.7,25.02,74.64,25.81,76.98,27.5,81.19,0.0,45
140.0,600,45,50,23.54,73.36,25.03,76.38,25.9,80.1,27.66,82.1,0.0,45
210.0,900,45,50,24.27,70.83,25.08,73.55,25.71,75.72,27.01,79.38,0.0,45
93.33333333333334,400,35,50,22.87,76.32,24.54,80.19,26.32,84.22,28.66,94.78,0.0,45
116.66666666666667,500,50,50,23.11,75.96,24.47,80.8,25.21,83.68,27.37,87.37,0.0,45
198.33333333333331,850,45,50,24.19,70.99,24.94,73.11,25.8,75.26,27.39,79.66,0.0,45
93.33333333333334,400,50,50,22.57,76.71,24.08,81.64,25.49,87.08,28.03,92.28,0.0,45
186.66666666666669,800,50,50,24.05,70.86,25.03,73.52,25.45,75.19,27.54,80.08,0.0,45
128.33333333333331,550,50,50,23.34,75.42,24.52,78.98,25.87,82.98,28.96,86.16,0.0,45
186.66666666666669,800,20,50,24.51,69.24,25.3,72.18,26.35,73.65,27.4,77.11,0.0,45
93.33333333333334,400,45,50,22.84,75.96,24.2,82.11,26.0,88.26,28.88,93.57,0.0,45
221.66666666666669,950,25,50,24.33,70.44,25.13,72.93,25.88,74.47,27.44,77.75,0.0,45
140.0,600,25,50,24.38,71.6,25.46,76.37,26.31,77.9,28.37,82.04,0.0,45
151.66666666666669,650,45,50,23.93,72.02,24.62,76.1,25.35,79.51,27.38,83.55,0.0,45
93.33333333333334,400,20,50,23.56,73.77,25.41,77.71,26.5,82.36,27.98,88.11,0.0,45
116.66666666666667,500,45,50,23.6,74.08,24.81,78.33,26.0,82.8,28.02,87.94,0.0,45
221.66666666666669,950,40,50,24.4,69.97,25.05,72.44,25.69,74.39,27.32,78.09,0.0,45
105.0,450,20,50,23.38,73.33,25.52,78.32,26.85,80.98,30.08,89.68,0.0,45
151.66666666666669,650,30,50,24.12,72.42,24.85,75.39,25.68,78.32,27.24,80.64,0.0,45
175.0,750,30,50,24.11,71.2,25.2,74.1,26.18,76.15,28.03,80.07,0.0,45
151.66666666666669,650,20,50,24.55,71.19,25.46,73.65,26.55,75.99,27.94,80.62,0.0,45
128.33333333333331,550,25,50,23.73,72.35,25.11,76.81,26.27,80.4,27.99,86.81,0.0,45
233.33333333333334,1000,45,50,24.35,69.37,25.16,71.94,25.95,73.62,26.61,75.34,0.0,45
116.66666666666667,500,25,50,23.62,73.68,25.18,78.71,26.22,82.31,27.59,86.22,0.0,45
70.0,300,35,50,21.75,80.52,24.06,87.89,26.75,92.5,29.2,98.44,0.0,45
233.33333333333334,1000,25,50,24.55,70.79,25.36,72.68,25.88,74.12,27.6,76.79,0.0,45
198.33333333333331,850,50,50,24.21,71.71,24.94,74.1,25.64,74.96,26.88,78.96,0.0,45
128.33333333333331,550,35,50,23.65,73.7,25.2,76.64,26.26,80.12,27.78,86.45,0.0,45
175.0,750,25,50,24.35,71.68,25.34,73.78,25.97,75.87,26.92,78.94,0.0,45
140.0,600,30,50,23.79,72.02,25.12,75.71,26.69,77.51,28.08,83.4,0.0,45
198.33333333333331,850,20,50,24.53,70.18,25.31,72.95,25.96,74.56,27.07,77.63,0.0,45
93.33333333333334,400,40,50,22.71,77.56,24.29,81.53,26.12,86.08,27.74,92.6,0.0,45
140.0,600,40,50,23.85,73.4,24.77,76.39,25.6,78.96,27.06,82.42,0.0,45
186.66666666666669,800,45,50,24.45,71.29,25.05,73.67,25.69,76.03,27.37,81.73,0.0,45
23.333333333333336,100,30,50,17.32,98.88,21.74,110.02,25.6,120.12,48.24,134.2,0.0,45
116.66666666666667,500,40,50,23.16,75.16,24.31,79.63,25.67,83.59,27.24,87.01,0.0,45
35.0,150,40,50,20.22,89.84,22.17,99.96,25.75,111.06,32.06,140.14,0.0,45
58.333333333333336,250,50,50,20.92,84.91,22.68,89.23,25.48,97.3,31.03,112.09,0.0,45
221.66666666666669,950,45,50,24.5,70.75,25.29,73.32,26.19,75.3,26.93,78.55,0.0,45
221.66666666666669,950,30,50,24.56,69.39,25.26,71.97,26.19,74.3,27.53,76.88,0.0,45
140.0,600,35,50,23.83,73.74,25.13,76.58,26.24,79.51,27.27,83.32,0.0,45
210.0,900,25,50,24.49,70.14,25.41,72.46,26.22,74.34,27.53,76.98,0.0,45
186.66666666666669,800,40,50,24.14,72.45,24.93,75.0,26.08,76.15,27.03,77.25,0.0,45
81.66666666666666,350,20,50,22.55,76.38,24.46,81.87,26.04,87.26,29.26,92.36,0.0,45
11.666666666666668,50,40,50,12.78,118.29,17.35,137.36,30.87,165.8,79.96,253.75,1.0,45
11.666666666666668,50,20,50,14.44,112.54,21.91,140.47,33.21,160.77,79.99,215.33,3.0,45
233.33333333333334,1000,35,50,24.73,70.32,25.33,72.58,25.87,74.31,26.44,76.59,0.0,45
58.333333333333336,250,30,50,21.72,83.25,23.88,88.42,26.56,95.63,33.91,102.45,0.0,45
58.333333333333336,250,40,50,21.04,84.41,24.03,89.56,26.45,93.05,30.58,104.57,0.0,45
105.0,450,50,50,22.86,76.82,24.49,81.17,26.06,85.65,27.6,88.25,0.0,45
70.0,300,25,50,22.37,79.17,24.2,87.82,26.17,92.45,30.74,99.43,0.0,45
163.33333333333331,700,20,50,24.23,70.89,25.1,73.88,26.33,76.54,27.24,79.62,0.0,45
186.66666666666669,800,25,50,24.64,69.68,25.52,73.13,25.91,75.86,26.99,80.39,0.0,45
58.333333333333336,250,45,50,20.85,83.82,22.77,91.47,25.32,99.59,28.63,105.64,0.0,45
198.33333333333331,850,30,50,24.41,70.68,25.05,73.14,26.21,75.35,27.52,78.66,0.0,45
186.66666666666669,800,30,50,24.39,70.75,25.39,72.74,25.91,74.83,27.59,78.56,0.0,45
233.33333333333334,1000,50,50,24.44,70.78,25.06,73.04,25.5,74.32,26.73,77.26,0.0,45
81.66666666666666,350,25,50,23.08,76.46,24.62,81.43,26.14,84.84,29.88,97.4,0.0,45
221.66666666666669,950,50,50,24.15,70.45,24.78,72.31,25.59,74.62,26.45,78.31,0.0,45
105.0,450,40,50,22.85,76.4,24.48,80.54,25.74,83.84,26.89,88.8,0.0,45
46.66666666666667,200,25,50,20.48,82.92,23.17,91.62,25.67,99.25,30.83,113.44,0.0,45
93.33333333333334,400,25,50,22.86,76.42,24.85,81.36,25.83,85.29,27.77,90.5,0.0,45
81.66666666666666,350,35,50,22.78,78.17,24.41,82.2,26.78,87.09,29.6,91.92,0.0,45
210.0,900,30,50,24.39,70.7,25.23,72.6,26.21,74.83,26.88,76.7,0.0,45
58.333333333333336,250,20,50,21.55,80.43,24.99,85.81,27.72,92.81,33.08,101.41,0.0,45
58.333333333333336,250,25,50,21.68,80.24,23.41,86.53,25.14,93.76,28.52,99.84,0.0,45
105.0,450,30,50,23.05,75.51,24.61,80.55,26.08,83.98,27.96,87.16,0.0,45
70.0,300,45,50,21.92,81.8,23.68,88.21,24.76,92.84,28.27,101.78,0.0,45
70.0,300,40,50,21.79,80.32,23.94,85.11,27.09,91.14,31.84,97.63,0.0,45
175.0,750,40,50,23.73,72.46,25.18,75.47,26.17,78.55,27.05,82.16,0.0,45
81.66666666666666,350,45,50,22.36,79.26,23.92,83.08,25.28,87.54,28.04,93.13,0.0,45
210.0,900,40,50,24.34,71.24,24.93,73.43,25.57,75.63,26.96,77.73,0.0,45
221.66666666666669,950,20,50,24.63,69.75,25.35,72.05,25.91,73.36,27.44,76.35,0.0,45
35.0,150,50,50,18.48,95.49,21.44,104.37,24.79,112.1,32.95,130.53,0.0,45
186.66666666666669,800,35,50,24.3,71.26,25.1,73.84,25.75,75.57,27.76,79.2,0.0,45
35.0,150,45,50,19.29,95.52,22.75,104.66,26.65,110.8,33.8,119.86,0.0,45
11.666666666666668,50,30,50,12.76,112.18,20.98,130.88,44.89,158.18,79.99,195.52,1.0,45
81.66666666666666,350,40,50,22.89,78.78,24.51,85.25,27.28,89.06,31.04,96.53,0.0,45
175.0,750,20,50,24.6,69.7,25.42,73.12,26.12,75.26,27.09,78.34,0.0,45
233.33333333333334,1000,30,50,24.8,69.03,25.35,71.36,26.07,73.01,27.01,75.26,0.0,45
210.0,900,20,50,24.43,70.05,25.33,72.0,26.01,74.2,26.92,76.37,0.0,45
11.666666666666668,50,45,50,12.17,115.21,17.25,134.95,27.53,157.14,79.98,185.75,1.0,45
23.333333333333336,100,40,50,15.65,101.76,19.38,115.12,24.38,126.18,42.41,145.54,0.0,45
233.33333333333334,1000,20,50,24.51,68.71,25.4,70.8,25.73,72.54,26.55,75.5,0.0,45
23.333333333333336,100,25,50,16.77,102.13,21.18,116.24,27.53,135.0,41.9,153.5,0.0,45
210.0,900,35,50,24.41,71.12,25.1,73.13,25.79,75.33,26.77,77.05,0.0,45
23.333333333333336,100,35,50,17.11,104.5,22.35,113.22,28.86,125.43,46.05,149.75,1.0,45
11.666666666666668,50,35,50,12.45,116.33,16.18,134.67,25.94,156.94,73.77,196.79,3.0,45
35.0,150,25,50,19.51,90.95,23.6,100.43,29.13,108.57,35.56,120.99,0.0,45
23.333333333333336,100,50,50,15.93,104.35,18.56,117.2,24.78,128.1,64.38,141.55,1.0,45
46.66666666666667,200,45,50,20.11,88.04,22.47,95.68,24.96,103.58,32.21,112.29,0.0,45
46.66666666666667,200,40,50,20.15,85.51,23.32,94.8,25.18,102.42,31.56,112.19,0.0,45
175.0,750,50,50,23.88,71.92,25.07,74.11,25.78,76.39,27.93,80.16,0.0,45
23.333333333333336,100,45,50,16.48,99.82,20.43,113.73,27.22,124.39,40.59,144.69,0.0,45
70.0,300,30,50,22.31,81.0,24.21,86.47,26.16,90.57,28.89,96.75,0.0,45
70.0,300,50,50,21.54,82.11,22.75,86.5,25.04,92.49,29.63,96.91,0.0,45
210.0,900,50,50,24.07,71.69,24.84,74.13,25.57,75.91,26.1,79.85,0.0,45
233.33333333333334,1000,40,50,24.7,70.03,25.43,72.89,25.95,74.48,27.18,77.64,0.0,45
46.66666666666667,200,35,50,19.98,87.15,22.06,95.97,24.95,102.42,35.72,108.82,0.0,45
221.66666666666669,950,35,50,24.61,69.99,25.26,71.84,26.05,74.64,28.22,76.14,0.0,45
58.333333333333336,250,35,50,20.87,82.56,22.21,89.58,24.96,94.07,29.59,104.76,0.0,45
151.66666666666669,650,40,50,23.79,73.38,24.66,75.97,25.95,78.07,27.9,80.52,0.0,45
140.0,600,20,50,23.66,71.96,24.79,75.67,26.43,78.06,28.18,83.11,0.0,45
163.33333333333331,700,30,50,24.04,72.42,25.05,75.3,25.82,77.16,27.4,81.58,0.0,45
116.66666666666667,500,35,50,23.31,74.76,24.44,77.77,25.67,82.84,27.61,86.4,0.0,45
128.33333333333331,550,45,50,23.45,73.8,24.64,76.89,25.91,79.76,27.01,83.92,0.0,45
81.66666666666666,350,50,50,22.31,80.46,23.55,85.62,25.51,89.35,28.22,94.55,0.0,45
163.33333333333331,700,40,50,24.21,71.39,24.99,73.9,25.91,77.36,27.63,82.76,0.0,45
105.0,450,35,50,23.16,76.96,24.45,79.68,25.5,85.37,31.01,89.3,0.0,45
128.33333333333331,550,40,50,23.36,75.11,24.76,78.87,26.07,81.6,27.15,84.61,0.0,45
35.0,150,30,50,18.96,88.14,22.27,97.98,26.21,108.42,32.84,119.41,0.0,45
81.66666666666666,350,30,50,22.69,76.31,24.59,81.36,26.02,88.35,28.76,97.1,0.0,45
35.0,150,35,50,18.08,92.37,21.61,102.41,25.59,111.31,32.24,121.5,0.0,45
163.33333333333331,700,45,50,24.03,72.36,24.94,75.46,26.26,77.96,27.46,82.66,0.0,45
35.0,150,20,50,20.21,87.17,23.31,97.56,25.44,106.43,30.7,114.95,0.0,45
23.333333333333336,100,20,50,17.33,96.75,21.29,110.38,27.67,124.39,64.25,134.78,0.0,45
46.66666666666667,200,20,50,21.51,84.07,24.76,94.02,27.25,101.05,35.02,110.73,0.0,45
11.666666666666668,50,25,50,14.48,118.32,22.6,145.41,42.31,182.05,80.0,269.84,2.0,45
175.0,750,35,50,18.18,74.22,19.07,77.56,19.8,80.21,21.14,83.31,0.0,60
46.66666666666667,200,50,50,13.65,95.28,16.06,104.2,18.96,115.07,24.42,118.95,0.0,60
116.66666666666667,500,30,50,17.38,78.0,18.79,81.75,19.82,87.03,20.97,92.5,0.0,60
11.666666666666668,50,50,50,7.84,134.26,10.19,156.78,13.95,178.36,46.1,207.28,0.0,60
163.33333333333331,700,50,50,17.84,75.83,18.43,78.91,19.09,82.87,20.63,87.92,0.0,60
105.0,450,45,50,17.19,81.12,18.06,84.99,19.36,89.76,21.14,100.86,0.0,60
163.33333333333331,700,25,50,18.0,74.88,18.72,77.71,19.31,80.06,19.97,85.01,0.0,60
46.66666666666667,200,30,50,14.94,92.48,17.2,101.97,19.36,107.84,23.43,118.87,0.0,60
128.33333333333331,550,20,50,18.4,75.8,19.13,79.78,19.92,83.27,22.65,89.59,0.0,60
151.66666666666669,650,25,50,17.93,75.01,18.58,78.64,19.59,81.0,20.93,84.32,0.0,60
163.33333333333331,700,35,50,17.77,75.85,18.75,79.86,19.37,82.93,21.4,85.54,0.0,60
198.33333333333331,850,35,50,18.16,73.55,18.81,75.78,19.48,78.22,20.42,82.45,0.0,60
151.66666666666669,650,50,50,17.2,77.72,18.02,80.91,18.87,83.63,20.01,90.59,0.0,60
70.0,300,20,50,16.47,83.26,18.26,88.78,20.78,95.26,24.19,106.29,0.0,60
93.33333333333334,400,50,50,16.24,84.37,17.96,90.05,18.97,96.18,22.91,101.79,0.0,60
140.0,600,50,50,17.34,77.78,18.32,82.41,19.12,85.6,19.87,89.87,0.0,60
198.33333333333331,850,25,50,18.38,72.53,19.04,76.01,19.83,78.4,20.61,82.95,0.0,60
140.0,600,45,50,17.54,76.69,18.49,82.92,19.39,86.23,20.75,90.54,0.0,60
186.66666666666669,800,50,50,17.81,75.6,18.7,79.35,19.25,81.65,20.6,84.14,0.0,60
116.66666666666667,500,20,50,17.46,77.73,18.59,81.82,19.41,87.28,21.07,90.46,0.0,60
105.0,450,25,50,16.99,78.88,18.09,83.06,19.38,87.15,20.94,91.68,0.0,60
151.66666666666669,650,35,50,17.65,76.48,18.62,79.77,19.17,82.62,21.15,91.55,0.0,60
221.66666666666669,950,40,50,18.31,73.02,18.75,76.02,19.79,78.07,21.05,80.06,0.0,60
128.33333333333331,550,50,50,17.58,77.58,18.25,83.6,19.2,87.13,21.99,92.6,0.0,60
116.66666666666667,500,45,50,17.04,80.29,18.27,84.49,19.23,88.28,20.16,95.75,0.0,60
140.0,600,25,50,17.77,76.31,18.76,80.19,19.56,82.87,20.65,86.9,0.0,60
198.33333333333331,850,40,50,18.27,73.66,18.87,77.12,19.47,78.99,21.53,82.55,0.0,60
151.66666666666669,650,20,50,18.16,74.86,19.05,77.69,19.77,82.16,20.74,87.66,0.0,60
186.66666666666669,800,20,50,18.31,73.3,19.11,76.04,19.61,80.06,20.88,83.98,0.0,60
128.33333333333331,550,30,50,17.5,78.36,18.29,82.93,19.12,86.05,21.23,92.1,0.0,60
210.0,900,45,50,18.31,72.95,19.04,75.84,19.44,78.67,20.84,83.09,0.0,60
93.33333333333334,400,45,50,16.45,83.11,17.63,87.7,19.44,92.07,21.0,98.18,0.0,60
93.33333333333334,400,35,50,17.06,80.74,18.26,85.21,19.72,89.84,23.31,94.12,0.0,60
116.66666666666667,500,50,50,16.87,80.33,17.96,84.68,18.96,89.79,20.47,94.75,0.0,60
93.33333333333334,400,20,50,17.57,78.45,18.62,83.88,20.45,88.82,22.43,95.86,0.0,60
93.33333333333334,400,30,50,16.73,79.39,18.37,86.56,19.8,90.83,22.1,98.67,0.0,60
175.0,750,45,50,17.87,76.49,18.67,79.18,19.13,81.5,20.16,86.04,0.0,60
70.0,300,35,50,16.22,85.94,17.46,92.15,20.13,98.68,26.15,108.14,0.0,60
233.33333333333334,1000,45,50,18.22,73.96,18.85,76.35,19.35,78.12,20.02,83.28,0.0,60
198.33333333333331,850,45,50,17.96,74.59,18.92,78.45,19.56,80.89,20.4,84.32,0.0,60
151.66666666666669,650,45,50,17.62,76.69,18.35,79.57,19.11,83.39,21.34,86.06,0.0,60
175.0,750,30,50,17.95,75.78,18.47,78.36,19.4,80.35,20.03,84.14,0.0,60
105.0,450,20,50,17.47,78.41,18.5,83.89,19.58,87.9,21.11,93.79,0.0,60
151.66666666666669,650,30,50,17.75,76.55,18.72,79.3,19.71,81.71,20.26,89.14,0.0,60
221.66666666666669,950,25,50,18.2,73.21,18.79,76.44,19.47,78.3,21.21,82.12,0.0,60
198.33333333333331,850,20,50,18.49,72.41,19.08,75.82,19.56,78.14,20.85,83.4,0.0,60
128.33333333333331,550,35,50,17.48,77.78,18.32,81.53,19.75,85.88,21.36,91.33,0.0,60
140.0,600,40,50,17.51,78.07,18.53,82.51,19.4,85.5,20.79,88.91,0.0,60
233.33333333333334,1000,25,50,18.47,72.9,19.11,74.96,19.61,77.47,20.13,80.48,0.0,60
198.33333333333331,850,50,50,17.94,74.99,18.53,77.0,18.88,79.53,19.98,84.29,0.0,60
175.0,750,25,50,18.11,73.8,18.65,77.61,19.32,80.81,20.62,84.88,0.0,60
140.0,600,35,50,17.57,77.13,18.59,80.35,19.19,84.09,21.41,89.79,0.0,60
221.66666666666669,950,45,50,18.35,72.41,19.16,74.98,19.92,77.28,20.69,81.48,0.0,60
116.66666666666667,500,25,50,17.54,78.34,18.27,82.38,19.48,86.26,20.83,89.23,0.0,60
93.33333333333334,400,40,50,16.43,83.31,17.47,88.35,19.1,94.07,19.6,100.23,0.0,60
140.0,600,30,50,17.56,76.25,18.52,80.09,19.22,83.05,20.88,89.99,0.0,60
23.333333333333336,100,30,50,11.03,109.44,13.74,121.63,19.09,135.62,32.26,159.32,0.0,60
128.33333333333331,550,25,50,17.59,76.96,18.71,80.39,19.7,84.32,20.76,91.17,0.0,60
186.66666666666669,800,45,50,18.04,75.29,18.73,78.62,19.23,82.61,19.79,86.77,0.0,60
58.333333333333336,250,50,50,14.72,92.32,16.66,98.37,18.08,105.62,21.03,115.55,0.0,60
186.66666666666669,800,40,50,18.11,75.71,18.71,78.04,19.32,80.8,20.57,83.81,0.0,60
210.0,900,25,50,18.44,71.81,19.08,75.16,19.74,77.91,20.79,82.85,0.0,60
186.66666666666669,800,30,50,18.17,74.06,18.9,77.26,19.58,80.31,20.51,85.36,0.0,60
105.0,450,40,50,16.64,80.64,18.08,85.68,18.96,91.94,21.13,99.56,0.0,60
233.33333333333334,1000,50,50,18.4,73.39,18.85,75.9,19.43,78.88,20.41,81.59,0.0,60
198.33333333333331,850,30,50,18.36,73.87,19.0,76.93,19.75,79.4,20.56,83.08,0.0,60
186.66666666666669,800,25,50,18.46,72.33,19.17,75.67,19.82,78.22,20.33,83.62,0.0,60
233.33333333333334,1000,35,50,18.25,72.74,18.91,75.12,19.49,77.85,20.91,80.54,0.0,60
116.66666666666667,500,40,50,17.22,79.98,18.14,84.18,19.8,88.74,20.75,93.5,0.0,60
210.0,900,30,50,18.26,73.21,19.08,76.45,19.87,79.49,20.77,83.49,0.0,60
81.66666666666666,350,35,50,16.75,83.74,17.85,91.8,19.24,95.52,22.59,101.85,0.0,60
93.33333333333334,400,25,50,17.01,80.78,18.29,85.66,19.26,91.42,21.64,95.37,0.0,60
58.333333333333336,250,40,50,14.91,91.56,16.34,98.01,18.38,102.91,22.34,116.0,0.0,60
35.0,150,40,50,13.78,103.47,15.78,112.08,19.43,125.52,24.88,137.91,0.0,60
58.333333333333336,250,20,50,15.65,88.68,17.4,93.84,19.24,100.32,22.78,107.07,0.0,60
35.0,150,50,50,13.2,104.35,15.63,113.31,17.97,122.76,27.43,154.29,0.0,60
163.33333333333331,700,20,50,18.37,73.62,19.1,77.31,19.97,79.97,21.21,85.44,0.0,60
175.0,750,40,50,18.03,75.43,18.58,78.5,19.28,80.41,20.48,83.94,0.0,60
81.66666666666666,350,20,50,16.71,82.77,18.14,87.72,20.21,92.15,23.52,98.54,0.0,60
58.333333333333336,250,45,50,15.6,88.22,16.95,95.18,18.75,100.73,23.6,111.63,0.0,60
46.66666666666667,200,25,50,14.97,89.28,17.21,101.55,20.04,110.2,24.91,117.76,0.0,60
11.666666666666668,50,20,50,9.33,124.3,13.88,150.12,20.58,170.31,79.99,215.29,2.0,60
58.333333333333336,250,25,50,15.49,87.58,17.86,94.66,19.91,101.83,22.55,108.65,0.0,60
105.0,450,50,50,16.39,82.0,17.7,87.96,19.07,90.27,20.43,94.78,0.0,60
210.0,900,40,50,18.25,73.6,18.85,76.61,19.63,79.53,20.55,84.59,0.0,60
221.66666666666669,950,30,50,18.41,73.5,18.82,75.62,19.44,78.05,20.35,82.11,0.0,60
70.0,300,25,50,15.74,84.56,17.12,92.59,19.23,97.72,21.44,106.55,0.0,60
58.333333333333336,250,30,50,15.17,88.01,17.11,95.1,18.7,104.23,23.63,112.04,0.0,60
11.666666666666668,50,45,50,8.06,128.8,11.08,151.82,14.84,177.99,25.96,211.67,0.0,60
11.666666666666668,50,40,50,8.02,121.16,11.52,143.73,15.36,165.56,36.4,229.57,1.0,60
221.66666666666669,950,50,50,18.18,74.7,18.7,77.26,19.46,78.7,20.14,81.4,0.0,60
81.66666666666666,350,25,50,16.8,82.64,18.48,88.84,19.48,93.12,23.73,98.93,0.0,60
233.33333333333334,1000,20,50,18.61,72.17,19.29,74.02,19.85,77.05,20.48,79.63,0.0,60
210.0,900,35,50,18.24,73.03,18.8,76.49,19.53,77.9,20.44,82.03,0.0,60
23.333333333333336,100,50,50,10.93,114.3,14.3,129.46,17.06,137.51,24.55,191.16,0.0,60
70.0,300,45,50,15.95,86.45,17.17,93.85,18.67,101.96,22.02,113.36,0.0,60
81.66666666666666,350,45,50,16.11,84.94,17.19,89.63,18.84,94.93,21.55,103.47,0.0,60
186.66666666666669,800,35,50,18.06,74.59,18.63,78.09,19.58,81.56,20.32,88.06,0.0,60
105.0,450,30,50,17.26,80.04,18.26,85.07,19.27,92.14,22.21,95.58,0.0,60
11.666666666666668,50,35,50,7.6,128.95,9.84,148.47,15.16,166.94,40.13,253.16,1.0,60
221.66666666666669,950,20,50,18.57,71.08,19.25,75.36,20.23,76.23,21.18,79.44,0.0,60
23.333333333333336,100,40,50,11.39,111.51,13.38,127.46,17.05,138.75,22.3,156.6,0.0,60
210.0,900,50,50,18.1,74.25,18.62,77.5,19.42,79.28,20.73,82.35,0.0,60
70.0,300,40,50,15.79,85.81,17.21,92.7,19.31,97.42,23.17,107.97,0.0,60
210.0,900,20,50,18.48,71.89,19.13,74.52,19.78,77.38,20.96,81.98,0.0,60
35.0,150,25,50,12.6,99.32,15.37,111.94,18.99,122.04,26.4,138.73,0.0,60
23.333333333333336,100,25,50,11.94,105.94,15.0,121.78,18.68,132.96,30.19,158.83,0.0,60
175.0,750,20,50,18.45,73.8,19.09,77.55,19.56,80.0,20.76,85.92,0.0,60
81.66666666666666,350,40,50,15.92,82.33,17.84,89.55,19.18,92.93,23.53,97.22,0.0,60
23.333333333333336,100,35,50,12.0,110.93,14.32,127.14,17.81,146.54,28.93,177.43,0.0,60
70.0,300,50,50,15.22,88.04,16.47,94.3,17.62,100.44,20.07,110.12,0.0,60
35.0,150,45,50,13.21,102.12,15.93,110.22,18.64,118.55,28.42,139.19,0.0,60
175.0,750,50,50,17.76,74.5,18.54,77.97,19.37,80.15,20.28,87.24,0.0,60
46.66666666666667,200,45,50,14.65,93.28,17.51,103.24,19.63,111.27,24.37,121.85,0.0,60
233.33333333333334,1000,40,50,18.29,73.2,18.91,75.31,19.51,77.52,20.4,80.44,0.0,60
11.666666666666668,50,30,50,8.88,123.31,12.59,144.8,22.06,174.24,64.47,297.92,0.0,60
46.66666666666667,200,40,50,14.57,91.07,16.42,98.88,19.12,108.33,21.8,122.61,0.0,60
70.0,300,30,50,16.07,84.23,17.54,92.61,18.81,100.74,22.4,105.85,0.0,60
23.333333333333336,100,45,50,11.75,104.23,14.05,120.5,18.01,129.23,30.29,144.09,0.0,60
233.33333333333334,1000,30,50,18.48,72.12,19.24,74.44,19.76,75.96,21.57,78.09,0.0,60
46.66666666666667,200,35,50,14.64,93.7,16.63,102.04,19.46,108.91,33.16,121.53,0.0,60
221.66666666666669,950,35,50,18.25,73.16,18.96,76.14,19.62,78.15,20.53,82.25,0.0,60
58.333333333333336,250,35,50,15.45,86.87,17.44,94.94,18.55,103.64,21.34,112.3,0.0,60
151.66666666666669,650,40,50,17.52,76.35,18.27,80.25,19.46,82.99,20.67,89.17,0.0,60
140.0,600,20,50,18.28,75.83,19.08,78.46,19.78,81.76,21.83,88.42,0.0,60
128.33333333333331,550,40,50,17.22,78.76,18.54,82.65,19.41,86.86,20.82,90.39,0.0,60
163.33333333333331,700,30,50,18.01,75.93,18.98,79.53,19.32,82.36,20.48,85.57,0.0,60
116.66666666666667,500,35,50,17.21,80.11,18.4,84.42,19.09,88.28,20.39,93.21,0.0,60
128.33333333333331,550,45,50,17.4,78.51,18.25,82.42,19.27,86.12,20.85,90.94,0.0,60
81.66666666666666,350,50,50,16.2,84.47,17.54,89.54,18.59,94.54,20.89,106.6,0.0,60
105.0,450,35,50,16.95,80.44,18.56,85.18,19.97,89.39,22.19,94.87,0.0,60
163.33333333333331,700,40,50,17.75,76.09,18.48,78.8,19.17,81.63,20.05,89.04,0.0,60
81.66666666666666,350,30,50,16.79,81.93,18.09,87.28,20.19,94.42,23.79,104.93,0.0,60
35.0,150,30,50,14.57,97.31,17.29,108.82,21.42,124.47,28.27,148.53,0.0,60
35.0,150,35,50,13.64,99.45,15.6,109.71,19.22,117.39,21.98,136.6,0.0,60
163.33333333333331,700,45,50,17.55,76.65,18.53,79.4,19.25,81.92,20.6,87.56,0.0,60
35.0,150,20,50,14.95,93.73,17.81,104.8,21.48,115.71,34.08,123.12,0.0,60
23.333333333333336,100,20,50,13.03,108.62,14.87,121.9,18.95,130.76,41.84,158.92,0.0,60
46.66666666666667,200,20,50,15.33,90.71,17.29,100.14,19.02,108.09,23.38,118.38,0.0,60
11.666666666666668,50,50,50,5.85,137.65,7.62,151.31,9.91,174.42,39.14,248.86,0.0,75
163.33333333333331,700,50,50,13.97,79.36,14.76,84.16,15.34,87.84,16.62,91.23,0.0,75
105.0,450,45,50,13.29,85.03,14.37,91.04,15.46,95.35,16.55,107.03,0.0,75
46.66666666666667,200,30,50,11.0,99.52,13.25,107.69,15.31,115.23,18.29,125.0,0.0,75
46.66666666666667,200,50,50,10.41,104.94,11.86,112.18,13.62,121.01,16.34,128.68,0.0,75
116.66666666666667,500,30,50,13.96,81.59,14.56,88.39,15.6,93.1,16.93,99.45,0.0,75
163.33333333333331,700,35,50,14.21,79.08,14.83,82.97,15.8,86.84,17.31,90.97,0.0,75
163.33333333333331,700,25,50,14.1,77.31,14.77,81.74,15.4,86.11,16.28,92.06,0.0,75
175.0,750,35,50,14.25,78.18,14.8,81.64,15.52,84.23,17.22,89.45,0.0,75
70.0,300,20,50,12.76,86.97,14.21,94.92,15.44,100.35,18.31,111.07,0.0,75
11.666666666666668,50,25,50,7.81,128.72,10.81,153.28,17.51,173.52,79.97,276.66,1.0,60
93.33333333333334,400,50,50,12.69,89.11,13.86,94.27,15.18,100.86,16.22,105.4,0.0,75
128.33333333333331,550,20,50,14.33,79.21,15.06,82.98,15.7,86.51,17.1,94.46,0.0,75
105.0,450,25,50,13.42,83.04,14.7,89.61,15.54,94.19,17.3,101.91,0.0,75
151.66666666666669,650,25,50,14.15,79.43,14.96,83.47,15.73,86.73,16.32,90.52,0.0,75
198.33333333333331,850,25,50,14.36,76.35,15.04,80.19,15.91,84.51,17.32,87.02,0.0,75
198.33333333333331,850,35,50,14.57,76.1,15.11,80.53,15.85,83.47,17.21,85.65,0.0,75
128.33333333333331,550,30,50,13.69,82.53,14.6,86.45,15.62,90.02,16.63,96.31,0.0,75
186.66666666666669,800,50,50,14.23,78.34,14.85,81.98,15.48,85.06,16.05,89.77,0.0,75
210.0,900,45,50,14.44,76.58,15.08,79.94,15.5,82.8,16.09,87.42,0.0,75
128.33333333333331,550,50,50,13.37,82.96,14.16,87.48,15.1,90.93,16.68,96.71,0.0,75
116.66666666666667,500,45,50,13.22,86.05,14.17,89.94,15.4,97.0,16.72,99.78,0.0,75
151.66666666666669,650,50,50,13.81,81.33,14.38,85.74,15.14,88.89,16.65,94.35,0.0,75
198.33333333333331,850,20,50,14.52,75.2,15.13,78.29,15.73,81.62,16.98,84.31,0.0,75
140.0,600,45,50,13.75,81.77,14.56,85.24,15.16,89.25,16.41,97.21,0.0,75
140.0,600,50,50,13.41,82.86,14.08,86.53,14.69,89.24,16.23,92.35,0.0,75
93.33333333333334,400,45,50,12.65,88.44,13.81,94.12,15.25,98.7,16.28,103.7,0.0,75
198.33333333333331,850,45,50,14.11,78.01,14.66,80.86,15.13,82.79,15.89,85.17,0.0,75
151.66666666666669,650,35,50,13.92,80.63,14.62,84.08,15.59,87.7,17.0,94.5,0.0,75
186.66666666666669,800,20,50,14.54,76.93,15.05,79.99,15.59,81.98,16.56,86.2,0.0,75
151.66666666666669,650,45,50,13.7,80.77,14.51,84.43,15.58,87.19,16.65,91.81,0.0,75
151.66666666666669,650,20,50,14.09,79.09,14.59,82.7,15.04,86.46,16.29,89.33,0.0,75
93.33333333333334,400,35,50,13.32,84.46,14.36,92.05,15.18,96.66,16.52,103.31,0.0,75
198.33333333333331,850,40,50,14.48,77.83,15.13,80.29,15.75,83.01,16.33,88.33,0.0,75
116.66666666666667,500,20,50,13.87,79.87,14.86,86.35,15.58,89.15,17.7,93.75,0.0,75
175.0,750,45,50,14.44,77.09,15.01,81.66,15.55,85.4,17.38,88.43,0.0,75
140.0,600,25,50,13.99,80.58,14.79,82.95,15.55,87.31,16.29,91.16,0.0,75
221.66666666666669,950,25,50,14.79,74.52,15.4,77.47,15.96,79.99,16.61,83.26,0.0,75
233.33333333333334,1000,45,50,14.53,75.79,15.01,78.52,15.5,80.24,16.22,85.25,0.0,75
128.33333333333331,550,35,50,13.93,80.44,14.69,84.04,15.4,87.62,16.49,93.14,0.0,75
221.66666666666669,950,45,50,14.4,76.65,14.91,79.76,15.26,82.13,15.9,86.08,0.0,75
58.333333333333336,250,50,50,11.58,97.04,12.83,105.59,14.92,112.15,16.79,119.4,0.0,75
58.333333333333336,250,20,50,11.94,93.03,13.35,100.35,14.11,107.85,16.79,118.92,0.0,75
116.66666666666667,500,50,50,13.15,86.09,13.65,90.17,14.5,93.01,16.27,101.22,0.0,75
221.66666666666669,950,40,50,14.55,76.41,15.0,79.11,15.55,81.22,16.58,83.81,0.0,75
93.33333333333334,400,20,50,13.46,83.42,14.86,91.15,15.47,96.54,17.37,105.34,0.0,75
186.66666666666669,800,25,50,14.51,77.8,15.03,80.12,15.6,83.58,16.7,88.16,0.0,75
186.66666666666669,800,40,50,14.42,78.31,14.81,80.35,15.38,82.9,16.47,87.19,0.0,75
140.0,600,35,50,14.03,80.8,14.65,84.48,15.63,86.93,16.65,95.27,0.0,75
210.0,900,30,50,14.34,76.48,14.86,80.15,15.54,82.52,16.23,86.18,0.0,75
186.66666666666669,800,30,50,14.26,77.7,14.9,79.77,15.25,83.43,16.66,85.8,0.0,75
198.33333333333331,850,30,50,14.41,76.53,14.96,78.91,15.69,82.22,16.96,84.48,0.0,75
233.33333333333334,1000,25,50,14.91,75.54,15.41,77.47,15.75,79.75,16.96,82.85,0.0,75
198.33333333333331,850,50,50,14.25,78.1,14.63,81.87,15.29,85.07,16.04,89.89,0.0,75
186.66666666666669,800,45,50,14.0,79.1,14.55,82.35,15.26,85.37,16.07,89.89,0.0,75
105.0,450,50,50,13.12,86.3,13.89,91.2,14.51,95.51,16.57,100.6,0.0,75
93.33333333333334,400,30,50,13.4,85.28,14.6,91.99,16.04,95.21,16.95,107.06,0.0,75
151.66666666666669,650,30,50,14.07,80.12,14.69,83.59,15.41,87.68,17.45,89.97,0.0,75
105.0,450,20,50,13.7,83.15,15.09,88.42,16.14,93.23,17.27,101.07,0.0,75
175.0,750,30,50,14.27,79.17,14.8,82.46,15.19,84.59,16.71,87.89,0.0,75
140.0,600,40,50,13.82,82.21,14.47,85.14,15.15,88.33,16.69,93.16,0.0,75
210.0,900,40,50,14.44,77.58,15.14,80.25,15.79,82.67,16.84,86.74,0.0,75
70.0,300,35,50,12.46,87.41,14.16,95.77,15.4,102.4,20.13,110.01,0.0,75
140.0,600,30,50,13.85,80.67,14.73,85.12,15.41,88.9,16.46,91.99,0.0,75
23.333333333333336,100,30,50,8.49,121.26,11.19,134.38,14.65,147.72,24.36,172.8,0.0,75
175.0,750,25,50,14.35,77.13,15.09,80.16,15.71,82.93,16.63,90.08,0.0,75
116.66666666666667,500,25,50,13.56,82.89,14.43,87.26,15.64,90.73,18.44,96.3,0.0,75
93.33333333333334,400,40,50,13.06,84.91,14.07,89.57,14.96,98.57,17.44,105.66,0.0,75
175.0,750,40,50,14.23,78.51,14.75,82.53,15.39,85.25,16.03,89.84,0.0,75
70.0,300,25,50,12.15,90.63,13.78,96.28,15.93,103.61,17.43,118.5,0.0,75
163.33333333333331,700,20,50,14.22,77.96,15.0,81.92,15.62,84.84,16.48,89.98,0.0,75
35.0,150,50,50,10.09,111.58,11.82,120.34,13.86,129.75,16.17,142.99,0.0,75
93.33333333333334,400,25,50,12.84,86.06,13.89,92.74,14.69,97.72,16.9,104.66,0.0,75
128.33333333333331,550,25,50,14.1,80.11,15.16,84.95,15.91,88.19,17.7,93.24,0.0,75
221.66666666666669,950,30,50,14.6,75.29,15.12,77.51,15.51,80.7,16.54,82.55,0.0,75
81.66666666666666,350,20,50,13.11,87.58,14.0,93.98,15.63,97.44,17.72,103.0,0.0,75
233.33333333333334,1000,50,50,14.52,76.11,15.13,79.25,15.35,81.53,16.13,84.5,0.0,75
233.33333333333334,1000,20,50,15.0,74.28,15.33,76.95,15.82,79.44,16.46,84.22,0.0,75
105.0,450,40,50,13.31,86.33,14.25,92.29,15.02,94.7,16.48,102.73,0.0,75
210.0,900,50,50,14.14,77.74,14.83,80.44,15.31,82.94,16.38,84.96,0.0,75
116.66666666666667,500,40,50,13.53,85.07,14.15,89.12,15.1,95.03,16.47,99.82,0.0,75
58.333333333333336,250,25,50,12.16,89.98,13.23,99.11,15.35,108.84,20.62,122.13,0.0,75
70.0,300,50,50,12.51,95.31,14.13,101.31,14.99,108.56,17.76,121.17,0.0,75
58.333333333333336,250,45,50,11.59,97.74,13.2,105.12,14.12,111.6,19.72,123.64,0.0,75
81.66666666666666,350,35,50,12.92,87.43,13.97,94.94,15.42,100.31,18.31,109.28,0.0,75
210.0,900,25,50,14.73,75.59,15.33,78.65,15.95,81.14,16.78,86.57,0.0,75
186.66666666666669,800,35,50,14.23,78.58,14.83,81.42,15.39,84.17,16.31,88.27,0.0,75
233.33333333333334,1000,35,50,14.61,75.56,15.12,78.5,15.59,80.84,16.23,83.94,0.0,75
46.66666666666667,200,25,50,11.3,96.35,13.25,104.7,15.13,111.15,21.0,125.1,0.0,75
11.666666666666668,50,40,50,5.54,140.61,7.18,158.37,9.75,188.16,38.31,256.2,1.0,75
210.0,900,20,50,14.71,75.16,15.32,78.03,15.83,80.25,16.22,82.45,0.0,75
11.666666666666668,50,20,50,6.45,131.69,9.08,151.31,13.0,177.79,57.67,255.9,1.0,75
35.0,150,40,50,10.05,108.21,12.27,121.29,13.6,130.65,17.05,140.04,0.0,75
11.666666666666668,50,45,50,5.69,138.84,7.53,160.9,11.46,188.56,41.22,278.68,0.0,75
58.333333333333336,250,30,50,12.17,94.48,13.35,100.36,14.77,108.44,18.55,124.09,0.0,75
23.333333333333336,100,40,50,8.21,117.85,10.47,136.85,12.12,153.63,31.3,179.39,1.0,75
58.333333333333336,250,40,50,11.45,96.34,13.02,103.68,15.01,112.9,17.67,130.02,0.0,75
221.66666666666669,950,50,50,14.31,76.58,14.8,79.73,15.3,82.98,16.15,85.18,0.0,75
11.666666666666668,50,35,50,5.41,138.34,7.56,156.06,9.85,175.55,21.54,264.17,0.0,75
81.66666666666666,350,25,50,12.69,87.41,14.13,95.38,15.12,99.3,19.94,110.94,0.0,75
46.66666666666667,200,45,50,10.65,104.73,12.01,113.3,13.31,120.49,16.53,141.62,0.0,75
23.333333333333336,100,25,50,8.66,114.4,11.01,126.54,16.05,143.11,44.47,161.55,1.0,75
175.0,750,50,50,13.97,78.57,14.74,83.04,15.36,86.66,16.13,93.08,0.0,75
210.0,900,35,50,14.6,76.88,15.16,79.94,15.73,83.47,16.09,85.92,0.0,75
23.333333333333336,100,50,50,7.75,118.78,9.8,134.97,11.01,151.84,18.91,185.06,0.0,75
70.0,300,45,50,12.04,94.36,13.18,101.27,14.76,106.31,18.37,121.7,0.0,75
70.0,300,40,50,12.41,91.36,13.67,96.49,14.92,105.91,17.28,113.77,0.0,75
81.66666666666666,350,45,50,12.69,89.51,13.8,96.74,15.06,102.1,17.5,111.45,0.0,75
105.0,450,30,50,13.47,82.75,14.41,90.54,15.35,95.03,17.64,103.08,0.0,75
23.333333333333336,100,35,50,8.61,120.39,10.83,131.97,13.16,145.46,19.37,163.66,0.0,75
35.0,150,45,50,9.75,106.82,11.71,121.14,12.86,131.04,22.44,143.7,0.0,75
221.66666666666669,950,35,50,14.59,76.49,15.04,79.98,15.44,81.87,15.93,84.0,0.0,75
81.66666666666666,350,40,50,12.57,91.84,13.52,97.39,14.46,101.64,16.86,106.82,0.0,75
221.66666666666669,950,20,50,14.77,75.18,15.34,78.26,15.83,80.75,16.66,84.02,0.0,75
175.0,750,20,50,14.41,76.08,15.04,79.67,15.65,84.15,16.57,88.09,0.0,75
35.0,150,25,50,10.33,106.11,12.42,116.8,16.31,124.69,38.95,138.01,0.0,75
23.333333333333336,100,45,50,8.39,120.62,10.01,133.34,12.99,154.98,20.94,182.22,0.0,75
46.66666666666667,200,35,50,11.19,101.5,13.16,110.36,14.51,119.6,17.22,128.13,0.0,75
11.666666666666668,50,30,50,6.3,144.3,7.66,166.6,12.65,200.72,36.41,245.88,0.0,75
233.33333333333334,1000,40,50,14.52,75.55,15.08,77.72,15.53,80.89,16.11,84.79,0.0,75
233.33333333333334,1000,30,50,14.68,75.7,15.26,78.59,15.78,80.5,16.58,83.85,0.0,75
70.0,300,30,50,12.52,91.72,13.74,98.46,14.77,105.56,19.13,116.05,0.0,75
58.333333333333336,250,35,50,11.71,94.71,13.26,101.9,14.09,110.96,15.86,117.81,0.0,75
46.66666666666667,200,40,50,11.64,101.31,12.83,110.59,14.84,117.12,18.55,136.41,0.0,75
128.33333333333331,550,40,50,13.88,82.97,14.71,87.61,15.47,90.44,17.01,98.21,0.0,75
151.66666666666669,650,40,50,13.83,80.8,14.51,84.77,15.23,87.96,16.54,91.9,0.0,75
140.0,600,20,50,14.21,78.52,15.16,81.67,16.01,84.66,17.07,92.98,0.0,75
163.33333333333331,700,30,50,14.17,76.98,15.01,81.23,15.45,85.09,16.17,88.71,0.0,75
128.33333333333331,550,45,50,13.65,82.1,14.29,86.13,15.72,89.73,16.88,96.89,0.0,75
116.66666666666667,500,35,50,13.49,83.06,14.34,88.27,15.47,91.81,17.25,101.33,0.0,75
81.66666666666666,350,50,50,13.05,89.83,13.95,95.76,14.79,101.23,17.84,112.18,0.0,75
81.66666666666666,350,30,50,12.68,88.83,14.07,95.41,15.51,101.1,17.81,109.46,0.0,75
105.0,450,35,50,13.21,85.35,14.24,90.45,15.06,93.71,16.27,103.02,0.0,75
35.0,150,30,50,10.69,102.44,13.2,113.57,15.6,129.66,19.71,158.61,0.0,75
163.33333333333331,700,40,50,14.29,78.06,14.95,83.03,15.59,86.37,16.75,88.81,0.0,75
163.33333333333331,700,45,50,14.09,80.3,14.65,84.31,15.44,87.24,16.19,92.44,0.0,75
35.0,150,35,50,10.33,111.06,11.78,123.06,13.24,131.22,17.24,143.16,0.0,75
23.333333333333336,100,20,50,9.18,112.57,11.08,128.37,12.83,147.46,20.96,173.92,0.0,75
35.0,150,20,50,10.33,105.07,12.08,119.06,15.7,126.6,19.51,154.82,0.0,75
105.0,450,45,50,10.67,90.38,11.48,95.24,12.48,99.36,14.29,107.37,0.0,90
11.666666666666668,50,50,50,4.45,146.51,5.96,171.86,7.72,192.72,31.76,339.25,0.0,90
46.66666666666667,200,30,50,8.7,105.03,10.3,113.34,12.4,120.39,19.84,132.52,0.0,90
163.33333333333331,700,50,50,11.45,83.72,12.04,87.22,12.71,90.16,14.29,93.94,0.0,90
128.33333333333331,550,20,50,11.68,83.67,12.62,88.16,13.29,93.15,14.67,99.46,0.0,90
93.33333333333334,400,50,50,10.32,91.72,11.14,97.53,11.96,103.65,13.64,107.01,0.0,90
46.66666666666667,200,20,50,12.23,93.18,13.99,105.94,15.84,112.52,21.71,137.49,0.0,75
46.66666666666667,200,50,50,8.58,110.8,9.41,120.98,11.24,128.44,13.85,144.14,0.0,90
116.66666666666667,500,30,50,11.19,85.79,12.07,92.87,12.89,96.93,14.08,105.05,0.0,90
163.33333333333331,700,25,50,11.66,82.02,12.31,86.19,13.01,89.63,13.8,94.15,0.0,90
70.0,300,20,50,10.5,92.33,11.83,100.76,13.11,107.58,15.81,119.89,0.0,90
210.0,900,45,50,11.96,78.87,12.48,83.59,12.82,86.15,13.72,91.17,0.0,90
186.66666666666669,800,50,50,11.54,81.57,12.25,85.6,12.8,89.09,13.8,93.71,0.0,90
175.0,750,35,50,11.65,80.72,12.13,85.25,12.65,88.41,13.61,94.5,0.0,90
198.33333333333331,850,35,50,11.97,79.07,12.48,83.24,13.11,85.71,14.1,88.57,0.0,90
198.33333333333331,850,25,50,12.09,79.22,12.72,82.89,13.19,85.06,14.19,88.62,0.0,90
221.66666666666669,950,45,50,11.99,78.49,12.33,82.85,12.86,85.35,13.51,90.01,0.0,90
58.333333333333336,250,50,50,9.31,102.24,10.57,109.89,12.05,121.45,13.75,131.44,0.0,90
186.66666666666669,800,25,50,11.99,78.6,12.66,83.88,13.32,86.68,14.05,90.59,0.0,90
116.66666666666667,500,20,50,11.43,84.52,12.23,87.76,13.29,91.72,15.37,98.13,0.0,90
128.33333333333331,550,35,50,11.36,85.39,12.04,89.9,13.23,94.44,14.52,100.05,0.0,90
175.0,750,45,50,11.54,83.5,12.25,87.2,12.82,89.88,13.44,92.75,0.0,90
93.33333333333334,400,45,50,10.54,92.31,11.28,97.22,12.19,101.43,13.98,112.12,0.0,90
186.66666666666669,800,30,50,11.8,80.61,12.47,83.88,13.16,87.37,13.51,91.03,0.0,90
151.66666666666669,650,25,50,11.79,82.1,12.44,85.78,12.86,91.97,14.31,95.79,0.0,90
186.66666666666669,800,45,50,11.78,81.77,12.25,85.46,12.64,88.65,13.58,92.21,0.0,90
58.333333333333336,250,20,50,9.93,97.04,11.26,103.14,12.5,110.66,14.61,127.39,0.0,90
151.66666666666669,650,45,50,11.44,82.95,11.89,87.57,12.93,90.71,14.51,103.17,0.0,90
198.33333333333331,850,20,50,12.06,78.46,12.54,81.63,13.11,84.27,14.14,87.9,0.0,90
198.33333333333331,850,50,50,11.82,81.25,12.47,83.51,12.84,86.9,13.98,90.98,0.0,90
35.0,150,50,50,7.65,120.07,9.05,130.06,11.08,144.05,13.88,163.12,0.0,90
105.0,450,25,50,11.03,85.81,11.75,90.62,12.7,95.76,14.45,102.92,0.0,90
198.33333333333331,850,45,50,11.95,80.37,12.48,83.98,12.93,87.57,13.99,91.6,0.0,90
128.33333333333331,550,50,50,10.96,87.56,11.56,91.42,12.34,96.48,13.2,102.43,0.0,90
116.66666666666667,500,45,50,11.09,87.8,11.59,93.75,12.2,98.48,13.8,103.53,0.0,90
186.66666666666669,800,20,50,12.17,79.71,12.69,83.02,13.11,85.05,13.73,90.23,0.0,90
198.33333333333331,850,30,50,11.96,79.56,12.43,83.09,12.9,85.58,13.61,87.92,0.0,90
128.33333333333331,550,30,50,11.3,84.39,12.09,89.48,12.8,94.42,14.93,100.52,0.0,90
233.33333333333334,1000,45,50,11.93,78.58,12.48,82.23,12.85,84.19,13.5,88.23,0.0,90
151.66666666666669,650,50,50,11.62,84.85,12.14,88.48,12.96,92.94,14.38,97.74,0.0,90
221.66666666666669,950,40,50,11.98,79.32,12.47,82.16,12.96,83.71,13.8,86.81,0.0,90
11.666666666666668,50,25,50,6.21,129.37,9.42,144.73,13.21,170.82,22.15,190.64,1.0,75
210.0,900,30,50,11.99,79.36,12.65,82.61,13.24,85.0,13.58,89.29,0.0,90
140.0,600,45,50,11.16,85.03,12.01,88.68,12.62,91.93,13.91,101.9,0.0,90
70.0,300,35,50,9.8,96.01,11.12,102.19,12.33,106.7,14.81,121.18,0.0,90
151.66666666666669,650,20,50,11.69,81.74,12.68,87.49,13.28,91.42,14.02,95.38,0.0,90
221.66666666666669,950,25,50,12.11,77.91,12.53,80.97,13.06,83.87,13.83,87.05,0.0,90
186.66666666666669,800,40,50,11.86,80.35,12.34,84.62,12.94,87.11,13.43,91.75,0.0,90
151.66666666666669,650,35,50,11.48,83.6,12.27,87.23,12.98,91.37,14.34,95.3,0.0,90
140.0,600,50,50,11.37,85.37,11.78,89.49,12.24,93.53,12.74,97.95,0.0,90
93.33333333333334,400,35,50,10.66,90.71,11.5,96.95,12.64,100.43,15.55,108.4,0.0,90
151.66666666666669,650,30,50,11.52,83.61,12.34,86.76,12.88,90.0,13.47,96.04,0.0,90
93.33333333333334,400,25,50,11.03,88.94,11.88,95.5,12.91,101.29,14.37,110.42,0.0,90
140.0,600,25,50,11.54,82.72,12.18,87.7,13.16,92.32,14.96,94.92,0.0,90
105.0,450,50,50,10.88,89.54,11.7,95.66,12.34,100.99,13.82,105.74,0.0,90
70.0,300,25,50,9.97,95.86,10.9,104.96,12.12,108.67,15.0,121.35,0.0,90
175.0,750,30,50,11.79,81.27,12.43,84.26,12.9,86.58,13.9,91.45,0.0,90
198.33333333333331,850,40,50,11.91,79.72,12.42,82.67,12.93,85.0,13.86,89.54,0.0,90
233.33333333333334,1000,20,50,12.3,76.63,12.72,79.17,13.17,81.5,13.43,84.48,0.0,90
140.0,600,35,50,11.29,84.03,12.08,88.09,13.21,91.94,14.8,97.44,0.0,90
93.33333333333334,400,20,50,10.9,88.21,11.89,93.35,12.86,99.29,15.48,110.4,0.0,90
116.66666666666667,500,50,50,10.96,88.87,11.42,94.57,12.36,100.69,13.72,108.47,0.0,90
210.0,900,50,50,11.87,80.2,12.33,83.6,12.69,86.91,13.57,95.47,0.0,90
163.33333333333331,700,35,50,11.65,81.74,12.2,86.0,12.86,88.65,14.51,94.72,0.0,90
93.33333333333334,400,30,50,10.9,90.62,11.81,95.76,12.2,100.81,13.09,107.53,0.0,90
23.333333333333336,100,30,50,6.72,117.29,8.81,134.7,10.39,147.9,16.15,187.06,0.0,90
175.0,750,40,50,11.44,82.59,12.16,85.22,12.58,88.13,13.48,90.99,0.0,90
81.66666666666666,350,20,50,10.56,89.34,11.58,93.95,12.96,99.67,14.28,109.35,0.0,90
105.0,450,40,50,10.71,90.14,11.7,96.38,12.56,98.96,13.27,104.02,0.0,90
58.333333333333336,250,30,50,9.42,99.34,10.69,107.54,12.07,114.58,13.95,127.6,0.0,90
233.33333333333334,1000,25,50,12.27,76.69,12.83,80.0,13.24,82.74,13.61,86.54,0.0,90
58.333333333333336,250,25,50,9.61,98.56,10.85,107.84,11.63,116.1,14.25,125.44,0.0,90
81.66666666666666,350,35,50,10.23,93.05,11.02,100.45,12.51,104.23,14.3,109.12,0.0,90
105.0,450,20,50,11.25,85.19,12.09,91.07,12.94,96.65,14.73,104.59,0.0,90
140.0,600,30,50,11.53,82.96,11.97,87.36,12.54,90.94,13.9,95.65,0.0,90
163.33333333333331,700,20,50,12.04,79.68,12.6,83.83,13.24,87.26,14.43,94.69,0.0,90
11.666666666666668,50,35,50,4.65,144.46,5.72,163.25,7.69,194.5,15.1,304.7,0.0,90
11.666666666666668,50,45,50,4.18,140.28,6.34,161.91,10.54,192.38,14.98,302.56,0.0,90
70.0,300,50,50,9.41,98.73,10.87,106.7,12.16,112.09,13.8,117.54,0.0,90
186.66666666666669,800,35,50,11.74,81.88,12.37,84.84,12.91,87.46,13.52,90.92,0.0,90
175.0,750,25,50,11.88,80.56,12.45,84.14,13.04,86.99,13.65,90.46,0.0,90
116.66666666666667,500,40,50,10.88,87.42,11.76,92.12,12.25,96.65,13.41,106.49,0.0,90
175.0,750,50,50,11.56,82.7,11.96,86.25,12.63,89.47,13.39,93.88,0.0,90
233.33333333333334,1000,35,50,12.23,78.21,12.59,80.95,12.89,82.73,13.59,85.98,0.0,90
221.66666666666669,950,35,50,12.03,78.01,12.52,81.93,13.07,84.35,13.57,86.05,0.0,90
221.66666666666669,950,30,50,12.04,78.66,12.48,81.62,13.06,84.11,13.79,89.53,0.0,90
58.333333333333336,250,40,50,9.78,100.79,10.63,107.99,11.69,117.53,15.16,125.18,0.0,90
140.0,600,40,50,11.25,85.23,11.81,89.78,12.37,94.16,13.59,99.09,0.0,90
46.66666666666667,200,25,50,9.68,103.39,10.68,113.51,12.34,122.26,14.1,137.73,0.0,90
93.33333333333334,400,40,50,10.45,91.41,11.37,99.1,12.48,104.28,14.03,109.6,0.0,90
11.666666666666668,50,20,50,4.77,135.17,6.93,156.28,13.55,205.22,68.53,261.98,2.0,90
58.333333333333336,250,45,50,9.42,102.42,10.55,110.58,12.46,116.78,14.67,126.3,0.0,90
35.0,150,40,50,7.91,112.4,9.39,122.46,10.72,131.02,13.21,146.84,0.0,90
210.0,900,20,50,12.18,78.18,12.73,81.84,13.08,84.15,13.84,87.83,0.0,90
233.33333333333334,1000,50,50,12.0,79.87,12.44,82.12,12.99,85.14,13.81,89.22,0.0,90
81.66666666666666,350,25,50,10.6,92.44,11.55,100.72,12.94,106.25,15.02,111.71,0.0,90
221.66666666666669,950,50,50,11.92,79.18,12.46,82.51,12.88,85.87,13.85,92.3,0.0,90
11.666666666666668,50,40,50,4.14,137.73,5.66,161.45,7.28,204.29,12.07,235.69,0.0,90
23.333333333333336,100,40,50,6.53,125.36,8.37,139.97,11.37,159.0,26.13,233.11,0.0,90
116.66666666666667,500,25,50,11.29,85.07,12.01,90.76,13.01,97.23,14.0,107.48,0.0,90
128.33333333333331,550,25,50,11.45,84.4,12.05,88.74,12.75,92.18,14.19,96.26,0.0,90
23.333333333333336,100,25,50,7.21,122.22,8.81,145.12,10.94,156.16,16.8,177.24,0.0,90
210.0,900,25,50,12.19,78.46,12.6,81.9,12.99,84.85,13.69,90.05,0.0,90
210.0,900,35,50,11.98,78.76,12.42,82.49,12.83,85.33,13.4,90.53,0.0,90
46.66666666666667,200,45,50,8.66,106.49,9.82,114.33,11.02,123.19,14.82,135.33,0.0,90
81.66666666666666,350,40,50,10.17,95.58,11.01,99.84,12.59,106.15,14.47,114.93,0.0,90
70.0,300,45,50,9.89,98.77,10.78,105.37,12.0,112.55,15.31,118.07,0.0,90
81.66666666666666,350,45,50,10.37,93.7,11.32,100.55,12.4,107.91,14.09,116.77,0.0,90
175.0,750,20,50,12.1,79.43,12.86,82.9,13.71,85.4,15.04,91.05,0.0,90
23.333333333333336,100,35,50,7.03,126.56,8.45,137.7,11.27,152.04,16.11,180.92,0.0,90
221.66666666666669,950,20,50,12.23,77.55,12.61,80.91,13.08,84.19,13.8,86.8,0.0,90
70.0,300,40,50,9.46,97.28,10.7,103.47,12.06,110.44,15.84,118.43,0.0,90
233.33333333333334,1000,40,50,12.0,78.96,12.63,81.96,13.2,85.0,13.88,89.68,0.0,90
210.0,900,40,50,11.96,79.72,12.47,83.03,12.8,86.01,13.54,89.24,0.0,90
23.333333333333336,100,50,50,5.99,129.13,7.94,146.99,10.94,158.71,30.03,194.71,0.0,90
46.66666666666667,200,35,50,9.04,106.08,10.62,116.61,11.62,128.25,17.74,136.57,0.0,90
11.666666666666668,50,30,50,4.52,144.06,6.34,168.74,8.7,193.52,28.87,311.12,0.0,90
105.0,450,30,50,11.07,86.93,11.91,91.86,13.02,97.28,15.18,103.43,0.0,90
233.33333333333334,1000,30,50,12.23,77.86,12.6,80.67,12.95,82.85,13.71,84.95,0.0,90
35.0,150,45,50,7.67,115.56,9.43,125.4,11.49,135.5,13.77,151.97,0.0,90
23.333333333333336,100,45,50,6.15,129.26,8.22,146.14,10.27,156.58,21.55,199.47,0.0,90
35.0,150,25,50,8.08,114.11,10.01,125.82,12.37,136.29,15.16,150.87,0.0,90
70.0,300,30,50,10.21,96.54,11.51,103.66,13.17,110.68,15.1,121.79,0.0,90
58.333333333333336,250,35,50,9.41,100.11,10.53,108.28,11.38,115.43,13.11,125.33,0.0,90
46.66666666666667,200,40,50,8.79,109.43,10.1,117.0,11.38,126.84,13.84,141.94,0.0,90
128.33333333333331,550,40,50,11.18,85.97,11.89,91.18,12.73,95.96,13.56,100.8,0.0,90
151.66666666666669,650,40,50,11.66,82.19,12.31,87.13,12.71,89.56,14.18,96.92,0.0,90
128.33333333333331,550,45,50,11.13,88.1,11.75,91.72,12.32,95.07,15.09,101.91,0.0,90
163.33333333333331,700,30,50,11.86,80.49,12.46,84.49,13.0,89.08,13.6,93.92,0.0,90
140.0,600,20,50,11.65,81.19,12.31,87.02,12.97,94.0,14.4,98.38,0.0,90
116.66666666666667,500,35,50,11.4,86.11,12.3,93.4,13.22,98.2,14.26,109.62,0.0,90
81.66666666666666,350,30,50,10.6,92.97,11.44,100.92,12.52,105.81,14.66,114.55,0.0,90
105.0,450,35,50,10.86,90.13,11.61,95.57,12.57,99.33,13.52,105.22,0.0,90
105.0,450,45,50,8.16,96.63,8.78,103.24,9.62,106.81,11.08,110.98,0.0,115
81.66666666666666,350,50,50,10.23,94.38,11.12,100.12,12.17,104.2,14.09,121.12,0.0,90
128.33333333333331,550,20,50,8.64,87.79,9.33,93.36,10.04,98.98,11.74,105.36,0.0,115
46.66666666666667,200,50,50,6.07,119.49,6.98,126.87,7.67,137.51,10.56,155.35,0.0,115
46.66666666666667,200,30,50,6.38,112.25,7.53,122.42,8.91,132.16,13.89,155.95,0.0,115
11.666666666666668,50,50,50,2.66,153.12,3.23,177.98,4.37,196.56,8.69,239.66,0.0,115
70.0,300,20,50,7.63,99.83,8.45,108.56,10.5,115.76,11.56,128.36,0.0,115
163.33333333333331,700,40,50,11.64,84.19,12.41,87.62,12.87,91.74,13.44,94.76,0.0,90
163.33333333333331,700,50,50,8.87,87.2,9.38,89.76,9.64,95.2,10.62,101.99,0.0,115
163.33333333333331,700,25,50,9.19,85.45,9.63,90.11,10.22,95.07,11.16,97.26,0.0,115
116.66666666666667,500,30,50,8.19,94.34,8.76,99.78,9.63,105.4,10.31,109.83,0.0,115
93.33333333333334,400,50,50,7.79,98.7,8.58,104.8,9.47,112.09,10.53,122.82,0.0,115
163.33333333333331,700,45,50,11.5,83.87,11.96,87.92,12.58,94.24,13.58,97.91,0.0,90
93.33333333333334,400,45,50,7.97,97.09,8.64,105.07,9.22,109.96,10.93,115.43,0.0,115
35.0,150,30,50,7.96,116.52,9.55,125.65,11.47,137.8,15.09,146.39,0.0,90
186.66666666666669,800,50,50,9.15,85.12,9.57,90.37,9.87,94.32,10.5,98.32,0.0,115
175.0,750,45,50,8.94,87.43,9.25,92.29,9.71,95.59,10.63,98.93,0.0,115
198.33333333333331,850,35,50,9.23,84.12,9.71,88.19,10.07,92.29,10.98,97.33,0.0,115
198.33333333333331,850,25,50,9.31,83.06,10.0,86.73,10.38,91.8,11.4,95.76,0.0,115
35.0,150,35,50,7.99,115.64,9.56,126.16,12.35,143.95,19.76,179.35,0.0,90
23.333333333333336,100,20,50,7.19,122.82,9.94,136.4,12.57,157.98,24.76,187.33,0.0,90
221.66666666666669,950,45,50,9.24,83.36,9.62,85.9,10.01,89.3,11.01,93.56,0.0,115
116.66666666666667,500,20,50,8.72,89.35,9.45,95.85,10.32,100.03,11.48,107.99,0.0,115
35.0,150,20,50,8.77,110.93,10.19,122.76,13.1,134.56,22.12,157.23,0.0,90
175.0,750,35,50,9.17,84.3,9.53,88.71,9.97,93.06,11.13,104.23,0.0,115
128.33333333333331,550,35,50,8.56,91.79,9.11,95.92,9.7,100.56,11.67,106.51,0.0,115
210.0,900,45,50,9.2,85.76,9.6,89.82,10.19,92.51,10.71,99.23,0.0,115
151.66666666666669,650,20,50,9.08,86.75,9.67,90.76,10.13,94.45,11.2,102.38,0.0,115
198.33333333333331,850,20,50,9.46,81.16,10.03,85.37,10.52,88.8,11.52,93.98,0.0,115
221.66666666666669,950,40,50,9.2,83.93,9.59,86.82,9.9,89.8,10.56,94.38,0.0,115
58.333333333333336,250,50,50,6.51,110.68,7.94,120.88,8.62,129.45,10.46,145.01,0.0,115
116.66666666666667,500,45,50,8.31,94.08,8.74,99.93,9.6,103.26,10.61,108.0,0.0,115
128.33333333333331,550,30,50,8.49,90.51,9.1,95.33,9.69,99.65,10.89,109.18,0.0,115
151.66666666666669,650,25,50,8.99,86.19,9.66,91.14,10.39,95.18,11.29,99.13,0.0,115
186.66666666666669,800,25,50,9.26,84.18,9.79,88.36,10.15,90.48,11.07,96.16,0.0,115
186.66666666666669,800,20,50,9.3,83.06,9.85,87.49,10.3,91.25,11.18,98.78,0.0,115
35.0,150,50,50,5.18,125.46,6.38,136.09,7.94,147.68,9.59,190.78,0.0,115
151.66666666666669,650,50,50,8.6,90.3,9.22,94.42,9.78,97.53,10.76,103.44,0.0,115
128.33333333333331,550,50,50,8.52,93.17,9.0,98.08,9.75,103.33,10.93,111.42,0.0,115
186.66666666666669,800,30,50,9.23,84.77,9.64,88.79,9.98,91.57,10.44,97.76,0.0,115
93.33333333333334,400,25,50,8.17,93.75,9.06,100.48,9.84,105.98,10.99,115.19,0.0,115
58.333333333333336,250,20,50,6.86,107.6,7.67,115.91,8.66,122.76,12.21,131.56,0.0,115
186.66666666666669,800,45,50,8.97,86.17,9.45,90.19,9.88,93.04,10.23,98.86,0.0,115
198.33333333333331,850,45,50,9.11,85.61,9.43,88.77,9.85,93.72,10.45,96.89,0.0,115
198.33333333333331,850,50,50,9.15,85.91,9.57,89.47,10.17,94.09,10.71,97.51,0.0,115
210.0,900,30,50,9.32,83.52,9.65,87.23,10.18,90.73,10.76,95.35,0.0,115
151.66666666666669,650,35,50,8.85,89.83,9.44,93.5,10.09,96.89,11.19,101.57,0.0,115
151.66666666666669,650,45,50,8.72,88.79,9.23,92.73,9.62,97.58,10.4,104.71,0.0,115
210.0,900,50,50,9.26,84.53,9.65,87.8,9.93,91.61,11.23,95.94,0.0,115
198.33333333333331,850,30,50,9.29,84.42,9.85,87.82,10.11,91.37,11.28,95.31,0.0,115
93.33333333333334,400,35,50,8.22,97.58,8.84,103.84,9.44,110.13,10.85,113.96,0.0,115
233.33333333333334,1000,20,50,9.65,80.6,9.98,84.25,10.3,88.29,11.07,92.44,0.0,115
105.0,450,25,50,8.44,92.85,9.15,98.22,10.2,102.1,12.33,115.13,0.0,115
46.66666666666667,200,20,50,9.23,102.89,10.76,118.44,13.02,127.25,16.87,142.53,0.0,90
70.0,300,25,50,7.56,100.35,8.63,109.0,9.8,116.72,11.82,124.95,0.0,115
105.0,450,50,50,8.2,97.24,8.77,101.24,9.25,105.21,9.97,112.54,0.0,115
140.0,600,30,50,8.88,88.54,9.46,91.84,9.87,100.36,10.6,104.63,0.0,115
140.0,600,35,50,8.79,88.84,9.21,93.18,10.02,97.74,11.43,101.18,0.0,115
140.0,600,45,50,8.51,92.7,9.02,96.5,9.75,100.92,10.62,105.2,0.0,115
221.66666666666669,950,25,50,9.52,81.37,9.89,84.44,10.36,87.92,10.71,92.23,0.0,115
233.33333333333334,1000,45,50,9.29,82.93,9.67,87.64,9.9,89.45,10.62,93.25,0.0,115
140.0,600,50,50,8.55,91.31,8.98,96.97,9.68,100.57,10.26,108.27,0.0,115
175.0,750,30,50,9.12,83.86,9.66,89.42,10.01,91.85,10.94,97.85,0.0,115
70.0,300,35,50,7.37,103.03,8.41,111.14,9.69,119.38,11.61,134.05,0.0,115
151.66666666666669,650,30,50,9.09,87.11,9.64,92.3,10.3,95.42,10.79,101.9,0.0,115
58.333333333333336,250,30,50,7.08,109.06,7.99,117.68,9.13,128.62,11.61,139.55,0.0,115
140.0,600,25,50,8.92,87.46,9.45,92.54,10.16,95.43,11.34,100.38,0.0,115
81.66666666666666,350,20,50,8.24,95.51,8.93,102.19,9.66,113.01,11.25,126.79,0.0,115
198.33333333333331,850,40,50,9.28,84.64,9.67,88.46,10.22,92.2,10.94,96.47,0.0,115
70.0,300,50,50,7.07,108.16,7.43,116.04,8.04,124.12,10.26,134.29,0.0,115
93.33333333333334,400,30,50,7.99,96.54,8.55,101.72,9.31,107.44,11.07,116.45,0.0,115
175.0,750,40,50,9.07,86.23,9.54,91.1,9.97,93.65,11.51,96.49,0.0,115
46.66666666666667,200,45,50,6.23,118.5,7.05,124.31,8.42,135.15,10.21,148.85,0.0,115
58.333333333333336,250,45,50,6.89,109.23,7.73,117.49,8.81,125.82,11.27,136.88,0.0,115
23.333333333333336,100,30,50,4.38,130.36,5.79,147.02,8.23,162.62,15.6,222.97,0.0,115
175.0,750,50,50,8.98,88.66,9.48,93.75,9.86,96.7,10.52,101.71,0.0,115
186.66666666666669,800,40,50,8.97,85.47,9.47,90.36,10.06,93.91,10.77,100.4,0.0,115
105.0,450,40,50,8.17,97.26,8.93,102.97,9.68,107.09,11.35,123.36,0.0,115
93.33333333333334,400,20,50,8.09,94.76,8.63,100.87,9.61,109.45,11.47,118.18,0.0,115
81.66666666666666,350,25,50,7.88,98.59,8.91,106.71,10.41,111.51,12.38,116.36,0.0,115
221.66666666666669,950,35,50,9.29,83.02,9.8,87.21,10.3,90.17,10.75,94.65,0.0,115
210.0,900,25,50,9.39,81.76,9.82,87.17,10.22,90.01,11.03,94.48,0.0,115
186.66666666666669,800,35,50,9.15,84.81,9.56,88.78,10.09,92.49,10.49,97.03,0.0,115
116.66666666666667,500,50,50,8.2,94.47,8.68,100.25,9.23,104.78,10.86,118.47,0.0,115
175.0,750,25,50,9.22,84.25,9.72,88.96,10.08,91.67,10.82,95.46,0.0,115
233.33333333333334,1000,25,50,9.52,80.59,9.87,83.89,10.15,86.25,10.99,91.67,0.0,115
11.666666666666668,50,45,50,2.98,164.31,3.97,195.51,5.48,245.66,16.64,323.1,0.0,115
58.333333333333336,250,25,50,7.04,108.38,8.16,114.85,9.11,124.17,10.98,137.34,0.0,115
11.666666666666668,50,20,50,3.47,156.68,5.11,180.83,8.04,216.09,22.03,341.29,0.0,115
81.66666666666666,350,45,50,7.71,103.38,8.45,109.62,9.07,115.23,10.26,130.39,0.0,115
210.0,900,20,50,9.53,80.81,9.87,84.49,10.41,89.12,10.87,93.74,0.0,115
233.33333333333334,1000,35,50,9.37,82.33,9.76,86.82,10.1,90.01,10.95,93.95,0.0,115
81.66666666666666,350,35,50,7.81,100.35,8.49,105.24,9.12,112.82,10.06,123.96,0.0,115
11.666666666666668,50,35,50,2.91,152.85,3.95,176.4,5.58,213.05,15.76,333.54,1.0,115
58.333333333333336,250,40,50,6.96,108.86,7.8,117.36,8.45,123.64,11.02,134.37,0.0,115
105.0,450,20,50,8.45,93.38,9.06,98.34,9.61,104.97,12.01,109.15,0.0,115
163.33333333333331,700,20,50,9.25,84.54,9.58,89.12,10.02,91.83,11.05,97.53,0.0,115
128.33333333333331,550,25,50,8.85,92.16,9.29,94.98,9.92,99.37,10.58,105.94,0.0,115
116.66666666666667,500,40,50,8.29,93.54,8.9,99.08,9.66,103.65,11.66,111.39,0.0,115
35.0,150,40,50,5.54,121.78,6.56,135.39,7.61,142.65,11.18,160.8,0.0,115
81.66666666666666,350,40,50,7.46,103.36,8.14,109.75,9.47,116.18,10.1,122.35,0.0,115
210.0,900,35,50,9.25,83.98,9.64,88.13,10.03,91.86,10.39,95.36,0.0,115
233.33333333333334,1000,50,50,9.38,82.39,9.66,86.43,9.95,90.41,10.47,92.84,0.0,115
175.0,750,20,50,9.34,82.88,9.83,88.61,10.23,91.35,11.86,97.89,0.0,115
221.66666666666669,950,50,50,9.16,84.52,9.55,87.23,10.02,90.24,10.54,93.53,0.0,115
46.66666666666667,200,25,50,6.77,110.09,7.46,122.33,9.01,132.68,11.36,151.94,0.0,115
93.33333333333334,400,40,50,7.71,99.54,8.34,106.87,9.08,110.47,10.2,123.79,0.0,115
221.66666666666669,950,20,50,9.47,80.55,9.8,84.62,10.28,88.69,11.06,91.74,0.0,115
23.333333333333336,100,40,50,4.67,137.26,5.46,153.37,7.02,168.04,10.38,216.98,0.0,115
140.0,600,40,50,8.71,90.42,9.12,94.95,9.61,98.5,11.01,105.74,0.0,115
221.66666666666669,950,30,50,9.49,83.33,9.86,86.31,10.18,88.78,10.58,94.21,0.0,115
70.0,300,45,50,7.05,107.35,8.02,115.29,9.01,122.69,12.07,130.12,0.0,115
116.66666666666667,500,25,50,8.58,91.25,9.09,96.4,10.14,101.32,11.05,112.04,0.0,115
23.333333333333336,100,50,50,4.31,138.9,5.77,154.01,7.33,173.18,11.72,240.67,0.0,115
233.33333333333334,1000,30,50,9.36,81.31,9.87,84.22,10.12,88.88,10.7,92.0,0.0,115
70.0,300,40,50,7.34,105.47,8.03,111.88,9.07,118.68,10.95,127.46,0.0,115
46.66666666666667,200,35,50,6.61,114.14,7.78,125.17,8.99,135.52,10.84,151.74,0.0,115
23.333333333333336,100,25,50,5.22,129.75,6.67,147.9,7.97,181.85,14.47,199.2,0.0,115
11.666666666666668,50,40,50,2.85,154.53,3.94,175.73,5.34,200.04,11.74,296.95,0.0,115
23.333333333333336,100,35,50,4.73,133.44,5.85,150.5,7.59,168.44,11.89,204.87,0.0,115
23.333333333333336,100,45,50,4.51,141.73,5.69,158.97,6.76,171.27,10.5,214.31,0.0,115
105.0,450,30,50,8.52,93.28,9.16,101.9,9.68,106.38,10.34,112.09,0.0,115
233.33333333333334,1000,40,50,9.43,81.54,9.76,84.66,10.02,87.48,10.5,90.75,0.0,115
35.0,150,25,50,6.34,120.7,7.35,133.13,9.41,144.96,12.57,176.16,0.0,115
163.33333333333331,700,35,50,8.86,86.26,9.44,91.77,10.04,94.32,10.72,98.16,0.0,115
11.666666666666668,50,30,50,3.13,149.67,4.02,178.29,5.6,197.08,9.8,237.03,0.0,115
35.0,150,45,50,5.75,123.8,6.45,135.3,7.23,144.73,9.69,162.91,0.0,115
11.666666666666668,50,25,50,4.57,143.46,6.9,163.72,8.83,193.0,26.91,311.37,0.0,90
70.0,300,30,50,7.51,102.7,8.35,111.89,9.08,119.31,11.13,139.03,0.0,115
58.333333333333336,250,35,50,6.92,109.24,8.12,119.53,9.46,124.12,11.73,137.31,0.0,115
210.0,900,40,50,9.16,84.36,9.68,88.03,10.05,92.28,10.94,95.21,0.0,115
128.33333333333331,550,40,50,8.33,92.19,8.97,98.58,9.79,102.02,10.59,109.03,0.0,115
46.66666666666667,200,40,50,6.38,115.13,7.2,126.1,8.41,134.72,10.83,147.56,0.0,115
128.33333333333331,550,20,50,6.75,93.03,7.18,101.21,7.78,106.79,9.37,111.43,0.0,150
151.66666666666669,650,40,50,8.82,89.18,9.29,94.43,9.83,97.63,11.05,102.87,0.0,115
105.0,450,45,50,5.94,106.13,6.55,110.1,7.11,115.91,8.16,131.48,0.0,150
46.66666666666667,200,30,50,4.41,125.52,5.35,136.03,6.22,148.0,7.27,155.13,0.0,150
11.666666666666668,50,50,50,1.69,155.67,2.01,182.39,2.79,211.65,8.6,280.5,0.0,150
70.0,300,20,50,5.47,106.43,6.2,116.36,6.92,123.08,10.3,137.25,0.0,150
46.66666666666667,200,50,50,4.18,129.52,4.83,138.87,5.48,148.86,6.89,161.25,0.0,150
163.33333333333331,700,50,50,6.57,96.98,7.03,100.91,7.29,105.16,7.74,110.79,0.0,150
128.33333333333331,550,45,50,8.33,92.43,8.85,96.97,9.58,102.15,10.67,106.01,0.0,115
93.33333333333334,400,50,50,5.47,107.63,6.17,113.78,6.57,122.2,7.74,127.89,0.0,150
163.33333333333331,700,25,50,6.77,90.98,7.24,94.58,7.64,100.1,8.28,105.92,0.0,150
81.66666666666666,350,30,50,8.03,99.82,9.13,107.39,9.82,112.78,12.49,122.35,0.0,115
93.33333333333334,400,45,50,5.62,108.72,6.14,116.77,7.02,120.45,7.95,131.58,0.0,150
140.0,600,20,50,8.88,87.94,9.77,94.12,10.3,96.78,11.48,101.68,0.0,115
163.33333333333331,700,30,50,9.02,87.59,9.46,91.36,9.99,94.2,11.51,98.26,0.0,115
116.66666666666667,500,30,50,6.26,99.45,6.82,105.55,7.31,111.63,8.86,124.72,0.0,150
198.33333333333331,850,25,50,7.04,87.6,7.52,91.98,7.83,97.21,8.24,104.28,0.0,150
116.66666666666667,500,35,50,8.37,92.55,9.05,98.42,9.64,103.48,10.44,109.56,0.0,115
210.0,900,45,50,7.06,90.17,7.31,94.82,7.57,96.88,8.15,101.48,0.0,150
186.66666666666669,800,20,50,7.03,88.07,7.42,93.21,7.87,95.44,9.11,99.7,0.0,150
186.66666666666669,800,25,50,6.9,88.9,7.25,94.39,7.65,98.73,8.18,106.52,0.0,150
175.0,750,35,50,6.74,92.64,7.15,96.74,7.44,101.53,8.49,106.84,0.0,150
151.66666666666669,650,25,50,6.55,94.68,7.06,100.59,7.43,106.38,8.79,113.38,0.0,150
186.66666666666669,800,50,50,6.68,93.12,7.06,97.19,7.24,101.18,8.02,104.06,0.0,150
198.33333333333331,850,35,50,6.93,90.93,7.27,94.81,7.63,97.79,8.27,100.76,0.0,150
81.66666666666666,350,50,50,7.34,103.93,8.04,111.3,9.09,116.39,10.61,127.54,0.0,115
175.0,750,45,50,6.8,92.64,7.11,96.96,7.68,99.81,8.21,106.77,0.0,150
93.33333333333334,400,25,50,6.16,103.54,6.75,111.6,7.4,116.96,7.99,122.47,0.0,150
105.0,450,35,50,8.26,96.3,8.91,101.49,9.77,105.71,11.54,114.02,0.0,115
233.33333333333334,1000,20,50,7.27,85.15,7.6,89.23,8.03,91.93,8.67,95.48,0.0,150
128.33333333333331,550,30,50,6.3,97.03,6.97,103.96,7.49,108.53,8.44,116.13,0.0,150
186.66666666666669,800,30,50,6.82,91.98,7.29,95.33,7.73,96.85,8.46,111.14,0.0,150
128.33333333333331,550,35,50,6.31,98.19,6.68,102.21,7.24,108.55,8.14,115.87,0.0,150
221.66666666666669,950,45,50,6.93,88.94,7.26,93.13,7.56,96.06,8.3,101.21,0.0,150
35.0,150,50,50,3.71,135.34,4.33,148.68,6.02,162.84,8.88,194.96,0.0,150
105.0,450,25,50,6.22,101.68,6.86,107.43,7.55,113.47,8.51,119.87,0.0,150
151.66666666666669,650,20,50,6.77,92.05,7.29,97.8,7.8,102.1,8.65,110.43,0.0,150
58.333333333333336,250,50,50,4.52,120.11,5.16,129.83,5.97,136.23,7.27,151.61,0.0,150
210.0,900,50,50,6.86,90.16,7.22,95.59,7.58,97.83,8.0,103.73,0.0,150
221.66666666666669,950,40,50,7.0,88.71,7.35,93.79,7.65,96.63,8.45,100.38,0.0,150
116.66666666666667,500,20,50,6.34,99.18,6.89,104.34,7.37,109.9,8.72,117.46,0.0,150
128.33333333333331,550,50,50,6.13,100.47,6.49,106.12,7.06,111.78,7.49,120.71,0.0,150
198.33333333333331,850,20,50,7.11,87.33,7.47,91.78,7.9,97.23,8.39,102.23,0.0,150
105.0,450,50,50,5.84,105.77,6.36,113.39,6.89,117.45,7.78,129.12,0.0,150
151.66666666666669,650,30,50,6.59,93.68,7.09,98.46,7.49,103.87,8.17,111.78,0.0,150
151.66666666666669,650,35,50,6.61,96.04,6.95,101.25,7.37,106.03,7.91,111.68,0.0,150
93.33333333333334,400,35,50,5.69,106.1,6.31,113.85,6.75,118.87,7.84,126.28,0.0,150
198.33333333333331,850,50,50,6.79,91.3,7.17,96.88,7.5,100.23,8.04,105.09,0.0,150
116.66666666666667,500,45,50,6.14,101.78,6.59,107.58,7.05,113.29,7.73,125.76,0.0,150
151.66666666666669,650,50,50,6.47,95.69,6.87,102.85,7.32,107.24,8.25,113.36,0.0,150
233.33333333333334,1000,45,50,7.05,88.91,7.3,92.5,7.59,97.75,8.06,100.87,0.0,150
198.33333333333331,850,45,50,6.83,89.81,7.15,93.92,7.42,99.09,8.24,103.81,0.0,150
186.66666666666669,800,45,50,6.65,91.66,7.09,96.23,7.45,100.07,8.09,112.12,0.0,150
198.33333333333331,850,30,50,6.94,89.05,7.26,92.94,7.57,97.12,8.05,100.73,0.0,150
163.33333333333331,700,45,50,8.77,87.6,9.28,92.57,9.77,95.67,11.02,103.71,0.0,115
70.0,300,35,50,5.49,113.6,6.08,124.67,6.91,131.54,9.11,142.36,0.0,150
140.0,600,25,50,6.61,93.89,7.04,99.45,7.47,103.04,8.33,111.46,0.0,150
58.333333333333336,250,30,50,4.85,117.28,5.67,125.76,6.42,133.15,9.32,143.71,0.0,150
163.33333333333331,700,40,50,8.76,88.09,9.35,91.89,9.72,95.53,11.06,101.57,0.0,115
175.0,750,30,50,6.83,94.01,7.24,98.11,7.59,102.06,8.28,106.27,0.0,150
140.0,600,30,50,6.54,93.83,7.02,100.45,7.41,106.59,8.41,111.43,0.0,150
58.333333333333336,250,20,50,5.33,116.62,5.97,126.57,6.56,136.09,11.01,150.17,0.0,150
140.0,600,45,50,6.34,97.13,6.73,103.37,7.12,110.62,7.81,117.43,0.0,150
70.0,300,25,50,5.66,109.33,6.51,119.27,7.68,128.85,10.05,136.83,0.0,150
151.66666666666669,650,45,50,6.49,96.0,6.84,101.86,7.17,107.12,8.01,114.05,0.0,150
140.0,600,35,50,6.37,95.8,6.69,101.13,7.13,104.9,7.87,113.18,0.0,150
81.66666666666666,350,20,50,5.88,106.24,6.49,113.31,7.24,120.03,9.16,128.81,0.0,150
175.0,750,50,50,6.66,93.86,7.03,98.58,7.24,101.55,7.87,107.26,0.0,150
35.0,150,35,50,5.38,119.18,6.76,131.32,7.92,146.78,10.55,166.98,0.0,115
210.0,900,30,50,7.11,88.74,7.43,93.47,7.82,96.58,8.4,104.48,0.0,150
11.666666666666668,50,45,50,1.88,166.42,2.33,188.53,3.41,219.33,7.16,283.14,0.0,150
175.0,750,40,50,6.65,93.06,7.0,96.04,7.41,100.58,8.04,110.54,0.0,150
198.33333333333331,850,40,50,6.85,89.96,7.33,94.58,7.87,98.4,8.32,103.18,0.0,150
116.66666666666667,500,50,50,6.14,104.63,6.5,108.88,6.91,114.64,7.99,125.83,0.0,150
140.0,600,50,50,6.29,98.66,6.66,105.49,7.23,108.9,8.25,114.69,0.0,150
35.0,150,30,50,5.66,120.72,6.82,132.54,7.95,143.78,10.6,175.6,0.0,115
105.0,450,40,50,5.94,104.46,6.49,108.17,7.02,113.22,7.98,118.57,0.0,150
186.66666666666669,800,35,50,6.9,90.08,7.23,94.48,7.79,100.66,8.35,106.84,0.0,150
221.66666666666669,950,35,50,7.16,89.0,7.52,92.99,7.89,97.35,8.56,103.35,0.0,150
93.33333333333334,400,20,50,6.15,102.38,6.73,109.16,7.48,119.29,9.21,126.39,0.0,150
35.0,150,40,50,3.76,136.03,4.43,144.64,5.68,153.64,7.09,180.7,0.0,150
93.33333333333334,400,30,50,6.0,107.07,6.46,111.82,7.12,117.64,7.72,128.07,0.0,150
81.66666666666666,350,35,50,5.55,107.52,5.97,116.67,6.75,123.96,8.07,131.37,0.0,150
175.0,750,20,50,6.89,90.67,7.21,95.37,7.73,98.3,8.38,102.53,0.0,150
23.333333333333336,100,20,50,5.05,129.11,6.56,147.61,8.84,163.2,15.12,188.95,0.0,115
81.66666666666666,350,25,50,5.66,108.51,6.42,116.35,7.05,123.98,8.27,132.18,0.0,150
105.0,450,20,50,6.28,98.82,7.02,105.28,7.59,112.55,9.37,121.27,0.0,150
186.66666666666669,800,40,50,6.78,92.13,7.14,97.06,7.44,100.38,8.02,106.84,0.0,150
70.0,300,50,50,5.07,115.96,5.66,126.59,6.03,135.78,7.19,143.6,0.0,150
58.333333333333336,250,45,50,4.77,120.81,5.35,130.92,5.88,140.92,8.37,148.12,0.0,150
221.66666666666669,950,25,50,7.09,86.28,7.42,92.49,7.83,96.74,8.75,100.39,0.0,150
233.33333333333334,1000,25,50,7.27,85.96,7.54,90.07,7.96,93.09,8.53,97.9,0.0,150
58.333333333333336,250,25,50,5.4,115.5,5.94,122.45,6.91,130.66,8.57,141.67,0.0,150
46.66666666666667,200,45,50,4.19,126.68,5.08,135.55,5.74,145.25,7.74,154.55,0.0,150
163.33333333333331,700,20,50,6.95,91.84,7.33,96.18,7.82,100.18,8.43,104.7,0.0,150
175.0,750,25,50,6.79,89.87,7.26,93.89,7.66,97.35,8.14,103.67,0.0,150
210.0,900,20,50,7.15,86.75,7.59,90.69,7.89,93.97,8.91,98.38,0.0,150
23.333333333333336,100,30,50,3.17,140.19,3.92,156.04,4.97,172.55,7.33,197.84,0.0,150
81.66666666666666,350,45,50,5.71,113.24,6.34,120.98,6.8,125.41,8.87,138.78,0.0,150
233.33333333333334,1000,30,50,7.15,87.08,7.37,90.72,7.95,94.7,8.25,97.59,0.0,150
233.33333333333334,1000,35,50,7.05,87.4,7.47,92.09,7.71,95.6,8.29,101.33,0.0,150
81.66666666666666,350,40,50,5.34,108.87,5.85,117.46,6.89,124.91,7.72,134.27,0.0,150
93.33333333333334,400,40,50,5.7,106.12,6.25,111.43,6.64,120.23,8.63,133.3,0.0,150
210.0,900,25,50,7.05,87.56,7.32,90.88,7.64,95.94,8.64,100.28,0.0,150
70.0,300,40,50,5.05,114.06,5.54,122.72,6.18,129.58,7.59,135.09,0.0,150
128.33333333333331,550,25,50,6.38,95.9,6.96,100.71,7.44,106.27,8.68,113.4,0.0,150
11.666666666666668,50,20,50,2.09,157.55,3.59,175.26,5.39,200.67,23.57,323.89,1.0,150
23.333333333333336,100,40,50,2.8,141.6,3.3,160.7,4.21,179.52,6.24,212.22,0.0,150
116.66666666666667,500,40,50,6.12,99.84,6.6,107.45,7.02,110.83,8.57,117.15,0.0,150
11.666666666666668,50,35,50,2.07,164.59,2.42,185.52,3.33,205.2,8.52,242.88,0.0,150
233.33333333333334,1000,50,50,7.0,89.41,7.3,92.91,7.7,97.32,8.45,103.24,0.0,150
58.333333333333336,250,40,50,4.77,120.13,5.65,126.64,6.3,134.5,8.41,146.11,0.0,150
35.0,150,20,50,5.97,116.59,7.15,132.36,8.51,148.51,17.93,191.36,0.0,115
210.0,900,35,50,6.98,88.94,7.34,93.33,7.67,97.07,8.3,101.16,0.0,150
221.66666666666669,950,20,50,7.2,86.9,7.56,90.46,7.94,93.43,8.6,99.77,0.0,150
221.66666666666669,950,50,50,6.87,88.75,7.24,92.91,7.57,95.92,8.11,100.3,0.0,150
221.66666666666669,950,30,50,7.12,88.0,7.41,91.51,7.79,95.21,8.65,99.4,0.0,150
70.0,300,45,50,5.19,117.21,5.85,127.52,6.55,133.56,7.59,145.72,0.0,150
46.66666666666667,200,25,50,4.74,124.42,5.53,133.88,6.76,144.32,8.0,173.25,0.0,150
116.66666666666667,500,25,50,6.4,96.13,6.86,102.61,7.69,110.15,8.99,120.87,0.0,150
46.66666666666667,200,35,50,4.47,125.78,5.09,140.11,6.39,146.23,7.94,162.55,0.0,150
140.0,600,40,50,6.45,96.95,6.82,102.77,7.24,108.4,8.24,114.58,0.0,150
46.66666666666667,200,20,50,6.79,110.18,7.87,120.48,8.4,131.19,14.7,138.63,0.0,115
23.333333333333336,100,50,50,2.88,143.69,3.41,161.91,4.63,176.84,6.14,205.95,0.0,150
23.333333333333336,100,45,50,2.85,146.83,3.66,160.66,5.04,179.97,6.57,228.1,0.0,150
11.666666666666668,50,40,50,2.01,166.9,2.69,194.04,3.89,227.22,9.85,293.4,0.0,150
35.0,150,25,50,4.03,128.01,5.2,143.04,6.34,154.72,8.84,208.02,0.0,150
23.333333333333336,100,35,50,2.99,149.75,3.9,166.32,4.99,181.06,11.72,220.95,0.0,150
105.0,450,30,50,6.02,101.29,6.57,106.77,6.95,117.74,8.7,122.01,0.0,150
11.666666666666668,50,30,50,1.9,164.9,2.52,184.37,3.35,210.36,7.21,367.17,0.0,150
23.333333333333336,100,25,50,3.28,140.69,4.12,160.38,4.69,181.59,10.46,205.49,0.0,150
35.0,150,45,50,3.76,136.84,4.35,148.23,5.25,160.36,7.58,187.55,0.0,150
70.0,300,30,50,5.37,113.13,5.87,120.68,6.76,125.85,7.76,141.24,0.0,150
233.33333333333334,1000,40,50,7.04,87.36,7.42,92.07,7.65,95.71,8.13,100.62,0.0,150
58.333333333333336,250,35,50,4.84,112.69,5.87,125.61,6.52,136.14,7.55,168.03,0.0,150
163.33333333333331,700,35,50,6.69,93.84,7.16,100.13,7.49,103.92,8.06,108.27,0.0,150
11.666666666666668,50,25,50,3.23,145.32,4.25,169.59,5.32,190.44,11.99,236.92,0.0,115
210.0,900,40,50,7.01,90.09,7.32,93.55,7.56,97.35,7.89,101.62,0.0,150
128.33333333333331,550,40,50,6.29,98.92,6.61,104.8,6.84,108.3,7.65,118.84,0.0,150
46.66666666666667,200,40,50,4.5,126.5,5.07,135.7,5.7,145.21,7.26,164.82,0.0,150
128.33333333333331,550,20,50,6.0,98.78,6.54,104.37,6.97,111.54,8.03,119.73,0.0,165
105.0,450,45,50,5.35,107.33,5.86,113.79,6.21,120.48,6.55,132.0,0.0,165
46.66666666666667,200,30,50,3.82,124.08,4.55,141.51,5.19,155.76,6.47,176.22,0.0,165
163.33333333333331,700,50,50,5.85,99.79,6.25,104.79,6.5,109.08,7.21,116.97,0.0,165
46.66666666666667,200,50,50,3.68,132.16,4.45,144.51,5.32,153.81,9.0,163.86,0.0,165
70.0,300,20,50,4.96,111.78,5.64,118.8,6.59,125.07,7.85,138.85,0.0,165
163.33333333333331,700,25,50,6.03,94.69,6.48,99.39,6.81,103.85,7.66,113.33,0.0,165
93.33333333333334,400,50,50,4.96,114.26,5.36,119.0,6.26,124.48,7.29,139.13,0.0,165
151.66666666666669,650,40,50,6.55,95.42,7.11,101.57,7.6,104.52,8.47,111.78,0.0,150
186.66666666666669,800,25,50,6.24,91.84,6.62,96.9,6.92,102.56,7.5,109.03,0.0,165
93.33333333333334,400,45,50,5.03,113.64,5.46,119.93,5.92,126.15,6.67,133.54,0.0,165
233.33333333333334,1000,20,50,6.53,88.17,6.83,91.67,7.09,94.63,7.82,98.71,0.0,165
175.0,750,35,50,6.08,95.61,6.47,100.52,6.85,103.71,7.48,110.84,0.0,165
198.33333333333331,850,25,50,6.38,92.23,6.6,96.35,6.92,100.12,7.22,104.16,0.0,165
151.66666666666669,650,30,50,6.0,95.62,6.38,102.63,6.88,108.02,7.62,115.57,0.0,165
116.66666666666667,500,30,50,5.5,101.9,6.13,109.25,6.61,114.8,7.08,126.31,0.0,165
210.0,900,45,50,6.19,92.5,6.63,97.06,7.09,102.57,7.57,108.07,0.0,165
198.33333333333331,850,35,50,6.22,92.0,6.58,97.02,6.89,102.03,7.48,106.77,0.0,165
186.66666666666669,800,20,50,6.32,91.68,6.64,96.54,7.16,99.75,7.65,104.15,0.0,165
221.66666666666669,950,40,50,6.29,91.04,6.59,94.69,6.85,98.47,7.37,106.36,0.0,165
11.666666666666668,50,50,50,1.52,176.6,1.89,199.87,2.66,223.13,10.12,279.42,0.0,165
35.0,150,50,50,3.12,142.37,3.83,155.44,4.69,172.06,7.19,194.24,0.0,165
116.66666666666667,500,20,50,5.86,97.25,6.54,104.53,7.1,109.28,8.48,120.75,0.0,165
93.33333333333334,400,25,50,5.53,106.46,6.0,114.58,6.53,123.49,9.01,133.28,0.0,165
58.333333333333336,250,30,50,4.34,120.74,5.21,132.52,5.79,141.62,7.56,155.15,0.0,165
186.66666666666669,800,50,50,6.13,96.95,6.41,100.12,6.83,103.73,7.13,108.16,0.0,165
151.66666666666669,650,20,50,6.17,93.1,6.62,98.6,7.02,105.18,7.37,111.6,0.0,165
198.33333333333331,850,45,50,6.03,95.53,6.32,98.73,6.63,101.62,7.37,104.59,0.0,165
128.33333333333331,550,35,50,5.78,100.91,6.37,106.75,6.69,113.66,7.73,120.29,0.0,165
70.0,300,35,50,4.67,115.26,5.28,125.95,5.88,133.89,6.74,142.91,0.0,165
151.66666666666669,650,25,50,6.05,96.4,6.68,102.69,7.17,106.3,7.77,113.27,0.0,165
221.66666666666669,950,45,50,6.24,93.04,6.54,95.72,6.78,99.89,7.56,103.24,0.0,165
128.33333333333331,550,30,50,5.78,100.46,6.1,106.98,6.8,112.24,7.34,118.13,0.0,165
175.0,750,45,50,5.99,96.35,6.42,100.65,6.78,105.74,7.34,108.08,0.0,165
128.33333333333331,550,50,50,5.53,104.55,5.84,109.37,6.29,114.79,7.1,121.22,0.0,165
198.33333333333331,850,20,50,6.43,88.29,6.9,93.77,7.39,96.78,7.96,101.67,0.0,165
198.33333333333331,850,30,50,6.2,92.94,6.58,97.21,6.98,101.02,7.93,107.72,0.0,165
140.0,600,45,50,5.74,100.92,6.08,105.12,6.66,109.53,7.14,117.6,0.0,165
233.33333333333334,1000,45,50,6.38,91.33,6.73,94.99,7.11,97.27,7.64,99.83,0.0,165
151.66666666666669,650,35,50,5.82,97.61,6.19,104.08,6.69,108.61,7.0,114.52,0.0,165
186.66666666666669,800,30,50,6.15,92.68,6.53,98.84,6.86,102.08,7.93,108.98,0.0,165
105.0,450,25,50,5.59,103.4,6.11,108.91,6.71,115.46,7.93,127.03,0.0,165
210.0,900,50,50,6.19,95.24,6.52,99.55,6.88,102.93,7.48,110.87,0.0,165
151.66666666666669,650,50,50,5.87,98.81,6.25,103.15,6.57,107.5,7.28,117.28,0.0,165
81.66666666666666,350,20,50,5.4,110.69,6.04,120.29,6.61,125.82,8.02,132.32,0.0,165
128.33333333333331,550,45,50,6.3,99.08,6.82,105.03,7.26,110.97,8.44,117.61,0.0,150
140.0,600,25,50,6.01,97.14,6.43,103.64,6.83,107.7,7.49,110.84,0.0,165
105.0,450,50,50,5.22,106.1,5.8,113.14,6.57,118.37,7.46,125.35,0.0,165
186.66666666666669,800,45,50,5.99,94.87,6.31,99.41,6.66,104.11,7.05,113.19,0.0,165
175.0,750,50,50,6.02,96.97,6.38,101.43,6.6,104.76,7.16,115.59,0.0,165
175.0,750,40,50,6.03,96.54,6.48,99.96,6.78,103.84,7.34,111.73,0.0,165
93.33333333333334,400,35,50,5.2,110.68,5.78,119.54,6.36,127.57,7.14,136.61,0.0,165
163.33333333333331,700,30,50,6.73,93.98,7.15,97.68,7.41,102.42,8.31,110.36,0.0,150
140.0,600,35,50,5.83,100.08,6.25,105.66,6.57,110.57,7.14,120.32,0.0,165
70.0,300,25,50,4.87,113.79,5.55,123.19,6.41,131.79,7.97,143.18,0.0,165
175.0,750,30,50,6.08,94.75,6.54,99.41,6.94,102.92,7.7,107.39,0.0,165
58.333333333333336,250,50,50,4.23,125.18,4.79,131.22,5.47,139.07,6.88,156.62,0.0,165
210.0,900,30,50,6.27,91.99,6.84,96.65,7.11,100.86,7.52,104.42,0.0,165
116.66666666666667,500,45,50,5.43,106.18,5.72,112.07,6.27,118.01,7.5,124.15,0.0,165
186.66666666666669,800,35,50,6.17,94.01,6.54,100.47,6.82,102.3,7.31,110.42,0.0,165
58.333333333333336,250,20,50,4.48,118.16,5.13,129.42,6.01,139.1,8.87,151.63,0.0,165
93.33333333333334,400,20,50,5.52,104.0,6.01,112.2,7.07,118.5,9.23,130.44,0.0,165
116.66666666666667,500,50,50,5.4,104.5,5.73,113.11,6.17,118.67,7.26,128.32,0.0,165
151.66666666666669,650,45,50,5.8,98.81,6.13,103.55,6.4,110.05,7.38,116.49,0.0,165
233.33333333333334,1000,30,50,6.41,89.28,6.66,93.07,7.0,96.29,7.27,102.64,0.0,165
221.66666666666669,950,25,50,6.37,91.0,6.74,95.01,7.2,97.13,7.93,101.71,0.0,165
210.0,900,20,50,6.61,88.34,6.95,93.6,7.37,97.31,7.69,101.49,0.0,165
81.66666666666666,350,40,50,5.05,115.39,5.61,125.97,6.11,131.97,7.14,143.74,0.0,165
140.0,600,20,50,6.67,93.19,7.2,97.97,7.54,103.77,8.39,108.69,0.0,150
70.0,300,40,50,4.72,118.62,5.27,127.03,5.8,134.42,6.64,140.22,0.0,165
58.333333333333336,250,45,50,4.29,125.72,4.82,135.52,5.6,142.75,6.69,167.47,0.0,165
116.66666666666667,500,35,50,6.07,99.25,6.68,105.73,7.57,110.36,8.08,118.78,0.0,150
35.0,150,40,50,3.39,139.98,4.17,156.29,4.84,173.18,8.04,224.13,0.0,165
93.33333333333334,400,30,50,5.17,105.08,5.61,113.92,6.23,121.06,7.68,131.06,0.0,165
198.33333333333331,850,40,50,6.14,94.32,6.5,98.85,6.72,101.3,7.54,106.63,0.0,165
81.66666666666666,350,30,50,5.71,108.77,6.13,114.94,6.59,120.53,8.31,128.01,0.0,150
105.0,450,35,50,6.01,104.24,6.64,111.7,7.19,116.58,8.73,124.05,0.0,150
140.0,600,30,50,5.77,99.5,6.36,105.19,6.89,110.74,8.09,116.46,0.0,165
175.0,750,20,50,6.37,92.04,6.76,97.96,7.1,101.49,8.17,106.19,0.0,165
221.66666666666669,950,35,50,6.24,92.6,6.55,96.99,6.83,100.48,7.3,104.84,0.0,165
81.66666666666666,350,35,50,4.96,114.02,5.58,120.59,6.43,129.89,8.37,144.15,0.0,165
105.0,450,40,50,5.24,106.35,5.56,114.86,6.17,120.25,7.02,128.51,0.0,165
93.33333333333334,400,40,50,5.1,107.86,5.62,116.5,6.22,123.36,7.3,131.47,0.0,165
175.0,750,25,50,6.08,93.55,6.45,97.12,6.75,103.0,7.48,107.64,0.0,165
46.66666666666667,200,45,50,3.82,133.63,4.54,147.98,5.06,155.49,5.78,176.91,0.0,165
163.33333333333331,700,20,50,6.32,91.27,6.79,97.15,7.04,102.48,8.03,109.3,0.0,165
105.0,450,20,50,5.61,102.25,6.22,110.21,6.55,119.07,7.82,135.39,0.0,165
81.66666666666666,350,50,50,5.47,111.6,6.07,119.46,6.46,125.52,7.36,130.88,0.0,150
81.66666666666666,350,25,50,5.05,111.23,5.67,120.17,6.23,127.54,7.12,138.68,0.0,165
128.33333333333331,550,25,50,5.84,98.74,6.36,105.38,6.82,111.03,7.47,130.94,0.0,165
58.333333333333336,250,25,50,4.6,119.95,5.27,133.37,5.95,143.58,7.58,156.33,0.0,165
140.0,600,50,50,5.77,101.74,6.1,106.92,6.54,114.15,7.29,118.65,0.0,165
186.66666666666669,800,40,50,6.09,93.66,6.57,98.74,7.03,105.25,7.61,112.01,0.0,165
70.0,300,50,50,4.6,119.95,4.95,128.78,5.39,137.07,6.66,146.45,0.0,165
11.666666666666668,50,45,50,1.5,171.07,2.06,186.16,2.79,218.75,4.8,248.55,0.0,165
233.33333333333334,1000,35,50,6.37,89.48,6.73,92.35,7.18,96.41,7.67,99.04,0.0,165
23.333333333333336,100,30,50,2.74,150.73,3.2,165.49,3.83,177.29,6.77,212.29,0.0,165
233.33333333333334,1000,25,50,6.62,89.74,6.89,93.41,7.19,95.79,7.57,101.24,0.0,165
81.66666666666666,350,45,50,4.92,115.66,5.43,123.37,5.95,132.53,7.22,147.0,0.0,165
221.66666666666669,950,50,50,6.14,93.02,6.63,97.98,6.92,101.82,7.35,111.08,0.0,165
23.333333333333336,100,40,50,2.59,148.35,3.25,165.83,4.19,185.17,8.55,213.68,0.0,165
11.666666666666668,50,20,50,1.84,165.4,2.58,189.39,3.66,223.43,5.76,303.81,0.0,165
11.666666666666668,50,35,50,1.65,171.85,1.92,183.91,2.36,216.73,6.25,321.82,0.0,165
210.0,900,25,50,6.37,89.76,6.72,94.4,7.05,96.81,7.59,100.04,0.0,165
58.333333333333336,250,40,50,4.37,124.5,5.08,133.89,5.78,143.88,8.25,156.72,0.0,165
198.33333333333331,850,50,50,6.08,92.58,6.35,96.53,6.53,101.08,6.82,108.34,0.0,165
233.33333333333334,1000,50,50,6.46,90.37,6.76,95.35,7.05,98.17,7.77,102.57,0.0,165
23.333333333333336,100,45,50,2.54,150.97,3.27,171.76,4.22,190.44,6.38,227.58,0.0,165
163.33333333333331,700,45,50,6.45,94.21,6.84,99.67,7.28,104.18,8.03,108.86,0.0,150
116.66666666666667,500,40,50,5.46,103.8,5.89,110.42,6.36,117.39,7.27,126.33,0.0,165
46.66666666666667,200,35,50,3.84,129.22,4.61,140.98,5.06,152.04,6.99,163.87,0.0,165
210.0,900,35,50,6.28,93.03,6.64,95.28,6.9,100.76,7.33,104.84,0.0,165
140.0,600,40,50,5.71,100.58,6.21,105.88,6.84,109.79,7.09,120.62,0.0,165
46.66666666666667,200,25,50,4.23,126.76,4.99,138.08,5.65,151.84,8.8,182.8,0.0,165
70.0,300,45,50,4.45,121.85,5.08,128.0,5.53,134.17,6.45,143.34,0.0,165
221.66666666666669,950,20,50,6.54,88.19,6.82,94.23,7.17,97.8,7.7,103.11,0.0,165
11.666666666666668,50,40,50,1.6,171.89,2.19,191.96,2.98,213.86,7.96,294.4,0.0,165
221.66666666666669,950,30,50,6.31,89.65,6.61,94.03,6.84,97.45,7.56,109.46,0.0,165
35.0,150,30,50,4.07,132.25,4.75,146.5,6.26,170.52,9.42,190.63,0.0,150
23.333333333333336,100,50,50,2.46,158.28,2.91,173.17,4.05,183.14,5.72,230.1,0.0,165
23.333333333333336,100,20,50,3.64,136.18,4.41,155.34,5.74,172.51,11.44,242.15,0.0,150
116.66666666666667,500,25,50,5.59,100.9,6.06,106.92,6.49,111.89,7.08,119.08,0.0,165
11.666666666666668,50,30,50,1.78,174.36,2.24,199.81,3.37,231.98,6.75,336.77,0.0,165
35.0,150,35,50,3.95,133.1,4.51,144.33,5.54,156.87,7.17,185.16,0.0,150
35.0,150,25,50,3.48,137.18,4.38,150.71,5.53,164.27,8.62,191.86,0.0,165
163.33333333333331,700,40,50,6.7,94.29,7.14,97.88,7.53,100.83,8.17,106.34,0.0,150
105.0,450,30,50,5.44,106.45,5.89,113.36,6.4,117.3,7.15,123.69,0.0,165
23.333333333333336,100,25,50,2.74,146.32,3.26,164.4,3.93,189.22,7.55,213.79,0.0,165
70.0,300,30,50,4.72,116.74,5.22,126.0,6.22,131.83,7.43,145.16,0.0,165
35.0,150,45,50,3.06,141.58,3.59,157.49,4.77,171.22,6.1,188.41,0.0,165
46.66666666666667,200,20,50,4.58,119.9,5.39,130.34,6.07,137.0,8.64,152.69,0.0,150
35.0,150,20,50,4.4,129.98,5.0,141.7,5.75,152.79,8.52,196.59,0.0,150
23.333333333333336,100,35,50,2.67,149.91,3.34,166.14,4.02,184.06,9.36,244.22,0.0,165
58.333333333333336,250,35,50,4.46,123.7,5.09,132.46,5.49,142.8,6.76,153.62,0.0,165
163.33333333333331,700,35,50,5.9,98.27,6.35,102.98,6.54,106.6,7.76,113.52,0.0,165
11.666666666666668,50,25,50,1.98,163.43,2.75,188.02,3.69,216.26,8.93,264.23,0.0,150
233.33333333333334,1000,40,50,6.48,88.5,6.67,92.14,7.04,95.75,7.33,102.86,0.0,165
128.33333333333331,550,40,50,5.67,102.1,6.02,107.31,6.43,111.43,7.3,121.83,0.0,165
210.0,900,40,50,6.22,93.24,6.48,96.74,6.92,101.12,7.33,104.58,0.0,165
128.33333333333331,550,20,50,5.37,100.07,6.04,105.57,6.5,109.08,6.96,116.86,0.0,180
105.0,450,45,50,4.65,111.68,5.28,118.83,5.76,125.0,6.61,135.21,0.0,180
163.33333333333331,700,50,50,5.35,99.41,5.71,105.87,6.17,110.09,6.5,118.57,0.0,180
46.66666666666667,200,30,50,3.64,131.19,4.28,142.64,4.89,153.3,6.07,170.63,0.0,180
70.0,300,20,50,4.46,115.31,5.02,125.84,5.76,136.27,6.76,149.23,0.0,180
186.66666666666669,800,25,50,5.66,95.59,5.9,99.95,6.5,103.82,6.81,109.41,0.0,180
46.66666666666667,200,50,50,3.32,139.12,3.65,148.26,4.15,163.35,4.71,175.68,0.0,180
163.33333333333331,700,25,50,5.48,96.52,5.95,101.67,6.29,107.09,7.02,115.36,0.0,180
233.33333333333334,1000,20,50,5.99,89.89,6.38,93.5,6.65,96.96,7.35,100.05,0.0,180
151.66666666666669,650,30,50,5.52,97.75,5.98,104.73,6.32,109.73,6.93,115.28,0.0,180
93.33333333333334,400,50,50,4.44,117.07,4.97,122.61,5.3,126.78,6.82,134.41,0.0,180
46.66666666666667,200,40,50,3.91,133.88,4.53,142.68,5.07,154.04,6.03,175.69,0.0,165
186.66666666666669,800,20,50,5.78,94.11,6.13,99.58,6.54,103.65,7.05,111.35,0.0,180
93.33333333333334,400,45,50,4.47,116.07,5.06,121.91,5.59,130.12,6.33,136.61,0.0,180
128.33333333333331,550,30,50,5.27,103.3,5.63,110.0,5.96,115.94,6.49,121.96,0.0,180
151.66666666666669,650,35,50,5.22,101.41,5.7,106.88,5.96,112.92,6.41,119.72,0.0,180
116.66666666666667,500,30,50,5.09,106.28,5.55,112.3,6.12,119.16,6.87,125.99,0.0,180
175.0,750,35,50,5.54,97.33,5.93,102.82,6.19,108.79,6.76,115.55,0.0,180
221.66666666666669,950,40,50,5.71,94.01,6.03,98.93,6.18,101.88,6.6,110.14,0.0,180
198.33333333333331,850,25,50,5.74,93.49,6.05,97.94,6.45,102.13,7.46,110.39,0.0,180
210.0,900,45,50,5.55,95.33,5.92,99.31,6.23,102.88,6.6,108.27,0.0,180
198.33333333333331,850,35,50,5.6,95.27,5.94,99.65,6.19,104.05,7.6,107.73,0.0,180
70.0,300,35,50,4.1,121.77,4.62,127.4,5.43,134.05,7.65,146.56,0.0,180
128.33333333333331,550,50,50,5.15,107.35,5.5,112.44,5.9,115.92,6.78,126.81,0.0,180
233.33333333333334,1000,45,50,5.76,93.38,6.02,98.86,6.32,101.86,6.61,109.66,0.0,180
93.33333333333334,400,25,50,4.65,109.03,5.03,117.63,5.63,125.27,6.6,140.7,0.0,180
151.66666666666669,650,25,50,5.4,98.72,5.97,104.39,6.4,109.14,7.11,118.99,0.0,180
175.0,750,45,50,5.39,99.79,5.67,103.12,6.02,105.68,6.7,111.08,0.0,180
151.66666666666669,650,20,50,5.7,96.28,6.11,101.37,6.56,109.26,7.68,122.86,0.0,180
186.66666666666669,800,35,50,5.6,96.92,5.91,101.85,6.22,105.39,7.09,113.33,0.0,180
210.0,900,50,50,5.51,95.85,5.82,99.86,6.09,104.41,6.66,110.01,0.0,180
198.33333333333331,850,30,50,5.67,95.06,6.01,100.56,6.26,105.75,6.71,111.92,0.0,180
116.66666666666667,500,20,50,5.07,102.34,5.65,109.75,6.07,113.55,6.73,123.45,0.0,180
128.33333333333331,550,35,50,5.15,105.59,5.52,112.6,5.78,115.5,6.36,125.99,0.0,180
175.0,750,50,50,5.33,101.16,5.87,106.43,6.15,109.12,6.54,113.74,0.0,180
11.666666666666668,50,50,50,1.32,174.92,1.64,191.81,2.12,206.62,4.08,247.88,0.0,180
105.0,450,25,50,5.05,107.71,5.55,113.75,6.14,120.68,6.67,134.49,0.0,180
140.0,600,45,50,5.15,104.5,5.5,109.74,5.82,113.73,6.22,121.73,0.0,180
151.66666666666669,650,50,50,5.14,103.42,5.48,108.4,5.85,114.18,6.73,123.85,0.0,180
198.33333333333331,850,45,50,5.6,96.41,5.85,100.69,6.17,104.08,7.0,110.92,0.0,180
140.0,600,35,50,5.29,99.88,5.61,106.92,5.94,112.12,6.63,121.57,0.0,180
186.66666666666669,800,30,50,5.67,93.9,5.95,98.97,6.31,102.34,6.78,106.67,0.0,180
81.66666666666666,350,20,50,4.68,109.85,5.27,118.24,5.71,131.11,8.83,141.26,0.0,180
221.66666666666669,950,45,50,5.68,94.08,6.1,98.43,6.18,102.77,6.48,106.66,0.0,180
186.66666666666669,800,50,50,5.54,97.67,5.85,102.46,6.04,105.46,6.83,111.91,0.0,180
151.66666666666669,650,40,50,5.86,98.39,6.19,104.88,6.68,109.27,7.41,115.93,0.0,165
35.0,150,50,50,2.87,146.32,3.29,162.24,3.94,175.03,4.76,190.5,0.0,180
186.66666666666669,800,45,50,5.5,99.28,5.81,103.32,6.24,107.12,6.66,111.39,0.0,180
175.0,750,40,50,5.51,99.09,5.94,104.0,6.15,109.22,6.89,113.23,0.0,180
198.33333333333331,850,20,50,5.87,92.57,6.29,95.97,6.58,100.81,7.29,105.76,0.0,180
221.66666666666669,950,35,50,5.75,94.01,6.09,96.81,6.24,101.24,6.87,106.66,0.0,180
70.0,300,25,50,4.24,116.14,4.8,125.2,5.69,134.31,7.72,158.66,0.0,180
210.0,900,30,50,5.81,92.97,6.15,97.59,6.43,101.44,6.79,105.45,0.0,180
140.0,600,25,50,5.28,99.69,5.69,107.58,6.11,113.13,8.09,117.66,0.0,180
93.33333333333334,400,35,50,4.76,113.87,5.28,119.1,5.63,127.34,7.23,133.1,0.0,180
175.0,750,30,50,5.49,97.9,5.8,103.84,6.2,107.85,6.8,114.07,0.0,180
93.33333333333334,400,20,50,5.08,109.76,5.61,116.52,6.15,124.38,7.73,132.87,0.0,180
175.0,750,20,50,5.82,95.13,6.15,100.28,6.78,105.19,7.41,111.52,0.0,180
210.0,900,20,50,5.97,89.14,6.31,94.42,6.59,97.74,7.25,106.11,0.0,180
116.66666666666667,500,50,50,4.88,108.99,5.38,114.54,5.97,120.34,6.8,127.36,0.0,180
128.33333333333331,550,25,50,5.35,103.45,5.76,108.23,6.31,111.67,7.07,118.44,0.0,180
58.333333333333336,250,20,50,4.05,120.3,4.61,128.98,5.32,140.5,9.05,157.76,0.0,180
58.333333333333336,250,50,50,3.84,130.51,4.34,140.37,4.94,147.13,6.23,159.95,0.0,180
151.66666666666669,650,45,50,5.28,102.73,5.79,108.99,6.08,113.97,6.72,117.38,0.0,180
58.333333333333336,250,30,50,3.87,125.91,4.54,134.18,5.29,146.34,6.87,151.49,0.0,180
105.0,450,50,50,4.71,113.28,5.05,117.43,5.41,121.87,6.67,133.85,0.0,180
81.66666666666666,350,35,50,4.48,117.58,4.87,123.89,5.49,131.24,6.76,140.81,0.0,180
58.333333333333336,250,45,50,3.69,131.82,4.27,140.96,4.96,149.95,6.64,167.04,0.0,180
221.66666666666669,950,25,50,5.91,91.37,6.27,96.37,6.63,98.83,7.12,107.63,0.0,180
70.0,300,40,50,4.07,123.34,4.44,132.27,5.25,138.97,6.4,154.17,0.0,180
116.66666666666667,500,45,50,4.89,109.94,5.39,115.2,5.67,119.98,6.41,126.57,0.0,180
163.33333333333331,700,20,50,5.76,95.72,6.15,101.29,6.65,107.03,7.2,112.08,0.0,180
81.66666666666666,350,40,50,4.38,117.27,4.75,123.28,5.19,130.26,6.64,149.73,0.0,180
81.66666666666666,350,25,50,4.5,114.37,5.2,121.14,5.95,130.35,7.05,138.5,0.0,180
233.33333333333334,1000,35,50,5.79,94.01,6.02,97.71,6.26,101.16,7.06,105.62,0.0,180
81.66666666666666,350,45,50,4.27,116.9,4.96,125.72,5.56,132.12,7.05,140.17,0.0,180
93.33333333333334,400,30,50,4.67,111.42,5.15,118.24,5.53,126.96,6.75,135.29,0.0,180
105.0,450,20,50,5.02,106.2,5.55,113.36,5.94,120.11,7.11,134.09,0.0,180
198.33333333333331,850,40,50,5.6,96.3,6.06,100.34,6.31,103.56,6.82,111.36,0.0,180
186.66666666666669,800,40,50,5.55,97.38,5.84,102.95,6.15,107.51,6.62,111.23,0.0,180
58.333333333333336,250,25,50,4.0,125.93,4.78,132.52,5.22,138.08,6.47,154.08,0.0,180
93.33333333333334,400,40,50,4.66,111.62,5.11,117.82,5.55,125.57,6.4,140.24,0.0,180
23.333333333333336,100,30,50,2.47,153.32,3.0,174.59,3.5,192.02,5.15,221.11,0.0,180
175.0,750,25,50,5.6,95.53,5.97,100.31,6.26,103.44,7.25,107.96,0.0,180
233.33333333333334,1000,50,50,5.72,94.04,5.92,96.77,6.19,101.4,6.5,104.99,0.0,180
105.0,450,40,50,4.86,110.33,5.39,117.31,5.94,122.56,6.58,132.6,0.0,180
140.0,600,50,50,5.07,106.52,5.46,114.09,5.85,117.5,6.59,129.91,0.0,180
46.66666666666667,200,45,50,3.33,133.76,3.83,142.86,4.45,152.08,5.42,161.32,0.0,180
11.666666666666668,50,20,50,1.49,166.29,2.02,187.92,3.24,206.73,8.07,334.69,0.0,180
128.33333333333331,550,45,50,5.47,104.85,5.99,110.65,6.56,114.34,7.16,121.79,0.0,165
23.333333333333336,100,45,50,2.23,154.9,2.74,172.58,3.38,196.67,4.25,226.86,0.0,180
11.666666666666668,50,45,50,1.29,181.15,1.57,200.16,2.08,222.9,3.3,292.12,0.0,180
140.0,600,30,50,5.31,101.19,5.69,108.24,6.03,114.16,7.3,121.13,0.0,180
58.333333333333336,250,40,50,3.83,125.92,4.31,136.88,5.26,147.2,6.25,159.21,0.0,180
163.33333333333331,700,30,50,6.07,96.01,6.41,100.4,6.95,104.36,7.59,110.61,0.0,165
233.33333333333334,1000,25,50,5.99,91.81,6.26,95.29,6.5,100.38,7.03,105.19,0.0,180
70.0,300,50,50,3.94,122.75,4.47,132.79,5.15,138.58,5.84,144.92,0.0,180
23.333333333333336,100,40,50,2.16,151.14,2.73,172.4,3.28,194.55,5.79,211.48,0.0,180
233.33333333333334,1000,30,50,5.83,91.68,6.07,96.12,6.38,99.7,7.56,104.05,0.0,180
46.66666666666667,200,25,50,3.68,131.71,4.46,144.44,5.03,161.69,6.59,191.79,0.0,180
210.0,900,25,50,5.89,92.99,6.23,96.99,6.52,101.77,7.03,107.78,0.0,180
35.0,150,40,50,2.84,144.76,3.53,156.79,4.17,172.03,7.58,207.12,0.0,180
46.66666666666667,200,35,50,3.39,132.31,4.01,144.23,4.54,155.32,5.73,173.71,0.0,180
221.66666666666669,950,50,50,5.61,94.8,5.99,97.68,6.25,101.91,6.75,107.87,0.0,180
105.0,450,35,50,5.45,107.75,5.99,113.72,6.47,119.12,7.27,129.87,0.0,165
210.0,900,35,50,5.77,94.34,6.07,98.13,6.28,101.45,6.61,109.43,0.0,180
198.33333333333331,850,50,50,5.5,97.71,5.84,102.19,6.25,104.91,6.62,111.41,0.0,180
11.666666666666668,50,35,50,1.35,173.33,1.76,192.49,2.25,217.07,6.41,246.8,0.0,180
140.0,600,40,50,5.31,101.99,5.62,108.72,6.08,112.46,6.92,117.57,0.0,180
221.66666666666669,950,20,50,5.87,90.97,6.21,95.92,6.51,99.47,6.75,103.45,0.0,180
116.66666666666667,500,40,50,4.97,106.53,5.35,115.15,6.02,121.6,7.05,133.17,0.0,180
70.0,300,45,50,4.04,123.19,4.63,131.35,5.18,136.93,5.93,149.98,0.0,180
221.66666666666669,950,30,50,5.83,91.77,6.2,95.3,6.48,100.95,7.2,111.56,0.0,180
11.666666666666668,50,40,50,1.38,170.89,1.7,201.2,2.36,225.88,3.99,293.16,0.0,180
35.0,150,25,50,3.15,137.15,3.71,151.48,4.98,161.17,6.69,187.66,0.0,180
140.0,600,20,50,5.95,95.74,6.47,101.55,7.05,106.77,8.19,111.1,0.0,165
116.66666666666667,500,25,50,5.07,104.71,5.44,112.97,5.65,118.89,6.5,126.64,0.0,180
116.66666666666667,500,35,50,5.47,104.76,5.92,111.44,6.59,116.38,7.47,123.9,0.0,165
11.666666666666668,50,30,50,1.44,165.23,1.66,189.72,2.22,220.94,5.49,279.2,0.0,180
23.333333333333336,100,50,50,2.17,156.48,2.66,175.66,3.32,187.21,4.18,206.78,0.0,180
70.0,300,30,50,4.37,117.53,4.86,126.47,5.7,137.58,7.09,155.01,0.0,180
105.0,450,30,50,4.87,111.94,5.41,117.34,6.0,121.27,7.28,139.41,0.0,180
81.66666666666666,350,30,50,5.1,110.19,5.6,119.44,6.21,128.0,7.02,140.56,0.0,165
23.333333333333336,100,25,50,2.4,152.3,3.06,169.27,4.22,192.26,9.7,221.99,0.0,180
81.66666666666666,350,50,50,4.76,113.58,5.34,122.16,5.91,129.54,8.48,150.6,0.0,165
163.33333333333331,700,45,50,5.94,97.83,6.3,103.98,6.5,108.11,7.05,114.01,0.0,165
35.0,150,45,50,2.91,142.27,3.39,155.46,4.45,167.7,5.24,219.75,0.0,180
35.0,150,30,50,3.48,137.45,4.1,152.65,4.96,165.5,7.2,194.36,0.0,165
163.33333333333331,700,40,50,5.9,97.68,6.35,103.49,6.87,108.57,8.4,113.54,0.0,165
23.333333333333336,100,20,50,2.96,146.96,3.9,160.91,5.46,179.6,12.33,231.38,0.0,165
35.0,150,35,50,3.39,137.06,4.05,149.37,4.94,169.21,6.29,193.79,0.0,165
58.333333333333336,250,35,50,3.86,125.19,4.36,136.44,5.01,145.13,5.78,156.8,0.0,180
35.0,150,20,50,3.75,132.2,4.48,146.21,5.72,167.68,8.08,185.59,0.0,165
46.66666666666667,200,20,50,4.33,126.15,5.39,140.15,6.4,149.42,8.23,189.13,0.0,165
23.333333333333336,100,35,50,2.46,159.83,2.93,178.71,3.5,197.85,5.28,228.59,0.0,180
128.33333333333331,550,40,50,5.04,105.63,5.51,111.03,5.97,116.05,6.63,124.58,0.0,180
11.666666666666668,50,25,50,1.8,171.86,2.47,208.59,4.0,236.7,9.23,386.89,0.0,165
233.33333333333334,1000,40,50,5.75,94.2,6.03,97.85,6.31,102.02,6.77,106.6,0.0,180
163.33333333333331,700,35,50,5.44,98.75,5.84,103.71,6.3,108.78,7.3,113.65,0.0,180
210.0,450,45,50,70.83,70.05,74.4,74.83,78.02,78.25,80.0,82.83,0.0,30
210.0,900,40,50,5.61,94.71,6.01,98.2,6.4,102.82,6.86,107.59,0.0,180
256.66666666666663,550,20,50,72.12,67.95,74.5,70.91,77.86,72.9,80.0,77.25,0.0,30
93.33333333333334,200,30,50,63.99,78.27,72.06,86.34,78.14,91.44,80.0,102.77,1.0,30
326.66666666666663,700,50,50,72.14,67.27,75.04,70.11,77.74,72.66,79.99,76.08,0.0,30
93.33333333333334,200,50,50,61.96,76.73,70.77,83.24,79.28,89.13,80.0,97.28,0.0,30
373.33333333333337,800,25,50,73.29,66.32,75.43,68.37,78.13,70.85,80.0,72.92,0.0,30
140.0,300,20,50,70.22,69.51,75.03,75.03,79.62,78.4,80.0,89.32,0.0,30
466.6666666666667,1000,20,50,74.09,65.98,75.78,67.2,77.36,68.56,79.2,69.8,0.0,30
326.66666666666663,700,25,50,72.75,66.63,75.02,69.31,78.19,71.25,80.0,73.83,0.0,30
303.33333333333337,650,30,50,71.54,68.46,74.35,70.43,76.56,72.45,79.21,76.53,0.0,30
186.66666666666669,400,50,50,68.04,72.71,72.37,76.46,77.29,78.5,80.0,83.75,0.0,30
256.66666666666663,550,50,50,70.08,69.08,72.62,71.83,75.91,74.86,79.99,79.67,0.0,30
280.0,600,35,50,70.7,67.87,74.56,70.37,76.67,73.39,80.0,79.0,0.0,30
373.33333333333337,800,20,50,74.61,65.14,76.65,67.41,77.97,69.44,79.98,72.9,0.0,30
256.66666666666663,550,30,50,71.33,69.07,74.16,71.72,77.49,74.12,79.99,76.57,0.0,30
140.0,300,35,50,68.06,72.94,73.21,77.78,78.43,81.23,79.99,85.31,0.0,30
303.33333333333337,650,35,50,72.53,68.0,74.99,70.77,77.68,72.84,80.0,75.4,0.0,30
70.0,150,50,50,60.96,81.81,68.52,89.39,79.94,97.49,80.0,104.99,0.0,30
186.66666666666669,400,25,50,69.97,69.98,74.77,73.43,79.71,78.12,80.0,84.76,0.0,30
373.33333333333337,800,30,50,72.79,66.3,74.67,68.63,76.8,70.74,79.98,72.9,0.0,30
350.0,750,35,50,72.5,66.93,75.08,69.56,76.64,72.1,79.99,75.5,0.0,30
396.66666666666663,850,30,50,73.36,65.93,75.26,68.2,77.63,70.24,79.99,72.7,0.0,30
443.33333333333337,950,40,50,73.17,66.45,75.18,68.14,76.45,69.67,79.98,71.1,0.0,30
420.0,900,45,50,73.31,66.84,74.65,68.62,76.37,70.44,79.14,72.7,0.0,30
396.66666666666663,850,35,50,73.32,66.07,75.84,68.08,77.04,70.15,79.99,71.61,0.0,30
466.6666666666667,1000,45,50,73.49,66.92,74.9,68.49,76.99,69.75,78.63,73.0,0.0,30
186.66666666666669,400,45,50,69.17,71.74,72.76,74.45,77.39,76.68,79.99,85.12,0.0,30
23.333333333333336,50,50,50,44.05,108.76,58.48,118.03,79.99,133.44,80.0,160.56,5.0,30
210.0,450,25,50,70.9,69.56,73.98,72.2,78.14,74.68,79.99,82.02,0.0,30
233.33333333333334,500,30,50,71.08,69.28,74.28,71.63,76.96,73.24,79.97,78.47,0.0,30
280.0,600,45,50,71.01,68.64,73.0,72.28,76.33,73.98,79.99,78.21,0.0,30
373.33333333333337,800,35,50,72.74,66.14,75.41,68.77,77.28,70.87,79.99,76.84,0.0,30
396.66666666666663,850,25,50,73.02,66.11,76.02,68.44,78.36,70.45,79.99,72.67,0.0,30
443.33333333333337,950,45,50,73.15,66.16,75.05,67.57,76.52,70.04,79.35,73.02,0.0,30
350.0,750,50,50,72.33,67.39,74.31,70.12,76.26,72.12,79.99,76.08,0.0,30
233.33333333333334,500,20,50,72.94,67.95,75.97,70.82,78.25,73.61,79.99,77.44,0.0,30
303.33333333333337,650,20,50,71.87,67.26,74.62,69.8,77.02,71.66,80.0,76.81,0.0,30
420.0,900,50,50,71.85,67.2,73.95,69.44,75.69,71.06,78.57,73.37,0.0,30
116.66666666666667,250,45,50,64.84,77.76,71.7,82.07,75.95,85.11,80.0,91.51,0.0,30
163.33333333333331,350,20,50,70.62,69.6,74.2,74.43,78.89,78.33,80.0,84.17,0.0,30
443.33333333333337,950,35,50,73.33,66.08,74.89,67.96,77.12,69.01,79.78,72.67,0.0,30
303.33333333333337,650,25,50,72.43,67.34,75.13,70.32,77.49,73.01,79.99,75.33,0.0,30
396.66666666666663,850,45,50,72.86,66.28,74.53,69.18,76.01,71.1,79.15,73.84,0.0,30
140.0,300,40,50,68.64,72.98,76.09,79.12,79.91,82.91,80.0,87.62,0.0,30
303.33333333333337,650,50,50,72.05,67.29,74.64,69.72,76.97,72.93,80.0,75.1,0.0,30
420.0,900,20,50,73.65,66.24,75.68,68.05,77.51,69.38,79.96,71.7,0.0,30
256.66666666666663,550,35,50,71.66,69.06,74.06,71.53,78.19,73.22,80.0,77.83,0.0,30
350.0,750,45,50,72.6,68.05,74.63,70.67,76.72,72.17,78.53,75.91,0.0,30
186.66666666666669,400,35,50,70.54,68.76,73.52,73.93,77.52,78.37,79.64,84.49,0.0,30
373.33333333333337,800,45,50,72.32,67.14,74.76,69.55,77.09,71.67,79.87,73.41,0.0,30
350.0,750,40,50,72.32,67.5,74.39,69.64,77.0,70.72,78.71,74.05,0.0,30
140.0,300,25,50,69.47,71.7,73.99,76.46,79.99,79.79,80.0,85.9,0.0,30
373.33333333333337,800,50,50,72.34,67.45,74.62,69.67,76.18,71.42,79.99,74.44,0.0,30
443.33333333333337,950,25,50,74.17,65.46,76.22,67.02,77.41,68.27,79.49,69.97,0.0,30
116.66666666666667,250,20,50,67.27,73.49,73.61,78.27,78.28,84.21,79.99,94.74,0.0,30
93.33333333333334,200,45,50,64.77,78.63,70.15,84.19,75.18,88.25,79.99,96.29,0.0,30
280.0,600,25,50,73.08,67.28,76.12,70.22,77.8,72.76,79.99,75.73,0.0,30
420.0,900,30,50,72.89,66.2,75.45,68.51,77.0,70.72,79.71,73.43,0.0,30
350.0,750,30,50,72.86,66.58,75.13,69.1,76.91,71.68,79.98,74.2,0.0,30
326.66666666666663,700,20,50,72.79,67.25,74.78,69.51,77.05,70.95,80.0,74.26,0.0,30
163.33333333333331,350,40,50,67.92,73.14,71.98,75.83,76.68,80.82,79.99,83.4,0.0,30
233.33333333333334,500,50,50,70.45,69.5,73.26,74.28,76.85,76.78,79.99,79.31,0.0,30
303.33333333333337,650,45,50,71.72,68.32,74.25,70.94,76.78,72.99,78.09,76.44,0.0,30
116.66666666666667,250,50,50,65.5,77.39,70.95,82.61,75.8,87.66,80.0,93.26,0.0,30
116.66666666666667,250,30,50,67.87,73.24,74.65,79.33,79.98,83.66,80.0,89.59,0.0,30
350.0,750,20,50,72.78,65.86,75.08,68.9,76.66,71.12,79.99,72.72,0.0,30
46.66666666666667,100,45,50,54.55,90.32,61.95,98.79,78.74,118.62,80.0,128.67,2.0,30
186.66666666666669,400,20,50,71.66,70.73,75.1,74.03,78.78,77.41,80.0,82.47,0.0,30
163.33333333333331,350,25,50,71.08,70.66,76.5,74.92,79.99,78.42,80.0,83.35,0.0,30
373.33333333333337,800,40,50,71.99,67.36,74.18,69.61,76.88,71.94,79.46,74.49,0.0,30
46.66666666666667,100,40,50,54.62,87.41,69.52,96.98,79.98,108.56,80.0,119.63,3.0,30
210.0,450,40,50,70.46,69.21,73.95,72.98,77.35,76.24,80.0,80.06,0.0,30
163.33333333333331,350,35,50,69.03,70.27,74.14,75.96,79.97,78.45,80.0,80.07,0.0,30
396.66666666666663,850,20,50,73.12,65.56,75.2,67.89,76.52,69.63,79.76,72.51,0.0,30
210.0,450,50,50,69.28,69.82,72.96,74.23,75.67,76.72,79.93,81.9,0.0,30
256.66666666666663,550,25,50,72.43,68.41,74.59,71.07,77.38,73.07,79.99,77.45,0.0,30
23.333333333333336,50,45,50,46.53,100.95,60.16,123.35,79.99,146.01,80.0,165.22,5.0,30
23.333333333333336,50,20,50,49.6,91.52,75.07,115.33,79.99,124.08,80.0,153.21,11.0,30
163.33333333333331,350,45,50,68.76,73.22,74.44,77.58,78.71,81.8,80.0,85.65,0.0,30
210.0,450,20,50,71.03,68.81,75.69,73.32,79.59,76.03,80.0,79.43,0.0,30
46.66666666666667,100,30,50,55.87,83.42,73.08,95.39,79.99,105.15,80.0,134.02,3.0,30
396.66666666666663,850,40,50,72.74,67.01,74.56,69.6,76.63,70.86,80.0,72.38,0.0,30
233.33333333333334,500,45,50,69.48,71.11,73.26,73.9,75.83,76.4,78.55,79.63,0.0,30
466.6666666666667,1000,50,50,72.98,65.97,74.8,68.56,77.0,69.95,77.88,71.39,0.0,30
93.33333333333334,200,25,50,64.99,76.57,71.15,83.7,79.35,89.16,80.0,95.9,0.0,30
116.66666666666667,250,25,50,65.58,73.41,72.06,79.55,79.96,85.41,80.0,92.62,0.0,30
466.6666666666667,1000,25,50,73.7,65.59,75.8,67.69,77.39,68.98,79.72,71.41,0.0,30
443.33333333333337,950,50,50,72.99,66.26,74.45,67.83,77.25,70.19,79.99,71.74,0.0,30
280.0,600,50,50,70.65,68.78,73.57,71.29,76.72,73.37,80.0,75.75,0.0,30
140.0,300,50,50,66.54,74.27,72.04,77.98,78.39,81.21,80.0,88.55,0.0,30
466.6666666666667,1000,35,50,73.82,65.77,75.25,67.99,77.16,69.83,79.98,72.12,0.0,30
186.66666666666669,400,30,50,69.95,70.47,73.49,74.53,77.19,77.4,80.0,85.52,0.0,30
93.33333333333334,200,35,50,62.41,78.53,69.55,87.32,79.85,93.47,80.0,95.96,0.0,30
116.66666666666667,250,40,50,67.38,75.2,71.08,79.33,79.62,84.43,80.0,91.57,0.0,30
280.0,600,30,50,72.59,67.48,74.9,69.98,78.31,72.66,80.0,78.69,0.0,30
350.0,750,25,50,72.76,66.4,75.15,69.16,75.97,71.9,79.99,74.15,0.0,30
466.6666666666667,1000,30,50,73.64,65.5,75.36,67.52,76.9,69.17,79.61,71.74,0.0,30
396.66666666666663,850,50,50,73.4,66.68,74.93,68.39,77.38,69.75,79.99,72.27,0.0,30
186.66666666666669,400,40,50,69.1,71.14,73.47,74.47,76.31,77.78,80.0,84.61,0.0,30
23.333333333333336,50,35,50,46.37,92.57,68.42,110.23,79.99,129.99,80.0,166.89,7.0,30
280.0,600,40,50,72.31,67.27,75.53,70.82,78.46,73.0,80.0,76.82,0.0,30
70.0,150,40,50,61.83,81.74,68.16,90.62,77.49,97.94,80.0,110.42,1.0,30
420.0,900,25,50,73.51,65.33,75.1,67.62,76.42,68.71,79.96,71.59,0.0,30
23.333333333333336,50,40,50,44.63,96.3,56.43,119.57,79.28,131.57,79.99,150.89,6.0,30
233.33333333333334,500,40,50,71.58,70.16,74.65,72.85,77.21,75.69,80.0,78.99,0.0,30
140.0,300,45,50,65.65,74.59,70.14,79.68,77.78,83.01,80.0,88.89,0.0,30
46.66666666666667,200,40,50,3.44,136.87,4.1,149.17,4.79,156.28,6.13,187.07,0.0,180
443.33333333333337,950,30,50,73.28,66.06,75.2,67.86,76.7,69.58,78.29,71.53,0.0,30
420.0,900,35,50,73.15,65.95,74.94,68.56,77.02,70.3,80.0,73.99,0.0,30
443.33333333333337,950,20,50,73.84,65.65,75.33,67.42,77.41,69.19,79.9,71.76,0.0,30
210.0,450,30,50,69.3,70.18,73.08,73.87,77.8,76.15,80.0,78.4,0.0,30
70.0,150,25,50,61.74,81.24,72.73,90.2,79.99,98.87,80.0,114.86,1.0,30
23.333333333333336,50,30,50,43.53,94.94,64.26,118.77,79.99,138.46,80.0,185.41,3.0,30
46.66666666666667,100,25,50,60.3,78.09,71.24,93.39,79.98,107.25,80.0,117.23,2.0,30
46.66666666666667,100,50,50,52.88,91.23,70.24,104.75,79.98,112.13,80.0,127.27,3.0,30
151.66666666666669,650,40,50,5.29,100.58,5.7,106.12,5.99,109.81,6.83,120.56,0.0,180
233.33333333333334,500,25,50,71.53,68.67,75.98,71.49,79.7,74.38,80.0,77.01,0.0,30
140.0,300,30,50,69.23,72.32,72.6,76.76,75.65,80.19,79.99,89.43,0.0,30
70.0,150,45,50,62.71,79.76,71.37,90.21,79.73,96.96,80.0,109.38,0.0,30
128.33333333333331,550,45,50,5.03,105.5,5.55,110.74,5.92,117.65,6.19,125.8,0.0,180
163.33333333333331,700,30,50,5.42,98.82,5.79,104.22,6.22,108.07,6.74,116.96,0.0,180
105.0,450,35,50,4.94,108.51,5.23,115.68,5.79,120.26,6.49,134.46,0.0,180
81.66666666666666,350,30,50,4.56,117.21,5.03,125.29,5.42,133.87,6.51,140.26,0.0,180
35.0,150,30,50,3.13,137.68,3.72,153.32,4.53,168.41,6.15,207.3,0.0,180
81.66666666666666,350,50,50,4.33,120.17,4.82,127.85,5.29,133.5,5.86,143.32,0.0,180
116.66666666666667,250,35,50,65.29,74.85,74.44,81.52,79.98,87.79,80.0,94.82,0.0,30
140.0,600,20,50,5.43,97.92,5.87,105.58,6.26,107.78,6.98,113.62,0.0,180
116.66666666666667,500,35,50,5.0,107.22,5.43,111.93,6.09,117.34,6.94,128.16,0.0,180
163.33333333333331,700,45,50,5.27,100.12,5.68,104.72,6.1,108.91,7.06,115.92,0.0,180
23.333333333333336,100,20,50,2.66,150.51,3.5,169.34,4.62,190.95,12.38,258.81,0.0,180
163.33333333333331,700,40,50,5.36,102.02,5.67,106.99,6.09,111.21,6.47,115.41,0.0,180
35.0,150,35,50,2.93,141.51,3.43,152.16,4.2,166.12,5.61,199.8,0.0,180
256.66666666666663,550,40,50,72.14,68.38,74.38,72.05,76.88,74.21,79.98,78.91,0.0,30
210.0,450,45,50,45.29,77.46,47.98,80.88,49.77,85.56,56.06,92.97,0.0,45
46.66666666666667,100,35,50,55.25,89.56,65.41,103.73,79.97,110.59,80.0,129.25,1.0,30
35.0,150,20,50,3.1,132.9,3.83,151.06,5.4,171.09,11.44,230.65,0.0,180
46.66666666666667,200,20,50,4.05,128.04,4.5,141.4,5.4,155.98,6.26,179.82,0.0,180
256.66666666666663,550,20,50,47.54,72.01,50.52,76.07,52.9,81.11,56.11,85.62,0.0,45
326.66666666666663,700,50,50,47.73,72.59,49.38,76.0,51.17,77.64,54.53,82.31,0.0,45
93.33333333333334,200,30,50,40.7,85.67,46.73,94.35,55.97,99.98,64.95,108.31,0.0,45
466.6666666666667,1000,40,50,73.26,66.29,74.83,68.03,77.32,69.15,79.99,71.82,0.0,30
93.33333333333334,200,50,50,40.32,87.92,44.49,95.55,50.64,101.98,59.52,108.02,0.0,45
373.33333333333337,800,25,50,48.84,70.71,50.55,73.68,51.61,74.64,54.79,77.52,0.0,45
326.66666666666663,700,35,50,72.39,67.28,74.4,69.98,77.2,71.97,79.99,74.33,0.0,30
11.666666666666668,50,25,50,1.54,172.22,1.96,200.21,2.66,233.19,4.52,297.87,0.0,180
140.0,300,20,50,44.7,76.74,50.08,83.74,54.76,90.52,68.86,96.9,0.0,45
466.6666666666667,1000,20,50,49.83,69.18,51.08,70.91,52.42,72.96,53.4,75.84,0.0,45
420.0,900,40,50,73.64,66.6,75.37,68.56,77.65,69.83,80.0,71.92,0.0,30
326.66666666666663,700,25,50,48.06,71.43,49.65,74.18,50.9,76.89,54.19,80.75,0.0,45
70.0,150,50,50,36.71,94.15,42.02,101.89,48.39,111.72,67.77,118.35,0.0,45
396.66666666666663,850,30,50,48.4,70.85,50.09,73.26,51.32,75.6,54.38,78.56,0.0,45
186.66666666666669,400,25,50,46.11,75.13,50.91,80.4,52.8,83.67,58.81,89.13,0.0,45
186.66666666666669,400,50,50,45.55,78.31,48.01,83.91,51.19,87.69,57.19,93.25,0.0,45
373.33333333333337,800,30,50,49.05,70.57,51.13,72.86,52.35,75.35,55.8,77.72,0.0,45
303.33333333333337,650,30,50,48.32,71.39,50.52,75.79,52.4,79.38,56.19,82.19,0.0,45
256.66666666666663,550,30,50,47.02,72.75,49.61,76.29,51.39,79.75,54.22,82.49,0.0,45
256.66666666666663,550,50,50,46.58,73.89,48.89,78.44,51.5,81.51,57.23,84.09,0.0,45
140.0,300,35,50,43.6,81.08,47.09,86.03,52.48,91.24,57.38,95.55,0.0,45
163.33333333333331,350,20,50,45.39,75.69,48.97,82.02,53.88,87.4,61.55,91.14,0.0,45
350.0,750,35,50,48.2,71.77,50.11,74.71,51.24,75.63,55.33,81.75,0.0,45
466.6666666666667,1000,45,50,49.2,69.66,50.46,72.25,52.02,73.76,54.52,77.07,0.0,45
443.33333333333337,950,45,50,48.77,70.34,49.87,73.19,52.09,75.05,55.27,77.17,0.0,45
303.33333333333337,650,35,50,47.91,72.48,50.39,74.9,52.3,77.26,54.71,82.39,0.0,45
373.33333333333337,800,20,50,48.99,69.92,51.17,72.98,52.33,75.16,56.17,78.72,0.0,45
443.33333333333337,950,40,50,48.65,69.84,50.04,72.64,51.65,74.76,53.28,77.71,0.0,45
373.33333333333337,800,35,50,48.38,71.63,50.01,74.39,52.13,76.2,53.99,79.27,0.0,45
23.333333333333336,50,50,50,25.23,120.07,33.46,140.49,50.88,159.89,79.99,203.15,0.0,45
420.0,900,50,50,48.85,70.63,50.28,73.28,51.25,75.34,55.0,76.88,0.0,45
280.0,600,35,50,47.37,72.65,49.21,76.98,51.22,79.5,54.62,83.54,0.0,45
233.33333333333334,500,20,50,47.24,72.34,50.82,75.76,53.98,79.84,56.5,82.04,0.0,45
396.66666666666663,850,35,50,48.86,69.94,50.54,72.73,52.6,75.51,54.05,78.73,0.0,45
210.0,450,25,50,46.65,74.65,48.89,77.88,50.69,82.12,54.76,91.0,0.0,45
420.0,900,45,50,48.68,71.25,49.97,73.13,51.13,75.0,54.12,78.17,0.0,45
350.0,750,50,50,47.9,72.26,49.56,74.6,50.78,77.37,54.04,81.66,0.0,45
280.0,600,25,50,47.51,73.52,49.4,76.63,52.04,79.19,55.0,84.96,0.0,45
443.33333333333337,950,35,50,49.09,70.25,50.58,72.29,51.53,74.55,54.01,77.44,0.0,45
350.0,750,45,50,48.17,72.22,49.83,75.24,51.83,77.58,53.23,78.6,0.0,45
303.33333333333337,650,50,50,47.43,73.42,49.29,76.87,51.06,78.36,55.41,83.26,0.0,45
116.66666666666667,250,45,50,40.6,84.12,46.24,88.75,49.75,94.25,61.34,103.2,0.0,45
116.66666666666667,250,20,50,44.17,78.57,50.24,85.32,53.69,92.1,66.88,99.64,0.0,45
163.33333333333331,350,40,50,43.52,77.37,47.66,82.12,52.24,85.33,57.38,89.86,0.0,45
396.66666666666663,850,25,50,48.74,70.18,50.73,72.27,52.86,74.3,55.24,76.13,0.0,45
280.0,600,45,50,46.81,74.34,48.23,77.48,51.12,79.62,53.59,81.55,0.0,45
93.33333333333334,200,45,50,42.34,83.75,47.06,91.46,51.83,98.23,67.02,108.71,0.0,45
303.33333333333337,650,25,50,48.16,72.32,50.64,75.48,51.86,78.06,56.44,82.81,0.0,45
396.66666666666663,850,45,50,47.98,71.33,49.94,74.27,51.4,76.12,53.77,81.03,0.0,45
233.33333333333334,500,30,50,47.39,74.66,50.69,79.01,53.61,82.88,58.49,86.96,0.0,45
186.66666666666669,400,35,50,46.42,76.42,50.34,80.45,53.54,85.96,56.12,89.7,0.0,45
140.0,300,25,50,45.01,80.22,48.04,85.11,51.42,90.29,64.05,95.3,0.0,45
186.66666666666669,400,45,50,44.84,78.17,47.49,83.76,50.17,86.63,52.85,93.08,0.0,45
163.33333333333331,350,35,50,44.75,79.09,48.54,82.43,52.36,86.81,61.69,97.2,0.0,45
373.33333333333337,800,40,50,48.31,71.7,49.85,73.88,51.41,76.33,52.38,80.49,0.0,45
350.0,750,40,50,48.07,71.25,49.76,74.83,51.55,77.09,53.31,80.54,0.0,45
210.0,450,40,50,46.15,75.46,49.74,78.46,51.69,82.57,58.74,90.39,0.0,45
140.0,300,40,50,43.78,80.67,46.35,86.01,50.04,91.52,59.57,98.2,0.0,45
163.33333333333331,350,25,50,45.23,76.73,48.23,81.37,52.93,87.41,56.88,96.27,0.0,45
303.33333333333337,650,20,50,48.25,71.31,49.72,73.94,52.59,77.14,55.27,81.41,0.0,45
420.0,900,20,50,49.15,70.05,50.39,72.09,51.86,74.59,54.71,76.75,0.0,45
116.66666666666667,250,50,50,41.73,85.15,46.18,91.94,50.32,97.41,56.86,106.67,0.0,45
46.66666666666667,100,40,50,33.21,104.69,38.95,116.59,52.25,127.65,79.96,137.52,0.0,45
256.66666666666663,550,35,50,47.06,73.18,49.18,76.58,52.08,79.44,58.84,82.52,0.0,45
350.0,750,20,50,48.5,70.45,50.36,72.77,51.41,75.37,54.84,77.78,0.0,45
46.66666666666667,100,45,50,34.17,101.48,42.34,115.26,54.12,126.93,79.97,154.35,1.0,45
93.33333333333334,200,35,50,40.46,84.2,46.71,93.11,53.56,98.94,63.5,107.0,0.0,45
373.33333333333337,800,50,50,48.22,72.64,49.59,74.78,51.85,76.43,54.8,78.56,0.0,45
443.33333333333337,950,25,50,49.04,69.72,50.59,71.72,51.95,73.55,54.63,79.65,0.0,45
396.66666666666663,850,40,50,48.85,69.29,50.69,72.54,52.3,74.64,53.8,77.76,0.0,45
420.0,900,30,50,49.57,69.35,51.13,72.23,52.32,74.5,54.08,79.49,0.0,45
466.6666666666667,1000,30,50,49.43,69.63,50.53,71.56,51.79,73.38,53.64,77.89,0.0,45
373.33333333333337,800,45,50,48.13,71.45,49.83,74.0,51.18,76.3,54.37,79.3,0.0,45
46.66666666666667,100,30,50,34.52,101.08,43.34,113.79,58.17,127.06,72.45,154.95,1.0,45
256.66666666666663,550,25,50,47.13,73.25,49.42,76.76,51.55,80.24,55.69,85.48,0.0,45
350.0,750,30,50,48.63,71.67,50.33,73.91,52.39,76.34,54.59,80.36,0.0,45
116.66666666666667,250,30,50,42.78,81.86,49.13,87.61,55.01,94.37,65.56,104.43,0.0,45
233.33333333333334,500,50,50,46.03,74.5,48.29,79.23,50.48,82.74,56.19,87.07,0.0,45
396.66666666666663,850,50,50,48.62,71.3,50.1,73.6,51.17,75.82,54.04,79.99,0.0,45
303.33333333333337,650,45,50,47.99,73.03,49.96,75.58,52.28,78.15,56.2,80.58,0.0,45
466.6666666666667,1000,50,50,48.91,70.47,50.09,72.07,51.22,73.31,53.17,76.07,0.0,45
93.33333333333334,200,25,50,40.44,84.99,46.16,91.18,53.99,101.13,67.31,110.87,1.0,45
280.0,600,50,50,46.34,74.68,49.03,77.74,51.66,80.09,53.61,83.55,0.0,45
326.66666666666663,700,20,50,48.31,70.8,50.37,73.26,52.48,76.46,54.57,79.81,0.0,45
210.0,450,20,50,46.07,74.97,49.82,78.98,53.32,83.0,56.65,85.66,0.0,45
443.33333333333337,950,50,50,48.7,70.8,49.97,72.99,50.9,74.21,52.39,76.54,0.0,45
396.66666666666663,850,20,50,48.99,69.81,50.59,72.31,52.36,74.4,54.46,80.69,0.0,45
163.33333333333331,350,45,50,44.7,79.22,47.87,85.54,50.93,89.92,59.1,96.23,0.0,45
350.0,750,25,50,48.75,70.87,49.96,74.18,52.1,76.61,54.42,80.54,0.0,45
23.333333333333336,50,45,50,23.84,120.01,32.89,141.49,47.5,153.79,80.0,204.17,1.0,45
116.66666666666667,250,25,50,43.75,83.81,48.05,90.96,50.87,95.24,55.8,105.07,0.0,45
210.0,450,50,50,44.53,78.05,48.11,82.98,50.65,86.61,53.22,89.72,0.0,45
186.66666666666669,400,20,50,46.08,74.3,49.3,80.42,52.81,85.38,56.84,89.72,0.0,45
140.0,300,50,50,42.18,81.73,45.3,87.78,49.39,94.17,56.55,103.42,0.0,45
23.333333333333336,50,20,50,29.22,112.7,40.07,125.53,54.5,152.41,80.0,196.85,2.0,45
280.0,600,30,50,47.26,72.05,49.78,74.28,51.67,78.99,54.95,84.43,0.0,45
23.333333333333336,50,35,50,26.33,113.98,35.31,134.88,53.51,151.96,80.0,186.85,2.0,45
466.6666666666667,1000,25,50,49.31,68.66,50.73,71.19,51.73,72.91,53.82,76.12,0.0,45
140.0,300,45,50,42.8,82.65,47.44,86.45,53.51,90.97,64.62,102.2,0.0,45
70.0,150,25,50,39.67,90.75,48.89,100.09,57.13,110.95,78.75,126.19,0.0,45
233.33333333333334,500,45,50,46.04,75.71,48.81,79.85,51.53,83.82,55.34,88.45,0.0,45
443.33333333333337,950,30,50,49.25,70.29,50.21,72.49,51.84,74.14,54.01,78.66,0.0,45
280.0,600,40,50,47.53,73.46,49.27,76.47,52.16,78.69,58.32,81.9,0.0,45
466.6666666666667,1000,35,50,49.47,69.92,50.43,71.94,51.64,74.01,53.16,77.47,0.0,45
116.66666666666667,250,40,50,43.51,83.42,47.02,89.89,51.49,94.61,59.85,102.66,0.0,45
23.333333333333336,50,40,50,27.43,116.33,37.9,145.63,59.6,165.8,79.99,234.61,1.0,45
186.66666666666669,400,30,50,46.2,75.73,49.55,80.84,53.55,85.98,61.42,90.33,0.0,45
210.0,450,30,50,46.39,74.93,49.09,80.33,50.84,83.49,57.5,90.0,0.0,45
70.0,150,40,50,37.63,93.43,42.14,102.04,47.39,108.03,67.5,120.48,0.0,45
443.33333333333337,950,20,50,49.52,68.91,50.69,71.27,51.52,73.15,53.63,74.83,0.0,45
420.0,900,35,50,48.93,70.78,50.79,72.94,52.21,75.07,55.11,79.32,0.0,45
23.333333333333336,50,30,50,24.52,106.87,34.63,131.47,52.47,153.44,79.99,203.82,1.0,45
186.66666666666669,400,40,50,45.02,77.36,47.67,81.95,53.04,85.61,56.86,93.33,0.0,45
420.0,900,25,50,48.73,69.67,50.09,71.99,51.22,73.99,54.82,76.81,0.0,45
233.33333333333334,500,40,50,46.56,76.78,48.34,79.25,50.22,82.07,52.55,87.9,0.0,45
46.66666666666667,100,25,50,34.84,94.87,46.31,111.25,65.89,121.0,80.0,148.1,1.0,45
46.66666666666667,100,50,50,33.04,105.51,40.91,118.51,57.98,134.16,80.0,159.22,0.0,45
233.33333333333334,500,25,50,47.14,75.15,49.95,78.31,52.38,81.14,57.44,84.16,0.0,45
140.0,300,30,50,44.34,78.26,47.38,84.69,51.57,91.71,61.18,98.66,0.0,45
70.0,150,45,50,38.22,92.51,42.29,102.78,49.18,113.94,77.33,137.51,0.0,45
93.33333333333334,200,40,50,63.68,77.17,73.27,84.25,79.18,90.26,80.0,95.73,0.0,30
303.33333333333337,650,40,50,72.36,68.44,74.01,70.71,75.76,72.38,80.0,75.42,0.0,30
116.66666666666667,250,35,50,41.91,83.79,46.93,90.31,50.77,94.67,58.19,104.79,0.0,45
163.33333333333331,350,30,50,68.53,72.1,74.59,76.86,78.59,80.6,79.99,86.88,0.0,30
70.0,150,30,50,62.49,78.4,72.03,85.79,79.98,94.16,80.0,103.83,0.0,30
326.66666666666663,700,30,50,72.23,66.84,74.85,69.42,77.2,71.88,79.99,75.77,0.0,30
256.66666666666663,550,45,50,71.27,68.67,74.8,72.05,76.08,75.5,78.17,79.14,0.0,30
210.0,450,35,50,71.78,69.98,74.59,73.15,79.04,76.93,80.0,81.77,0.0,30
280.0,600,20,50,71.77,67.2,74.94,70.6,77.74,72.73,79.99,76.99,0.0,30
326.66666666666663,700,45,50,72.49,67.37,75.22,69.22,77.12,71.92,78.43,74.69,0.0,30
163.33333333333331,350,50,50,68.13,71.45,73.18,77.01,77.96,81.65,79.99,92.7,0.0,30
233.33333333333334,500,35,50,70.98,68.82,74.49,73.4,78.54,76.39,80.0,81.25,0.0,30
256.66666666666663,550,40,50,47.25,73.82,49.21,77.73,52.72,80.65,56.76,89.83,0.0,45
70.0,150,35,50,62.37,77.53,71.01,88.92,79.36,95.76,80.0,108.28,1.0,30
326.66666666666663,700,50,50,35.49,77.16,36.78,80.13,37.91,82.5,41.35,86.06,0.0,60
46.66666666666667,100,20,50,58.63,84.75,70.76,98.26,79.98,105.39,80.0,129.78,2.0,30
210.0,450,45,50,33.31,81.71,36.67,86.09,38.07,90.33,41.71,94.94,0.0,60
256.66666666666663,550,20,50,35.54,76.64,37.74,80.84,39.19,83.18,41.55,89.24,0.0,60
466.6666666666667,1000,20,50,36.71,71.86,38.22,74.38,39.89,76.09,42.18,80.2,0.0,60
93.33333333333334,200,30,50,29.92,91.33,35.46,98.76,40.09,108.72,48.35,119.08,0.0,60
373.33333333333337,800,25,50,36.69,73.74,38.08,76.93,39.83,79.02,41.42,83.98,0.0,60
140.0,300,20,50,32.27,82.88,36.04,90.6,38.82,97.02,49.99,103.19,0.0,60
326.66666666666663,700,40,50,73.15,67.46,75.15,70.38,77.37,72.65,79.99,76.05,0.0,30
93.33333333333334,200,50,50,29.29,95.62,32.89,104.05,36.17,112.07,52.06,126.57,0.0,60
46.66666666666667,100,35,50,34.63,102.84,40.99,113.82,51.25,131.53,66.81,149.04,0.0,45
93.33333333333334,200,20,50,67.12,74.24,73.63,81.08,79.96,88.55,80.0,97.38,1.0,30
70.0,150,50,50,25.47,101.58,29.9,112.53,32.26,125.14,42.77,137.36,0.0,60
186.66666666666669,400,25,50,34.63,80.66,37.01,85.21,38.72,89.31,44.19,97.26,0.0,60
70.0,150,20,50,64.57,77.31,73.12,87.15,79.98,95.94,80.0,113.82,1.0,30
396.66666666666663,850,30,50,36.42,73.8,37.55,76.02,39.55,79.19,43.73,82.47,0.0,60
140.0,300,35,50,32.15,86.49,34.93,91.45,37.78,95.09,44.8,107.35,0.0,60
186.66666666666669,400,50,50,32.73,84.67,35.06,90.09,38.11,94.03,42.26,103.78,0.0,60
443.33333333333337,950,45,50,36.48,73.43,38.09,76.19,39.43,78.49,41.03,82.59,0.0,60
163.33333333333331,350,20,50,35.05,80.36,38.11,86.42,41.09,92.57,48.04,98.67,0.0,60
373.33333333333337,800,35,50,36.28,75.52,37.45,77.6,38.86,80.25,42.28,82.9,0.0,60
396.66666666666663,850,35,50,36.11,73.35,37.45,76.58,38.76,79.44,39.93,84.22,0.0,60
326.66666666666663,700,25,50,36.15,75.17,38.09,78.76,40.02,80.31,41.25,83.04,0.0,60
256.66666666666663,550,50,50,35.35,78.65,37.36,82.38,39.57,86.46,40.87,94.21,0.0,60
443.33333333333337,950,35,50,36.7,72.43,37.92,76.16,38.62,79.02,40.54,82.45,0.0,60
350.0,750,35,50,36.04,74.68,37.56,77.78,38.98,80.49,42.29,84.37,0.0,60
396.66666666666663,850,45,50,36.01,74.66,37.46,77.61,38.32,79.89,39.64,81.8,0.0,60
210.0,450,25,50,33.97,80.28,36.61,85.39,38.49,88.43,41.81,96.62,0.0,60
350.0,750,50,50,35.89,75.73,37.03,78.84,38.95,81.07,43.37,84.59,0.0,60
373.33333333333337,800,30,50,35.96,74.88,37.43,77.89,39.18,81.47,41.34,86.02,0.0,60
256.66666666666663,550,30,50,35.33,76.13,36.77,80.69,38.8,83.56,41.6,88.88,0.0,60
23.333333333333336,50,50,50,14.35,135.05,19.58,155.62,28.13,172.44,43.2,242.55,1.0,60
373.33333333333337,800,20,50,36.55,72.49,38.01,75.01,39.79,77.91,42.45,81.06,0.0,60
280.0,600,25,50,36.07,75.9,37.55,78.92,39.48,83.0,41.88,87.85,0.0,60
443.33333333333337,950,40,50,36.79,73.19,37.86,76.39,38.93,79.03,40.6,82.33,0.0,60
23.333333333333336,50,25,50,44.76,96.63,65.99,119.54,80.0,132.04,80.0,153.51,5.0,30
466.6666666666667,1000,40,50,48.66,70.07,50.27,71.9,51.86,73.66,53.1,76.89,0.0,45
303.33333333333337,650,30,50,35.53,75.9,36.94,80.79,38.49,84.17,41.9,90.76,0.0,60
420.0,900,50,50,36.42,73.38,37.9,75.97,38.82,77.62,40.17,82.48,0.0,60
210.0,450,40,50,34.38,80.9,36.27,85.11,38.32,88.77,39.74,92.32,0.0,60
116.66666666666667,250,45,50,29.76,88.52,33.44,96.56,38.01,105.56,48.0,115.68,0.0,60
373.33333333333337,800,40,50,36.49,75.02,37.64,77.83,39.25,80.77,41.21,84.55,0.0,60
116.66666666666667,250,20,50,31.62,85.8,35.73,93.89,37.87,99.45,45.18,113.01,0.0,60
233.33333333333334,500,30,50,34.62,79.12,36.78,83.66,39.84,86.93,46.35,93.02,0.0,60
326.66666666666663,700,35,50,48.19,71.91,49.88,74.88,51.22,77.84,53.34,82.1,0.0,45
280.0,600,35,50,35.53,76.41,37.71,80.33,39.35,83.95,41.73,89.52,0.0,60
350.0,750,40,50,35.9,75.24,37.46,78.09,38.76,81.09,40.42,83.09,0.0,60
163.33333333333331,350,40,50,32.11,81.99,35.9,87.81,38.59,91.41,44.96,99.23,0.0,60
466.6666666666667,1000,45,50,36.99,72.55,38.03,75.57,38.84,77.17,40.12,80.93,0.0,60
140.0,300,25,50,32.58,85.08,36.17,91.59,40.64,97.88,47.29,105.54,0.0,60
420.0,900,45,50,36.5,73.62,37.63,76.38,38.94,78.58,40.5,82.54,0.0,60
163.33333333333331,350,35,50,32.11,85.94,34.96,91.48,39.29,94.76,44.51,104.21,0.0,60
303.33333333333337,650,50,50,35.3,76.67,36.78,80.41,39.11,83.53,40.94,91.12,0.0,60
303.33333333333337,650,35,50,35.25,75.92,36.91,79.59,38.51,84.01,42.69,88.46,0.0,60
233.33333333333334,500,20,50,35.1,76.89,36.76,81.99,38.43,85.04,41.84,92.8,0.0,60
140.0,300,40,50,32.06,86.37,35.25,93.44,38.46,98.18,43.54,102.79,0.0,60
303.33333333333337,650,25,50,35.44,75.15,37.58,78.34,39.47,80.47,41.75,86.16,0.0,60
466.6666666666667,1000,30,50,36.85,71.91,38.27,74.9,39.32,77.38,41.47,82.47,0.0,60
163.33333333333331,350,25,50,32.35,81.43,35.31,86.98,38.2,93.05,42.53,99.44,0.0,60
93.33333333333334,200,35,50,28.91,95.73,33.23,105.4,37.27,111.4,43.57,115.96,0.0,60
420.0,900,40,50,48.63,69.92,50.36,72.36,51.26,74.85,52.75,78.03,0.0,45
93.33333333333334,200,45,50,27.94,95.04,30.59,104.96,37.05,110.63,44.5,120.78,0.0,60
396.66666666666663,850,25,50,36.78,72.56,38.02,75.93,39.08,78.3,41.81,81.96,0.0,60
420.0,900,30,50,36.39,73.3,37.94,76.21,38.91,78.85,40.55,82.9,0.0,60
46.66666666666667,100,40,50,22.05,109.59,27.47,120.73,35.6,132.59,47.77,162.04,0.0,60
280.0,600,45,50,35.29,77.24,37.3,81.92,39.0,85.35,42.0,90.61,0.0,60
46.66666666666667,100,30,50,22.82,109.63,28.96,125.83,36.75,139.1,76.12,170.65,0.0,60
186.66666666666669,400,45,50,33.13,82.97,36.27,87.43,39.21,92.11,43.0,101.53,0.0,60
303.33333333333337,650,20,50,36.55,73.6,37.92,77.2,39.19,80.35,41.51,86.1,0.0,60
350.0,750,45,50,35.4,75.04,37.24,78.4,38.67,81.04,40.52,85.63,0.0,60
350.0,750,20,50,36.3,73.96,37.83,77.3,39.04,80.71,41.24,84.07,0.0,60
186.66666666666669,400,35,50,32.87,83.26,35.67,87.76,38.3,92.17,44.31,96.33,0.0,60
396.66666666666663,850,50,50,36.03,75.62,36.97,77.8,38.18,80.68,39.94,85.47,0.0,60
116.66666666666667,250,50,50,29.66,92.15,32.49,98.37,34.86,101.77,40.5,109.01,0.0,60
93.33333333333334,200,25,50,30.55,94.49,34.45,104.61,39.06,109.21,47.53,128.8,0.0,60
46.66666666666667,100,45,50,21.59,114.02,28.85,126.62,35.61,137.23,56.98,180.97,0.0,60
116.66666666666667,250,25,50,31.29,90.25,34.44,97.06,39.65,104.72,48.49,108.71,0.0,60
373.33333333333337,800,45,50,35.94,74.6,37.21,78.14,37.93,80.83,41.85,85.79,0.0,60
420.0,900,20,50,36.99,72.22,38.11,75.17,39.05,77.92,39.74,81.08,0.0,60
443.33333333333337,950,50,50,36.39,73.54,37.66,75.32,38.26,77.23,40.44,83.19,0.0,60
350.0,750,30,50,35.86,74.92,37.47,77.8,38.65,80.66,40.56,83.5,0.0,60
443.33333333333337,950,25,50,36.86,72.48,38.06,75.32,39.55,77.89,41.92,81.12,0.0,60
373.33333333333337,800,50,50,35.78,75.08,36.82,77.72,37.82,79.76,40.12,82.36,0.0,60
256.66666666666663,550,35,50,35.1,78.43,36.59,82.81,38.95,86.07,42.03,89.88,0.0,60
140.0,300,45,50,31.29,87.6,33.71,94.48,38.51,99.78,45.02,105.67,0.0,60
256.66666666666663,550,25,50,35.36,77.79,37.3,81.79,39.46,85.11,42.57,89.31,0.0,60
210.0,450,50,50,33.48,82.52,35.38,86.25,37.9,89.96,40.21,96.2,0.0,60
280.0,600,50,50,34.43,77.71,36.3,81.01,38.23,84.09,42.05,89.52,0.0,60
326.66666666666663,700,20,50,36.12,74.54,37.62,76.85,39.13,79.94,41.02,84.39,0.0,60
210.0,450,20,50,34.27,77.73,37.04,83.05,39.18,85.4,45.84,92.14,0.0,60
443.33333333333337,950,20,50,37.21,71.77,38.38,74.22,39.81,75.67,42.55,79.51,0.0,60
23.333333333333336,50,40,50,16.82,127.82,22.35,149.76,32.71,169.01,49.79,205.38,0.0,60
23.333333333333336,50,20,50,16.85,128.21,25.75,147.4,38.75,172.18,79.98,249.9,2.0,60
233.33333333333334,500,50,50,34.04,79.05,36.56,84.33,38.25,87.91,43.19,98.05,0.0,60
140.0,300,50,50,31.09,88.99,33.54,94.24,37.56,99.4,40.07,113.38,0.0,60
396.66666666666663,850,40,50,36.22,74.61,37.42,77.47,38.99,79.51,41.58,83.34,0.0,60
163.33333333333331,350,45,50,31.88,85.49,34.57,90.87,37.29,97.03,42.37,104.77,0.0,60
466.6666666666667,1000,50,50,36.56,73.39,37.72,75.34,38.84,77.88,41.61,80.18,0.0,60
116.66666666666667,250,30,50,30.88,89.89,35.67,97.32,39.26,103.79,45.63,117.85,0.0,60
70.0,150,25,50,28.3,95.43,32.92,105.0,37.78,116.05,57.77,125.84,0.0,60
303.33333333333337,650,45,50,35.35,76.97,37.29,80.28,38.51,83.1,42.42,88.2,0.0,60
23.333333333333336,50,35,50,16.96,122.09,22.85,142.69,35.44,181.51,65.97,267.62,1.0,60
280.0,600,40,50,35.26,78.63,36.85,81.21,38.48,85.54,40.87,90.12,0.0,60
70.0,150,40,50,28.11,96.99,33.23,110.02,38.46,118.22,54.59,138.52,0.0,60
233.33333333333334,500,45,50,34.13,80.06,36.1,84.59,38.15,88.98,44.58,91.8,0.0,60
280.0,600,30,50,35.5,76.21,37.37,80.24,38.89,83.98,42.37,87.69,0.0,60
23.333333333333336,50,45,50,17.06,131.71,23.23,153.65,30.99,181.72,59.49,305.6,0.0,60
396.66666666666663,850,20,50,36.63,72.47,38.05,76.18,39.25,78.96,41.44,80.91,0.0,60
466.6666666666667,1000,25,50,37.53,72.38,38.65,74.85,39.44,76.42,41.29,80.24,0.0,60
466.6666666666667,1000,35,50,36.92,73.43,37.71,75.3,38.68,77.0,40.59,79.74,0.0,60
186.66666666666669,400,20,50,34.57,79.85,36.6,85.1,39.1,89.66,42.58,95.14,0.0,60
116.66666666666667,250,40,50,29.92,91.31,33.06,100.68,36.64,106.0,41.33,116.18,0.0,60
443.33333333333337,950,30,50,36.22,73.35,37.61,76.68,38.99,79.47,41.28,83.8,0.0,60
420.0,900,35,50,36.25,73.52,37.34,76.26,38.59,78.23,39.55,81.49,0.0,60
350.0,750,25,50,36.54,74.57,37.77,78.44,39.82,80.19,41.36,85.69,0.0,60
46.66666666666667,100,25,50,23.68,108.47,28.83,122.95,38.14,134.18,69.68,159.67,0.0,60
23.333333333333336,50,30,50,16.25,134.5,23.72,155.42,32.94,183.21,80.0,268.83,0.0,60
186.66666666666669,400,40,50,33.93,82.98,35.99,87.93,38.78,93.12,42.36,99.42,0.0,60
46.66666666666667,100,50,50,21.67,114.52,27.42,125.25,34.39,139.68,46.32,152.1,0.0,60
420.0,900,25,50,37.06,72.55,38.26,74.81,39.3,76.72,40.75,80.54,0.0,60
186.66666666666669,400,30,50,32.7,81.82,34.54,87.25,37.56,90.44,41.37,96.96,0.0,60
210.0,450,30,50,33.99,81.58,35.95,86.56,38.62,89.23,43.82,94.09,0.0,60
233.33333333333334,500,40,50,34.48,80.39,36.68,84.65,38.88,86.86,43.32,94.01,0.0,60
70.0,150,45,50,26.17,102.69,30.83,114.42,34.49,126.61,47.09,133.49,0.0,60
233.33333333333334,500,25,50,34.65,76.89,37.08,81.45,39.32,85.89,43.24,93.53,0.0,60
140.0,300,30,50,32.3,84.47,35.4,90.52,39.57,97.63,46.51,111.09,0.0,60
116.66666666666667,250,35,50,30.21,88.36,33.75,95.77,39.51,104.06,45.55,117.58,0.0,60
93.33333333333334,200,40,50,40.72,87.31,45.93,93.75,51.41,102.2,62.43,117.28,0.0,45
303.33333333333337,650,40,50,47.26,72.69,49.45,75.67,51.21,78.01,55.01,82.23,0.0,45
70.0,150,30,50,39.92,91.91,45.55,101.98,52.24,109.61,66.07,122.23,0.0,45
163.33333333333331,350,30,50,44.82,78.27,47.32,83.05,52.12,87.81,59.4,97.73,0.0,45
256.66666666666663,550,40,50,35.02,78.65,37.25,82.75,39.84,86.33,43.29,91.81,0.0,60
256.66666666666663,550,45,50,46.85,75.53,48.65,79.21,50.67,81.15,53.67,85.23,0.0,45
326.66666666666663,700,30,50,48.29,72.52,50.44,75.25,51.97,77.35,54.19,82.72,0.0,45
326.66666666666663,700,45,50,48.0,72.12,49.79,75.11,50.89,77.43,56.76,81.55,0.0,45
466.6666666666667,1000,20,50,29.44,74.43,30.43,77.28,31.77,79.01,33.68,83.23,0.0,75
210.0,450,35,50,46.4,75.79,48.33,78.64,51.07,80.99,56.99,88.68,0.0,45
233.33333333333334,500,35,50,47.42,74.23,49.97,77.56,51.6,80.61,55.86,88.5,0.0,45
326.66666666666663,700,50,50,28.46,79.37,29.62,82.77,30.91,85.6,32.65,91.44,0.0,75
280.0,600,20,50,48.25,70.64,50.57,75.86,52.96,78.81,57.0,81.98,0.0,45
210.0,450,45,50,26.76,86.46,28.53,92.88,30.47,98.19,33.64,108.15,0.0,75
373.33333333333337,800,25,50,29.33,75.59,30.57,79.27,31.89,82.13,34.01,85.17,0.0,75
140.0,300,20,50,25.98,89.33,29.49,96.97,32.89,103.77,36.58,117.76,0.0,75
93.33333333333334,200,30,50,23.01,98.35,27.13,108.45,30.37,112.81,36.5,125.11,0.0,75
93.33333333333334,200,50,50,21.14,102.94,24.0,111.03,26.93,117.61,41.78,125.24,0.0,75
256.66666666666663,550,20,50,28.19,79.7,30.25,84.62,31.89,88.54,35.51,95.42,0.0,75
163.33333333333331,350,50,50,42.87,81.53,45.94,85.33,50.8,89.12,54.91,97.52,0.0,45
70.0,150,50,50,18.55,110.22,22.7,120.96,27.77,129.81,32.0,149.42,0.0,75
186.66666666666669,400,50,50,25.17,88.93,26.85,94.48,28.65,99.33,32.02,105.37,0.0,75
46.66666666666667,100,20,50,36.84,99.49,45.8,110.51,60.38,126.16,79.99,146.0,1.0,45
70.0,150,35,50,36.69,95.76,45.91,104.82,55.42,111.86,79.99,121.84,1.0,45
186.66666666666669,400,25,50,26.83,85.78,29.95,91.5,32.21,97.1,35.5,106.17,0.0,75
373.33333333333337,800,35,50,28.85,78.02,30.35,81.03,31.08,83.79,33.07,87.39,0.0,75
396.66666666666663,850,30,50,29.12,76.84,30.15,80.73,30.99,83.57,32.22,88.19,0.0,75
396.66666666666663,850,35,50,28.83,76.89,30.44,80.25,31.7,82.31,33.6,87.18,0.0,75
443.33333333333337,950,45,50,29.0,76.51,30.21,80.02,31.11,82.43,33.4,84.78,0.0,75
163.33333333333331,350,20,50,26.42,86.5,28.24,91.89,30.02,98.31,36.02,112.11,0.0,75
140.0,300,35,50,24.52,91.18,27.46,99.1,31.38,102.07,37.24,111.31,0.0,75
116.66666666666667,250,20,50,24.42,91.54,28.21,100.17,32.22,108.47,39.68,126.41,0.0,75
256.66666666666663,550,50,50,26.98,83.34,28.39,87.16,30.18,90.93,34.42,95.0,0.0,75
373.33333333333337,800,40,50,28.84,77.67,30.12,79.93,31.04,82.85,33.27,87.53,0.0,75
23.333333333333336,50,50,50,12.49,147.97,15.78,168.85,22.12,196.66,53.57,259.91,0.0,75
93.33333333333334,200,20,50,42.11,83.8,47.83,90.88,55.33,97.57,64.99,107.52,0.0,45
350.0,750,50,50,28.23,78.87,30.01,83.27,31.35,85.45,32.91,89.74,0.0,75
443.33333333333337,950,35,50,29.26,76.15,30.47,79.48,31.66,81.44,33.34,85.01,0.0,75
350.0,750,35,50,28.65,78.44,29.64,81.1,31.34,84.78,35.65,90.44,0.0,75
280.0,600,25,50,28.04,79.7,29.49,83.75,32.06,88.54,35.68,93.57,0.0,75
373.33333333333337,800,30,50,28.91,77.04,30.11,80.26,31.4,82.44,33.68,90.0,0.0,75
350.0,750,40,50,28.34,78.77,29.64,81.45,31.57,84.89,34.58,88.58,0.0,75
326.66666666666663,700,40,50,48.17,71.16,50.24,74.23,52.14,75.91,55.45,79.09,0.0,45
326.66666666666663,700,25,50,28.41,78.31,29.56,81.79,30.85,83.81,33.36,87.84,0.0,75
420.0,900,50,50,28.63,77.4,29.42,80.01,30.78,83.0,33.23,88.3,0.0,75
46.66666666666667,100,35,50,23.57,110.0,29.66,128.21,39.49,143.85,55.9,168.27,0.0,60
443.33333333333337,950,25,50,29.59,75.1,30.49,77.76,31.13,80.15,33.1,86.83,0.0,75
163.33333333333331,350,40,50,25.32,89.13,27.15,95.72,29.41,99.36,32.94,106.42,0.0,75
373.33333333333337,800,20,50,29.04,76.01,30.14,79.05,31.57,81.42,32.39,84.87,0.0,75
163.33333333333331,350,35,50,25.92,87.82,27.5,94.19,29.24,97.08,33.6,106.51,0.0,75
140.0,300,40,50,25.01,90.07,27.76,96.59,29.46,102.08,32.93,110.94,0.0,75
466.6666666666667,1000,30,50,29.17,75.24,30.32,77.8,31.1,80.55,33.31,82.3,0.0,75
303.33333333333337,650,50,50,27.71,80.77,29.32,85.63,30.62,89.94,32.68,93.95,0.0,75
466.6666666666667,1000,40,50,36.43,72.77,37.69,75.46,38.7,77.4,41.15,81.79,0.0,60
233.33333333333334,500,30,50,26.99,81.26,28.81,88.49,31.12,91.85,33.22,95.09,0.0,75
443.33333333333337,950,40,50,28.96,76.39,30.06,79.37,31.59,81.37,32.86,83.55,0.0,75
140.0,300,25,50,25.3,90.13,28.9,98.82,31.43,104.1,37.99,110.28,0.0,75
396.66666666666663,850,45,50,28.97,77.17,30.17,79.76,31.19,83.95,33.42,87.62,0.0,75
256.66666666666663,550,30,50,27.07,81.86,28.46,86.59,29.64,91.12,32.88,96.17,0.0,75
420.0,900,30,50,29.22,75.72,30.09,79.79,31.53,82.13,33.17,87.19,0.0,75
303.33333333333337,650,30,50,27.93,79.19,29.49,82.46,31.24,86.19,33.94,92.3,0.0,75
210.0,450,25,50,27.11,83.15,29.11,89.34,31.58,95.22,35.38,101.29,0.0,75
420.0,900,45,50,29.05,76.52,29.99,79.29,31.14,81.94,32.43,84.92,0.0,75
210.0,450,40,50,26.85,84.04,28.85,88.67,29.97,94.02,34.39,97.27,0.0,75
303.33333333333337,650,20,50,28.38,78.76,30.14,83.28,31.72,86.47,35.2,96.63,0.0,75
93.33333333333334,200,45,50,22.6,103.28,26.34,111.18,29.74,126.23,39.42,134.08,0.0,75
93.33333333333334,200,35,50,22.62,99.09,25.25,110.52,30.32,117.0,39.73,131.92,0.0,75
163.33333333333331,350,25,50,25.01,89.9,27.46,95.14,30.03,98.74,34.12,109.87,0.0,75
466.6666666666667,1000,45,50,29.45,75.49,30.7,78.07,31.81,80.03,33.28,84.41,0.0,75
396.66666666666663,850,50,50,28.22,79.0,29.35,82.01,31.16,84.28,32.63,89.12,0.0,75
280.0,600,35,50,27.92,80.59,29.63,84.87,31.31,88.08,33.94,95.29,0.0,75
116.66666666666667,250,25,50,24.58,94.25,27.57,98.74,31.43,107.83,39.4,119.85,0.0,75
70.0,150,20,50,39.98,86.32,47.15,95.68,55.97,100.96,79.99,126.92,0.0,45
303.33333333333337,650,35,50,28.49,79.37,30.48,81.91,31.56,87.08,32.64,93.16,0.0,75
46.66666666666667,100,30,50,17.84,120.66,22.62,138.0,28.22,155.81,55.87,189.59,0.0,75
350.0,750,20,50,29.19,76.35,30.86,79.99,31.76,82.48,34.27,90.02,0.0,75
46.66666666666667,100,40,50,16.71,118.75,21.19,128.89,29.36,145.84,41.81,191.14,0.0,75
396.66666666666663,850,25,50,29.26,76.87,30.35,79.62,31.68,82.0,33.83,84.99,0.0,75
303.33333333333337,650,25,50,28.35,79.65,29.6,83.77,31.51,85.59,34.38,90.91,0.0,75
233.33333333333334,500,20,50,27.85,81.38,29.51,86.28,31.25,90.68,33.54,101.74,0.0,75
186.66666666666669,400,45,50,25.99,87.31,28.34,92.29,30.11,98.57,32.71,103.84,0.0,75
443.33333333333337,950,50,50,28.86,77.16,29.66,80.14,30.89,83.1,33.21,86.35,0.0,75
373.33333333333337,800,45,50,28.69,77.64,29.92,80.99,30.88,83.86,33.17,87.4,0.0,75
210.0,450,50,50,25.68,86.93,27.76,91.64,30.39,96.0,31.92,105.57,0.0,75
280.0,600,45,50,27.12,82.6,28.83,86.96,30.2,90.57,32.32,96.6,0.0,75
443.33333333333337,950,20,50,29.34,74.09,30.37,77.11,31.79,80.05,33.34,81.41,0.0,75
140.0,300,45,50,24.26,95.24,26.68,100.46,28.56,104.96,36.11,112.98,0.0,75
23.333333333333336,50,25,50,26.72,115.21,39.84,136.41,54.64,155.2,80.0,208.68,2.0,45
466.6666666666667,1000,50,50,28.66,76.95,29.74,80.03,30.65,82.26,32.79,84.56,0.0,75
116.66666666666667,250,45,50,23.67,95.3,26.71,102.01,29.66,111.9,33.25,142.14,0.0,75
350.0,750,45,50,28.29,79.28,29.4,83.36,30.64,85.37,32.86,89.48,0.0,75
186.66666666666669,400,35,50,25.89,88.97,28.03,93.26,29.79,97.45,32.61,107.54,0.0,75
350.0,750,30,50,28.86,78.12,30.17,81.34,31.93,83.74,34.27,90.22,0.0,75
93.33333333333334,200,25,50,23.56,95.59,26.41,102.4,28.36,113.21,41.24,129.02,0.0,75
163.33333333333331,350,45,50,25.52,89.65,27.34,96.46,29.02,101.07,33.7,111.07,0.0,75
420.0,900,20,50,29.47,74.92,30.19,77.98,31.48,80.82,33.57,85.85,0.0,75
23.333333333333336,50,20,50,13.84,139.22,20.78,163.38,29.66,194.52,79.99,315.45,0.0,75
326.66666666666663,700,20,50,28.48,77.67,30.04,80.67,31.03,84.98,33.9,89.5,0.0,75
233.33333333333334,500,45,50,26.83,84.29,28.69,88.22,30.31,92.4,32.87,96.08,0.0,75
116.66666666666667,250,50,50,23.3,94.63,25.39,105.15,32.26,111.25,42.21,125.89,0.0,75
23.333333333333336,50,45,50,11.61,139.76,17.32,165.79,24.7,199.27,45.24,238.25,1.0,75
116.66666666666667,250,30,50,24.13,94.42,26.49,100.44,29.22,105.81,33.37,119.8,0.0,75
256.66666666666663,550,25,50,28.08,80.43,30.03,85.17,31.64,90.15,33.09,92.65,0.0,75
70.0,150,25,50,20.33,105.11,23.68,117.68,28.11,130.46,40.88,140.33,0.0,75
396.66666666666663,850,40,50,28.73,77.31,29.73,80.4,31.18,82.43,32.97,86.84,0.0,75
466.6666666666667,1000,25,50,29.3,76.18,30.32,78.06,31.36,80.21,33.23,84.06,0.0,75
233.33333333333334,500,50,50,26.48,84.3,28.29,88.33,29.85,92.82,31.98,101.58,0.0,75
70.0,150,40,50,19.2,109.08,23.55,121.23,27.69,129.63,35.24,146.16,0.0,75
420.0,900,40,50,36.41,73.96,37.31,75.84,38.33,78.21,39.94,81.97,0.0,60
326.66666666666663,700,35,50,35.52,74.66,37.13,77.69,38.17,80.2,42.34,86.07,0.0,60
46.66666666666667,100,45,50,16.09,126.11,20.03,140.43,29.21,151.17,67.86,212.51,0.0,75
280.0,600,30,50,28.43,80.21,29.65,84.04,30.94,87.82,35.1,93.57,0.0,75
256.66666666666663,550,35,50,27.52,82.86,28.7,86.12,31.24,90.07,33.42,96.81,0.0,75
396.66666666666663,850,20,50,29.42,74.42,31.07,77.23,32.3,79.46,34.73,86.75,0.0,75
420.0,900,35,50,29.15,76.01,30.03,79.27,31.43,82.22,32.5,85.58,0.0,75
23.333333333333336,50,35,50,12.25,130.75,17.25,150.63,25.49,190.98,48.31,333.68,0.0,75
23.333333333333336,50,30,50,11.73,133.95,17.92,154.07,26.32,172.83,79.98,272.22,0.0,75
280.0,600,50,50,27.82,83.25,29.48,87.2,30.59,90.72,31.97,94.16,0.0,75
210.0,450,20,50,27.4,82.4,29.45,87.72,31.15,90.66,33.32,98.25,0.0,75
140.0,300,50,50,23.16,93.89,26.62,102.09,29.2,107.97,33.2,113.69,0.0,75
280.0,600,40,50,27.84,82.32,29.14,86.37,30.27,88.89,34.37,96.06,0.0,75
46.66666666666667,100,25,50,16.23,117.89,22.47,130.19,35.66,144.66,79.97,192.21,0.0,75
116.66666666666667,250,40,50,23.89,95.54,26.61,101.87,29.49,107.19,35.06,115.17,0.0,75
186.66666666666669,400,20,50,26.58,83.7,28.98,88.82,31.18,95.71,36.74,106.59,0.0,75
23.333333333333336,50,40,50,11.07,143.54,14.35,163.08,22.51,184.5,79.22,318.18,0.0,75
466.6666666666667,1000,35,50,29.36,75.93,30.44,79.15,31.4,82.19,34.03,87.37,0.0,75
303.33333333333337,650,45,50,27.89,80.94,29.42,84.71,30.74,87.48,32.38,93.0,0.0,75
350.0,750,25,50,29.2,77.56,30.35,81.82,31.56,85.78,35.04,88.55,0.0,75
186.66666666666669,400,40,50,26.31,87.58,28.33,91.71,31.03,95.74,34.45,103.78,0.0,75
46.66666666666667,100,50,50,15.63,118.89,19.58,137.69,26.28,152.57,50.25,189.26,0.0,75
373.33333333333337,800,50,50,28.43,78.62,29.41,81.83,30.63,84.55,31.84,87.62,0.0,75
443.33333333333337,950,30,50,29.11,75.47,30.18,78.53,31.34,81.03,33.85,85.28,0.0,75
186.66666666666669,400,30,50,26.2,85.51,28.38,91.78,30.27,96.21,38.08,103.03,0.0,75
210.0,450,30,50,27.14,83.73,28.83,88.57,30.99,91.81,34.43,101.1,0.0,75
420.0,900,25,50,29.29,75.82,30.53,78.73,31.65,82.53,33.64,88.09,0.0,75
233.33333333333334,500,40,50,27.08,83.99,29.05,88.54,31.2,92.25,34.4,95.98,0.0,75
70.0,150,45,50,19.0,109.6,22.7,121.7,25.97,126.74,32.57,143.28,0.0,75
233.33333333333334,500,25,50,27.62,81.75,29.3,87.86,31.87,91.86,35.94,96.05,0.0,75
116.66666666666667,250,35,50,23.67,94.4,26.36,105.32,28.54,111.26,35.57,118.76,0.0,75
140.0,300,30,50,24.3,91.68,26.91,97.88,30.05,102.42,34.78,111.72,0.0,75
93.33333333333334,200,40,50,28.55,93.87,32.34,103.7,35.4,112.7,48.19,123.03,0.0,60
256.66666666666663,550,40,50,27.43,80.97,29.07,85.8,30.35,90.03,31.91,94.37,0.0,75
70.0,150,30,50,28.13,98.39,32.2,110.33,42.43,121.84,48.78,128.98,0.0,60
466.6666666666667,1000,20,50,24.96,75.68,25.65,79.58,26.34,82.73,27.32,87.43,0.0,90
303.33333333333337,650,40,50,35.01,77.44,36.28,80.35,37.84,83.67,41.11,86.41,0.0,60
373.33333333333337,800,25,50,23.78,79.39,25.05,82.95,26.01,86.51,28.31,91.37,0.0,90
210.0,450,45,50,20.97,89.66,22.53,96.32,25.15,101.21,27.62,106.54,0.0,90
93.33333333333334,200,50,50,17.35,110.98,19.23,120.05,22.45,127.5,27.76,139.95,0.0,90
280.0,600,20,50,36.0,75.08,38.0,79.46,39.2,82.14,42.15,90.2,0.0,60
163.33333333333331,350,30,50,33.48,83.06,36.7,90.3,38.5,94.96,48.55,103.24,0.0,60
326.66666666666663,700,50,50,22.87,82.41,24.03,86.59,25.5,91.41,27.49,95.59,0.0,90
140.0,300,20,50,20.15,93.67,22.41,101.76,24.6,107.42,32.52,120.23,0.0,90
93.33333333333334,200,30,50,17.91,104.04,20.85,113.3,25.57,122.73,33.27,141.55,0.0,90
326.66666666666663,700,45,50,35.86,75.98,37.49,79.89,38.47,83.04,39.8,88.94,0.0,60
373.33333333333337,800,35,50,23.78,80.2,24.67,83.85,25.45,88.4,26.51,91.29,0.0,90
443.33333333333337,950,45,50,24.04,80.43,24.98,82.35,26.16,85.21,28.73,89.37,0.0,90
256.66666666666663,550,45,50,34.48,79.07,36.68,82.54,38.59,86.73,40.95,89.15,0.0,60
256.66666666666663,550,20,50,23.4,84.37,24.81,88.65,25.74,90.9,27.85,98.32,0.0,90
70.0,150,50,50,14.57,116.99,16.75,125.7,20.47,137.85,38.02,147.27,0.0,90
186.66666666666669,400,50,50,20.77,92.45,22.74,99.79,24.26,104.04,28.12,114.49,0.0,90
396.66666666666663,850,30,50,23.6,80.15,24.86,83.11,26.01,86.07,28.38,91.62,0.0,90
163.33333333333331,350,20,50,20.92,91.8,23.33,99.77,25.4,105.63,31.42,119.67,0.0,90
140.0,300,35,50,19.26,96.3,21.68,105.17,24.58,111.4,30.63,122.22,0.0,90
396.66666666666663,850,35,50,23.87,79.88,24.75,83.75,25.71,86.29,28.05,89.12,0.0,90
186.66666666666669,400,25,50,21.59,88.57,23.31,96.05,24.75,102.17,29.22,111.7,0.0,90
116.66666666666667,250,20,50,19.94,97.03,22.83,106.03,26.62,113.68,34.58,123.99,0.0,90
326.66666666666663,700,30,50,36.2,75.08,37.67,79.21,39.14,81.51,43.01,84.99,0.0,60
443.33333333333337,950,35,50,24.36,78.98,25.2,81.26,25.81,84.36,28.24,87.97,0.0,90
256.66666666666663,550,50,50,21.99,86.81,23.63,90.85,24.74,95.04,27.3,99.82,0.0,90
373.33333333333337,800,20,50,24.22,79.37,25.35,82.48,25.93,86.21,28.34,93.01,0.0,90
350.0,750,50,50,23.33,83.05,24.52,86.8,25.71,90.48,27.35,93.67,0.0,90
23.333333333333336,50,50,50,8.44,148.99,11.38,171.25,15.45,195.0,27.25,290.83,0.0,90
373.33333333333337,800,40,50,23.52,81.18,24.35,84.29,25.34,87.41,26.8,92.54,0.0,90
210.0,450,35,50,34.33,80.65,36.67,85.38,38.49,88.73,41.36,94.24,0.0,60
350.0,750,40,50,23.68,80.74,24.87,85.69,25.95,89.19,27.86,93.65,0.0,90
280.0,600,25,50,23.21,83.01,24.46,86.79,25.61,91.51,27.88,97.49,0.0,90
233.33333333333334,500,35,50,34.38,79.33,36.18,83.62,37.36,86.6,41.54,95.44,0.0,60
163.33333333333331,350,35,50,20.12,93.56,22.78,101.62,24.88,107.16,28.7,113.45,0.0,90
233.33333333333334,500,30,50,22.34,84.63,24.28,92.55,26.1,97.16,27.61,103.5,0.0,90
420.0,900,50,50,23.67,79.65,24.56,83.31,25.83,86.49,27.27,90.82,0.0,90
443.33333333333337,950,25,50,24.14,78.36,25.42,80.64,26.99,83.31,28.65,86.86,0.0,90
396.66666666666663,850,45,50,23.37,80.58,24.6,84.19,25.33,87.0,26.44,90.33,0.0,90
373.33333333333337,800,30,50,23.75,80.86,25.25,84.72,26.1,87.65,27.73,92.04,0.0,90
466.6666666666667,1000,30,50,24.37,78.01,25.51,81.45,26.29,83.47,27.48,87.6,0.0,90
420.0,900,30,50,23.95,78.54,25.25,82.2,25.82,85.46,27.85,87.92,0.0,90
350.0,750,35,50,23.86,81.95,24.7,84.55,25.47,88.61,27.33,96.12,0.0,90
140.0,300,40,50,19.89,98.22,21.63,105.88,23.57,110.4,29.56,121.99,0.0,90
163.33333333333331,350,50,50,32.14,85.56,34.22,91.28,37.86,93.99,46.24,99.31,0.0,60
233.33333333333334,500,20,50,22.6,85.56,23.75,89.26,25.53,95.03,27.96,100.57,0.0,90
210.0,450,25,50,22.87,85.49,24.36,90.93,26.46,95.16,28.87,101.9,0.0,90
280.0,600,35,50,22.54,85.23,23.95,89.82,25.25,92.49,27.46,96.06,0.0,90
70.0,150,35,50,26.38,100.27,31.9,112.16,36.6,120.79,51.28,130.85,0.0,60
303.33333333333337,650,50,50,22.75,85.77,23.87,89.35,25.28,92.48,26.53,98.43,0.0,90
210.0,450,50,50,21.66,92.1,23.65,97.36,25.76,102.39,28.4,111.85,0.0,90
163.33333333333331,350,40,50,21.23,93.42,22.75,100.71,24.57,106.84,29.45,115.71,0.0,90
326.66666666666663,700,25,50,23.61,80.94,25.19,83.97,26.46,86.93,27.28,93.68,0.0,90
163.33333333333331,350,25,50,21.29,93.85,23.38,100.11,26.69,105.26,29.97,115.79,0.0,90
46.66666666666667,100,20,50,24.84,110.18,33.2,122.06,49.81,143.09,79.99,169.64,1.0,60
396.66666666666663,850,50,50,23.48,81.17,24.72,84.75,25.63,87.23,26.78,92.19,0.0,90
140.0,300,25,50,19.97,94.84,22.26,104.13,24.84,112.66,30.62,119.57,0.0,90
46.66666666666667,100,30,50,13.22,121.7,17.74,139.0,21.66,152.78,37.64,181.99,0.0,90
443.33333333333337,950,20,50,24.25,77.52,25.35,80.26,26.14,82.05,27.53,86.32,0.0,90
396.66666666666663,850,25,50,23.99,78.88,24.94,82.14,25.93,84.77,27.33,89.06,0.0,90
93.33333333333334,200,45,50,17.31,114.42,19.66,124.16,21.65,134.0,26.3,153.15,0.0,90
93.33333333333334,200,35,50,17.5,106.46,21.22,117.34,24.43,125.79,38.2,131.97,0.0,90
303.33333333333337,650,30,50,23.66,81.94,24.79,86.94,26.06,90.11,28.36,93.25,0.0,90
443.33333333333337,950,40,50,24.18,78.36,25.23,82.53,25.92,85.88,27.64,89.89,0.0,90
210.0,450,40,50,21.96,89.88,23.47,95.84,25.63,100.66,27.17,106.79,0.0,90
256.66666666666663,550,30,50,22.26,84.45,23.74,89.18,25.5,92.49,29.2,98.78,0.0,90
116.66666666666667,250,25,50,19.53,96.61,23.47,107.45,26.85,112.45,32.46,126.47,0.0,90
303.33333333333337,650,20,50,23.44,81.07,24.59,84.17,25.92,89.08,28.07,91.22,0.0,90
420.0,900,45,50,23.89,80.46,24.87,83.98,25.7,86.19,27.69,90.41,0.0,90
46.66666666666667,100,40,50,13.18,120.74,16.19,136.13,19.42,151.04,27.63,190.82,0.0,90
140.0,300,45,50,19.34,97.85,21.41,103.91,23.26,110.76,31.76,120.65,0.0,90
116.66666666666667,250,45,50,18.56,102.32,20.73,109.3,24.1,114.77,28.42,128.71,0.0,90
350.0,750,20,50,23.92,80.28,24.93,83.29,26.36,86.52,29.3,92.08,0.0,90
163.33333333333331,350,45,50,20.8,92.5,22.75,100.82,24.4,106.48,27.4,116.74,0.0,90
70.0,150,25,50,15.64,111.95,18.58,124.41,23.98,133.92,34.67,147.05,0.0,90
466.6666666666667,1000,45,50,24.22,79.28,24.97,81.57,25.47,83.81,27.3,87.6,0.0,90
303.33333333333337,650,25,50,23.12,82.08,24.31,86.84,25.49,89.97,27.53,92.53,0.0,90
303.33333333333337,650,35,50,23.02,82.71,24.3,86.32,25.31,91.21,26.44,93.28,0.0,90
256.66666666666663,550,25,50,23.12,83.05,24.62,88.5,26.24,92.93,29.14,102.02,0.0,90
93.33333333333334,200,20,50,31.4,88.81,36.43,100.12,39.69,106.98,50.78,113.38,0.0,60
23.333333333333336,50,20,50,10.31,140.77,14.33,164.45,21.88,189.95,44.92,290.91,0.0,90
93.33333333333334,200,25,50,18.53,105.98,20.72,116.09,26.59,123.25,36.04,141.71,0.0,90
443.33333333333337,950,50,50,24.08,79.9,25.18,83.04,26.18,84.83,27.63,89.16,0.0,90
350.0,750,30,50,23.68,81.15,24.9,84.51,25.83,87.23,27.29,90.56,0.0,90
373.33333333333337,800,45,50,23.73,80.66,25.01,83.84,25.69,86.76,27.37,92.34,0.0,90
186.66666666666669,400,45,50,20.89,95.11,22.93,100.26,24.88,105.11,29.44,110.55,0.0,90
280.0,600,45,50,22.69,85.43,23.95,90.76,24.75,95.15,26.85,101.44,0.0,90
466.6666666666667,1000,40,50,29.09,75.77,30.03,78.67,31.02,81.12,33.29,83.52,0.0,75
70.0,150,40,50,15.35,110.25,17.96,123.49,21.72,134.52,35.69,151.69,0.0,90
466.6666666666667,1000,50,50,23.79,79.98,24.74,83.11,25.65,84.74,26.99,87.57,0.0,90
396.66666666666663,850,40,50,23.81,80.21,25.01,83.69,25.92,86.38,27.39,90.31,0.0,90
116.66666666666667,250,50,50,18.14,101.5,20.36,107.81,23.31,114.19,29.08,122.3,0.0,90
46.66666666666667,100,45,50,13.05,127.33,15.32,142.84,20.85,156.45,39.8,184.84,1.0,90
116.66666666666667,250,30,50,19.57,102.01,21.63,110.42,24.27,117.28,26.61,122.7,0.0,90
233.33333333333334,500,50,50,21.56,90.14,23.0,94.87,24.74,98.36,27.35,106.24,0.0,90
280.0,600,30,50,22.62,85.21,24.3,88.87,25.41,92.73,27.4,101.15,0.0,90
420.0,900,20,50,24.24,78.24,25.6,80.52,26.6,83.29,27.51,84.74,0.0,90
186.66666666666669,400,35,50,21.65,89.03,23.5,94.85,25.02,100.57,30.01,110.77,0.0,90
23.333333333333336,50,45,50,7.59,140.37,10.57,163.64,15.32,196.4,26.18,236.95,0.0,90
233.33333333333334,500,45,50,21.55,87.22,23.55,92.77,24.87,96.68,27.04,106.79,0.0,90
466.6666666666667,1000,25,50,24.18,77.4,25.03,79.97,25.92,82.73,26.81,85.98,0.0,90
350.0,750,45,50,23.32,82.01,24.63,85.39,26.03,89.15,26.72,93.9,0.0,90
23.333333333333336,50,30,50,9.18,141.69,12.2,171.08,16.67,198.9,44.28,266.8,0.0,90
420.0,900,35,50,24.16,79.86,25.26,82.36,26.15,85.04,27.38,89.66,0.0,90
326.66666666666663,700,20,50,23.68,78.71,24.96,82.75,26.27,87.32,28.19,92.34,0.0,90
23.333333333333336,50,40,50,8.17,146.9,10.74,171.86,16.88,200.63,35.56,267.77,0.0,90
326.66666666666663,700,40,50,35.56,76.66,37.2,79.78,38.35,81.47,41.36,87.9,0.0,60
23.333333333333336,50,25,50,17.71,126.73,23.73,142.7,31.29,165.84,61.71,195.01,2.0,60
256.66666666666663,550,35,50,22.08,85.2,23.39,90.34,25.38,94.57,26.52,98.56,0.0,90
46.66666666666667,100,50,50,12.76,128.45,16.08,141.24,19.19,156.53,36.99,189.43,0.0,90
116.66666666666667,250,40,50,18.71,101.0,20.76,111.67,22.96,118.1,33.14,132.37,0.0,90
70.0,150,20,50,28.23,102.88,32.79,112.33,41.61,121.94,74.14,148.27,1.0,60
46.66666666666667,100,35,50,18.05,119.79,21.07,134.43,26.76,147.27,56.99,178.05,0.0,75
23.333333333333336,50,35,50,8.13,144.7,10.53,165.62,13.04,178.42,28.58,241.94,0.0,90
280.0,600,50,50,23.0,85.19,23.88,90.3,24.74,93.96,26.9,100.95,0.0,90
396.66666666666663,850,20,50,24.02,79.41,24.88,82.04,26.47,84.88,27.81,87.82,0.0,90
210.0,450,20,50,22.38,85.58,24.05,90.69,26.38,94.7,30.21,104.47,0.0,90
186.66666666666669,400,20,50,21.94,86.47,23.87,93.04,26.11,97.05,29.33,114.69,0.0,90
280.0,600,40,50,23.18,84.98,24.11,88.38,25.35,93.25,27.84,97.01,0.0,90
46.66666666666667,100,25,50,14.62,128.78,17.48,145.02,23.34,160.13,43.16,216.49,0.0,90
140.0,300,50,50,20.02,99.77,21.94,105.63,23.57,112.98,27.43,120.89,0.0,90
420.0,900,40,50,28.93,76.44,29.98,79.21,30.93,82.26,32.74,84.37,0.0,75
186.66666666666669,400,30,50,21.39,91.51,22.6,94.72,24.67,98.83,28.79,108.4,0.0,90
466.6666666666667,1000,35,50,24.44,77.23,25.33,81.2,26.19,84.28,27.04,86.0,0.0,90
303.33333333333337,650,45,50,22.75,85.06,23.94,88.67,25.12,91.75,27.29,97.95,0.0,90
350.0,750,25,50,23.53,79.01,25.12,83.95,25.95,86.32,28.82,89.99,0.0,90
186.66666666666669,400,40,50,21.16,88.75,22.82,96.05,25.11,101.51,28.73,112.03,0.0,90
210.0,450,30,50,21.19,88.41,23.57,92.94,25.52,97.41,29.71,104.62,0.0,90
443.33333333333337,950,30,50,24.48,78.18,25.45,81.64,26.24,84.95,28.09,88.65,0.0,90
420.0,900,25,50,24.21,78.08,25.2,81.77,26.14,84.27,27.2,87.8,0.0,90
70.0,150,45,50,14.95,113.67,17.66,125.64,20.73,137.89,27.56,155.14,0.0,90
233.33333333333334,500,40,50,22.2,86.47,23.96,92.5,25.38,97.13,28.18,107.15,0.0,90
326.66666666666663,700,35,50,27.92,78.24,29.29,82.07,31.03,85.36,34.18,90.71,0.0,75
233.33333333333334,500,25,50,22.45,84.06,24.53,89.29,26.3,95.4,28.5,102.05,0.0,90
116.66666666666667,250,35,50,18.86,101.13,21.12,109.47,24.38,114.89,29.18,124.66,0.0,90
140.0,300,30,50,19.9,97.03,22.23,103.11,24.99,108.49,29.75,114.3,0.0,90
373.33333333333337,800,50,50,23.1,82.76,24.03,86.39,25.44,88.26,27.33,93.03,0.0,90
256.66666666666663,550,40,50,22.02,85.31,24.11,88.58,25.77,93.01,28.06,100.17,0.0,90
93.33333333333334,200,40,50,22.27,101.88,24.56,111.25,28.26,118.9,34.89,131.06,0.0,75
373.33333333333337,800,25,50,18.48,84.36,19.45,87.32,20.32,92.41,22.12,98.4,0.0,115
466.6666666666667,1000,20,50,19.33,80.09,19.85,83.82,20.77,86.22,22.38,92.04,0.0,115
280.0,600,20,50,28.2,77.95,30.28,82.47,31.56,86.8,33.46,96.7,0.0,75
326.66666666666663,700,50,50,17.48,88.7,18.54,92.59,19.69,96.07,21.82,101.44,0.0,115
210.0,450,45,50,16.21,96.73,17.32,103.13,18.6,109.17,20.89,112.81,0.0,115
93.33333333333334,200,50,50,12.2,118.66,14.76,125.79,16.95,135.11,21.55,152.96,0.0,115
70.0,150,30,50,20.3,107.94,24.25,118.11,29.35,133.08,41.73,149.24,0.0,75
443.33333333333337,950,45,50,18.43,84.39,19.22,87.79,19.67,91.77,20.72,96.08,0.0,115
373.33333333333337,800,35,50,18.12,85.8,19.17,89.16,19.93,92.28,21.13,95.59,0.0,115
140.0,300,20,50,15.39,101.66,16.63,109.25,18.42,116.26,21.67,125.34,0.0,115
70.0,150,50,50,11.09,129.76,13.8,138.61,15.61,147.93,26.2,178.62,0.0,115
396.66666666666663,850,30,50,18.25,82.7,19.57,86.15,20.76,89.09,21.46,94.59,0.0,115
140.0,300,35,50,14.64,103.39,16.58,112.6,18.8,116.47,23.0,129.59,0.0,115
93.33333333333334,200,30,50,13.13,112.32,15.53,122.7,16.97,130.88,22.81,144.13,0.0,115
163.33333333333331,350,20,50,15.9,99.3,17.4,107.15,19.17,115.19,22.1,119.11,0.0,115
256.66666666666663,550,20,50,17.66,88.72,19.16,92.99,20.44,98.99,23.87,106.16,0.0,115
116.66666666666667,250,20,50,14.82,107.55,17.03,114.74,20.98,123.39,25.58,136.28,0.0,115
326.66666666666663,700,45,50,27.9,80.68,29.33,84.31,31.57,87.19,33.26,90.53,0.0,75
186.66666666666669,400,50,50,15.19,99.89,16.43,103.31,18.61,110.47,20.58,117.87,0.0,115
280.0,600,25,50,17.85,88.56,18.85,92.07,19.93,98.27,22.24,107.8,0.0,115
350.0,750,40,50,17.84,88.21,18.99,91.46,19.71,94.38,21.05,99.73,0.0,115
396.66666666666663,850,45,50,18.51,83.58,19.26,88.68,20.31,91.69,21.09,96.77,0.0,115
373.33333333333337,800,20,50,18.81,82.95,20.2,86.67,20.74,91.23,22.52,93.92,0.0,115
373.33333333333337,800,40,50,18.18,85.11,19.28,89.11,20.2,92.5,21.66,98.92,0.0,115
303.33333333333337,650,40,50,27.85,80.66,29.01,84.76,30.26,87.8,32.6,90.28,0.0,75
233.33333333333334,500,20,50,17.57,89.55,19.0,96.42,19.96,101.2,22.59,109.08,0.0,115
163.33333333333331,350,35,50,15.77,100.96,17.5,107.13,19.52,115.1,21.77,124.65,0.0,115
163.33333333333331,350,30,50,25.92,88.53,28.12,94.32,31.49,98.77,35.88,106.66,0.0,75
443.33333333333337,950,35,50,18.77,82.13,19.56,85.33,20.33,89.2,21.72,91.46,0.0,115
396.66666666666663,850,35,50,18.42,83.87,19.06,87.18,19.78,90.65,21.4,94.56,0.0,115
256.66666666666663,550,45,50,27.27,82.94,28.8,87.39,30.31,91.78,33.77,96.43,0.0,75
186.66666666666669,400,25,50,16.7,92.95,18.35,100.99,20.12,106.58,22.81,111.38,0.0,115
350.0,750,50,50,17.91,87.8,18.71,91.42,20.04,93.49,21.42,97.97,0.0,115
280.0,600,35,50,17.52,88.7,18.59,94.66,19.93,99.58,21.86,103.13,0.0,115
256.66666666666663,550,50,50,16.92,91.75,18.07,98.32,19.37,104.01,21.98,109.66,0.0,115
466.6666666666667,1000,30,50,18.88,82.34,19.89,85.04,20.38,87.2,21.44,92.79,0.0,115
23.333333333333336,50,50,50,5.48,155.95,6.83,179.82,9.64,209.19,15.59,321.58,0.0,115
210.0,450,25,50,16.5,92.98,17.9,98.37,19.13,102.26,21.67,109.31,0.0,115
373.33333333333337,800,30,50,18.28,84.28,19.21,88.42,20.18,93.22,23.05,96.17,0.0,115
233.33333333333334,500,30,50,17.02,94.96,18.12,100.51,19.82,105.77,21.39,111.24,0.0,115
420.0,900,30,50,18.52,83.38,19.05,86.92,19.71,90.62,21.15,96.51,0.0,115
303.33333333333337,650,50,50,17.32,91.09,18.69,96.62,19.7,100.29,22.41,104.53,0.0,115
140.0,300,40,50,14.43,105.0,15.74,113.55,17.86,119.66,21.31,135.29,0.0,115
140.0,300,25,50,15.08,103.46,16.42,109.84,18.24,116.03,20.71,125.02,0.0,115
420.0,900,50,50,18.12,85.55,18.7,89.25,19.8,91.42,21.27,95.84,0.0,115
163.33333333333331,350,40,50,15.0,102.17,16.34,108.18,18.44,113.28,21.37,119.29,0.0,115
350.0,750,35,50,18.1,85.85,19.22,89.93,20.35,93.84,21.96,102.62,0.0,115
210.0,450,50,50,16.16,96.68,17.17,103.47,18.41,106.96,20.63,117.66,0.0,115
443.33333333333337,950,25,50,18.84,81.97,19.49,85.14,20.77,88.58,22.19,94.44,0.0,115
163.33333333333331,350,25,50,16.23,96.66,17.76,103.34,19.58,110.93,23.16,124.22,0.0,115
396.66666666666663,850,50,50,18.07,84.44,18.83,89.61,20.2,92.03,21.34,96.09,0.0,115
93.33333333333334,200,45,50,13.03,118.25,15.14,130.91,17.91,139.6,22.09,153.26,0.0,115
163.33333333333331,350,45,50,15.16,101.58,16.55,110.16,18.52,115.81,20.36,121.31,0.0,115
443.33333333333337,950,20,50,19.01,80.45,19.96,84.24,20.81,86.06,21.46,90.26,0.0,115
116.66666666666667,250,45,50,13.3,113.43,14.73,122.09,16.75,127.61,20.19,141.06,0.0,115
326.66666666666663,700,25,50,18.44,85.41,19.35,89.09,21.06,93.72,23.0,99.46,0.0,115
93.33333333333334,200,35,50,12.94,114.52,14.87,126.95,16.38,138.46,22.32,164.85,0.0,115
396.66666666666663,850,25,50,18.75,82.95,19.6,86.74,20.28,91.33,22.37,93.19,0.0,115
303.33333333333337,650,20,50,18.44,85.07,19.24,89.91,20.22,93.82,23.12,96.17,0.0,115
326.66666666666663,700,30,50,28.41,78.86,29.84,81.06,31.27,85.3,33.18,92.17,0.0,75
420.0,900,45,50,18.17,85.17,19.38,87.72,20.15,90.68,20.59,95.09,0.0,115
46.66666666666667,100,30,50,9.19,130.21,11.86,148.5,15.53,161.22,31.12,241.35,0.0,115
70.0,150,25,50,12.16,116.83,14.92,128.41,18.47,143.51,31.2,170.99,0.0,115
140.0,300,45,50,13.99,106.11,15.83,114.38,17.22,120.98,21.32,133.29,0.0,115
116.66666666666667,250,25,50,14.36,107.3,16.49,115.43,18.16,125.63,24.26,144.49,0.0,115
23.333333333333336,50,20,50,6.86,148.52,8.74,168.0,13.35,204.62,28.75,309.83,0.0,115
303.33333333333337,650,30,50,17.89,87.31,18.98,92.4,19.99,96.9,23.19,103.4,0.0,115
233.33333333333334,500,35,50,26.76,81.7,28.19,87.81,30.4,91.55,34.47,99.99,0.0,75
443.33333333333337,950,40,50,18.79,82.85,19.46,87.24,20.47,90.36,22.0,94.25,0.0,115
350.0,750,20,50,18.72,82.98,19.83,87.01,20.71,89.93,22.15,95.74,0.0,115
303.33333333333337,650,25,50,17.92,87.49,19.0,92.49,20.02,96.15,21.03,100.76,0.0,115
46.66666666666667,100,40,50,8.86,135.34,10.88,149.06,14.07,157.07,23.65,200.4,0.0,115
210.0,450,40,50,16.05,97.27,17.09,103.62,18.45,107.41,21.39,114.82,0.0,115
116.66666666666667,250,30,50,14.13,106.48,15.82,114.43,17.75,121.97,21.01,140.49,0.0,115
443.33333333333337,950,50,50,18.21,83.41,18.82,87.6,19.64,89.95,20.73,93.03,0.0,115
46.66666666666667,100,45,50,8.31,138.65,10.78,152.47,13.66,165.45,25.26,203.83,0.0,115
256.66666666666663,550,25,50,17.49,87.14,18.31,94.3,19.44,98.5,21.52,104.05,0.0,115
280.0,600,45,50,16.76,91.71,17.8,97.02,18.88,102.82,20.96,108.01,0.0,115
256.66666666666663,550,30,50,16.85,91.05,18.29,96.26,20.12,100.44,21.8,108.33,0.0,115
93.33333333333334,200,25,50,13.67,113.4,15.83,125.86,17.97,140.08,22.63,153.23,0.0,115
23.333333333333336,50,40,50,5.67,161.32,6.78,182.61,9.86,206.97,41.02,311.35,0.0,115
70.0,150,35,50,20.43,112.48,23.89,118.56,27.78,130.06,37.14,151.81,0.0,75
280.0,600,30,50,17.68,89.69,18.76,93.93,20.2,97.28,22.72,101.36,0.0,115
23.333333333333336,50,30,50,5.83,151.95,7.75,175.0,12.31,199.62,45.84,245.38,0.0,115
23.333333333333336,50,45,50,5.51,154.36,6.92,178.19,11.19,207.95,19.86,277.33,0.0,115
420.0,900,35,50,18.36,82.77,19.33,86.44,19.74,89.86,20.98,95.19,0.0,115
466.6666666666667,1000,25,50,18.73,81.13,19.66,84.44,20.21,86.52,21.44,90.26,0.0,115
396.66666666666663,850,40,50,18.48,84.36,19.4,87.69,20.15,91.35,21.04,96.73,0.0,115
350.0,750,30,50,18.32,84.26,19.34,88.5,20.25,93.24,21.21,96.65,0.0,115
303.33333333333337,650,35,50,17.45,87.41,18.7,91.9,19.68,95.42,21.47,100.45,0.0,115
186.66666666666669,400,45,50,15.97,97.48,17.39,103.99,19.1,110.0,21.82,115.88,0.0,115
466.6666666666667,1000,45,50,18.48,82.76,19.37,84.94,20.09,88.46,21.01,92.58,0.0,115
210.0,450,35,50,26.57,82.69,28.98,88.78,30.51,93.66,32.87,100.48,0.0,75
256.66666666666663,550,35,50,17.34,89.37,18.25,95.22,19.62,98.73,22.01,107.01,0.0,115
233.33333333333334,500,45,50,16.3,94.51,17.56,100.4,19.2,104.63,21.67,112.43,0.0,115
46.66666666666667,100,20,50,17.99,115.43,22.86,127.0,27.65,141.57,51.0,179.47,0.0,75
116.66666666666667,250,50,50,13.3,110.31,15.33,120.07,16.94,127.18,18.44,139.48,0.0,115
46.66666666666667,100,50,50,8.65,145.44,10.08,155.12,13.22,174.8,22.42,187.29,0.0,115
420.0,900,20,50,19.0,82.94,19.65,86.91,20.22,89.17,20.88,91.87,0.0,115
163.33333333333331,350,50,50,24.62,92.41,27.15,97.83,28.76,101.42,31.04,105.98,0.0,75
466.6666666666667,1000,50,50,18.57,83.34,19.41,87.18,19.97,89.82,20.76,96.47,0.0,115
186.66666666666669,400,35,50,15.99,97.92,17.36,105.31,19.35,113.32,23.09,123.28,0.0,115
373.33333333333337,800,45,50,18.15,86.52,18.78,90.05,20.03,92.47,22.09,97.79,0.0,115
70.0,150,40,50,11.33,123.22,12.8,136.06,14.81,153.83,21.26,186.19,0.0,115
23.333333333333336,50,35,50,6.02,157.19,8.03,182.42,11.21,225.37,33.33,306.14,0.0,115
326.66666666666663,700,20,50,18.25,85.02,19.45,89.36,20.45,94.59,22.9,100.32,0.0,115
233.33333333333334,500,50,50,16.52,94.42,17.83,99.44,19.77,106.7,22.38,111.37,0.0,115
46.66666666666667,100,25,50,9.26,134.51,11.61,146.75,14.37,159.29,31.48,194.93,0.0,115
280.0,600,50,50,16.82,91.4,17.61,94.75,18.6,99.67,19.81,106.55,0.0,115
350.0,750,45,50,18.23,85.58,19.01,90.6,20.06,93.06,21.61,98.09,0.0,115
116.66666666666667,250,40,50,13.75,107.28,15.49,118.16,17.51,128.1,20.21,146.41,0.0,115
140.0,300,50,50,14.22,108.24,15.69,114.55,16.94,123.03,19.38,130.27,0.0,115
93.33333333333334,200,20,50,22.78,94.92,25.61,107.81,30.72,116.0,44.77,122.45,0.0,75
303.33333333333337,650,45,50,17.4,89.51,18.43,94.94,19.44,98.47,20.45,102.86,0.0,115
210.0,450,20,50,16.87,91.54,18.15,97.45,20.05,100.82,22.83,103.96,0.0,115
396.66666666666663,850,20,50,18.6,82.45,19.68,87.26,20.53,89.67,21.86,92.3,0.0,115
186.66666666666669,400,20,50,16.5,94.52,18.23,101.32,19.56,111.87,22.57,119.99,0.0,115
466.6666666666667,1000,35,50,18.74,82.49,19.61,86.49,20.21,88.71,20.72,93.47,0.0,115
466.6666666666667,1000,40,50,23.87,79.42,24.85,81.96,25.82,84.52,26.79,88.99,0.0,90
280.0,600,40,50,17.41,91.01,18.41,95.94,19.7,101.01,21.05,102.55,0.0,115
420.0,900,25,50,18.74,83.29,19.81,85.83,20.69,88.78,21.77,92.88,0.0,115
70.0,150,20,50,20.71,101.39,24.53,112.79,28.8,121.73,35.18,140.04,0.0,75
186.66666666666669,400,30,50,16.13,97.54,17.05,103.15,19.83,109.54,24.4,116.02,0.0,115
186.66666666666669,400,40,50,16.2,96.42,17.29,105.07,18.24,111.06,19.87,120.43,0.0,115
23.333333333333336,50,25,50,11.57,135.81,17.13,157.69,25.58,185.63,43.79,247.64,0.0,75
350.0,750,25,50,18.36,84.69,19.18,89.69,20.02,92.53,22.39,101.22,0.0,115
46.66666666666667,100,35,50,13.0,126.39,15.36,140.24,18.73,157.44,40.9,190.55,0.0,90
210.0,450,30,50,16.85,95.01,17.76,100.99,18.97,104.4,20.98,107.72,0.0,115
70.0,150,45,50,11.01,123.3,12.84,134.12,15.47,153.91,19.55,168.01,0.0,115
233.33333333333334,500,40,50,16.92,93.21,17.85,98.82,18.88,102.79,21.1,111.45,0.0,115
420.0,900,40,50,23.61,78.21,24.64,82.32,25.76,85.69,26.86,89.91,0.0,90
443.33333333333337,950,30,50,18.77,82.61,19.72,85.67,20.45,90.05,21.73,93.72,0.0,115
326.66666666666663,700,40,50,28.33,79.38,29.83,82.52,30.97,86.84,34.41,90.42,0.0,75
116.66666666666667,250,35,50,14.02,109.96,15.88,119.3,18.67,126.86,28.71,136.03,0.0,115
233.33333333333334,500,25,50,16.88,91.29,18.37,98.15,19.38,102.76,22.15,107.25,0.0,115
140.0,300,30,50,15.34,105.82,17.07,113.76,18.92,120.71,19.89,134.16,0.0,115
326.66666666666663,700,35,50,23.5,81.06,24.7,86.22,26.27,90.26,27.8,93.61,0.0,90
373.33333333333337,800,50,50,18.27,85.69,18.92,89.17,19.72,92.91,21.19,96.64,0.0,115
466.6666666666667,1000,20,50,14.77,84.22,15.4,87.79,16.4,92.14,17.47,98.7,0.0,150
373.33333333333337,800,25,50,13.58,89.83,14.37,94.24,15.46,97.2,16.15,101.88,0.0,150
256.66666666666663,550,40,50,17.28,92.68,18.32,99.7,19.57,102.92,21.28,106.13,0.0,115
280.0,600,20,50,23.02,82.26,24.06,85.63,25.81,90.15,30.35,93.78,0.0,90
396.66666666666663,850,30,50,13.95,89.42,14.59,94.94,15.7,98.32,17.22,103.39,0.0,150
93.33333333333334,200,50,50,8.43,126.4,9.76,135.18,12.57,142.26,15.91,157.17,0.0,150
210.0,450,45,50,11.65,102.79,13.05,108.48,13.94,113.92,15.48,128.89,0.0,150
443.33333333333337,950,45,50,13.77,90.2,14.45,93.6,14.93,95.58,16.04,99.67,0.0,150
93.33333333333334,200,40,50,17.67,106.03,19.42,116.79,22.68,123.54,27.75,132.98,0.0,90
326.66666666666663,700,50,50,13.27,95.88,14.03,100.48,14.91,102.78,16.01,107.55,0.0,150
373.33333333333337,800,35,50,13.79,90.07,14.42,93.86,14.9,99.01,16.06,103.24,0.0,150
140.0,300,20,50,11.08,108.86,12.53,117.84,14.69,124.86,20.11,129.17,0.0,150
163.33333333333331,350,20,50,12.01,104.87,13.11,111.48,14.87,117.78,18.06,130.62,0.0,150
70.0,150,50,50,7.16,136.58,8.84,147.09,10.93,164.22,16.29,204.42,0.0,150
140.0,300,35,50,10.99,111.93,12.46,122.16,13.83,128.09,16.78,140.55,0.0,150
350.0,750,40,50,13.71,92.17,14.26,97.81,14.74,100.36,16.05,105.45,0.0,150
70.0,150,30,50,16.1,111.3,19.31,124.26,23.14,137.01,29.83,155.24,0.0,90
373.33333333333337,800,40,50,13.64,90.74,14.63,95.09,15.14,98.46,17.12,103.97,0.0,150
116.66666666666667,250,20,50,10.45,115.06,11.6,127.03,13.95,142.56,17.82,149.39,0.0,150
186.66666666666669,400,50,50,11.28,107.64,12.31,115.6,13.38,123.74,15.19,136.18,0.0,150
280.0,600,25,50,12.89,95.17,14.14,100.08,15.25,104.06,16.99,109.79,0.0,150
93.33333333333334,200,30,50,9.42,120.45,10.57,132.21,12.53,142.01,16.93,155.86,0.0,150
396.66666666666663,850,45,50,13.39,92.29,14.3,96.03,14.91,99.3,15.81,102.53,0.0,150
233.33333333333334,500,20,50,13.09,96.45,14.25,103.94,15.18,110.12,18.23,115.97,0.0,150
163.33333333333331,350,35,50,10.9,109.58,12.23,117.9,13.31,123.61,15.37,132.28,0.0,150
443.33333333333337,950,35,50,14.05,88.5,14.62,91.3,15.3,95.81,16.12,103.48,0.0,150
466.6666666666667,1000,30,50,14.3,85.48,14.8,89.05,15.6,92.15,17.38,96.94,0.0,150
396.66666666666663,850,50,50,13.78,92.36,14.43,96.39,15.15,100.12,16.16,104.13,0.0,150
256.66666666666663,550,20,50,12.98,95.96,13.97,101.52,14.68,105.7,16.34,113.25,0.0,150
420.0,900,30,50,13.91,89.23,14.42,92.75,15.08,95.26,15.91,101.73,0.0,150
350.0,750,50,50,13.2,93.9,13.91,99.2,14.61,103.33,16.07,108.18,0.0,150
373.33333333333337,800,20,50,13.94,87.94,14.82,92.11,15.55,95.83,18.11,100.01,0.0,150
443.33333333333337,950,20,50,14.35,85.2,15.12,88.9,15.65,91.83,17.06,95.38,0.0,150
233.33333333333334,500,30,50,12.79,98.37,13.5,105.3,14.89,110.22,15.53,118.42,0.0,150
23.333333333333336,50,50,50,3.61,167.24,4.55,187.28,6.37,217.92,11.32,268.74,0.0,150
303.33333333333337,650,50,50,12.91,96.95,13.95,103.16,14.6,108.34,15.69,114.55,0.0,150
140.0,300,40,50,10.36,116.21,11.79,124.63,13.09,128.99,15.91,136.99,0.0,150
210.0,450,50,50,11.44,109.15,12.41,113.61,13.49,118.69,15.32,128.93,0.0,150
373.33333333333337,800,30,50,13.81,90.26,14.77,94.83,15.38,99.12,17.3,106.03,0.0,150
326.66666666666663,700,45,50,23.16,82.96,24.55,85.74,25.49,90.67,27.49,96.85,0.0,90
93.33333333333334,200,45,50,8.93,128.19,10.31,137.36,11.71,147.72,14.63,178.17,0.0,150
396.66666666666663,850,35,50,13.83,90.74,14.55,94.85,15.2,98.38,16.48,101.34,0.0,150
163.33333333333331,350,40,50,11.25,115.2,12.6,122.65,13.62,129.03,16.81,140.49,0.0,150
420.0,900,50,50,13.48,90.89,14.19,95.0,14.67,98.38,16.0,102.52,0.0,150
163.33333333333331,350,45,50,11.02,109.36,12.31,117.26,13.83,124.54,17.24,135.57,0.0,150
256.66666666666663,550,50,50,12.23,101.99,13.26,106.19,13.99,110.69,14.7,115.49,0.0,150
163.33333333333331,350,25,50,11.49,105.6,12.8,112.88,13.99,120.62,15.56,131.67,0.0,150
140.0,300,25,50,11.2,108.67,12.54,117.9,14.18,126.8,18.42,142.71,0.0,150
280.0,600,35,50,12.86,96.28,13.58,102.19,14.31,107.12,16.18,115.52,0.0,150
186.66666666666669,400,25,50,11.69,102.27,13.26,111.35,14.99,119.09,16.89,129.67,0.0,150
116.66666666666667,250,25,50,10.43,114.96,12.23,123.41,13.53,137.95,16.52,153.94,0.0,150
116.66666666666667,250,45,50,9.24,122.29,10.46,130.7,11.64,138.62,15.35,150.06,0.0,150
93.33333333333334,200,35,50,9.09,126.87,10.21,139.34,12.17,147.68,19.27,165.72,0.0,150
350.0,750,35,50,13.5,92.5,14.46,98.26,15.33,102.17,15.83,106.27,0.0,150
210.0,450,25,50,12.09,100.96,13.76,108.05,15.03,112.64,17.81,121.69,0.0,150
70.0,150,25,50,8.44,131.1,9.96,149.57,11.95,161.79,18.42,201.3,0.0,150
443.33333333333337,950,25,50,13.99,87.6,14.84,91.2,15.66,93.98,16.67,98.39,0.0,150
350.0,750,20,50,13.8,90.51,14.99,94.81,15.78,97.53,16.84,100.66,0.0,150
163.33333333333331,350,30,50,20.42,93.89,22.13,98.76,23.65,103.62,29.94,109.92,0.0,90
396.66666666666663,850,25,50,13.96,87.76,14.41,93.23,15.11,97.23,16.16,104.72,0.0,150
23.333333333333336,50,20,50,4.33,159.02,6.08,185.07,7.99,211.3,13.76,267.68,0.0,150
46.66666666666667,100,30,50,6.13,142.61,7.15,160.34,8.53,176.23,13.29,192.61,0.0,150
303.33333333333337,650,20,50,13.71,91.17,14.69,97.33,15.65,100.96,17.09,107.07,0.0,150
303.33333333333337,650,30,50,13.22,95.46,14.09,99.1,14.99,104.05,15.63,111.64,0.0,150
93.33333333333334,200,25,50,9.39,120.2,11.0,139.28,12.53,146.88,22.96,166.97,0.0,150
140.0,300,45,50,10.03,116.1,11.43,123.25,13.27,129.34,17.38,135.54,0.0,150
326.66666666666663,700,25,50,13.63,91.99,14.47,96.13,15.45,99.26,16.85,108.48,0.0,150
46.66666666666667,100,40,50,6.15,149.34,7.93,165.03,9.44,185.52,21.17,209.04,0.0,150
116.66666666666667,250,30,50,10.23,114.19,12.35,123.83,13.47,134.71,18.44,147.6,0.0,150
46.66666666666667,100,45,50,6.06,153.0,7.0,162.15,8.77,179.99,14.67,227.4,0.0,150
303.33333333333337,650,25,50,13.41,93.66,14.3,96.85,15.09,102.91,16.02,112.38,0.0,150
420.0,900,35,50,13.92,88.5,14.59,92.71,15.16,96.2,16.13,100.41,0.0,150
443.33333333333337,950,50,50,13.48,90.71,14.06,95.25,14.94,97.67,16.23,102.56,0.0,150
23.333333333333336,50,40,50,3.6,167.46,4.47,186.37,5.89,207.45,11.96,311.94,0.0,150
420.0,900,45,50,14.09,89.56,14.59,95.34,15.44,97.44,16.78,101.3,0.0,150
280.0,600,45,50,12.88,98.04,13.78,104.31,14.59,109.35,16.28,117.2,0.0,150
303.33333333333337,650,40,50,23.08,82.78,24.7,87.21,25.37,91.25,27.22,97.87,0.0,90
443.33333333333337,950,40,50,13.67,89.32,14.45,93.7,15.33,98.02,16.77,101.94,0.0,150
70.0,150,40,50,7.74,137.13,9.7,147.37,11.53,164.61,17.15,182.89,0.0,150
256.66666666666663,550,35,50,12.48,98.86,13.51,105.98,14.31,109.53,15.56,118.79,0.0,150
46.66666666666667,100,50,50,5.88,150.86,7.35,170.52,9.06,192.3,16.33,226.03,0.0,150
420.0,900,20,50,14.16,87.55,14.78,91.22,15.76,93.91,17.29,96.82,0.0,150
210.0,450,40,50,11.71,105.74,13.15,113.47,14.56,118.37,15.74,132.08,0.0,150
350.0,750,30,50,13.58,92.02,14.28,96.72,15.38,100.87,15.95,107.13,0.0,150
23.333333333333336,50,45,50,3.34,166.62,4.16,189.68,5.13,204.15,7.27,253.98,0.0,150
256.66666666666663,550,45,50,22.41,86.4,23.97,90.23,25.44,95.72,30.75,100.26,0.0,90
256.66666666666663,550,25,50,12.9,96.24,14.02,103.58,15.13,110.51,17.68,113.9,0.0,150
466.6666666666667,1000,25,50,14.46,86.22,15.1,89.95,15.6,93.82,16.71,98.5,0.0,150
23.333333333333336,50,30,50,3.78,159.71,4.8,185.47,6.65,212.19,12.82,303.77,0.0,150
46.66666666666667,100,25,50,6.2,142.81,7.47,157.11,9.56,170.93,19.07,204.51,0.0,150
280.0,600,30,50,12.86,96.36,13.63,102.39,14.55,105.97,15.49,111.64,0.0,150
280.0,600,50,50,12.79,96.94,13.69,102.77,14.57,107.5,15.82,113.97,0.0,150
466.6666666666667,1000,50,50,13.93,88.35,14.61,92.4,15.34,95.57,16.37,99.75,0.0,150
396.66666666666663,850,40,50,13.63,91.37,14.33,94.62,14.96,98.82,15.65,101.27,0.0,150
116.66666666666667,250,40,50,9.54,114.6,11.13,124.08,12.59,135.89,18.06,152.22,0.0,150
326.66666666666663,700,20,50,13.8,90.8,14.57,96.02,15.46,100.64,16.44,109.32,0.0,150
373.33333333333337,800,45,50,13.37,92.32,14.06,96.09,15.06,99.1,17.05,104.93,0.0,150
23.333333333333336,50,35,50,3.91,163.83,5.03,185.75,6.67,218.66,17.17,407.82,0.0,150
303.33333333333337,650,35,50,13.03,97.1,13.88,101.52,14.5,104.86,16.03,108.58,0.0,150
256.66666666666663,550,30,50,12.57,97.03,13.67,103.16,14.78,107.25,16.12,117.21,0.0,150
116.66666666666667,250,50,50,9.41,122.67,10.91,131.72,11.82,138.73,15.16,150.41,0.0,150
70.0,150,35,50,16.0,117.36,18.08,127.12,23.15,138.35,36.1,153.66,1.0,90
186.66666666666669,400,45,50,11.32,110.74,12.67,116.56,13.84,123.0,16.96,130.02,0.0,150
303.33333333333337,650,45,50,13.34,95.15,14.0,101.04,15.25,106.2,16.62,115.04,0.0,150
233.33333333333334,500,45,50,12.06,103.28,13.36,109.97,14.03,116.38,15.4,124.12,0.0,150
466.6666666666667,1000,45,50,14.25,87.79,14.8,92.91,15.34,95.67,16.77,99.71,0.0,150
233.33333333333334,500,50,50,12.08,105.1,13.06,110.83,13.67,115.79,15.26,121.1,0.0,150
186.66666666666669,400,35,50,11.61,105.13,12.53,113.37,13.9,117.92,15.65,127.36,0.0,150
350.0,750,45,50,13.33,94.54,14.08,98.0,14.87,102.57,15.73,106.29,0.0,150
210.0,450,20,50,13.18,98.82,14.15,105.96,14.92,112.37,16.45,120.13,0.0,150
326.66666666666663,700,30,50,23.56,80.72,24.69,85.2,26.61,89.19,27.79,93.28,0.0,90
140.0,300,50,50,10.31,115.91,11.82,121.76,13.28,127.39,14.55,140.31,0.0,150
186.66666666666669,400,40,50,11.25,109.47,12.22,115.06,13.29,119.41,16.1,123.05,0.0,150
233.33333333333334,500,35,50,22.1,88.82,24.16,93.22,25.65,98.13,28.65,103.16,0.0,90
186.66666666666669,400,20,50,12.26,99.78,13.54,106.74,14.9,111.49,17.51,130.33,0.0,150
420.0,900,25,50,14.19,87.08,14.84,90.9,15.37,96.17,16.75,100.78,0.0,150
46.66666666666667,100,20,50,14.72,120.79,17.95,141.14,22.94,154.39,41.7,169.25,0.0,90
396.66666666666663,850,20,50,14.24,86.78,15.03,92.15,15.78,94.95,16.93,98.19,0.0,150
466.6666666666667,1000,35,50,14.12,88.15,14.83,92.28,15.72,94.33,16.4,100.39,0.0,150
210.0,450,35,50,22.07,86.91,23.63,92.79,24.91,99.53,27.57,104.29,0.0,90
280.0,600,40,50,12.88,97.07,13.96,102.65,14.69,106.79,17.47,109.66,0.0,150
70.0,150,20,50,17.74,112.49,20.62,123.57,26.42,139.73,46.04,148.74,0.0,90
186.66666666666669,400,30,50,11.88,105.5,13.04,111.73,14.22,120.52,16.89,126.95,0.0,150
233.33333333333334,500,40,50,12.31,99.88,13.42,105.77,14.24,109.08,15.98,114.31,0.0,150
350.0,750,25,50,13.76,88.84,14.71,94.07,15.17,98.0,17.05,105.17,0.0,150
116.66666666666667,250,35,50,9.81,116.72,11.44,126.82,13.52,137.6,17.3,144.73,0.0,150
466.6666666666667,1000,40,50,18.69,82.14,19.33,86.49,20.24,88.79,21.73,91.44,0.0,115
163.33333333333331,350,50,50,20.67,94.1,22.59,101.39,24.29,106.07,28.72,114.65,0.0,90
93.33333333333334,200,20,50,18.84,101.17,22.23,110.95,27.15,120.77,35.52,141.13,0.0,90
210.0,450,30,50,11.96,101.87,13.36,108.81,14.34,115.53,16.56,122.51,0.0,150
233.33333333333334,500,25,50,12.97,97.85,14.08,102.95,15.2,108.56,19.75,114.56,0.0,150
443.33333333333337,950,30,50,14.17,88.29,14.99,92.37,15.61,94.0,16.21,99.6,0.0,150
23.333333333333336,50,25,50,8.91,142.1,11.14,163.28,18.38,183.65,47.29,261.85,0.0,90
46.66666666666667,100,35,50,9.12,136.93,12.03,151.48,14.64,162.32,21.73,186.76,0.0,115
420.0,900,40,50,18.54,83.34,19.36,86.78,20.35,89.56,21.63,94.14,0.0,115
70.0,150,45,50,7.62,137.64,8.78,151.29,10.71,167.56,16.49,196.95,0.0,150
326.66666666666663,700,40,50,23.07,84.34,24.31,86.98,25.12,91.2,28.1,97.27,0.0,90
466.6666666666667,1000,20,50,13.29,86.36,13.76,91.01,14.45,94.74,15.92,101.45,0.0,165
140.0,300,30,50,10.18,111.65,12.19,120.59,13.64,127.9,16.3,142.3,0.0,150
326.66666666666663,700,35,50,17.84,86.73,18.97,89.72,19.93,93.56,20.88,100.75,0.0,115
373.33333333333337,800,25,50,12.49,91.22,13.29,97.69,14.13,102.05,15.94,107.43,0.0,165
280.0,600,20,50,17.8,86.19,19.13,90.28,20.36,94.9,21.53,98.24,0.0,115
256.66666666666663,550,40,50,12.35,98.38,13.23,103.74,13.77,108.3,15.02,114.15,0.0,150
93.33333333333334,200,50,50,7.34,131.97,8.61,144.15,9.83,151.11,13.54,183.62,0.0,165
443.33333333333337,950,45,50,12.55,90.62,13.23,94.99,13.65,98.57,14.37,104.11,0.0,165
140.0,300,20,50,10.19,111.49,10.97,121.41,12.1,131.96,15.75,142.69,0.0,165
396.66666666666663,850,30,50,12.55,92.47,13.12,97.17,13.74,101.68,14.91,107.53,0.0,165
373.33333333333337,800,35,50,12.21,93.38,13.07,98.47,13.92,102.61,14.88,108.8,0.0,165
163.33333333333331,350,20,50,10.55,109.81,11.89,115.83,13.54,124.16,15.97,136.94,0.0,165
373.33333333333337,800,50,50,13.52,92.93,14.2,95.88,14.78,99.01,15.81,102.79,0.0,150
140.0,300,35,50,9.25,115.77,10.72,126.6,11.98,134.89,14.26,153.58,0.0,165
350.0,750,40,50,12.02,95.85,12.91,100.76,13.67,105.87,14.68,113.8,0.0,165
210.0,450,45,50,10.39,109.33,11.22,114.53,12.22,119.09,14.83,124.49,0.0,165
326.66666666666663,700,50,50,11.84,99.12,12.61,104.26,13.2,108.52,14.11,119.11,0.0,165
396.66666666666663,850,50,50,12.3,95.25,12.82,98.93,13.48,104.01,14.55,109.71,0.0,165
70.0,150,50,50,6.55,141.86,7.81,151.15,9.18,158.95,15.54,204.6,0.0,165
116.66666666666667,250,20,50,9.53,116.95,10.87,125.13,12.25,134.88,15.73,156.12,0.0,165
373.33333333333337,800,40,50,12.17,94.05,12.94,98.32,13.63,103.75,14.23,106.85,0.0,165
443.33333333333337,950,35,50,12.58,89.99,13.38,94.41,13.82,97.98,15.1,102.73,0.0,165
186.66666666666669,400,50,50,10.09,111.9,11.0,119.45,12.07,125.75,12.87,131.9,0.0,165
280.0,600,25,50,12.08,98.65,12.77,103.9,13.69,109.17,14.79,115.04,0.0,165
396.66666666666663,850,45,50,12.36,94.04,12.87,98.47,13.38,100.53,14.94,106.39,0.0,165
350.0,750,50,50,11.91,97.41,12.49,102.56,13.06,106.0,14.29,110.43,0.0,165
93.33333333333334,200,40,50,12.8,115.81,14.43,126.71,16.95,133.17,23.14,146.36,0.0,115
420.0,900,30,50,12.8,91.61,13.25,95.16,13.97,98.84,14.84,108.66,0.0,165
233.33333333333334,500,20,50,11.45,101.81,12.2,108.05,13.08,113.4,15.13,121.18,0.0,165
466.6666666666667,1000,30,50,12.88,88.46,13.63,92.5,14.16,96.58,14.83,103.6,0.0,165
93.33333333333334,200,30,50,8.13,127.49,9.36,135.27,11.1,148.83,15.91,163.53,0.0,165
163.33333333333331,350,35,50,9.68,111.74,10.72,119.16,12.45,125.91,16.31,138.36,0.0,165
443.33333333333337,950,20,50,13.09,86.77,13.69,89.9,14.41,95.12,15.61,100.14,0.0,165
256.66666666666663,550,20,50,11.8,98.19,12.78,102.36,13.51,107.83,14.97,115.29,0.0,165
163.33333333333331,350,40,50,10.01,113.2,10.81,119.29,11.86,126.24,15.39,133.88,0.0,165
420.0,900,50,50,12.19,93.2,13.04,98.05,13.35,101.4,15.11,105.9,0.0,165
23.333333333333336,50,50,50,3.02,168.75,3.76,193.67,4.74,212.61,7.58,253.29,1.0,165
140.0,300,40,50,9.4,121.02,10.56,128.0,11.96,139.28,12.92,148.11,0.0,165
303.33333333333337,650,50,50,11.62,99.07,12.13,104.1,13.07,109.81,15.01,120.19,0.0,165
373.33333333333337,800,30,50,12.48,93.53,13.25,98.34,13.99,100.8,15.32,108.1,0.0,165
116.66666666666667,250,25,50,9.35,119.87,10.73,129.63,12.13,136.83,14.99,161.77,0.0,165
163.33333333333331,350,25,50,10.34,106.56,11.51,114.76,12.83,122.73,18.32,137.31,0.0,165
93.33333333333334,200,35,50,8.08,131.06,9.45,139.86,10.74,148.2,15.76,176.87,0.0,165
93.33333333333334,200,45,50,7.72,130.01,9.03,140.21,10.32,150.58,13.05,171.94,0.0,165
256.66666666666663,550,50,50,11.09,103.91,11.98,110.73,12.79,116.18,14.19,125.91,0.0,165
140.0,300,25,50,9.72,116.21,10.87,125.77,12.53,135.31,18.78,145.55,0.0,165
233.33333333333334,500,30,50,11.23,103.65,12.04,109.4,13.08,116.66,14.08,122.06,0.0,165
210.0,450,50,50,10.58,108.97,11.42,114.72,12.67,120.02,14.55,126.17,0.0,165
373.33333333333337,800,20,50,12.56,89.54,13.46,93.55,14.1,98.91,15.13,103.7,0.0,165
396.66666666666663,850,35,50,12.51,93.87,13.18,97.85,13.76,100.43,15.22,104.0,0.0,165
70.0,150,30,50,11.84,121.07,14.7,133.14,17.37,146.3,21.0,163.14,0.0,115
70.0,150,25,50,7.05,134.35,8.87,148.91,11.49,164.98,20.24,206.95,0.0,165
350.0,750,35,50,12.02,96.03,12.99,100.58,13.59,103.82,14.54,112.59,0.0,165
116.66666666666667,250,45,50,8.24,124.05,9.71,134.46,11.39,141.32,14.65,157.42,0.0,165
186.66666666666669,400,25,50,10.66,107.38,11.58,114.74,12.67,119.59,14.82,126.38,0.0,165
396.66666666666663,850,25,50,12.5,91.51,13.08,97.61,13.68,100.57,15.25,106.15,0.0,165
280.0,600,35,50,11.7,101.83,12.38,107.06,13.15,110.29,14.75,118.48,0.0,165
350.0,750,20,50,12.74,90.76,13.47,96.91,14.49,99.81,16.18,106.86,0.0,165
46.66666666666667,100,30,50,5.37,150.81,6.52,162.76,7.67,177.29,10.65,220.76,0.0,165
443.33333333333337,950,50,50,12.52,91.84,13.06,95.68,13.48,99.28,14.46,105.3,0.0,165
303.33333333333337,650,30,50,11.75,97.34,12.75,100.99,13.07,105.58,15.02,114.03,0.0,165
210.0,450,25,50,11.22,104.58,12.0,112.76,13.29,118.29,15.9,120.4,0.0,165
140.0,300,45,50,9.09,119.06,10.32,128.14,11.81,138.98,13.98,150.52,0.0,165
116.66666666666667,250,30,50,8.95,121.43,10.15,130.17,11.92,139.26,16.17,152.1,0.0,165
23.333333333333336,50,20,50,3.59,160.55,4.71,181.53,8.68,206.96,14.6,363.57,0.0,165
70.0,150,40,50,6.54,144.75,7.93,155.34,10.26,165.99,13.93,182.7,0.0,165
443.33333333333337,950,25,50,13.03,88.84,13.85,92.85,14.64,95.77,15.55,101.8,0.0,165
326.66666666666663,700,45,50,17.94,87.59,18.61,90.14,19.59,94.73,20.41,97.88,0.0,115
46.66666666666667,100,45,50,4.86,150.18,6.11,162.99,7.55,181.63,15.06,227.3,0.0,165
326.66666666666663,700,25,50,12.13,94.09,12.89,98.71,13.58,102.9,15.05,108.68,0.0,165
420.0,900,35,50,12.79,92.11,13.41,95.29,13.79,99.43,15.07,103.54,0.0,165
303.33333333333337,650,20,50,12.13,94.78,12.98,100.12,13.82,105.14,14.89,107.09,0.0,165
163.33333333333331,350,45,50,9.84,113.61,10.98,121.22,11.81,127.0,14.89,133.25,0.0,165
303.33333333333337,650,25,50,12.25,94.88,13.03,99.09,14.03,105.36,15.08,111.84,0.0,165
46.66666666666667,100,40,50,5.27,152.05,6.32,165.94,8.27,183.31,12.78,209.2,0.0,165
23.333333333333336,50,40,50,3.28,166.05,4.29,190.73,5.47,214.22,7.82,281.89,0.0,165
256.66666666666663,550,35,50,11.31,101.65,12.39,107.55,13.37,113.02,16.02,119.82,0.0,165
280.0,600,45,50,11.69,98.59,12.28,103.96,13.22,108.85,14.46,117.55,0.0,165
466.6666666666667,1000,50,50,12.82,89.84,13.3,94.77,13.86,97.76,14.63,103.4,0.0,165
466.6666666666667,1000,25,50,12.91,87.64,13.61,92.27,14.43,94.76,15.67,98.5,0.0,165
350.0,750,30,50,12.3,94.16,13.17,98.38,14.18,104.68,15.07,108.02,0.0,165
280.0,600,50,50,11.54,99.65,12.28,106.49,12.86,111.84,14.91,118.68,0.0,165
420.0,900,45,50,12.31,91.95,13.02,96.18,13.56,99.36,14.71,103.91,0.0,165
116.66666666666667,250,40,50,8.71,124.73,9.76,132.78,11.34,146.14,18.94,157.02,0.0,165
93.33333333333334,200,25,50,8.4,127.11,9.88,139.98,11.11,152.56,15.48,175.54,0.0,165
163.33333333333331,350,30,50,15.68,97.37,17.93,104.6,19.81,110.64,23.1,121.6,0.0,115
420.0,900,20,50,12.88,88.62,13.91,93.86,14.58,97.53,16.11,99.4,0.0,165
46.66666666666667,100,50,50,5.04,149.25,5.9,166.03,7.07,180.42,12.69,206.34,0.0,165
46.66666666666667,100,25,50,5.93,143.49,7.03,165.83,8.62,186.14,14.11,208.56,0.0,165
210.0,450,40,50,10.7,108.02,11.84,115.12,12.52,120.36,14.66,131.25,0.0,165
116.66666666666667,250,50,50,8.52,125.92,9.2,135.61,10.56,141.15,14.45,151.89,0.0,165
23.333333333333336,50,45,50,3.01,170.71,3.65,192.96,5.34,217.79,8.49,248.94,0.0,165
280.0,600,30,50,11.67,98.67,12.34,102.62,12.98,107.22,14.93,118.45,0.0,165
396.66666666666663,850,40,50,12.4,93.21,13.03,98.55,13.83,101.45,14.63,105.91,0.0,165
256.66666666666663,550,30,50,11.51,98.18,12.43,106.47,13.55,111.77,16.07,121.58,0.0,165
443.33333333333337,950,40,50,12.5,91.7,13.33,95.79,13.99,98.95,15.01,104.83,0.0,165
256.66666666666663,550,25,50,11.45,100.2,12.35,104.32,13.17,110.61,16.39,117.84,0.0,165
233.33333333333334,500,45,50,10.56,104.35,11.61,111.21,12.84,114.64,14.37,123.63,0.0,165
326.66666666666663,700,20,50,12.4,92.48,13.35,96.82,14.14,101.59,15.15,110.51,0.0,165
23.333333333333336,50,30,50,3.43,162.39,3.95,181.46,5.55,218.44,20.87,290.89,0.0,165
373.33333333333337,800,45,50,12.2,94.79,12.95,99.3,13.61,104.69,14.53,111.11,0.0,165
303.33333333333337,650,35,50,11.64,98.23,12.48,102.42,13.37,106.53,15.15,113.55,0.0,165
466.6666666666667,1000,45,50,12.7,90.96,13.28,94.93,14.02,98.23,14.89,100.97,0.0,165
303.33333333333337,650,45,50,11.7,100.2,12.52,104.59,13.16,109.12,15.63,113.66,0.0,165
186.66666666666669,400,45,50,10.09,111.75,11.03,119.61,12.7,125.91,14.75,134.21,0.0,165
186.66666666666669,400,40,50,10.25,109.05,11.25,116.84,12.43,123.57,15.45,138.46,0.0,165
233.33333333333334,500,50,50,10.6,104.75,11.45,111.33,12.45,117.14,13.58,122.12,0.0,165
210.0,450,20,50,10.97,101.81,11.8,110.35,12.79,114.13,14.93,126.62,0.0,165
186.66666666666669,400,20,50,10.38,108.06,11.45,116.86,12.6,120.69,14.69,139.97,0.0,165
186.66666666666669,400,35,50,10.8,108.64,11.74,115.58,12.88,123.62,15.04,131.15,0.0,165
420.0,900,25,50,12.6,90.04,13.39,94.74,13.87,98.96,15.6,105.43,0.0,165
140.0,300,50,50,9.1,120.36,9.83,126.94,11.43,131.14,13.59,148.34,0.0,165
23.333333333333336,50,35,50,3.46,161.53,4.78,189.12,5.92,214.67,13.53,313.33,0.0,165
303.33333333333337,650,40,50,17.6,89.31,18.92,94.04,20.25,98.34,22.77,106.93,0.0,115
350.0,750,45,50,11.92,96.76,12.76,101.06,13.22,104.67,13.97,109.92,0.0,165
466.6666666666667,1000,35,50,12.79,90.69,13.51,94.66,13.93,97.24,14.95,105.64,0.0,165
256.66666666666663,550,45,50,16.94,91.36,17.72,97.34,19.23,100.93,22.51,108.19,0.0,115
396.66666666666663,850,20,50,12.64,90.01,13.38,93.62,14.26,96.7,14.88,101.44,0.0,165
233.33333333333334,500,40,50,11.07,105.5,11.88,111.85,12.81,117.2,14.4,124.53,0.0,165
116.66666666666667,250,35,50,8.71,120.12,9.85,134.38,11.39,142.95,14.29,167.14,0.0,165
280.0,600,40,50,11.55,100.24,12.45,106.39,13.41,111.05,14.52,122.27,0.0,165
70.0,150,35,50,10.87,124.84,12.82,138.59,14.0,146.5,24.45,162.36,0.0,115
350.0,750,25,50,12.34,92.74,13.06,96.61,14.11,99.53,15.43,102.9,0.0,165
46.66666666666667,100,20,50,9.68,129.66,12.98,147.65,16.82,170.07,23.05,218.32,0.0,115
326.66666666666663,700,30,50,18.2,87.71,19.28,91.31,20.13,93.94,21.81,97.85,0.0,115
210.0,450,30,50,10.85,105.31,12.1,112.99,12.93,119.34,14.82,122.92,0.0,165
70.0,150,20,50,12.01,118.5,15.3,128.53,17.97,141.97,28.19,172.69,0.0,115
233.33333333333334,500,25,50,11.29,100.92,12.14,107.06,13.56,113.54,16.36,120.42,0.0,165
233.33333333333334,500,35,50,16.92,92.91,18.53,97.87,20.22,103.28,21.65,115.08,0.0,115
443.33333333333337,950,30,50,12.79,89.69,13.3,94.54,13.86,98.66,14.73,102.51,0.0,165
466.6666666666667,1000,40,50,14.27,88.05,14.73,92.11,15.16,96.44,16.15,101.52,0.0,150
210.0,450,35,50,16.14,97.01,17.68,103.86,19.39,108.37,21.21,115.22,0.0,115
93.33333333333334,200,20,50,14.49,108.7,16.83,117.64,21.46,126.8,25.64,156.2,0.0,115
186.66666666666669,400,30,50,10.66,108.34,11.64,115.43,12.61,122.29,13.88,131.8,0.0,165
163.33333333333331,350,50,50,15.18,102.58,16.08,107.33,17.72,114.12,20.73,123.49,0.0,115
420.0,900,40,50,14.01,90.64,14.53,94.28,15.24,96.29,16.58,100.1,0.0,150
46.66666666666667,100,35,50,6.14,140.23,7.71,161.38,9.12,178.91,14.71,214.78,0.0,150
23.333333333333336,50,25,50,5.82,152.43,7.81,174.03,14.09,191.44,27.21,334.3,0.0,115
466.6666666666667,1000,20,50,11.91,89.31,12.5,94.17,13.3,97.28,14.67,101.96,0.0,180
70.0,150,45,50,6.77,138.08,7.94,150.17,9.52,160.8,13.91,215.99,0.0,165
373.33333333333337,800,25,50,11.6,95.06,12.19,98.59,12.89,102.02,14.06,107.46,0.0,180
326.66666666666663,700,40,50,17.59,86.59,18.35,91.62,19.38,96.0,20.43,101.97,0.0,115
280.0,600,20,50,13.6,92.43,14.42,97.91,15.4,102.71,17.46,109.75,0.0,150
93.33333333333334,200,50,50,6.66,135.61,7.71,147.06,9.34,160.2,11.72,180.69,0.0,180
350.0,750,40,50,10.69,97.92,11.4,102.55,12.08,108.66,13.37,112.9,0.0,180
256.66666666666663,550,40,50,11.09,101.94,12.19,108.54,13.69,115.05,15.12,127.63,0.0,165
396.66666666666663,850,30,50,11.58,93.48,12.19,97.37,12.68,100.66,12.9,107.48,0.0,180
140.0,300,20,50,9.17,115.49,9.86,128.41,11.15,135.24,14.39,141.31,0.0,180
373.33333333333337,800,35,50,11.3,96.22,11.74,101.77,12.88,106.3,13.7,109.56,0.0,180
163.33333333333331,350,20,50,9.79,111.55,11.2,119.32,12.49,125.88,14.73,132.41,0.0,180
443.33333333333337,950,45,50,11.45,94.46,12.01,98.08,12.46,102.59,13.05,107.89,0.0,180
140.0,300,35,50,8.6,122.28,9.35,130.74,10.26,138.41,11.8,145.71,0.0,180
326.66666666666663,700,35,50,13.36,94.67,14.12,99.25,15.03,101.89,17.44,105.82,0.0,150
210.0,450,45,50,9.67,111.69,10.43,118.03,11.52,124.56,13.2,132.3,0.0,180
70.0,150,50,50,5.5,146.5,6.61,158.72,7.95,171.73,11.69,197.41,0.0,180
326.66666666666663,700,50,50,10.52,103.46,11.41,107.67,12.27,111.75,13.42,116.84,0.0,180
350.0,750,50,50,10.82,99.67,11.32,103.53,11.71,107.09,13.52,111.36,0.0,180
140.0,300,30,50,9.57,118.83,11.06,125.72,12.03,134.78,15.21,148.44,0.0,165
396.66666666666663,850,50,50,10.87,96.56,11.65,102.15,12.49,105.81,13.87,109.94,0.0,180
443.33333333333337,950,35,50,11.63,93.13,12.21,96.19,12.81,99.6,13.69,105.99,0.0,180
116.66666666666667,250,20,50,7.76,120.15,8.97,129.82,10.76,136.77,15.36,149.49,0.0,180
373.33333333333337,800,40,50,11.08,97.17,11.78,101.24,12.69,104.65,13.35,111.12,0.0,180
396.66666666666663,850,45,50,10.95,97.16,11.45,101.71,12.24,105.19,13.57,113.22,0.0,180
420.0,900,30,50,11.49,92.37,12.06,96.73,12.8,100.31,13.63,105.21,0.0,180
280.0,600,25,50,10.54,100.57,11.5,107.26,12.32,112.02,13.08,114.67,0.0,180
443.33333333333337,950,20,50,12.02,89.7,12.52,94.01,13.09,96.59,14.17,104.31,0.0,180
163.33333333333331,350,35,50,8.62,116.49,9.75,124.2,10.81,133.11,13.73,146.29,0.0,180
93.33333333333334,200,30,50,7.16,134.97,8.11,144.66,9.27,156.47,11.9,178.27,0.0,180
186.66666666666669,400,50,50,8.9,115.27,9.61,123.0,10.92,131.26,12.44,143.45,0.0,180
140.0,300,40,50,8.3,119.16,9.14,127.81,10.66,132.99,12.51,155.75,0.0,180
466.6666666666667,1000,30,50,11.81,89.67,12.35,94.23,12.82,98.26,13.56,100.71,0.0,180
420.0,900,50,50,11.24,98.15,11.87,100.98,12.49,103.83,13.15,109.28,0.0,180
233.33333333333334,500,20,50,10.55,103.52,11.43,110.43,12.36,118.36,14.04,122.07,0.0,180
256.66666666666663,550,20,50,10.97,99.34,11.73,107.01,12.86,112.13,14.58,122.01,0.0,180
163.33333333333331,350,40,50,8.97,116.1,9.86,123.54,10.91,129.08,12.17,135.43,0.0,180
140.0,300,25,50,8.63,118.71,9.93,128.85,12.12,138.99,14.58,157.74,0.0,180
23.333333333333336,50,50,50,2.53,172.33,3.35,192.95,4.03,217.41,6.96,274.42,0.0,180
163.33333333333331,350,25,50,9.55,112.46,10.38,121.41,11.92,127.88,13.98,138.51,0.0,180
93.33333333333334,200,35,50,6.8,133.24,8.02,143.98,9.46,153.58,12.58,171.24,0.0,180
116.66666666666667,250,25,50,7.94,123.71,8.99,133.87,9.92,144.19,14.56,158.21,0.0,180
256.66666666666663,550,50,50,10.0,105.27,10.91,112.1,11.7,117.74,14.07,124.8,0.0,180
373.33333333333337,800,50,50,11.91,96.4,12.69,100.19,13.34,103.41,14.05,107.78,0.0,165
210.0,450,50,50,9.35,111.31,10.09,118.89,11.3,121.52,12.58,130.43,0.0,180
373.33333333333337,800,30,50,11.56,95.38,12.21,100.22,12.86,103.23,13.75,110.55,0.0,180
93.33333333333334,200,45,50,6.83,135.63,7.68,144.12,8.48,151.46,11.12,178.76,0.0,180
280.0,600,35,50,10.39,101.5,10.96,107.55,12.22,110.69,13.63,123.79,0.0,180
233.33333333333334,500,30,50,9.96,107.14,10.92,114.01,12.62,117.0,14.1,123.36,0.0,180
116.66666666666667,250,45,50,7.32,127.48,8.22,134.4,9.1,143.19,9.98,152.31,0.0,180
303.33333333333337,650,50,50,10.23,103.49,11.1,108.55,12.23,112.99,13.0,120.85,0.0,180
70.0,150,25,50,6.43,142.26,7.68,157.24,9.61,167.75,14.87,193.21,0.0,180
373.33333333333337,800,20,50,11.52,92.83,12.18,97.49,12.96,101.6,13.65,106.45,0.0,180
46.66666666666667,100,30,50,4.51,152.19,5.97,167.19,7.74,190.22,17.83,283.11,0.0,180
350.0,750,35,50,11.19,98.05,11.88,102.63,12.65,106.7,14.48,112.5,0.0,180
396.66666666666663,850,25,50,11.52,93.81,12.12,97.39,12.81,99.48,13.95,107.43,0.0,180
116.66666666666667,250,30,50,7.8,123.93,9.25,137.37,10.84,147.32,12.24,158.53,0.0,180
443.33333333333337,950,50,50,11.58,92.68,12.04,97.65,12.62,102.82,13.62,107.19,0.0,180
396.66666666666663,850,35,50,11.43,95.34,12.2,99.02,13.23,104.11,13.65,110.06,0.0,180
186.66666666666669,400,25,50,9.83,110.12,10.95,117.4,11.93,124.84,14.31,135.66,0.0,180
350.0,750,20,50,11.33,93.14,12.0,98.77,13.1,104.34,14.28,108.95,0.0,180
93.33333333333334,200,40,50,9.06,130.51,10.22,140.39,11.25,148.32,13.66,167.81,0.0,150
420.0,900,35,50,11.27,93.75,11.88,98.1,12.53,102.91,13.28,107.32,0.0,180
46.66666666666667,100,45,50,4.53,154.11,5.43,167.58,7.03,185.85,9.36,210.53,0.0,180
140.0,300,45,50,7.85,123.08,8.96,131.44,9.96,138.05,13.51,155.27,0.0,180
70.0,150,40,50,5.69,144.05,7.27,159.35,9.2,171.9,11.93,196.64,0.0,180
46.66666666666667,100,40,50,4.26,151.72,5.56,167.9,6.52,189.0,12.62,246.02,0.0,180
303.33333333333337,650,25,50,10.9,98.7,11.49,103.72,12.26,106.16,13.59,114.35,0.0,180
210.0,450,25,50,9.97,105.98,10.79,113.29,11.95,120.24,14.68,127.93,0.0,180
23.333333333333336,50,40,50,2.64,169.6,3.48,191.18,4.29,220.32,6.6,285.85,0.0,180
303.33333333333337,650,30,50,10.93,100.68,11.72,106.14,12.64,113.34,13.88,124.04,0.0,180
466.6666666666667,1000,50,50,11.43,94.62,11.97,97.24,12.41,100.18,12.98,102.35,0.0,180
23.333333333333336,50,20,50,3.05,170.95,4.2,189.81,5.63,225.99,11.05,263.35,0.0,180
443.33333333333337,950,25,50,11.89,91.64,12.41,94.95,12.99,97.47,14.28,103.24,0.0,180
280.0,600,50,50,10.3,106.83,10.89,112.57,11.85,117.2,12.89,121.04,0.0,180
280.0,600,45,50,10.21,104.16,11.23,108.84,12.04,112.78,15.04,121.13,0.0,180
303.33333333333337,650,20,50,10.8,99.02,11.49,104.46,11.95,110.1,12.92,115.68,0.0,180
326.66666666666663,700,25,50,11.08,98.17,11.72,103.92,12.34,107.09,13.35,113.94,0.0,180
46.66666666666667,100,25,50,4.97,153.94,5.9,172.73,7.27,186.67,10.19,224.46,0.0,180
256.66666666666663,550,35,50,10.06,104.44,11.01,111.2,12.17,114.29,14.97,121.52,0.0,180
70.0,150,30,50,8.01,135.55,9.31,148.56,12.84,159.72,23.54,179.55,0.0,150
46.66666666666667,100,50,50,4.47,154.2,5.45,172.33,6.51,184.56,10.62,210.53,0.0,180
420.0,900,45,50,11.37,94.81,11.89,100.32,12.43,103.87,13.28,109.27,0.0,180
420.0,900,20,50,11.71,92.41,12.37,97.82,13.11,100.46,13.83,104.73,0.0,180
350.0,750,30,50,11.19,94.53,11.76,99.79,12.55,103.43,13.17,110.49,0.0,180
116.66666666666667,250,40,50,7.71,126.43,8.92,137.38,10.26,145.27,11.36,170.84,0.0,180
116.66666666666667,250,50,50,7.11,131.08,8.06,142.41,9.76,147.71,12.74,166.34,0.0,180
466.6666666666667,1000,25,50,11.88,90.88,12.45,94.92,13.05,97.7,13.64,105.14,0.0,180
396.66666666666663,850,40,50,11.29,95.44,11.89,98.86,12.49,102.79,14.09,106.44,0.0,180
326.66666666666663,700,20,50,10.97,95.89,11.62,100.94,12.62,107.27,14.16,112.69,0.0,180
326.66666666666663,700,45,50,13.2,96.64,13.98,99.81,14.92,105.05,15.73,110.94,0.0,150
210.0,450,40,50,9.53,112.01,10.38,119.48,10.99,124.93,13.7,130.36,0.0,180
23.333333333333336,50,45,50,2.66,173.03,3.34,194.1,4.02,215.59,7.25,256.89,0.0,180
466.6666666666667,1000,45,50,11.53,93.79,12.14,97.16,12.48,100.1,13.17,107.26,0.0,180
256.66666666666663,550,30,50,10.3,104.17,11.14,109.04,11.94,115.5,13.3,123.24,0.0,180
373.33333333333337,800,45,50,11.04,98.11,11.62,102.59,12.25,106.18,13.37,114.27,0.0,180
163.33333333333331,350,45,50,8.69,120.94,9.51,127.74,10.32,134.44,12.57,141.02,0.0,180
303.33333333333337,650,35,50,10.71,99.19,11.45,104.6,11.93,109.2,14.01,117.99,0.0,180
443.33333333333337,950,40,50,11.4,93.83,11.95,97.57,12.38,100.19,13.0,103.36,0.0,180
280.0,600,30,50,10.56,99.37,11.63,108.34,12.77,112.58,14.96,117.14,0.0,180
256.66666666666663,550,25,50,10.54,100.96,11.53,107.24,12.3,111.53,14.63,116.65,0.0,180
233.33333333333334,500,45,50,9.68,109.19,10.43,114.81,11.07,119.59,12.79,124.17,0.0,180
23.333333333333336,50,30,50,2.77,169.04,3.65,191.72,5.37,215.9,8.87,308.69,0.0,180
93.33333333333334,200,25,50,7.16,130.89,8.29,141.95,9.44,150.06,13.79,169.61,0.0,180
186.66666666666669,400,40,50,9.09,114.45,9.9,122.05,10.76,128.45,12.26,134.85,0.0,180
186.66666666666669,400,20,50,10.13,107.41,11.19,115.65,12.21,124.29,16.42,132.03,0.0,180
233.33333333333334,500,50,50,9.81,107.43,10.54,114.32,11.63,119.41,13.68,132.63,0.0,180
303.33333333333337,650,45,50,10.66,102.33,11.4,107.17,12.23,111.48,13.35,115.86,0.0,180
186.66666666666669,400,45,50,8.98,113.92,9.95,121.05,11.07,127.58,12.94,143.98,0.0,180
23.333333333333336,50,35,50,2.87,172.73,3.85,194.59,5.73,221.93,8.41,283.38,0.0,180
186.66666666666669,400,35,50,9.17,110.46,10.03,117.85,11.12,124.8,12.63,138.04,0.0,180
420.0,900,25,50,11.74,91.47,12.5,95.74,13.17,99.92,13.77,104.03,0.0,180
163.33333333333331,350,30,50,11.44,107.48,12.42,118.42,13.69,122.04,15.42,135.8,0.0,150
210.0,450,20,50,9.85,105.41,11.17,113.96,12.12,119.52,14.44,129.03,0.0,180
466.6666666666667,1000,35,50,11.67,92.38,12.15,95.52,12.66,98.91,13.38,106.5,0.0,180
140.0,300,50,50,8.04,125.21,9.25,134.44,10.5,142.18,14.36,151.49,0.0,180
350.0,750,45,50,11.0,98.44,11.73,102.97,12.72,108.37,13.91,115.41,0.0,180
396.66666666666663,850,20,50,11.74,91.55,12.34,95.91,13.03,100.02,14.74,106.4,0.0,180
280.0,600,40,50,10.54,103.04,11.13,108.95,11.88,113.15,13.71,117.51,0.0,180
233.33333333333334,500,40,50,9.73,107.44,10.72,113.05,11.33,119.27,12.85,129.21,0.0,180
350.0,750,25,50,11.54,94.26,12.19,99.32,13.01,103.47,14.88,109.34,0.0,180
116.66666666666667,250,35,50,7.6,127.11,8.61,136.64,10.34,146.32,11.9,161.41,0.0,180
233.33333333333334,500,25,50,10.08,104.32,11.15,109.93,12.21,118.52,14.74,126.1,0.0,180
210.0,450,30,50,9.83,108.85,10.56,116.22,11.49,120.97,13.19,134.82,0.0,180
256.66666666666663,550,45,50,12.38,100.47,13.42,106.66,14.36,111.02,15.81,118.39,0.0,150
303.33333333333337,650,40,50,12.82,97.4,13.59,102.19,14.13,105.49,15.82,110.33,0.0,150
326.66666666666663,700,30,50,13.36,93.2,14.16,98.51,14.96,102.88,17.22,106.23,0.0,150
70.0,150,35,50,7.53,133.93,8.64,148.77,10.93,159.75,20.89,186.23,0.0,150
46.66666666666667,100,20,50,6.18,142.99,8.08,156.11,10.19,181.14,23.52,212.75,0.0,150
443.33333333333337,950,30,50,11.54,91.7,12.23,96.38,12.95,101.83,13.5,107.48,0.0,180
70.0,150,20,50,8.44,124.31,10.08,135.62,12.5,151.34,18.21,179.51,0.0,150
700.0,1000,20,50,79.99,21.46,80.0,26.02,80.0,27.92,80.0,32.31,0.0,30
233.33333333333334,500,35,50,12.55,99.64,13.38,106.5,14.66,111.76,16.96,117.75,0.0,150
466.6666666666667,1000,40,50,12.7,90.82,13.38,93.14,13.82,96.3,14.97,102.37,0.0,165
93.33333333333334,200,20,50,9.9,122.72,11.63,133.31,13.41,142.37,18.67,161.3,0.0,150
210.0,450,35,50,12.18,103.18,13.43,109.41,14.58,116.2,17.2,120.99,0.0,150
560.0,800,25,50,79.99,21.48,80.0,26.73,80.0,32.35,80.0,39.6,1.0,30
420.0,900,40,50,12.4,92.6,13.18,95.79,13.86,98.5,14.43,104.08,0.0,165
186.66666666666669,400,30,50,9.38,110.66,10.41,118.89,11.81,126.89,13.69,135.12,0.0,180
163.33333333333331,350,50,50,10.71,111.95,11.46,118.92,12.8,125.64,16.47,145.97,0.0,150
280.0,600,20,50,11.95,94.75,12.93,101.14,13.58,105.95,15.59,111.6,0.0,165
46.66666666666667,100,35,50,5.15,154.61,6.14,165.17,7.48,180.89,9.94,208.65,0.0,165
23.333333333333336,50,25,50,3.9,158.78,5.01,182.47,7.42,207.01,14.74,286.7,0.0,150
140.0,200,50,50,79.99,50.1,80.0,69.1,80.0,84.12,80.0,100.51,1.0,30
245.0,350,20,50,79.99,31.82,80.0,39.69,80.0,45.97,80.0,53.77,3.0,30
665.0,950,45,50,79.99,23.33,80.0,27.16,80.0,30.15,80.0,35.35,0.0,30
256.66666666666663,550,40,50,10.13,106.18,11.06,113.4,11.5,119.88,12.42,123.62,0.0,180
210.0,300,20,50,79.99,28.65,80.0,42.52,80.0,58.98,80.0,70.5,4.0,30
525.0,750,40,50,79.99,25.22,80.0,28.76,80.0,34.94,80.0,39.0,0.0,30
595.0,850,30,50,79.99,22.42,80.0,26.84,80.0,29.92,80.0,38.23,0.0,30
560.0,800,35,50,79.99,23.91,80.0,29.14,80.0,32.23,80.0,37.66,0.0,30
70.0,150,45,50,5.7,146.09,6.72,155.33,7.96,170.29,9.73,177.98,0.0,180
210.0,300,35,50,79.99,31.6,80.0,44.82,80.0,55.97,80.0,76.46,2.0,30
525.0,750,50,50,79.99,24.92,80.0,30.22,80.0,35.81,80.0,40.73,0.0,30
105.0,150,50,50,79.99,52.53,80.0,74.28,80.0,90.79,80.0,98.56,7.0,30
595.0,850,45,50,79.99,23.81,80.0,26.87,80.0,31.4,80.0,37.69,0.0,30
595.0,850,50,50,79.99,25.38,80.0,30.52,80.0,34.28,80.0,38.95,0.0,30
326.66666666666663,700,40,50,13.29,94.15,14.23,99.79,14.84,104.28,17.65,107.14,0.0,150
175.0,250,20,50,79.99,33.1,80.0,52.16,80.0,69.06,80.0,83.65,3.0,30
560.0,800,40,50,79.99,24.29,80.0,29.12,80.0,32.35,80.0,37.68,0.0,30
210.0,300,40,50,79.99,38.07,80.0,48.38,80.0,57.96,80.0,72.78,2.0,30
315.0,450,45,50,79.99,30.59,80.0,38.19,80.0,42.94,80.0,52.0,0.0,30
665.0,950,35,50,79.99,21.93,80.0,24.04,80.0,27.64,80.0,33.85,0.0,30
630.0,900,30,50,79.99,23.09,80.0,26.68,80.0,29.54,80.0,32.71,0.0,30
420.0,600,25,50,79.99,26.46,80.0,31.15,80.0,35.1,80.0,44.13,2.0,30
490.0,700,50,50,79.99,24.19,80.0,29.33,80.0,34.63,80.0,43.46,0.0,30
140.0,200,30,50,79.99,52.33,80.0,66.23,80.0,77.21,80.0,84.31,1.0,30
665.0,950,20,50,79.99,20.06,80.0,23.98,80.0,30.28,80.0,35.15,0.0,30
245.0,350,35,50,79.99,31.96,80.0,41.36,80.0,49.38,80.0,72.47,2.0,30
280.0,400,50,50,79.99,32.53,80.0,43.56,80.0,50.63,80.0,67.31,1.0,30
35.0,50,50,50,58.21,97.17,74.46,113.95,79.99,127.2,80.0,160.62,4.0,30
700.0,1000,30,50,79.99,22.2,80.0,25.87,80.0,28.33,80.0,32.82,0.0,30
630.0,900,50,50,79.99,23.48,80.0,26.81,80.0,30.85,80.0,34.18,0.0,30
326.66666666666663,700,35,50,11.94,96.23,12.62,100.5,13.62,104.09,14.46,112.42,0.0,165
210.0,300,25,50,79.99,33.29,80.0,44.7,80.0,52.62,80.0,67.08,2.0,30
245.0,350,40,50,79.99,38.1,80.0,45.27,80.0,52.34,80.0,63.66,2.0,30
245.0,350,25,50,79.99,30.97,80.0,39.5,80.0,51.17,80.0,68.07,1.0,30
350.0,500,20,50,79.99,26.54,80.0,34.34,80.0,42.65,80.0,48.59,0.0,30
350.0,500,30,50,79.99,28.1,80.0,35.42,80.0,42.43,80.0,53.75,1.0,30
385.0,550,50,50,79.99,28.87,80.0,36.41,80.0,41.86,80.0,46.53,0.0,30
385.0,550,20,50,79.99,22.02,80.0,29.48,80.0,35.53,80.0,50.5,0.0,30
140.0,200,45,50,79.99,55.8,80.0,66.5,80.0,85.85,80.0,94.87,3.0,30
140.0,200,35,50,79.99,48.56,80.0,68.13,80.0,78.34,80.0,92.66,3.0,30
175.0,250,25,50,79.99,37.78,80.0,53.6,80.0,62.03,80.0,85.18,3.0,30
420.0,600,35,50,79.99,26.8,80.0,33.5,80.0,37.04,80.0,43.97,0.0,30
560.0,800,30,50,79.99,23.03,80.0,27.65,80.0,32.29,80.0,37.34,0.0,30
315.0,450,50,50,79.99,32.7,80.0,39.85,80.0,47.86,80.0,56.79,0.0,30
595.0,850,35,50,79.99,23.08,80.0,26.8,80.0,31.02,80.0,34.86,0.0,30
70.0,100,30,50,79.98,59.67,80.0,79.0,80.0,96.0,80.0,115.09,8.0,30
280.0,400,25,50,79.99,27.66,80.0,34.86,80.0,42.95,80.0,48.4,3.0,30
175.0,250,45,50,79.99,42.39,80.0,54.31,80.0,69.53,80.0,86.35,3.0,30
105.0,150,25,50,79.99,56.01,80.0,83.33,80.0,92.04,80.0,102.44,4.0,30
455.0,650,50,50,79.99,25.83,80.0,29.9,80.0,33.39,80.0,40.54,0.0,30
525.0,750,35,50,79.99,23.29,80.0,28.03,80.0,32.6,80.0,40.05,0.0,30
595.0,850,25,50,79.99,21.21,80.0,26.6,80.0,29.81,80.0,36.07,0.0,30
210.0,300,45,50,79.99,37.98,79.99,47.44,80.0,62.58,80.0,65.85,2.0,30
665.0,950,50,50,79.99,23.69,80.0,27.92,80.0,31.09,80.0,33.74,0.0,30
175.0,250,30,50,79.99,36.53,80.0,49.88,80.0,64.25,80.0,80.47,4.0,30
560.0,800,20,50,79.99,22.35,80.0,26.3,80.0,30.7,80.0,35.46,1.0,30
525.0,750,20,50,79.99,23.72,80.0,27.83,80.0,31.98,80.0,36.64,0.0,30
630.0,900,35,50,79.99,22.3,80.0,26.66,80.0,29.41,80.0,36.09,0.0,30
105.0,150,40,50,79.98,59.3,79.99,80.81,80.0,89.1,80.0,113.19,3.0,30
70.0,100,40,50,79.97,76.86,79.99,95.36,80.0,103.09,80.0,117.45,10.0,30
70.0,100,45,50,79.98,77.02,79.99,87.0,80.0,96.81,80.0,110.25,5.0,30
35.0,50,20,50,75.66,83.33,79.99,111.41,80.0,124.07,80.0,191.19,11.0,30
140.0,300,30,50,8.33,119.38,9.14,125.77,10.59,132.69,12.49,143.03,0.0,180
315.0,450,25,50,79.99,28.69,80.0,35.02,80.0,44.05,80.0,64.18,3.0,30
35.0,50,40,50,70.68,91.94,79.99,115.48,80.0,128.5,80.0,158.75,6.0,30
490.0,700,25,50,79.99,23.86,80.0,27.98,80.0,33.46,80.0,41.76,0.0,30
455.0,650,30,50,79.99,24.04,80.0,29.18,80.0,34.21,80.0,41.55,0.0,30
455.0,650,25,50,79.99,25.37,80.0,29.83,80.0,34.17,80.0,43.07,0.0,30
70.0,100,50,50,79.98,75.13,79.99,92.78,80.0,98.57,80.0,113.05,7.0,30
70.0,100,25,50,79.98,75.97,79.99,91.69,80.0,103.74,80.0,120.93,8.0,30
175.0,250,40,50,79.99,41.15,80.0,52.99,80.0,62.14,80.0,85.55,4.0,30
35.0,50,45,50,68.88,98.46,79.99,114.4,80.0,129.33,80.0,153.58,7.0,30
700.0,1000,50,50,79.99,23.37,80.0,27.31,80.0,29.28,80.0,34.27,0.0,30
665.0,950,25,50,79.99,21.38,80.0,24.76,80.0,29.67,80.0,33.38,0.0,30
525.0,750,30,50,79.99,23.74,80.0,28.12,80.0,32.65,80.0,36.51,0.0,30
385.0,550,35,50,79.99,28.92,80.0,33.7,80.0,39.63,80.0,48.44,0.0,30
420.0,600,45,50,79.99,29.98,80.0,34.22,80.0,40.02,80.0,48.25,1.0,30
420.0,600,50,50,79.99,28.36,80.0,32.89,80.0,39.16,80.0,48.47,0.0,30
373.33333333333337,800,50,50,10.91,99.14,11.45,103.37,11.78,106.7,12.95,110.57,0.0,180
455.0,650,20,50,79.99,24.39,80.0,31.32,80.0,34.07,80.0,41.06,0.0,30
175.0,250,50,50,79.99,43.78,80.0,60.84,80.0,70.29,80.0,86.62,0.0,30
630.0,900,20,50,79.99,21.78,80.0,24.67,80.0,28.08,80.0,33.26,0.0,30
630.0,900,45,50,79.99,23.5,80.0,27.52,80.0,30.14,80.0,32.84,0.0,30
700.0,1000,25,50,79.99,22.5,80.0,25.55,80.0,30.25,80.0,34.32,0.0,30
490.0,700,20,50,79.99,23.73,80.0,27.6,80.0,31.89,80.0,40.13,0.0,30
560.0,800,45,50,79.99,23.16,80.0,27.13,80.0,31.59,80.0,40.91,0.0,30
315.0,450,40,50,79.99,29.03,80.0,38.2,80.0,43.71,80.0,59.57,0.0,30
385.0,550,30,50,79.99,25.91,80.0,33.02,80.0,38.84,80.0,45.57,0.0,30
245.0,350,45,50,79.99,33.62,80.0,44.73,80.0,53.99,80.0,68.46,3.0,30
455.0,650,35,50,79.99,25.63,80.0,30.23,80.0,35.28,80.0,43.13,0.0,30
595.0,850,40,50,79.99,24.22,80.0,29.36,80.0,34.2,80.0,38.91,0.0,30
665.0,950,40,50,79.99,23.16,80.0,25.43,80.0,29.56,80.0,35.48,0.0,30
35.0,50,30,50,67.59,88.06,79.99,112.58,80.0,124.61,80.0,156.5,8.0,30
140.0,200,25,50,79.99,40.51,80.0,59.97,80.0,69.33,80.0,83.97,6.0,30
385.0,550,25,50,79.99,23.41,80.0,30.22,80.0,35.53,80.0,42.59,0.0,30
350.0,500,45,50,79.99,29.39,80.0,35.17,80.0,40.7,80.0,51.31,1.0,30
700.0,1000,45,50,79.99,23.59,80.0,27.66,80.0,29.21,80.0,32.46,0.0,30
93.33333333333334,200,40,50,7.55,131.21,9.04,142.01,10.32,154.98,12.1,167.33,0.0,165
350.0,500,50,50,79.99,29.06,80.0,37.29,80.0,45.52,80.0,50.79,0.0,30
420.0,600,30,50,79.99,27.55,80.0,33.82,80.0,36.82,80.0,45.97,0.0,30
280.0,400,40,50,79.99,31.43,80.0,40.31,80.0,46.96,80.0,58.56,3.0,30
35.0,50,35,50,72.91,92.54,79.98,113.76,79.99,126.37,80.0,155.47,8.0,30
455.0,650,45,50,79.99,26.09,80.0,32.23,80.0,36.59,80.0,41.14,1.0,30
280.0,400,20,50,79.99,26.17,80.0,38.25,80.0,42.8,80.0,55.47,1.0,30
280.0,400,45,50,79.99,32.99,80.0,42.08,80.0,49.31,80.0,64.15,0.0,30
525.0,750,25,50,79.99,22.76,80.0,26.3,80.0,29.94,80.0,38.88,0.0,30
700.0,1000,35,50,79.99,22.88,80.0,25.94,80.0,28.69,80.0,34.94,0.0,30
70.0,150,30,50,7.05,136.46,8.21,154.44,10.18,167.44,14.36,188.0,0.0,165
280.0,400,35,50,79.99,32.0,80.0,39.97,80.0,50.89,80.0,65.27,2.0,30
326.66666666666663,700,45,50,11.74,97.78,12.43,102.2,13.07,106.57,14.5,112.21,0.0,165
315.0,450,20,50,79.99,28.97,80.0,37.02,80.0,41.87,80.0,56.25,1.0,30
350.0,500,40,50,79.99,29.71,80.0,37.86,80.0,43.42,80.0,56.8,0.0,30
420.0,600,40,50,79.99,25.74,80.0,29.98,80.0,36.16,80.0,41.75,0.0,30
630.0,900,25,50,79.99,21.94,80.0,27.93,80.0,31.16,80.0,35.71,0.0,30
210.0,300,50,50,79.99,37.75,80.0,49.72,80.0,56.93,80.0,73.69,3.0,30
595.0,850,20,50,79.99,21.12,80.0,25.62,80.0,29.66,80.0,34.36,0.0,30
175.0,250,35,50,79.99,38.86,80.0,52.58,80.0,60.87,80.0,79.7,2.0,30
163.33333333333331,350,30,50,10.21,111.15,11.56,121.0,12.77,125.91,14.65,136.12,0.0,165
315.0,450,30,50,79.99,28.82,80.0,35.58,80.0,41.72,80.0,50.99,1.0,30
350.0,500,25,50,79.99,26.59,80.0,33.93,80.0,41.63,80.0,46.19,1.0,30
700.0,1000,20,50,74.33,68.77,76.54,71.5,78.38,73.64,80.0,76.2,0.0,45
525.0,750,45,50,79.99,25.2,80.0,30.34,80.0,34.13,80.0,40.4,0.0,30
665.0,950,30,50,79.99,22.49,80.0,25.77,80.0,28.25,80.0,33.57,0.0,30
256.66666666666663,550,45,50,11.14,103.31,12.04,108.88,12.93,113.66,14.23,122.11,0.0,165
326.66666666666663,700,30,50,12.27,96.21,13.26,99.74,13.73,103.7,15.09,109.93,0.0,165
560.0,800,25,50,73.31,69.44,75.55,72.58,79.27,74.36,80.0,77.94,0.0,45
303.33333333333337,650,40,50,11.88,98.53,12.64,104.38,13.44,108.55,14.35,116.17,0.0,165
46.66666666666667,100,20,50,5.95,144.76,7.5,168.08,10.07,189.94,16.36,248.38,0.0,165
70.0,150,35,50,6.57,132.8,8.24,148.74,9.76,160.5,12.08,182.76,0.0,165
280.0,600,20,50,10.8,98.49,11.79,104.73,13.02,108.76,13.85,112.75,0.0,180
140.0,200,50,50,60.58,87.58,68.09,97.34,78.86,106.56,80.0,112.36,1.0,45
70.0,150,20,50,7.34,136.08,8.96,149.76,10.72,166.56,20.39,185.38,0.0,165
466.6666666666667,1000,40,50,11.65,92.86,12.24,96.82,12.73,99.98,13.3,102.85,0.0,180
210.0,450,35,50,10.76,106.7,11.71,113.18,13.52,120.31,15.56,133.31,0.0,165
245.0,350,20,50,69.09,74.33,74.74,79.18,79.98,83.1,80.0,93.18,0.0,45
525.0,750,40,50,72.42,71.57,74.94,74.37,76.99,75.87,79.98,80.02,0.0,45
560.0,800,35,50,72.69,70.65,75.28,73.46,78.18,75.63,80.0,80.0,0.0,45
595.0,850,30,50,73.91,70.84,76.65,73.77,79.7,74.87,80.0,78.05,0.0,45
525.0,750,50,50,71.45,71.97,74.28,74.48,76.27,77.04,79.56,80.22,0.0,45
210.0,300,20,50,68.38,76.47,74.84,82.07,79.97,89.66,80.0,95.69,0.0,45
233.33333333333334,500,35,50,11.15,103.14,12.13,109.06,13.12,117.58,14.99,129.02,0.0,165
665.0,950,45,50,73.44,70.35,76.67,72.71,78.91,74.72,80.0,78.76,0.0,45
93.33333333333334,200,20,50,8.67,120.66,9.88,131.18,11.37,144.03,14.77,161.73,0.0,165
210.0,300,35,50,65.73,78.9,72.1,85.95,78.21,89.94,80.0,99.19,0.0,45
595.0,850,50,50,72.97,71.42,75.93,73.28,77.48,75.31,79.99,78.71,0.0,45
385.0,550,40,50,79.99,28.72,80.0,34.4,80.0,38.96,80.0,45.56,0.0,30
210.0,300,40,50,67.08,81.12,71.37,87.37,77.11,93.31,80.0,103.28,0.0,45
105.0,150,50,50,55.94,93.51,66.14,104.34,71.76,110.79,80.0,119.28,0.0,45
280.0,400,30,50,79.99,29.32,80.0,35.31,80.0,45.11,80.0,62.16,2.0,30
175.0,250,20,50,65.96,79.89,74.64,87.47,79.98,91.75,80.0,102.48,0.0,45
560.0,800,40,50,72.47,71.07,75.06,73.39,76.88,76.16,80.0,80.01,0.0,45
420.0,900,40,50,11.29,94.94,11.99,98.93,12.52,104.49,13.22,106.54,0.0,180
630.0,900,30,50,73.34,70.75,75.53,72.92,77.08,75.18,79.23,78.48,0.0,45
163.33333333333331,350,50,50,9.71,117.0,10.98,125.1,12.13,131.12,14.84,146.09,0.0,165
140.0,200,30,50,66.06,82.06,72.5,92.97,79.99,100.41,80.0,109.93,2.0,45
23.333333333333336,50,25,50,3.73,160.16,4.53,185.95,6.53,228.29,22.83,373.81,0.0,165
420.0,600,25,50,71.42,72.53,74.45,76.19,77.64,80.28,80.0,89.6,0.0,45
665.0,950,35,50,74.01,69.93,75.93,72.02,78.36,73.5,80.0,75.97,0.0,45
315.0,450,45,50,68.84,77.31,72.49,81.2,77.51,84.43,80.0,89.65,0.0,45
245.0,350,35,50,67.17,75.94,73.07,81.52,78.9,88.33,79.99,93.54,0.0,45
280.0,400,50,50,68.22,78.03,72.21,81.31,76.6,84.77,80.0,96.6,0.0,45
665.0,950,20,50,73.91,69.38,76.78,71.04,79.2,74.06,80.0,77.16,0.0,45
490.0,700,50,50,72.18,72.7,73.96,75.78,76.92,78.76,79.99,81.03,0.0,45
350.0,500,30,50,70.19,74.12,73.53,77.62,76.66,81.24,79.99,87.54,0.0,45
105.0,150,45,50,79.98,62.1,79.99,82.39,80.0,91.02,80.0,108.71,6.0,30
46.66666666666667,100,35,50,5.05,159.33,6.21,174.1,9.1,192.15,19.63,323.95,0.0,180
630.0,900,50,50,72.74,70.75,75.01,73.68,77.95,75.5,79.99,79.13,0.0,45
385.0,550,20,50,71.79,73.43,74.59,76.53,77.65,80.73,79.99,85.14,0.0,45
700.0,1000,30,50,74.29,69.91,75.82,71.57,77.65,73.33,79.73,76.78,0.0,45
35.0,50,50,50,38.06,112.78,61.3,135.48,79.96,148.82,80.0,225.64,2.0,45
595.0,850,35,50,73.0,71.18,75.64,73.51,77.47,75.15,80.0,77.59,0.0,45
315.0,450,50,50,69.06,75.73,72.74,81.38,76.56,84.75,79.99,92.66,0.0,45
455.0,650,50,50,70.82,73.89,73.91,76.43,76.66,79.63,79.99,85.05,0.0,45
245.0,350,25,50,70.68,76.53,74.74,83.5,79.96,89.22,80.0,93.46,0.0,45
245.0,350,40,50,67.29,78.57,71.42,84.36,77.13,87.25,80.0,98.51,0.0,45
595.0,850,45,50,73.22,70.13,76.07,73.21,78.43,75.48,79.99,78.55,0.0,45
210.0,300,25,50,67.15,79.03,73.38,85.62,79.34,90.32,79.99,94.48,0.0,45
385.0,550,50,50,70.85,74.56,73.13,78.73,75.62,82.26,79.98,85.18,0.0,45
420.0,600,35,50,70.68,73.93,73.99,76.57,77.87,79.0,80.0,83.35,0.0,45
350.0,500,20,50,71.19,73.43,75.14,76.37,79.99,80.41,80.0,87.8,0.0,45
70.0,100,30,50,50.48,100.24,61.82,113.23,75.2,117.99,79.99,146.84,0.0,45
210.0,300,45,50,63.94,82.7,69.08,87.21,74.15,91.39,79.99,102.32,0.0,45
140.0,200,35,50,62.34,85.7,71.08,93.46,77.71,100.2,80.0,103.51,0.0,45
140.0,200,45,50,60.73,87.29,67.53,95.86,78.83,104.11,80.0,118.01,0.0,45
595.0,850,25,50,74.22,69.29,76.34,72.27,78.82,74.88,79.98,77.4,0.0,45
175.0,250,45,50,63.2,84.12,67.92,90.34,78.61,94.89,80.0,100.27,0.0,45
560.0,800,30,50,73.24,70.46,75.41,72.43,78.3,74.27,80.0,77.16,0.0,45
175.0,250,30,50,65.16,82.45,71.54,89.6,76.91,96.41,80.0,101.9,0.0,45
525.0,750,35,50,71.93,71.56,75.14,74.0,77.31,76.33,80.0,80.54,0.0,45
665.0,950,50,50,72.94,71.08,75.55,72.69,77.46,74.14,79.19,76.98,0.0,45
280.0,400,25,50,71.02,73.73,74.86,78.92,79.96,83.16,80.0,91.01,0.0,45
326.66666666666663,700,40,50,11.88,97.97,12.44,101.87,13.31,106.81,14.54,116.73,0.0,165
630.0,900,35,50,72.97,69.68,75.1,72.04,76.98,74.52,79.98,76.62,0.0,45
105.0,150,40,50,58.03,88.64,65.65,98.21,71.86,108.05,79.99,116.68,1.0,45
105.0,150,25,50,59.41,87.34,72.17,102.52,79.98,113.98,80.0,129.26,0.0,45
70.0,100,45,50,51.2,100.43,63.4,115.12,79.98,130.32,80.0,148.42,2.0,45
35.0,50,40,50,36.45,121.11,45.15,136.74,63.17,156.21,80.0,219.62,2.0,45
175.0,250,25,50,65.96,81.11,71.78,88.32,75.33,95.29,80.0,100.12,0.0,45
560.0,800,20,50,74.44,69.01,76.57,71.36,79.49,73.4,80.0,77.15,0.0,45
35.0,50,20,50,43.96,103.47,64.06,126.24,79.98,152.56,80.0,186.76,6.0,45
455.0,650,30,50,71.65,72.59,73.84,76.15,77.26,77.62,80.0,82.15,0.0,45
455.0,650,25,50,71.85,70.37,74.28,74.52,77.5,76.98,79.99,80.38,0.0,45
175.0,250,40,50,64.0,80.39,71.85,87.48,79.97,93.67,80.0,104.49,0.0,45
490.0,700,25,50,72.29,71.58,76.16,75.09,79.97,77.07,80.0,80.42,0.0,45
70.0,100,25,50,52.58,96.62,65.06,109.78,79.98,117.94,80.0,141.95,2.0,45
315.0,450,25,50,69.57,74.98,73.63,79.02,76.85,82.76,80.0,87.92,0.0,45
525.0,750,20,50,72.98,70.42,77.09,73.85,79.44,76.61,80.0,79.55,0.0,45
35.0,50,45,50,36.6,114.89,52.6,135.52,77.28,153.14,80.0,198.44,2.0,45
326.66666666666663,700,35,50,10.92,99.87,11.71,104.99,12.33,108.98,13.39,116.62,0.0,180
70.0,100,50,50,46.94,99.09,59.51,115.64,74.57,124.66,79.99,142.45,2.0,45
630.0,900,20,50,73.91,69.95,75.45,71.75,78.02,73.46,79.99,76.22,0.0,45
385.0,550,35,50,70.91,73.86,74.75,77.77,76.54,81.85,79.99,85.85,0.0,45
70.0,100,40,50,51.38,100.28,62.54,114.01,79.98,131.25,80.0,149.2,5.0,45
525.0,750,30,50,73.1,71.08,75.32,73.63,78.87,76.42,80.0,79.86,0.0,45
420.0,600,50,50,71.58,73.36,74.44,76.76,77.73,79.45,79.99,82.48,0.0,45
420.0,600,45,50,71.54,73.22,74.55,76.03,77.32,78.9,80.0,83.16,0.0,45
175.0,250,50,50,62.32,83.93,70.87,91.05,78.0,97.66,80.0,105.66,0.0,45
700.0,1000,25,50,74.29,69.18,76.48,71.61,78.12,73.17,79.99,76.21,0.0,45
455.0,650,20,50,72.72,71.53,75.7,73.81,77.97,76.31,79.99,80.3,0.0,45
35.0,50,30,50,40.47,115.0,61.04,131.72,79.98,147.3,80.0,170.83,2.0,45
665.0,950,25,50,73.78,69.01,76.37,72.07,78.39,75.15,79.99,76.33,0.0,45
700.0,1000,50,50,74.13,70.35,75.71,72.37,77.24,74.08,79.05,77.27,0.0,45
455.0,650,35,50,71.74,71.52,74.09,75.11,76.45,77.54,79.96,81.34,0.0,45
140.0,200,25,50,59.69,83.87,72.25,93.73,79.97,99.57,80.0,115.39,0.0,45
630.0,900,45,50,73.74,70.45,76.51,72.87,77.92,74.75,79.98,76.72,0.0,45
595.0,850,40,50,72.89,70.77,74.97,73.22,77.5,75.91,79.99,80.35,0.0,45
315.0,450,40,50,68.61,74.7,71.26,80.42,76.15,83.72,80.0,89.59,0.0,45
490.0,700,20,50,72.13,70.91,75.55,73.57,78.4,75.49,79.99,79.25,0.0,45
420.0,600,30,50,71.57,73.12,74.86,76.12,77.61,81.05,79.98,83.95,0.0,45
560.0,800,45,50,72.22,71.06,75.51,73.59,77.19,76.17,80.0,78.75,0.0,45
385.0,550,25,50,71.71,72.19,74.37,75.87,77.68,79.06,80.0,85.19,0.0,45
350.0,500,45,50,70.71,73.24,74.14,77.95,79.15,81.93,80.0,87.93,0.0,45
245.0,350,45,50,68.02,78.65,73.9,83.14,78.39,86.57,79.99,97.5,0.0,45
700.0,1000,45,50,73.58,69.98,75.52,72.18,77.07,73.91,79.12,77.81,0.0,45
210.0,300,30,50,79.99,36.21,80.0,49.11,80.0,59.65,80.0,75.47,4.0,30
665.0,950,40,50,73.05,71.25,75.22,72.55,77.05,74.92,79.21,79.46,0.0,45
385.0,550,30,50,71.95,72.01,75.06,76.38,78.46,80.27,80.0,89.09,0.0,45
35.0,50,35,50,38.22,119.46,51.16,136.42,70.46,158.36,80.0,213.72,3.0,45
350.0,500,50,50,68.7,76.2,71.96,79.7,74.81,84.23,79.99,88.86,0.0,45
280.0,400,40,50,67.06,77.36,73.09,82.17,79.06,85.96,79.99,92.15,0.0,45
455.0,650,45,50,71.88,71.06,75.2,76.0,78.58,78.3,80.0,82.22,0.0,45
525.0,750,25,50,72.56,71.56,75.03,74.11,78.07,77.05,79.99,82.64,0.0,45
280.0,400,20,50,71.51,75.38,75.26,79.84,78.79,82.97,79.99,86.52,0.0,45
280.0,400,35,50,67.38,75.86,72.04,80.81,77.86,85.43,80.0,96.36,0.0,45
700.0,1000,35,50,74.32,69.61,76.26,71.38,78.68,72.87,79.98,76.03,0.0,45
420.0,600,40,50,71.41,72.75,74.54,76.55,78.38,80.09,79.99,83.23,0.0,45
280.0,400,45,50,69.43,77.94,72.47,82.53,76.24,87.51,80.0,93.57,0.0,45
630.0,900,25,50,74.08,69.99,75.61,72.28,78.17,74.98,79.96,78.9,0.0,45
315.0,450,20,50,71.39,72.94,75.97,77.02,79.99,81.93,80.0,86.63,0.0,45
350.0,500,40,50,70.46,75.19,75.02,79.84,78.64,83.62,80.0,87.19,0.0,45
560.0,800,50,50,79.99,23.88,80.0,28.26,80.0,31.82,80.0,38.37,0.0,30
595.0,850,20,50,74.14,69.11,76.65,71.98,79.3,74.15,79.99,78.06,0.0,45
210.0,300,50,50,65.29,81.37,71.36,87.28,79.33,91.35,79.99,100.82,0.0,45
175.0,250,35,50,64.58,83.99,70.08,92.3,77.32,98.31,80.0,103.69,0.0,45
315.0,450,30,50,70.14,75.34,74.63,79.66,79.98,82.49,80.0,87.06,0.0,45
350.0,500,25,50,70.28,74.23,74.28,78.05,78.02,81.62,80.0,84.35,0.0,45
93.33333333333334,200,40,50,6.92,131.16,7.76,142.55,8.93,156.34,11.68,177.03,0.0,180
700.0,1000,20,50,54.77,72.49,57.15,74.98,58.74,76.31,61.93,79.52,0.0,60
326.66666666666663,700,45,50,10.59,100.89,11.41,106.55,11.99,110.61,13.33,119.04,0.0,180
665.0,950,30,50,73.33,69.59,75.0,72.03,76.66,73.8,79.99,77.33,0.0,45
163.33333333333331,350,30,50,8.94,113.26,10.13,121.58,11.27,130.16,13.09,138.69,0.0,180
70.0,150,30,50,6.3,143.54,8.15,157.19,9.42,165.59,13.32,192.4,0.0,180
560.0,800,25,50,54.41,73.23,56.28,76.26,58.17,78.68,64.35,83.27,0.0,60
525.0,750,45,50,71.7,72.31,74.21,75.12,77.39,78.04,79.98,81.06,0.0,45
595.0,850,30,50,54.92,73.75,56.78,76.14,58.55,78.17,62.98,82.28,0.0,60
140.0,200,50,50,43.69,98.17,49.45,104.67,54.86,113.71,76.49,121.75,0.0,60
245.0,350,20,50,49.79,81.86,54.7,89.5,59.13,92.63,63.2,99.13,0.0,60
420.0,600,20,50,79.99,24.71,80.0,30.11,80.0,35.94,80.0,43.35,2.0,30
665.0,950,45,50,54.33,73.09,56.45,75.1,58.56,78.31,62.98,80.99,0.0,60
525.0,750,50,50,53.08,75.05,55.35,78.39,57.07,80.93,60.11,86.55,0.0,60
525.0,750,40,50,53.34,75.73,56.16,78.97,57.94,81.11,63.35,86.41,0.0,60
560.0,800,35,50,53.52,75.06,55.82,78.59,57.93,80.73,61.82,85.03,0.0,60
210.0,300,20,50,48.73,84.73,54.33,91.68,59.67,97.98,70.02,104.63,0.0,60
210.0,300,35,50,48.18,87.11,52.88,93.33,55.8,98.35,71.09,101.98,0.0,60
385.0,550,40,50,70.3,73.85,73.67,77.56,76.73,80.15,79.99,86.92,0.0,45
595.0,850,50,50,53.59,74.55,56.5,77.71,58.14,80.16,60.23,82.38,0.0,60
256.66666666666663,550,45,50,10.0,103.92,10.87,111.82,11.6,117.61,13.51,128.05,0.0,180
630.0,900,30,50,55.0,73.01,57.1,76.12,58.64,79.01,61.76,80.67,0.0,60
175.0,250,20,50,47.67,86.47,53.38,94.36,61.52,101.23,74.46,114.62,0.0,60
210.0,300,40,50,48.28,88.86,52.36,95.12,59.29,99.95,76.36,113.39,0.0,60
560.0,800,40,50,54.45,75.0,56.58,77.4,57.72,79.95,60.29,84.61,0.0,60
420.0,600,25,50,53.42,75.05,56.46,79.2,59.98,82.89,62.58,87.37,0.0,60
140.0,200,30,50,44.02,93.68,49.46,100.08,53.65,108.69,64.96,121.48,0.0,60
105.0,150,50,50,37.73,104.51,45.78,113.2,54.62,123.8,66.36,135.31,0.0,60
245.0,350,35,50,50.01,82.11,53.12,89.36,59.39,95.44,68.67,104.02,0.0,60
280.0,400,50,50,48.95,83.12,51.78,88.35,55.46,92.98,59.11,98.86,0.0,60
70.0,150,35,50,6.14,144.59,7.12,155.07,8.58,167.16,10.85,187.81,0.0,180
326.66666666666663,700,30,50,11.02,97.57,11.71,103.09,12.32,107.2,13.44,112.79,0.0,180
490.0,700,50,50,53.21,76.11,54.81,79.16,57.03,81.65,61.01,89.4,0.0,60
700.0,1000,40,50,79.99,21.65,80.0,25.66,80.0,29.13,80.0,32.29,0.0,30
665.0,950,35,50,54.83,73.69,57.23,76.24,58.93,78.08,61.6,79.3,0.0,60
665.0,950,20,50,55.64,72.73,57.57,74.9,59.18,77.41,62.76,82.36,0.0,60
315.0,450,45,50,51.15,80.6,54.09,85.72,56.91,91.24,63.64,96.48,0.0,60
315.0,450,50,50,50.1,81.52,53.44,87.89,58.36,92.03,66.35,98.02,0.0,60
210.0,450,35,50,9.34,109.75,10.24,114.81,11.2,119.82,13.42,123.65,0.0,180
303.33333333333337,650,40,50,10.74,100.66,11.47,106.63,12.39,110.37,13.54,125.17,0.0,180
46.66666666666667,100,20,50,5.23,150.79,6.44,162.35,8.32,183.08,13.61,210.99,0.0,180
140.0,200,35,50,43.74,94.86,50.19,103.6,58.49,111.33,79.96,120.14,0.0,60
350.0,500,30,50,51.55,79.44,55.42,83.26,57.72,86.71,62.17,90.45,0.0,60
350.0,500,20,50,52.62,78.16,55.39,81.3,59.73,85.35,65.83,92.35,0.0,60
420.0,600,35,50,53.13,76.35,55.42,79.63,57.96,83.96,65.11,86.94,0.0,60
245.0,350,25,50,50.46,82.48,54.61,89.64,58.48,94.21,69.04,101.97,0.0,60
700.0,1000,30,50,55.98,72.55,57.71,75.09,58.77,77.33,61.3,80.72,0.0,60
630.0,900,50,50,54.66,74.44,56.28,77.61,57.24,80.04,60.19,82.77,0.0,60
385.0,550,50,50,52.06,80.29,54.7,83.75,56.96,87.2,64.29,90.99,0.0,60
245.0,350,40,50,49.37,83.89,53.16,90.02,58.47,94.24,65.51,104.74,0.0,60
595.0,850,35,50,54.62,74.67,56.55,77.11,58.29,78.67,60.56,81.61,0.0,60
210.0,300,25,50,47.88,87.18,52.27,94.37,59.53,97.76,71.26,104.44,0.0,60
385.0,550,20,50,52.74,76.65,55.82,80.29,58.62,85.4,61.86,91.16,0.0,60
140.0,200,45,50,44.52,96.45,50.31,102.87,56.83,112.88,69.37,122.51,0.0,60
455.0,650,50,50,52.79,77.7,55.33,81.37,57.9,84.39,61.28,89.5,0.0,60
595.0,850,45,50,54.32,74.16,56.55,77.47,58.75,79.56,61.23,83.19,0.0,60
175.0,250,30,50,46.99,90.19,50.95,98.75,54.87,104.3,66.45,119.63,0.0,60
233.33333333333334,500,35,50,10.06,105.06,10.65,111.7,11.64,118.89,13.08,133.31,0.0,180
175.0,250,45,50,44.65,89.75,48.1,98.14,53.65,104.55,65.94,116.17,0.0,60
280.0,400,30,50,68.9,74.87,73.85,79.82,77.71,84.82,80.0,91.94,0.0,45
210.0,300,45,50,46.64,88.42,51.05,96.06,55.26,100.2,64.92,110.76,0.0,60
35.0,50,50,50,21.01,130.81,30.95,151.54,49.96,173.55,79.98,236.57,1.0,60
70.0,100,30,50,35.87,113.16,43.17,125.73,56.4,139.99,80.0,167.14,1.0,60
525.0,750,35,50,54.87,75.61,56.73,78.39,60.11,82.26,62.71,88.2,0.0,60
70.0,150,20,50,6.42,136.62,7.98,145.39,10.68,158.09,15.71,178.64,0.0,180
560.0,800,30,50,54.25,73.79,56.59,77.73,59.46,79.93,63.94,84.17,0.0,60
595.0,850,25,50,54.76,73.39,56.39,77.46,57.9,80.27,60.13,84.71,0.0,60
665.0,950,50,50,55.71,72.52,57.24,75.07,58.92,77.64,61.38,82.02,0.0,60
280.0,400,25,50,51.38,80.52,55.39,85.31,58.22,89.86,62.97,92.27,0.0,60
93.33333333333334,200,20,50,7.48,128.43,9.16,137.12,11.59,147.95,14.45,168.92,0.0,180
105.0,150,25,50,41.29,93.42,49.55,103.54,57.82,112.56,79.99,117.63,0.0,60
70.0,100,45,50,34.13,113.82,40.33,126.93,48.51,137.97,79.99,164.29,0.0,60
455.0,650,25,50,54.21,75.18,56.84,77.96,58.69,79.93,63.05,85.8,0.0,60
35.0,50,20,50,23.75,118.5,36.38,133.15,53.69,161.65,79.98,221.15,0.0,60
560.0,800,20,50,54.62,73.62,56.8,76.46,59.12,79.46,63.54,81.9,0.0,60
630.0,900,40,50,79.99,22.46,80.0,26.29,80.0,30.1,80.0,35.67,0.0,30
455.0,650,30,50,53.68,75.19,55.88,78.95,59.06,82.33,63.59,87.07,0.0,60
490.0,700,25,50,54.69,75.24,57.13,78.18,58.22,80.75,60.12,83.16,0.0,60
35.0,50,40,50,22.33,127.89,30.92,157.83,60.7,176.3,80.0,238.75,2.0,60
105.0,150,40,50,40.81,99.45,48.11,108.4,56.03,117.84,80.0,131.67,0.0,60
163.33333333333331,350,50,50,8.69,121.29,9.52,130.75,10.3,135.47,11.35,144.63,0.0,180
175.0,250,25,50,47.14,91.14,51.23,95.23,55.73,101.07,67.64,108.18,0.0,60
630.0,900,20,50,54.93,72.45,57.6,75.09,59.27,77.03,61.85,81.44,0.0,60
105.0,150,45,50,57.58,95.56,67.06,105.42,79.41,116.28,80.0,134.36,0.0,45
315.0,450,25,50,52.4,76.84,54.68,83.49,58.04,87.19,72.4,96.06,0.0,60
385.0,550,35,50,51.93,78.24,54.35,81.87,57.15,85.38,61.1,93.63,0.0,60
175.0,250,40,50,44.83,90.91,50.13,98.57,56.56,105.05,67.43,117.89,0.0,60
70.0,100,50,50,32.13,111.51,37.52,124.31,47.71,133.74,79.7,145.86,0.0,60
70.0,100,25,50,35.71,110.89,43.43,122.43,56.59,135.81,71.81,159.87,0.0,60
630.0,900,35,50,55.21,73.24,56.8,75.84,58.34,78.22,61.54,81.36,0.0,60
70.0,100,40,50,35.45,108.6,41.25,123.71,50.04,137.23,80.0,150.02,0.0,60
420.0,600,50,50,52.33,77.11,54.38,81.3,56.35,83.68,60.39,88.4,0.0,60
420.0,600,45,50,52.75,78.5,54.55,82.53,57.53,85.23,62.45,91.61,0.0,60
525.0,750,30,50,54.44,74.97,56.3,78.25,57.87,80.21,61.08,83.62,0.0,60
23.333333333333336,50,25,50,3.08,174.01,3.86,195.79,5.55,217.51,14.15,416.43,0.0,180
70.0,100,35,50,79.98,71.16,79.99,89.62,80.0,101.84,80.0,113.4,6.0,30
35.0,50,30,50,25.82,120.87,35.29,137.78,53.61,159.42,79.99,198.25,1.0,60
525.0,750,20,50,53.89,74.29,56.23,76.92,59.03,80.25,60.88,85.29,0.0,60
315.0,450,40,50,51.16,83.04,54.71,86.78,58.55,90.64,64.64,95.6,0.0,60
35.0,50,45,50,23.2,130.22,31.6,149.03,43.06,176.41,68.2,223.61,0.0,60
700.0,1000,50,50,55.07,73.08,56.56,76.95,58.14,77.97,60.48,80.1,0.0,60
455.0,650,20,50,54.28,74.75,57.12,79.04,59.59,81.33,63.82,84.3,0.0,60
700.0,1000,25,50,55.25,72.63,56.66,74.99,58.59,77.03,61.56,79.37,0.0,60
455.0,650,35,50,52.98,76.36,56.06,80.29,58.88,83.37,63.92,86.9,0.0,60
175.0,250,50,50,46.66,89.34,51.4,97.42,57.9,103.31,76.77,109.43,0.0,60
245.0,350,45,50,48.3,84.34,53.22,90.08,57.4,93.96,64.73,102.15,0.0,60
665.0,950,25,50,55.25,71.75,57.75,74.99,59.56,78.01,62.14,79.42,0.0,60
326.66666666666663,700,40,50,10.87,100.04,11.67,104.29,12.4,107.54,13.74,114.13,0.0,180
490.0,700,20,50,53.92,74.78,56.22,78.01,57.73,80.54,61.98,83.41,0.0,60
420.0,600,30,50,52.93,76.38,55.79,79.83,58.13,83.75,62.91,86.45,0.0,60
560.0,800,45,50,53.85,75.29,55.78,77.66,58.28,79.61,61.19,82.86,0.0,60
350.0,500,45,50,51.26,81.54,53.83,84.84,57.01,87.21,59.77,97.11,0.0,60
35.0,50,35,50,24.11,125.99,31.52,150.03,40.29,169.6,79.99,216.15,1.0,60
140.0,200,25,50,43.05,89.75,50.91,98.14,57.92,104.15,71.54,115.4,0.0,60
595.0,850,40,50,54.06,74.9,56.0,77.71,58.45,81.18,62.69,85.61,0.0,60
630.0,900,45,50,54.25,73.86,56.31,76.56,57.44,79.78,59.33,83.45,0.0,60
385.0,550,25,50,53.31,76.09,55.51,81.35,58.74,84.14,63.21,86.97,0.0,60
700.0,1000,45,50,54.95,72.81,56.48,75.18,57.97,76.86,60.31,78.94,0.0,60
385.0,550,30,50,52.52,77.34,56.13,80.52,59.65,85.6,66.35,89.39,0.0,60
455.0,650,45,50,53.24,77.31,55.73,81.95,58.34,85.32,62.22,88.02,0.0,60
665.0,950,40,50,54.77,72.62,56.56,75.43,58.18,77.05,61.47,80.4,0.0,60
350.0,500,50,50,52.03,80.02,54.6,84.09,57.51,89.29,61.94,95.29,0.0,60
525.0,750,25,50,53.95,74.19,56.53,77.83,58.34,80.25,60.45,86.05,0.0,60
630.0,900,25,50,54.77,73.7,56.91,76.48,58.52,79.43,62.42,82.87,0.0,60
700.0,1000,35,50,55.14,73.52,56.73,76.6,57.96,78.93,59.19,80.33,0.0,60
420.0,600,40,50,52.01,78.15,55.79,81.79,58.32,84.13,63.51,89.97,0.0,60
280.0,400,40,50,49.35,83.27,54.09,88.13,57.94,92.37,66.29,98.82,0.0,60
280.0,400,20,50,52.42,79.41,55.87,84.36,58.59,89.53,67.29,94.47,0.0,60
490.0,700,35,50,79.99,24.95,80.0,29.15,80.0,33.76,80.0,36.92,0.0,30
280.0,400,45,50,49.08,81.95,53.21,87.7,57.04,91.69,72.02,94.99,0.0,60
315.0,450,20,50,52.25,76.92,55.96,83.39,60.17,87.48,68.91,91.75,0.0,60
350.0,500,40,50,50.89,78.95,54.22,84.21,56.53,88.13,63.04,92.17,0.0,60
280.0,400,35,50,51.53,78.9,54.86,84.43,57.45,91.76,67.83,97.52,0.0,60
210.0,300,30,50,66.05,78.69,73.95,85.16,79.97,89.27,80.0,98.1,0.0,45
175.0,250,35,50,46.19,87.91,52.6,95.95,58.19,104.23,66.77,115.05,0.0,60
350.0,500,25,50,53.05,77.57,54.97,82.5,59.3,86.08,66.54,92.19,0.0,60
315.0,450,30,50,49.8,79.94,55.04,83.88,58.88,89.62,61.19,95.13,0.0,60
595.0,850,20,50,55.42,72.56,57.36,75.21,58.89,78.15,61.33,85.67,0.0,60
210.0,300,50,50,47.87,90.04,51.54,95.75,56.25,100.94,67.07,109.13,0.0,60
700.0,1000,20,50,44.61,74.23,45.74,77.1,47.13,79.27,49.69,84.52,0.0,75
665.0,950,30,50,55.19,73.16,56.81,75.52,58.22,78.45,62.0,82.66,0.0,60
560.0,800,50,50,72.36,71.91,74.52,73.99,77.47,77.7,79.99,81.15,0.0,45
560.0,800,25,50,43.48,76.86,45.2,79.91,47.14,82.08,51.26,84.56,0.0,75
140.0,200,40,50,79.99,53.6,80.0,63.8,80.0,77.43,80.0,93.27,2.0,30
665.0,950,45,50,43.18,76.41,44.73,79.7,46.05,82.8,48.11,87.01,0.0,75
595.0,850,30,50,42.85,76.9,45.22,79.8,47.83,82.39,53.54,85.48,0.0,75
560.0,800,35,50,43.06,77.81,44.98,81.04,46.5,83.51,50.07,86.21,0.0,75
140.0,200,50,50,32.74,102.85,36.79,111.71,40.67,118.78,51.23,127.27,0.0,75
245.0,350,20,50,40.34,85.46,43.24,91.65,47.92,96.79,56.5,104.29,0.0,75
525.0,750,50,50,42.09,79.14,44.1,82.23,46.7,86.61,48.54,91.0,0.0,75
490.0,700,45,50,79.99,27.53,80.0,31.64,80.0,36.47,80.0,43.31,0.0,30
595.0,850,50,50,42.75,78.14,44.54,81.24,46.05,84.47,49.82,88.86,0.0,75
525.0,750,40,50,42.17,79.34,44.07,82.87,45.21,85.5,51.09,90.13,0.0,75
420.0,600,20,50,71.62,73.09,75.27,76.35,78.58,79.65,80.0,84.04,0.0,45
210.0,300,20,50,38.05,87.79,42.28,93.05,47.21,100.84,54.13,108.99,0.0,75
560.0,800,40,50,42.83,78.28,44.56,82.09,46.38,85.3,48.26,88.15,0.0,75
175.0,250,20,50,37.59,92.75,42.52,105.58,47.52,111.6,56.6,117.17,0.0,75
210.0,300,35,50,36.59,91.4,40.19,98.5,45.27,103.54,54.15,113.36,0.0,75
245.0,350,30,50,79.99,33.26,80.0,44.72,80.0,52.68,80.0,69.54,0.0,30
630.0,900,30,50,43.69,76.0,45.49,79.25,47.09,83.04,48.96,86.33,0.0,75
245.0,350,35,50,38.24,88.49,41.25,94.96,45.84,99.69,53.57,111.54,0.0,75
385.0,550,40,50,52.04,77.9,56.2,81.92,59.01,86.74,62.24,94.23,0.0,60
105.0,150,30,50,79.99,47.96,79.99,69.68,80.0,78.21,80.0,95.52,8.0,30
420.0,600,25,50,41.66,80.66,43.65,83.8,46.7,87.61,51.44,91.87,0.0,75
210.0,300,40,50,36.18,91.91,39.57,97.96,43.56,103.47,54.22,107.61,0.0,75
105.0,150,50,50,28.9,105.46,34.43,119.22,41.42,128.4,47.87,145.16,0.0,75
665.0,950,35,50,43.5,75.91,45.02,79.97,46.57,82.53,49.04,86.06,0.0,75
140.0,200,30,50,32.77,98.97,38.83,107.17,44.03,116.71,54.53,128.2,0.0,75
525.0,750,45,50,53.5,75.81,56.06,79.3,58.54,81.98,59.85,84.97,0.0,60
280.0,400,50,50,38.5,86.86,40.89,91.49,45.85,95.9,49.71,103.29,0.0,75
315.0,450,50,50,39.7,85.61,42.37,90.58,45.38,94.62,52.35,99.88,0.0,75
490.0,700,50,50,41.29,79.6,42.98,83.74,45.82,86.95,51.66,95.3,0.0,75
140.0,200,35,50,32.95,99.79,39.55,108.29,44.55,117.42,67.79,128.09,0.0,75
665.0,950,20,50,44.34,73.8,46.35,76.71,47.72,79.65,50.1,85.01,0.0,75
595.0,850,45,50,42.45,77.83,44.55,80.09,45.94,82.43,49.1,85.14,0.0,75
175.0,250,30,50,35.5,94.58,38.86,102.67,44.48,107.97,60.4,117.65,0.0,75
700.0,1000,30,50,43.72,75.15,45.43,78.66,47.28,81.54,49.96,84.25,0.0,75
630.0,900,50,50,43.41,76.91,44.8,79.96,46.1,81.6,48.76,89.18,0.0,75
245.0,350,25,50,38.79,87.76,43.01,92.9,47.25,96.87,54.81,104.32,0.0,75
315.0,450,45,50,39.44,85.48,41.58,91.47,44.0,95.55,47.79,98.79,0.0,75
455.0,650,50,50,41.71,80.39,43.55,84.37,45.82,88.0,48.83,92.13,0.0,75
140.0,200,45,50,32.76,101.78,38.43,108.68,43.35,116.6,57.88,126.05,0.0,75
350.0,500,30,50,41.18,83.26,43.31,87.81,45.29,93.26,48.67,97.36,0.0,75
420.0,600,35,50,41.61,79.14,43.9,85.37,46.92,87.78,52.54,95.32,0.0,75
175.0,250,45,50,34.36,95.63,38.35,104.39,44.5,112.26,53.67,127.49,0.0,75
70.0,100,30,50,27.34,116.33,31.91,128.73,40.48,143.43,80.0,159.97,0.0,75
350.0,500,20,50,42.03,80.09,44.95,86.14,46.79,89.42,57.76,92.63,0.0,75
595.0,850,35,50,42.26,77.18,44.25,81.08,46.01,82.69,47.89,85.62,0.0,75
525.0,750,35,50,42.73,78.61,44.65,82.15,46.47,85.12,49.27,89.92,0.0,75
210.0,300,25,50,38.1,90.46,41.69,98.75,45.44,104.49,55.96,117.21,0.0,75
385.0,550,20,50,42.01,78.2,44.96,83.27,46.49,88.72,50.22,93.71,0.0,75
385.0,550,50,50,39.69,83.12,42.35,88.03,45.08,91.23,51.43,96.65,0.0,75
560.0,800,30,50,43.3,77.98,45.41,81.28,47.31,83.94,50.62,87.35,0.0,75
210.0,300,45,50,36.56,94.68,39.76,101.17,42.96,104.79,56.28,114.81,0.0,75
245.0,350,40,50,39.5,89.07,43.06,95.53,45.14,99.72,56.19,105.81,0.0,75
105.0,150,35,50,79.99,53.63,80.0,76.81,80.0,86.65,80.0,96.55,6.0,30
700.0,1000,40,50,73.05,70.12,75.13,71.9,77.52,74.44,79.28,77.26,0.0,45
70.0,100,45,50,23.29,121.52,27.03,134.59,33.19,146.18,52.7,169.71,0.0,75
105.0,150,25,50,30.26,103.71,36.06,116.47,44.21,123.89,62.02,137.62,0.0,75
455.0,650,25,50,42.72,78.82,44.93,82.82,47.0,87.44,50.04,90.41,0.0,75
665.0,950,50,50,43.18,76.11,44.61,78.99,46.4,81.61,47.96,86.71,0.0,75
455.0,650,30,50,42.83,79.29,45.21,83.26,46.42,86.91,50.41,94.33,0.0,75
35.0,50,50,50,17.32,142.62,22.97,167.7,32.2,189.48,79.99,244.57,0.0,75
35.0,50,40,50,17.73,140.32,26.14,154.34,35.14,178.71,79.97,256.88,0.0,75
70.0,100,20,50,79.97,73.38,79.99,91.44,80.0,99.05,80.0,114.49,10.0,30
315.0,450,25,50,39.94,84.26,42.85,90.26,44.65,93.93,49.87,99.43,0.0,75
385.0,550,45,50,79.99,27.98,80.0,34.88,80.0,41.99,80.0,49.62,0.0,30
595.0,850,25,50,43.86,75.54,45.63,79.79,47.65,82.4,51.46,84.05,0.0,75
105.0,150,40,50,31.19,108.5,36.41,118.19,43.99,127.31,79.99,143.07,0.0,75
175.0,250,40,50,34.9,97.63,39.47,103.26,42.02,108.95,48.03,118.8,0.0,75
560.0,800,20,50,43.83,75.54,45.9,79.4,48.0,81.84,49.95,85.78,0.0,75
385.0,550,35,50,40.61,81.89,43.58,86.52,46.21,89.3,54.31,96.81,0.0,75
175.0,250,25,50,37.42,93.61,41.99,100.65,48.2,107.61,64.4,113.92,0.0,75
630.0,900,20,50,43.79,75.12,45.9,77.83,48.53,80.14,51.08,84.71,0.0,75
280.0,400,25,50,41.11,84.97,44.27,92.24,47.12,95.22,52.35,105.37,0.0,75
70.0,100,50,50,25.27,119.58,30.15,136.2,36.4,152.05,79.99,168.92,1.0,75
455.0,650,40,50,79.99,26.49,80.0,31.41,80.0,37.89,80.0,44.32,0.0,30
35.0,50,20,50,18.68,131.36,25.32,156.83,40.76,166.82,77.84,207.38,0.0,75
490.0,700,30,50,79.99,24.24,80.0,30.16,80.0,35.4,80.0,39.82,1.0,30
420.0,600,45,50,41.35,81.87,43.65,85.53,45.58,89.52,49.33,94.36,0.0,75
630.0,900,35,50,43.36,76.54,45.39,79.85,46.56,82.27,51.45,86.66,0.0,75
490.0,700,25,50,43.15,77.69,45.51,81.59,48.4,85.28,51.42,89.71,0.0,75
315.0,450,35,50,79.99,28.03,80.0,37.31,80.0,45.51,80.0,58.41,1.0,30
70.0,100,25,50,27.17,114.96,33.97,124.97,46.88,146.59,77.53,168.24,0.0,75
525.0,750,30,50,43.01,78.3,45.12,81.18,46.71,83.04,49.44,89.41,0.0,75
70.0,100,40,50,27.38,115.82,32.64,131.09,37.83,141.75,64.75,166.0,0.0,75
420.0,600,50,50,40.67,82.0,43.66,86.46,45.4,89.13,49.26,92.21,0.0,75
35.0,50,30,50,18.23,132.22,23.7,158.01,35.0,174.38,80.0,235.29,1.0,75
525.0,750,20,50,43.26,76.83,44.43,81.13,46.79,83.15,50.5,88.07,0.0,75
315.0,450,40,50,39.46,85.22,43.66,90.07,45.82,95.06,51.63,102.32,0.0,75
455.0,650,20,50,41.75,77.26,44.65,82.04,46.89,84.68,48.54,89.96,0.0,75
35.0,50,45,50,15.74,136.38,21.07,151.43,28.9,176.41,73.82,249.82,1.0,75
140.0,200,20,50,79.99,46.44,80.0,65.59,80.0,78.06,80.0,86.76,3.0,30
700.0,1000,50,50,43.24,76.72,44.39,79.93,46.32,81.92,49.21,87.68,0.0,75
105.0,150,45,50,38.43,102.4,46.46,111.21,54.27,118.97,75.56,130.06,0.0,60
280.0,400,30,50,51.69,79.66,54.15,86.05,60.28,90.1,65.37,96.94,0.0,60
665.0,950,25,50,43.19,75.86,44.93,78.0,46.27,79.83,49.16,83.47,0.0,75
105.0,150,20,50,79.99,45.77,80.0,67.3,80.0,84.12,80.0,95.57,4.0,30
175.0,250,50,50,35.68,97.54,39.14,107.71,43.21,115.3,53.18,121.15,0.0,75
455.0,650,35,50,41.74,79.45,44.36,82.06,47.11,85.14,50.91,90.8,0.0,75
385.0,550,25,50,40.81,80.13,43.43,85.66,47.47,89.81,51.38,94.42,0.0,75
700.0,1000,25,50,43.98,75.05,45.58,77.66,46.98,80.12,50.9,82.1,0.0,75
245.0,350,45,50,37.63,90.52,41.15,95.5,43.55,100.43,50.51,117.0,0.0,75
490.0,700,20,50,43.38,77.69,45.24,81.59,47.2,84.03,50.77,92.46,0.0,75
630.0,900,40,50,73.21,70.91,75.08,73.28,76.36,75.3,79.95,77.32,0.0,45
35.0,50,35,50,17.43,134.15,22.96,158.83,30.66,172.99,60.92,222.52,0.0,75
350.0,500,50,50,39.85,84.14,42.31,88.31,43.9,94.79,49.75,104.36,0.0,75
595.0,850,40,50,42.48,78.33,44.49,80.76,46.09,83.0,50.43,87.65,0.0,75
350.0,500,35,50,79.99,28.65,80.0,36.22,80.0,42.62,80.0,54.97,3.0,30
140.0,200,25,50,33.45,96.92,39.18,105.61,46.1,113.42,52.37,130.53,0.0,75
455.0,650,45,50,41.11,81.66,43.32,86.43,45.93,89.29,51.33,93.33,0.0,75
700.0,1000,45,50,43.78,75.88,45.29,78.92,46.55,82.27,48.01,88.3,0.0,75
350.0,500,45,50,40.73,84.04,43.03,88.1,45.34,93.52,47.93,96.56,0.0,75
420.0,600,30,50,41.55,81.4,43.83,85.76,45.95,89.58,50.45,92.13,0.0,75
630.0,900,45,50,42.98,77.52,44.55,80.37,46.55,82.76,49.17,87.59,0.0,75
70.0,100,35,50,51.07,103.17,63.86,114.11,79.97,124.0,80.0,139.19,3.0,45
560.0,800,45,50,43.37,77.52,45.43,81.43,47.47,84.58,50.16,86.9,0.0,75
700.0,1000,35,50,44.44,74.98,45.83,78.42,47.02,80.75,49.43,84.21,0.0,75
525.0,750,25,50,43.3,76.88,45.23,79.81,47.11,83.52,49.97,88.31,0.0,75
280.0,400,40,50,39.39,86.46,42.46,92.83,46.74,96.74,51.34,103.25,0.0,75
245.0,350,50,50,79.99,33.5,80.0,43.8,80.0,51.57,80.0,60.28,2.0,30
280.0,400,20,50,40.5,82.79,44.63,89.28,47.76,94.24,52.38,101.02,0.0,75
35.0,50,25,50,65.95,99.46,79.98,111.9,79.99,124.21,80.0,142.56,12.0,30
630.0,900,25,50,44.21,74.41,45.66,77.56,47.43,80.07,49.45,84.36,0.0,75
385.0,550,30,50,41.29,81.45,44.16,85.37,46.52,89.47,50.0,96.55,0.0,75
315.0,450,20,50,41.43,80.85,45.22,86.91,47.14,90.86,53.43,99.55,0.0,75
665.0,950,40,50,43.95,76.01,45.23,78.87,47.34,81.86,49.93,85.69,0.0,75
420.0,600,40,50,41.86,81.91,43.87,86.38,45.59,88.85,50.8,93.15,0.0,75
350.0,500,40,50,40.64,83.58,43.15,89.85,46.28,93.67,51.69,98.74,0.0,75
280.0,400,45,50,38.57,87.33,42.41,92.57,46.01,99.48,51.17,105.04,0.0,75
280.0,400,35,50,39.8,86.8,43.1,94.29,45.38,100.25,50.79,107.62,0.0,75
175.0,250,35,50,35.07,95.57,40.7,105.05,46.75,111.21,58.51,122.23,0.0,75
700.0,1000,20,50,37.36,74.95,38.31,78.27,39.46,82.19,43.67,85.51,0.0,90
315.0,450,30,50,40.09,83.71,43.23,90.18,45.71,95.04,51.1,100.11,0.0,75
350.0,500,25,50,41.14,79.84,44.25,86.61,47.71,91.82,52.0,103.22,0.0,75
490.0,700,40,50,79.99,26.27,80.0,30.84,80.0,35.29,80.0,41.21,0.0,30
210.0,300,50,50,36.21,94.1,40.17,100.6,42.96,105.9,48.95,109.81,0.0,75
595.0,850,20,50,43.64,74.32,45.31,79.25,46.35,81.99,50.77,84.99,0.0,75
490.0,700,35,50,71.76,72.28,74.52,77.04,77.93,79.6,80.0,84.28,0.0,45
210.0,300,30,50,48.84,84.29,53.19,89.21,57.54,95.67,71.03,103.88,0.0,60
665.0,950,30,50,43.77,75.41,45.26,78.44,47.17,81.05,49.83,83.9,0.0,75
595.0,850,30,50,35.76,80.19,37.16,82.96,38.36,84.71,41.7,89.4,0.0,90
560.0,800,25,50,35.76,80.38,37.48,84.04,39.02,86.62,42.88,93.26,0.0,90
140.0,200,50,50,24.96,108.43,28.84,119.64,35.45,128.97,57.55,144.04,0.0,90
595.0,850,50,50,35.42,81.88,36.68,85.18,38.36,88.02,42.58,93.64,0.0,90
665.0,950,45,50,35.69,79.77,37.58,83.35,38.29,87.09,40.25,89.34,0.0,90
560.0,800,35,50,34.95,80.89,36.5,84.87,38.68,88.38,40.6,94.57,0.0,90
525.0,750,50,50,34.87,82.67,37.2,86.32,38.39,88.79,40.48,90.92,0.0,90
245.0,350,20,50,32.66,89.92,35.59,98.77,39.19,102.54,44.15,110.5,0.0,90
210.0,300,20,50,30.39,94.44,33.6,100.82,37.93,108.69,44.25,120.82,0.0,90
525.0,750,40,50,35.41,81.19,37.11,85.47,39.02,88.93,40.96,91.92,0.0,90
560.0,800,40,50,35.16,79.71,36.79,83.94,38.46,86.5,41.64,92.36,0.0,90
560.0,800,50,50,54.19,74.77,56.01,78.14,57.21,81.42,60.81,84.2,0.0,60
630.0,900,30,50,35.95,77.63,37.26,80.73,38.69,83.8,41.17,87.7,0.0,90
420.0,600,20,50,53.78,75.54,56.43,78.65,58.91,80.79,64.11,89.28,0.0,60
210.0,300,35,50,30.31,93.9,34.02,103.35,36.3,109.24,43.2,117.92,0.0,90
140.0,200,40,50,61.29,84.87,72.9,93.28,79.96,100.77,80.0,110.08,1.0,45
490.0,700,45,50,72.31,72.53,75.11,75.62,77.52,77.36,79.95,81.29,0.0,45
105.0,150,50,50,24.17,116.26,27.71,128.03,33.44,140.9,44.88,153.61,0.0,90
175.0,250,20,50,29.75,97.55,33.35,105.71,38.06,116.58,42.74,124.48,0.0,90
245.0,350,35,50,31.28,93.51,33.48,100.63,36.23,104.14,42.84,118.2,0.0,90
385.0,550,40,50,40.9,81.82,43.41,85.57,45.33,90.75,50.14,96.44,0.0,75
210.0,300,40,50,29.22,98.59,32.46,104.77,37.46,110.43,45.38,119.48,0.0,90
490.0,700,50,50,34.46,85.01,36.47,88.53,38.36,91.03,43.2,95.78,0.0,90
665.0,950,35,50,36.28,78.48,37.44,82.41,38.64,84.52,41.21,89.27,0.0,90
140.0,200,30,50,27.0,104.66,31.0,114.68,37.14,123.47,44.04,139.95,0.0,90
245.0,350,30,50,67.3,76.46,74.26,81.66,79.98,86.26,80.0,93.65,0.0,45
105.0,150,30,50,56.71,92.7,68.47,102.54,77.84,106.38,79.99,125.05,1.0,45
140.0,200,35,50,27.69,104.41,32.51,114.54,36.07,124.32,48.36,135.74,0.0,90
420.0,600,25,50,34.84,82.76,36.54,87.07,38.52,92.19,43.06,99.56,0.0,90
280.0,400,50,50,31.66,92.1,33.9,98.18,37.87,102.65,41.06,108.53,0.0,90
455.0,650,50,50,34.34,84.34,35.77,87.95,37.59,91.67,40.5,96.79,0.0,90
175.0,250,30,50,29.32,99.83,32.43,108.9,36.25,117.73,42.45,134.28,0.0,90
665.0,950,20,50,37.02,76.17,38.5,79.69,40.17,83.02,42.49,87.19,0.0,90
630.0,900,50,50,35.49,80.69,36.68,84.48,38.1,87.07,39.3,89.59,0.0,90
700.0,1000,30,50,36.11,78.37,37.29,81.33,38.66,83.38,41.57,85.79,0.0,90
140.0,200,45,50,25.91,108.81,28.96,118.37,32.31,124.81,40.19,136.28,0.0,90
245.0,350,25,50,32.48,91.09,34.97,99.56,38.82,105.84,45.03,112.76,0.0,90
595.0,850,45,50,35.99,80.87,37.16,84.22,37.96,85.82,39.98,91.43,0.0,90
315.0,450,50,50,32.41,89.69,34.91,96.05,37.06,100.98,41.34,105.73,0.0,90
560.0,800,30,50,35.26,80.86,37.03,84.52,38.88,87.08,41.15,92.7,0.0,90
175.0,250,45,50,27.75,101.57,31.57,111.44,35.53,116.99,39.31,132.82,0.0,90
210.0,300,25,50,30.92,95.39,34.42,102.28,36.91,111.51,46.57,121.58,0.0,90
315.0,450,45,50,31.48,89.62,33.66,94.54,36.24,99.26,39.07,105.37,0.0,90
420.0,600,35,50,34.67,83.04,37.15,88.54,38.58,92.79,41.54,98.28,0.0,90
525.0,750,35,50,35.12,81.18,36.72,85.49,38.58,88.23,40.47,94.77,0.0,90
70.0,100,45,50,18.56,131.04,22.45,145.38,27.2,158.82,40.04,178.61,0.0,90
245.0,350,40,50,30.84,95.43,34.13,100.64,37.58,106.77,42.22,120.0,0.0,90
210.0,300,45,50,28.27,96.61,32.1,104.62,35.89,109.04,44.47,123.23,0.0,90
350.0,500,30,50,33.48,85.81,35.46,90.27,38.07,94.19,44.15,100.45,0.0,90
70.0,100,30,50,20.5,125.86,24.85,135.77,34.22,146.56,69.95,201.71,0.0,90
595.0,850,35,50,35.59,78.74,36.91,82.64,38.63,86.09,41.47,89.53,0.0,90
455.0,650,25,50,35.13,81.6,36.89,87.03,37.9,89.36,40.75,92.9,0.0,90
385.0,550,50,50,33.36,87.52,35.33,92.79,37.99,96.12,40.58,103.18,0.0,90
455.0,650,30,50,35.18,81.77,37.62,86.95,38.74,90.39,42.06,96.35,0.0,90
315.0,450,25,50,33.45,85.45,35.81,92.32,39.45,99.19,45.85,104.6,0.0,90
350.0,500,20,50,34.23,85.31,36.37,89.86,38.64,94.62,43.13,98.11,0.0,90
385.0,550,20,50,35.49,81.91,37.15,87.18,38.87,91.6,42.51,100.87,0.0,90
105.0,150,25,50,24.92,110.19,31.59,120.78,38.14,131.15,72.64,152.73,0.0,90
665.0,950,50,50,35.94,79.45,37.42,82.3,38.31,84.89,39.95,89.79,0.0,90
105.0,150,40,50,23.51,114.97,29.9,126.77,33.5,135.81,55.83,147.69,0.0,90
175.0,250,25,50,29.65,97.88,33.2,107.12,35.75,114.56,43.38,120.54,0.0,90
35.0,50,50,50,12.76,141.64,17.17,163.58,26.58,182.14,79.98,251.09,1.0,90
560.0,800,20,50,36.39,79.9,37.75,82.53,39.87,85.57,42.65,90.57,0.0,90
175.0,250,40,50,28.63,100.93,32.12,109.48,38.16,118.52,49.17,134.81,0.0,90
595.0,850,25,50,35.89,79.66,37.85,83.51,39.29,86.25,42.11,89.07,0.0,90
35.0,50,40,50,13.14,143.95,18.28,166.71,25.97,197.41,46.05,244.1,0.0,90
70.0,100,50,50,18.8,127.5,23.79,142.81,30.91,161.38,46.11,191.63,0.0,90
630.0,900,35,50,36.34,78.86,38.01,82.28,39.4,85.29,40.89,89.55,0.0,90
35.0,50,20,50,14.05,137.06,19.24,164.8,29.84,187.95,66.18,226.11,1.0,90
525.0,750,45,50,42.61,78.21,44.12,81.52,45.97,85.02,48.18,89.91,0.0,75
630.0,900,20,50,36.19,78.68,37.74,80.86,38.92,84.05,40.86,88.28,0.0,90
525.0,750,20,50,35.91,78.59,37.98,83.36,39.65,86.44,43.25,91.28,0.0,90
385.0,550,35,50,34.05,85.35,35.76,89.87,38.15,94.31,42.77,96.85,0.0,90
420.0,600,45,50,34.43,85.99,36.11,89.87,38.36,94.08,42.27,99.53,0.0,90
70.0,100,40,50,19.64,129.69,23.62,141.13,28.18,158.1,39.18,180.96,0.0,90
70.0,100,25,50,20.74,125.48,25.08,143.3,30.37,159.04,49.57,181.07,0.0,90
525.0,750,30,50,35.44,82.0,36.54,85.72,37.83,88.28,40.12,90.87,0.0,90
420.0,600,50,50,33.75,85.5,35.79,89.06,37.7,95.44,38.67,99.43,0.0,90
280.0,400,25,50,33.37,90.18,36.16,97.29,38.25,100.98,43.75,106.49,0.0,90
105.0,150,35,50,55.82,94.94,62.9,103.95,68.7,111.45,80.0,124.6,0.0,45
700.0,1000,40,50,55.42,73.32,56.79,75.3,58.38,77.51,62.43,79.05,0.0,60
35.0,50,30,50,13.15,135.02,18.77,162.24,28.32,205.56,45.25,254.9,0.0,90
455.0,650,20,50,35.52,81.55,37.13,85.06,39.43,87.79,42.26,95.01,0.0,90
490.0,700,25,50,35.34,80.32,37.24,84.86,38.89,87.4,41.78,92.82,0.0,90
175.0,250,50,50,28.96,102.41,31.8,109.96,35.07,120.82,47.72,127.07,0.0,90
315.0,450,40,50,33.39,89.37,36.21,94.77,38.14,99.91,43.1,110.37,0.0,90
245.0,350,45,50,29.87,97.12,32.61,102.12,36.08,106.91,44.56,113.06,0.0,90
385.0,550,25,50,33.93,83.95,36.71,88.11,39.23,91.91,43.13,98.82,0.0,90
35.0,50,45,50,11.86,139.34,16.05,161.81,19.51,175.91,39.6,204.95,0.0,90
665.0,950,25,50,36.73,77.59,37.9,81.16,39.37,84.64,41.38,86.18,0.0,90
140.0,200,25,50,27.11,105.93,31.02,115.12,35.29,123.06,42.94,134.56,0.0,90
700.0,1000,25,50,36.58,77.14,38.01,80.61,39.1,82.68,41.96,87.71,0.0,90
70.0,100,20,50,56.13,92.47,68.98,112.29,79.99,121.45,80.0,136.81,5.0,45
700.0,1000,50,50,35.53,80.07,36.95,83.43,38.01,85.65,40.68,88.86,0.0,90
350.0,500,45,50,32.52,89.08,34.45,93.07,37.46,96.83,40.81,105.16,0.0,90
595.0,850,40,50,35.82,80.75,37.57,83.18,38.75,86.03,40.89,92.4,0.0,90
385.0,550,45,50,70.81,73.58,73.64,76.62,78.08,80.47,80.0,84.69,0.0,45
700.0,1000,35,50,36.35,78.47,37.83,82.74,39.26,84.91,40.91,87.76,0.0,90
35.0,50,35,50,13.13,140.8,17.51,162.89,27.96,191.19,79.97,233.8,2.0,90
455.0,650,35,50,34.83,83.12,36.97,88.2,38.54,90.54,40.5,93.83,0.0,90
350.0,500,50,50,31.8,88.56,34.16,93.93,37.07,97.85,42.67,103.24,0.0,90
280.0,400,40,50,31.48,93.19,34.1,98.5,35.78,104.1,38.74,115.87,0.0,90
455.0,650,45,50,34.66,84.65,36.52,88.98,37.92,92.03,44.37,95.22,0.0,90
490.0,700,20,50,35.85,79.26,38.05,82.39,39.38,86.12,43.61,90.26,0.0,90
630.0,900,45,50,35.44,80.25,37.21,83.24,37.92,85.23,39.76,88.53,0.0,90
140.0,200,20,50,62.13,83.99,70.4,92.42,79.98,98.62,80.0,109.37,0.0,45
700.0,1000,45,50,36.2,77.56,37.28,81.28,39.1,84.02,40.3,88.8,0.0,90
455.0,650,40,50,72.28,73.16,74.88,75.71,77.65,79.75,79.99,83.27,0.0,45
490.0,700,30,50,73.07,71.22,76.01,74.77,78.2,76.47,80.0,79.64,0.0,45
105.0,150,45,50,29.38,108.9,34.99,121.34,44.01,131.76,61.08,147.53,0.0,75
525.0,750,25,50,35.5,80.17,37.51,83.87,39.4,86.65,41.84,92.3,0.0,90
315.0,450,20,50,33.53,87.03,35.36,91.35,37.3,97.1,43.57,104.58,0.0,90
665.0,950,40,50,36.16,79.1,37.9,81.75,38.74,84.34,40.41,89.28,0.0,90
630.0,900,25,50,36.66,77.78,38.13,81.3,39.68,84.54,42.49,91.2,0.0,90
560.0,800,45,50,34.91,81.65,36.68,85.23,38.14,88.97,42.32,91.64,0.0,90
280.0,400,20,50,34.48,87.11,36.82,93.03,39.55,97.99,44.4,110.43,0.0,90
105.0,150,20,50,63.23,87.24,68.54,98.07,77.4,105.72,80.0,111.62,0.0,45
630.0,900,40,50,53.33,75.07,55.62,77.88,57.05,80.09,60.53,82.2,0.0,60
315.0,450,35,50,70.1,75.43,74.69,79.91,79.97,82.81,80.0,88.0,0.0,45
700.0,1000,20,50,28.37,82.02,29.8,84.18,31.13,86.61,33.39,89.87,0.0,115
385.0,550,30,50,33.38,84.44,35.79,90.05,37.19,95.4,39.49,98.76,0.0,90
420.0,600,40,50,34.26,84.6,36.19,88.67,38.4,91.65,42.33,95.76,0.0,90
350.0,500,40,50,32.32,87.4,35.52,91.67,38.74,97.0,40.9,102.72,0.0,90
280.0,400,30,50,40.12,85.31,43.18,92.12,47.13,95.64,54.76,101.5,0.0,75
175.0,250,35,50,28.71,99.93,32.09,107.3,35.33,115.98,45.51,124.86,0.0,90
315.0,450,30,50,32.38,88.44,34.67,94.17,37.59,98.05,43.14,104.39,0.0,90
280.0,400,45,50,32.24,91.52,34.1,97.59,35.77,102.98,40.36,112.36,0.0,90
35.0,50,25,50,38.96,108.46,58.47,134.37,77.11,154.34,80.0,189.52,4.0,45
280.0,400,35,50,32.07,90.77,34.4,95.43,35.99,100.58,41.33,110.68,0.0,90
350.0,500,35,50,69.24,75.58,73.17,78.74,76.05,81.39,80.0,87.79,0.0,45
420.0,600,30,50,33.95,84.84,36.06,88.9,38.51,92.89,44.44,99.87,0.0,90
210.0,300,50,50,29.17,99.77,32.91,104.68,36.1,111.75,42.33,122.71,0.0,90
70.0,100,35,50,35.22,114.68,44.73,126.06,52.53,144.84,71.43,175.03,0.0,60
350.0,500,25,50,33.99,85.46,36.1,90.66,38.44,94.22,42.12,100.69,0.0,90
595.0,850,20,50,36.57,77.94,38.5,80.24,39.8,83.36,41.58,87.49,0.0,90
245.0,350,50,50,66.85,79.03,72.22,84.55,78.33,88.3,79.99,92.84,0.0,45
665.0,950,30,50,36.14,77.76,37.82,81.97,39.37,83.81,42.02,90.11,0.0,90
595.0,850,30,50,27.47,84.2,28.69,87.08,29.92,91.85,32.14,95.01,0.0,115
665.0,950,45,50,27.65,84.34,28.98,87.21,30.36,90.48,32.36,92.44,0.0,115
140.0,200,50,50,17.59,114.79,21.19,124.08,24.74,133.64,33.79,156.43,0.0,115
490.0,700,40,50,71.49,72.78,74.27,75.16,75.94,76.83,79.99,80.93,0.0,45
525.0,750,50,50,26.81,87.61,28.59,90.76,30.15,93.35,32.43,100.73,0.0,115
595.0,850,50,50,27.36,86.43,28.57,90.29,30.14,93.88,31.4,101.42,0.0,115
560.0,800,35,50,27.18,85.01,28.95,90.12,30.25,93.79,31.87,97.67,0.0,115
210.0,300,20,50,22.99,101.43,25.84,107.84,28.37,113.3,36.31,124.55,0.0,115
245.0,350,20,50,23.9,96.53,26.83,104.24,29.68,109.75,38.78,116.13,0.0,115
560.0,800,25,50,27.57,85.29,29.31,89.49,30.72,93.49,33.31,101.5,0.0,115
490.0,700,35,50,53.77,75.27,56.37,79.01,58.43,84.41,63.11,88.19,0.0,60
525.0,750,40,50,26.92,87.32,28.62,92.79,30.27,96.84,33.43,102.32,0.0,115
560.0,800,40,50,27.61,86.72,28.47,90.14,29.92,92.37,31.15,97.03,0.0,115
630.0,900,30,50,28.35,82.66,29.4,85.64,30.33,87.93,31.99,93.58,0.0,115
420.0,600,20,50,42.12,79.66,45.3,82.88,47.61,87.2,51.82,92.21,0.0,75
210.0,300,30,50,37.23,94.56,40.41,101.44,43.77,108.11,48.82,118.54,0.0,75
210.0,300,35,50,22.06,104.71,25.16,112.01,29.42,119.26,40.29,132.06,0.0,115
245.0,350,35,50,22.74,101.94,25.32,107.7,27.63,113.59,32.76,125.24,0.0,115
175.0,250,20,50,21.77,100.74,25.49,113.18,28.54,121.6,31.61,137.24,0.0,115
105.0,150,50,50,16.47,127.73,18.79,139.26,22.49,147.9,36.96,167.81,0.0,115
385.0,550,40,50,33.7,86.59,35.24,90.82,36.84,94.74,39.85,105.35,0.0,90
210.0,300,40,50,21.4,107.16,23.62,114.32,26.88,120.54,33.28,132.94,0.0,115
490.0,700,45,50,53.65,75.78,55.98,78.13,58.49,81.65,62.14,86.35,0.0,60
420.0,600,25,50,26.7,86.85,28.88,93.22,30.53,98.7,32.67,103.24,0.0,115
140.0,200,30,50,19.73,111.21,22.39,123.76,24.81,142.06,34.41,153.54,0.0,115
665.0,950,35,50,28.32,82.79,29.32,85.84,30.71,88.69,32.22,90.89,0.0,115
665.0,950,20,50,28.76,80.85,30.41,84.75,31.74,86.81,34.06,90.83,0.0,115
140.0,200,35,50,18.89,115.4,22.76,125.54,27.57,135.13,38.17,148.88,0.0,115
140.0,200,40,50,43.1,94.79,50.13,102.75,57.26,108.48,67.61,124.03,0.0,60
490.0,700,50,50,26.1,88.43,27.43,92.21,28.74,96.36,31.98,103.21,0.0,115
280.0,400,50,50,23.62,99.15,25.81,106.2,27.58,112.4,33.8,121.03,0.0,115
245.0,350,25,50,23.17,99.37,25.66,106.43,27.84,112.88,31.4,118.82,0.0,115
630.0,900,50,50,27.31,85.59,28.68,89.71,29.72,91.93,30.83,97.99,0.0,115
140.0,200,45,50,18.5,117.69,21.62,127.89,26.37,135.53,34.03,152.81,0.0,115
175.0,250,30,50,21.26,109.16,23.11,117.66,25.93,123.31,33.21,129.58,0.0,115
560.0,800,30,50,27.65,85.07,28.88,89.37,30.35,92.36,32.72,95.18,0.0,115
700.0,1000,30,50,28.38,82.18,29.36,86.83,30.42,89.01,31.79,91.98,0.0,115
210.0,300,25,50,22.85,98.71,26.25,109.15,29.64,114.71,34.65,131.65,0.0,115
525.0,750,35,50,27.03,86.57,28.79,90.67,30.4,94.37,32.39,100.8,0.0,115
455.0,650,50,50,26.17,90.16,27.5,94.87,29.26,99.23,32.37,102.07,0.0,115
560.0,800,50,50,42.59,78.63,44.45,82.08,46.81,85.27,49.02,90.27,0.0,75
315.0,450,50,50,24.2,97.51,26.35,103.72,29.27,109.8,30.72,116.92,0.0,115
70.0,100,45,50,12.78,136.01,15.52,153.63,19.41,168.38,63.03,207.45,0.0,115
595.0,850,45,50,27.72,84.36,29.19,88.28,30.43,89.96,32.98,97.47,0.0,115
420.0,600,35,50,25.81,88.97,27.58,94.49,29.45,96.74,31.35,105.33,0.0,115
210.0,300,45,50,21.86,105.56,24.28,114.84,28.29,119.5,32.06,128.82,0.0,115
455.0,650,25,50,27.1,86.21,29.23,90.37,31.0,93.25,34.82,99.71,0.0,115
315.0,450,45,50,24.1,95.94,26.22,101.94,28.47,106.8,35.22,110.86,0.0,115
245.0,350,30,50,49.89,82.54,54.84,88.48,59.85,92.77,68.3,101.14,0.0,60
245.0,350,40,50,22.96,103.25,24.74,108.89,27.22,115.44,37.75,120.91,0.0,115
70.0,100,30,50,14.24,126.82,18.16,141.53,24.21,153.72,33.07,209.54,0.0,115
175.0,250,45,50,20.33,109.9,22.76,118.15,26.0,125.57,30.49,141.19,0.0,115
350.0,500,20,50,25.76,89.17,28.75,94.36,30.66,98.99,35.35,108.17,0.0,115
105.0,150,25,50,18.1,120.84,21.6,131.15,27.28,142.53,41.05,169.47,0.0,115
105.0,150,30,50,43.51,100.41,49.72,110.48,65.17,122.27,79.97,149.91,0.0,60
385.0,550,50,50,25.33,93.0,27.03,98.15,29.03,101.62,32.75,110.75,0.0,115
455.0,650,30,50,25.99,87.91,27.94,92.47,29.7,97.36,33.72,105.1,0.0,115
665.0,950,50,50,27.91,84.56,28.85,87.91,29.77,89.66,30.73,93.87,0.0,115
315.0,450,25,50,25.38,91.96,27.71,100.25,29.6,106.71,36.25,114.36,0.0,115
105.0,150,40,50,17.2,124.15,20.24,132.59,27.17,147.52,33.1,162.31,0.0,115
595.0,850,35,50,27.73,84.54,29.15,88.03,30.66,90.84,32.45,97.68,0.0,115
35.0,50,40,50,8.95,152.34,11.64,175.32,15.59,193.97,27.64,289.57,0.0,115
35.0,50,50,50,8.52,158.66,11.54,182.26,15.89,213.38,27.49,289.63,0.0,115
560.0,800,20,50,27.87,82.97,29.07,87.14,30.5,90.16,32.86,94.49,0.0,115
350.0,500,30,50,25.55,92.54,27.03,98.06,29.05,102.6,32.62,112.81,0.0,115
175.0,250,25,50,21.43,108.8,24.75,114.93,28.96,126.37,39.82,136.98,0.0,115
630.0,900,35,50,27.97,81.9,29.29,86.4,30.19,89.34,32.31,92.73,0.0,115
175.0,250,40,50,19.85,111.57,23.24,118.07,25.2,129.46,29.12,141.83,0.0,115
70.0,100,40,50,12.66,140.13,16.74,154.18,21.53,166.41,52.3,218.52,0.0,115
420.0,600,45,50,26.07,91.27,27.84,95.72,28.67,100.5,30.89,106.33,0.0,115
630.0,900,20,50,28.39,81.63,29.79,84.46,30.62,88.31,32.68,94.76,0.0,115
525.0,750,20,50,27.59,84.35,29.11,88.06,30.72,92.74,32.79,96.88,0.0,115
35.0,50,20,50,10.13,151.58,15.12,178.91,23.77,215.71,42.69,264.76,1.0,115
70.0,100,50,50,13.05,141.08,15.78,158.58,20.88,175.21,41.9,203.99,0.0,115
385.0,550,20,50,26.56,88.4,28.47,91.37,30.78,98.01,34.71,105.41,0.0,115
70.0,100,25,50,13.89,127.08,18.8,148.77,28.98,162.32,49.31,205.2,0.0,115
595.0,850,25,50,28.57,81.61,29.99,85.52,32.29,90.52,35.15,94.22,0.0,115
385.0,550,35,50,24.84,90.75,27.27,95.11,28.88,98.97,31.24,103.06,0.0,115
280.0,400,25,50,24.57,93.39,26.68,100.8,29.09,110.24,31.46,121.07,0.0,115
420.0,600,50,50,25.56,91.39,27.01,95.76,28.87,101.66,30.41,106.48,0.0,115
525.0,750,30,50,27.45,85.04,29.01,89.17,30.59,93.9,32.17,96.79,0.0,115
455.0,650,20,50,26.93,85.93,28.36,90.41,30.11,95.31,33.45,102.12,0.0,115
385.0,550,25,50,26.69,88.87,28.85,93.83,30.23,99.71,34.17,106.73,0.0,115
245.0,350,45,50,22.95,102.17,24.68,109.84,27.01,114.08,29.99,125.07,0.0,115
175.0,250,50,50,20.01,112.78,22.0,121.3,24.53,128.75,33.48,137.16,0.0,115
35.0,50,30,50,9.27,149.54,13.13,172.56,18.18,224.31,57.15,349.82,1.0,115
140.0,200,25,50,20.21,110.44,24.4,121.78,29.64,132.26,44.27,149.22,0.0,115
490.0,700,25,50,26.73,86.07,28.5,89.96,30.23,94.18,34.59,97.93,0.0,115
700.0,1000,25,50,28.25,80.83,29.58,83.74,30.9,85.91,33.2,91.44,0.0,115
350.0,500,45,50,25.12,92.44,26.78,98.83,28.31,102.72,31.49,111.14,0.0,115
315.0,450,40,50,24.29,95.29,26.14,101.98,29.27,105.89,33.64,111.48,0.0,115
665.0,950,25,50,28.45,81.73,29.5,84.69,30.43,88.45,32.89,91.17,0.0,115
35.0,50,45,50,8.1,151.75,10.57,173.07,14.61,195.09,47.93,278.89,0.0,115
700.0,1000,50,50,27.71,84.27,29.09,87.25,30.09,90.32,31.24,94.15,0.0,115
35.0,50,35,50,8.67,154.5,10.78,173.32,14.49,192.53,30.67,355.33,0.0,115
700.0,1000,35,50,28.04,82.69,28.91,86.28,29.96,89.07,31.91,91.92,0.0,115
665.0,950,40,50,27.86,83.64,29.32,86.59,30.25,89.93,32.36,94.97,0.0,115
455.0,650,35,50,25.96,89.66,27.72,93.51,29.4,97.67,32.91,101.89,0.0,115
595.0,850,40,50,27.76,83.97,29.31,88.05,30.24,90.21,31.63,94.5,0.0,115
525.0,750,45,50,34.86,83.25,36.2,86.28,37.8,90.33,40.18,97.89,0.0,90
490.0,700,20,50,27.2,85.42,28.78,90.25,30.14,94.4,32.37,101.12,0.0,115
350.0,500,50,50,24.88,93.77,26.93,99.4,28.75,105.03,31.35,115.81,0.0,115
105.0,150,35,50,39.77,101.96,47.37,111.64,54.66,119.17,79.96,129.52,0.0,60
630.0,900,45,50,27.83,84.22,28.62,88.43,29.69,90.55,30.93,95.12,0.0,115
560.0,800,45,50,27.02,86.19,28.23,91.06,29.32,94.01,30.53,96.55,0.0,115
455.0,650,45,50,26.01,90.19,28.2,94.1,29.92,97.07,33.52,102.38,0.0,115
280.0,400,40,50,23.23,99.77,25.66,105.86,28.7,110.62,35.72,127.86,0.0,115
630.0,900,25,50,28.22,82.77,29.15,85.84,30.33,89.47,32.16,92.75,0.0,115
700.0,1000,45,50,28.06,82.73,29.2,85.44,30.11,87.94,31.64,91.05,0.0,115
700.0,1000,20,50,21.84,85.62,22.92,89.1,23.69,92.91,25.79,99.3,0.0,150
700.0,1000,40,50,43.67,76.6,44.89,78.44,46.3,80.91,49.54,84.29,0.0,75
315.0,450,20,50,25.37,92.41,27.48,100.21,29.22,104.17,33.75,112.02,0.0,115
525.0,750,25,50,27.64,84.09,29.21,88.0,30.36,92.89,33.15,96.92,0.0,115
70.0,100,20,50,38.03,106.5,47.31,117.13,57.87,131.44,79.99,174.46,1.0,60
280.0,400,20,50,24.48,93.66,26.59,101.23,29.4,105.71,37.19,113.07,0.0,115
420.0,600,40,50,25.51,90.43,27.22,96.07,29.13,100.04,31.79,105.96,0.0,115
385.0,550,30,50,26.56,90.08,28.13,94.82,30.25,98.6,32.68,107.58,0.0,115
385.0,550,45,50,52.42,78.94,55.04,83.26,56.72,85.83,63.5,92.44,0.0,60
280.0,400,45,50,23.62,97.92,25.36,103.97,27.38,110.93,31.89,121.89,0.0,115
350.0,500,40,50,24.89,93.67,26.68,97.91,29.04,102.88,31.59,108.95,0.0,115
175.0,250,35,50,20.69,110.48,23.72,119.31,26.31,128.1,32.56,138.33,0.0,115
105.0,150,20,50,41.72,100.94,46.68,106.62,57.51,118.73,79.99,143.62,0.0,60
455.0,650,40,50,53.38,76.84,56.2,81.1,57.9,83.6,60.34,87.58,0.0,60
105.0,150,45,50,22.56,117.83,26.99,127.66,28.92,135.65,36.31,153.9,0.0,90
315.0,450,30,50,25.23,92.09,26.87,97.74,28.45,102.9,34.37,109.9,0.0,115
490.0,700,30,50,53.69,75.36,56.44,78.79,58.06,80.6,62.05,84.07,0.0,60
140.0,200,20,50,43.67,91.55,50.49,97.78,55.07,104.6,68.22,124.76,0.0,60
280.0,400,35,50,24.32,96.63,26.2,101.6,28.16,105.55,32.13,112.56,0.0,115
210.0,300,50,50,21.32,106.8,24.32,116.88,27.21,123.97,32.6,134.3,0.0,115
630.0,900,40,50,42.91,76.49,45.18,79.15,46.42,81.54,47.77,84.49,0.0,75
315.0,450,35,50,51.14,80.78,54.41,85.29,58.51,90.07,65.0,96.06,0.0,60
595.0,850,20,50,28.08,82.31,29.54,86.45,30.75,89.38,32.33,97.45,0.0,115
350.0,500,25,50,25.66,92.98,27.01,98.24,29.55,102.43,32.74,111.48,0.0,115
35.0,50,25,50,23.51,125.18,34.46,150.76,56.89,181.84,80.0,268.28,0.0,60
280.0,400,30,50,32.26,90.91,33.99,96.07,36.55,101.07,40.54,111.52,0.0,90
70.0,100,35,50,25.3,117.78,31.08,130.15,42.74,143.42,79.98,166.27,1.0,75
665.0,950,30,50,28.02,82.7,29.44,85.21,30.32,88.44,32.11,92.94,0.0,115
350.0,500,35,50,50.23,79.48,54.0,84.37,57.24,87.77,65.36,93.21,0.0,60
595.0,850,30,50,20.94,88.24,22.02,92.01,23.05,97.05,24.45,104.29,0.0,150
420.0,600,30,50,26.88,88.31,28.58,92.69,30.0,96.56,36.34,103.67,0.0,115
665.0,950,45,50,20.99,89.56,21.94,93.79,23.08,96.11,23.84,98.3,0.0,150
595.0,850,50,50,20.13,92.47,21.35,96.97,22.79,101.7,24.46,105.71,0.0,150
140.0,200,50,50,12.63,126.56,14.93,137.83,16.47,143.22,27.84,165.25,0.0,150
245.0,350,50,50,47.05,85.51,51.03,90.47,54.41,96.46,64.26,109.72,0.0,60
245.0,350,20,50,17.86,104.78,19.39,111.48,21.06,117.96,25.24,131.44,0.0,150
560.0,800,35,50,21.09,92.16,22.06,96.34,23.61,100.07,24.65,108.59,0.0,150
525.0,750,50,50,19.8,95.41,20.89,98.82,21.77,101.47,25.08,110.94,0.0,150
560.0,800,25,50,20.74,88.56,21.89,93.25,23.3,98.25,25.39,106.51,0.0,150
210.0,300,20,50,16.38,108.81,18.71,117.67,22.59,126.01,27.1,140.22,0.0,150
525.0,750,40,50,20.07,93.44,21.39,96.18,22.49,100.49,24.08,103.6,0.0,150
560.0,800,40,50,20.13,91.93,21.32,96.23,22.29,99.28,25.29,105.18,0.0,150
630.0,900,30,50,21.17,88.2,22.53,91.45,23.21,95.31,24.2,105.72,0.0,150
210.0,300,35,50,15.92,115.67,17.71,123.09,19.53,132.27,22.61,141.25,0.0,150
420.0,600,20,50,35.15,81.47,36.91,86.47,40.27,91.47,44.57,96.96,0.0,90
175.0,250,20,50,15.61,117.53,19.01,126.76,22.13,135.24,29.76,153.0,0.0,150
245.0,350,35,50,16.78,108.79,18.49,115.91,20.51,121.05,23.64,129.24,0.0,150
490.0,700,40,50,52.94,76.57,56.46,80.29,58.81,82.19,62.92,85.72,0.0,60
105.0,150,50,50,11.44,136.58,13.34,147.72,15.49,159.46,21.07,186.94,0.0,150
385.0,550,40,50,25.77,92.66,27.26,97.8,29.35,104.01,32.07,111.06,0.0,115
420.0,600,25,50,19.72,95.11,21.23,100.53,23.24,102.22,25.72,108.6,0.0,150
665.0,950,20,50,21.12,86.28,22.33,90.35,22.95,93.55,24.69,100.3,0.0,150
245.0,350,25,50,17.31,104.33,19.86,113.88,22.49,120.41,29.91,132.5,0.0,150
210.0,300,40,50,15.3,112.64,17.39,124.49,19.99,130.24,25.22,145.1,0.0,150
490.0,700,35,50,42.57,79.29,44.82,83.26,46.99,87.42,49.8,91.17,0.0,75
140.0,200,30,50,13.72,126.18,16.11,139.53,18.94,148.07,27.53,163.92,0.0,150
665.0,950,35,50,21.35,88.17,22.02,91.46,22.92,94.18,24.72,97.98,0.0,150
175.0,250,30,50,15.21,114.92,17.43,124.82,19.91,132.31,25.53,142.94,0.0,150
140.0,200,35,50,13.97,124.86,16.98,134.56,19.92,147.51,24.47,159.8,0.0,150
560.0,800,30,50,20.53,89.43,21.82,95.49,23.37,99.83,25.23,107.18,0.0,150
490.0,700,50,50,19.6,95.81,20.87,100.65,22.08,103.05,23.05,112.57,0.0,150
140.0,200,45,50,12.96,128.42,15.41,137.15,18.63,149.69,25.42,165.53,0.0,150
280.0,400,50,50,17.25,110.9,18.95,116.99,21.05,124.12,24.54,131.74,0.0,150
700.0,1000,30,50,21.38,87.5,22.43,90.73,23.49,93.96,26.0,100.94,0.0,150
630.0,900,50,50,20.67,89.92,21.69,92.94,23.08,96.89,25.2,102.1,0.0,150
455.0,650,50,50,19.22,97.08,20.98,101.94,22.5,106.66,24.26,111.94,0.0,150
525.0,750,35,50,20.23,90.93,21.78,96.29,22.58,101.7,24.42,110.12,0.0,150
315.0,450,50,50,17.58,106.1,18.96,111.91,20.43,114.55,24.11,122.82,0.0,150
70.0,100,45,50,8.21,146.15,9.72,160.05,11.85,177.0,19.43,192.06,0.0,150
210.0,300,30,50,30.52,95.95,35.44,104.06,38.71,109.59,45.07,119.68,0.0,90
490.0,700,45,50,42.26,79.43,43.78,83.43,46.39,86.46,48.98,90.52,0.0,75
140.0,200,40,50,33.23,103.52,37.56,114.81,43.29,122.02,53.62,131.61,0.0,75
210.0,300,45,50,15.22,118.39,17.5,124.38,19.48,130.89,24.34,136.8,0.0,150
210.0,300,25,50,16.72,111.19,19.09,118.57,21.38,125.54,24.73,136.36,0.0,150
245.0,350,40,50,16.75,107.1,18.73,115.8,20.4,124.59,25.1,138.24,0.0,150
595.0,850,45,50,20.47,92.26,21.73,95.36,22.68,99.28,24.73,103.4,0.0,150
455.0,650,25,50,20.19,94.3,21.44,99.45,23.91,103.9,26.11,114.18,0.0,150
315.0,450,45,50,17.53,105.44,18.99,110.78,20.46,116.25,23.09,121.64,0.0,150
70.0,100,30,50,9.33,143.79,11.61,160.09,14.41,183.77,24.36,220.08,0.0,150
420.0,600,35,50,19.31,96.32,20.63,101.84,21.89,106.7,23.52,113.99,0.0,150
560.0,800,20,50,21.32,88.7,22.54,93.72,24.36,96.97,25.96,102.12,0.0,150
350.0,500,30,50,18.81,97.97,20.35,103.41,22.01,112.22,27.44,121.06,0.0,150
175.0,250,45,50,14.48,118.75,16.27,128.03,17.77,133.04,22.45,151.18,0.0,150
385.0,550,35,50,19.21,98.59,20.52,102.55,21.71,109.73,23.83,117.5,0.0,150
665.0,950,50,50,20.47,89.98,21.61,93.96,22.57,98.05,24.56,101.53,0.0,150
350.0,500,20,50,18.68,97.03,20.27,102.59,21.72,109.48,25.31,117.14,0.0,150
315.0,450,25,50,18.43,102.42,20.28,109.88,21.53,113.41,24.73,126.43,0.0,150
630.0,900,35,50,20.89,88.63,21.89,92.59,22.82,96.17,24.86,102.3,0.0,150
70.0,100,40,50,9.34,146.16,11.51,161.01,13.5,187.33,36.65,216.45,0.0,150
105.0,150,30,50,30.34,108.63,37.53,119.42,43.56,130.45,52.8,147.11,0.0,75
175.0,250,25,50,15.25,117.65,17.39,126.34,19.15,132.49,26.53,142.02,0.0,150
420.0,600,45,50,19.84,98.14,21.12,101.03,22.09,105.65,25.88,110.71,0.0,150
595.0,850,35,50,21.08,90.17,21.98,93.48,23.18,96.72,24.57,101.09,0.0,150
630.0,900,20,50,21.34,87.94,22.43,90.76,23.66,92.97,26.34,98.3,0.0,150
35.0,50,50,50,5.39,166.41,6.85,188.85,8.7,214.92,17.92,256.91,0.0,150
385.0,550,50,50,18.4,102.86,19.64,108.75,20.77,114.82,22.48,125.15,0.0,150
105.0,150,25,50,12.02,131.53,14.32,144.21,18.4,155.58,28.37,165.77,0.0,150
455.0,650,30,50,20.07,92.9,21.59,98.32,23.1,102.58,25.6,111.25,0.0,150
105.0,150,40,50,11.83,137.81,13.92,150.61,16.25,164.75,23.03,183.55,0.0,150
175.0,250,40,50,13.69,116.6,16.02,127.43,18.14,137.99,22.08,161.55,0.0,150
525.0,750,20,50,21.28,88.44,22.31,93.94,23.16,97.68,24.55,105.46,0.0,150
70.0,100,50,50,8.59,150.17,9.64,167.21,12.04,182.08,17.1,203.02,0.0,150
35.0,50,20,50,6.42,148.84,8.42,173.68,11.92,209.65,51.84,352.53,0.0,150
385.0,550,20,50,19.68,95.57,21.47,103.95,23.32,107.17,27.29,113.32,0.0,150
35.0,50,40,50,5.69,165.66,6.9,182.78,8.77,205.76,15.27,263.79,0.0,150
70.0,100,25,50,9.73,146.56,11.92,162.04,15.05,173.51,28.64,220.7,0.0,150
595.0,850,25,50,21.1,88.74,21.95,92.6,23.38,96.2,26.65,99.11,0.0,150
420.0,600,50,50,18.81,100.35,20.11,105.12,20.9,111.8,23.03,117.6,0.0,150
560.0,800,50,50,35.16,80.7,36.93,84.79,38.34,87.08,40.01,92.19,0.0,90
455.0,650,20,50,20.22,91.19,22.06,96.19,23.68,103.38,25.53,110.34,0.0,150
245.0,350,45,50,16.68,111.53,18.25,118.27,19.72,123.09,23.42,135.02,0.0,150
245.0,350,30,50,39.49,88.22,43.08,95.85,46.51,101.51,50.71,106.35,0.0,75
280.0,400,25,50,17.39,106.04,19.58,112.11,21.93,118.53,25.52,127.02,0.0,150
385.0,550,25,50,19.45,97.63,20.74,102.33,22.22,108.29,23.42,112.42,0.0,150
525.0,750,30,50,20.52,90.68,22.05,95.86,23.29,100.02,25.28,104.33,0.0,150
175.0,250,50,50,14.56,123.04,16.28,131.53,18.82,140.9,21.4,153.19,0.0,150
700.0,1000,25,50,21.28,85.97,22.61,88.69,24.12,92.99,25.01,96.89,0.0,150
665.0,950,25,50,21.56,87.27,22.58,90.72,23.46,95.42,26.03,99.92,0.0,150
140.0,200,25,50,14.47,126.6,16.84,137.22,20.8,147.57,33.21,179.82,0.0,150
35.0,50,30,50,6.0,166.5,7.56,181.68,12.51,205.92,41.02,298.26,0.0,150
490.0,700,25,50,20.46,91.46,21.89,96.98,22.87,99.93,24.51,107.75,0.0,150
35.0,50,45,50,5.32,164.11,6.41,189.82,8.66,213.5,27.38,305.6,0.0,150
490.0,700,20,50,20.65,90.75,21.99,96.96,23.45,100.71,25.45,105.47,0.0,150
315.0,450,40,50,17.84,102.21,19.9,108.36,21.35,112.54,24.39,123.81,0.0,150
665.0,950,40,50,20.99,89.18,22.02,93.39,23.08,96.8,26.51,102.32,0.0,150
700.0,1000,50,50,20.91,89.41,22.19,93.18,23.0,97.73,24.1,101.91,0.0,150
700.0,1000,35,50,21.12,87.93,22.25,90.87,23.03,95.27,24.29,100.01,0.0,150
455.0,650,35,50,20.0,95.22,21.45,99.75,22.81,102.51,25.95,111.48,0.0,150
35.0,50,35,50,5.67,165.28,7.96,189.87,11.3,224.7,20.18,281.67,0.0,150
630.0,900,25,50,21.51,87.38,22.7,91.64,23.42,95.26,25.87,102.19,0.0,150
350.0,500,50,50,18.05,103.24,19.88,110.89,21.37,116.13,23.96,121.69,0.0,150
700.0,1000,45,50,20.99,88.32,22.1,92.64,22.73,95.01,24.01,100.78,0.0,150
700.0,1000,20,50,19.75,85.97,20.86,89.9,21.69,94.45,23.55,98.38,0.0,165
630.0,900,45,50,20.83,89.51,21.87,93.75,22.99,97.56,24.52,100.89,0.0,150
595.0,850,40,50,20.98,88.62,22.13,94.51,23.18,99.69,25.5,104.5,0.0,150
280.0,400,40,50,17.98,108.0,19.16,116.06,20.39,124.17,23.11,132.11,0.0,150
560.0,800,45,50,20.2,92.98,21.49,96.3,22.59,100.66,24.15,104.8,0.0,150
280.0,400,20,50,18.68,101.93,19.97,107.44,21.92,114.74,23.46,125.33,0.0,150
525.0,750,25,50,20.88,90.98,22.11,95.32,23.32,99.3,25.57,104.29,0.0,150
455.0,650,45,50,19.48,97.96,20.3,103.1,21.8,107.14,24.24,114.87,0.0,150
350.0,500,45,50,18.16,100.27,19.68,107.76,20.87,113.34,23.04,124.32,0.0,150
420.0,600,40,50,19.51,98.38,20.79,103.18,22.08,108.34,23.97,117.57,0.0,150
105.0,150,35,50,31.06,110.27,37.2,119.88,40.61,130.52,53.08,137.72,0.0,75
315.0,450,20,50,18.83,101.11,20.41,106.09,21.47,111.37,24.72,119.93,0.0,150
385.0,550,30,50,19.38,98.13,21.02,103.3,22.5,109.13,24.24,116.61,0.0,150
280.0,400,45,50,16.96,110.01,18.5,114.75,20.0,120.55,22.76,127.32,0.0,150
280.0,400,35,50,17.23,105.2,18.88,111.96,20.63,117.03,23.57,126.65,0.0,150
175.0,250,35,50,15.26,119.48,17.18,126.98,19.48,138.9,21.7,151.0,0.0,150
350.0,500,40,50,18.23,102.63,20.35,107.42,21.98,113.67,24.15,119.65,0.0,150
700.0,1000,40,50,36.26,77.59,37.57,81.1,39.02,83.49,41.29,87.35,0.0,90
70.0,100,20,50,28.32,110.93,33.25,126.63,38.5,145.69,71.64,173.32,1.0,75
525.0,750,45,50,26.91,87.17,28.75,91.43,30.08,95.34,33.15,100.21,0.0,115
210.0,300,50,50,15.29,117.0,16.42,125.83,18.44,130.81,22.24,139.97,0.0,150
315.0,450,30,50,18.18,102.39,20.39,108.9,22.14,114.53,23.64,126.88,0.0,150
595.0,850,20,50,21.27,87.86,22.05,92.17,23.35,96.4,25.3,100.68,0.0,150
105.0,150,20,50,33.89,99.76,39.55,110.49,43.9,122.79,64.54,134.28,0.0,75
350.0,500,25,50,18.65,99.17,20.13,104.45,21.63,110.35,25.91,119.42,0.0,150
385.0,550,45,50,40.59,82.48,42.76,85.49,45.78,90.28,52.92,96.69,0.0,75
105.0,150,45,50,16.13,126.34,19.2,137.15,23.39,150.84,37.71,170.29,0.0,115
455.0,650,40,50,42.23,80.06,44.0,83.69,45.83,87.81,48.65,92.9,0.0,75
630.0,900,40,50,35.59,79.74,36.9,83.37,38.67,85.68,41.89,91.31,0.0,90
35.0,50,25,50,18.55,133.31,29.04,160.55,54.5,188.65,80.0,273.11,1.0,75
595.0,850,50,50,18.04,93.63,18.73,98.18,19.65,101.88,21.3,107.32,0.0,165
315.0,450,35,50,39.99,85.38,42.44,91.55,45.98,95.3,49.88,98.81,0.0,75
140.0,200,20,50,35.45,96.57,40.0,108.03,45.18,119.84,71.2,135.16,0.0,75
595.0,850,30,50,18.94,91.01,20.11,95.41,21.33,99.65,23.04,103.41,0.0,165
490.0,700,30,50,42.47,78.6,44.12,82.06,45.52,84.45,48.0,88.29,0.0,75
665.0,950,45,50,18.99,92.69,19.66,96.72,20.71,98.59,22.13,104.41,0.0,165
140.0,200,50,50,11.59,132.71,12.84,144.18,15.45,150.06,22.13,175.57,0.0,165
665.0,950,30,50,21.14,87.9,22.31,92.07,23.38,95.82,24.83,102.28,0.0,150
210.0,300,20,50,14.86,112.12,16.39,121.53,20.24,129.05,27.62,141.89,0.0,165
245.0,350,20,50,16.06,108.27,17.65,119.03,19.93,127.76,24.85,142.23,0.0,165
560.0,800,25,50,18.65,92.46,19.85,96.62,21.28,99.74,22.07,103.26,0.0,165
70.0,100,35,50,20.31,126.27,24.44,144.55,32.7,160.35,73.45,209.96,0.0,90
560.0,800,35,50,18.61,93.17,19.55,97.53,20.36,102.55,23.03,110.29,0.0,165
525.0,750,50,50,17.85,96.64,18.89,100.52,20.0,104.44,21.88,110.58,0.0,165
525.0,750,40,50,18.43,95.1,19.63,100.29,20.9,104.22,22.04,107.74,0.0,165
280.0,400,30,50,24.12,97.04,25.51,104.46,29.16,109.96,33.4,115.61,0.0,115
560.0,800,40,50,18.71,92.97,19.59,97.23,20.52,100.87,21.95,106.75,0.0,165
630.0,900,30,50,19.64,89.87,20.39,93.79,20.89,97.48,22.04,104.66,0.0,165
210.0,300,35,50,14.33,115.6,16.25,125.36,17.4,133.82,22.58,156.83,0.0,165
350.0,500,35,50,40.61,82.53,42.51,87.45,45.03,93.5,49.19,98.75,0.0,75
175.0,250,20,50,13.7,119.5,16.36,125.6,17.75,135.94,19.87,153.24,0.0,165
245.0,350,35,50,14.61,114.52,16.16,121.62,18.97,129.05,20.66,139.79,0.0,165
420.0,600,30,50,19.67,95.07,20.8,101.64,22.02,105.97,25.1,110.09,0.0,150
420.0,600,20,50,26.86,87.88,28.31,93.2,30.06,96.08,35.16,100.39,0.0,115
105.0,150,50,50,10.08,135.41,11.26,151.52,13.97,157.87,19.67,194.54,0.0,165
245.0,350,50,50,38.68,90.35,42.88,97.99,46.64,102.88,56.0,110.98,0.0,75
420.0,600,25,50,17.76,94.39,19.06,100.19,21.11,105.3,24.65,116.9,0.0,165
665.0,950,20,50,19.62,86.48,20.72,91.85,21.76,96.86,23.16,99.21,0.0,165
560.0,800,30,50,18.36,92.39,19.06,96.79,20.03,101.31,23.42,105.3,0.0,165
245.0,350,25,50,15.26,110.15,17.51,120.95,19.24,126.29,21.81,138.88,0.0,165
175.0,250,30,50,13.09,119.46,15.88,130.66,17.79,137.97,20.05,148.26,0.0,165
210.0,300,40,50,14.05,119.21,15.6,124.6,16.92,134.72,20.34,146.63,0.0,165
140.0,200,35,50,11.86,126.61,13.36,137.8,15.78,149.29,18.13,161.61,0.0,165
385.0,550,40,50,18.43,100.72,20.42,105.76,22.57,110.66,25.29,115.64,0.0,150
490.0,700,50,50,17.47,97.71,18.6,102.7,19.81,105.43,21.17,108.31,0.0,165
630.0,900,50,50,18.61,92.55,19.51,96.46,20.3,99.92,21.7,104.41,0.0,165
700.0,1000,30,50,19.42,88.84,20.32,93.76,21.1,96.16,22.93,102.03,0.0,165
665.0,950,35,50,18.71,91.67,19.56,94.96,20.53,99.68,22.14,104.4,0.0,165
315.0,450,50,50,15.42,107.26,17.33,113.65,18.57,119.37,19.69,123.59,0.0,165
70.0,100,45,50,7.71,147.22,9.68,162.08,11.34,183.07,16.99,241.63,0.0,165
245.0,350,40,50,14.06,118.44,15.88,123.71,17.24,128.93,19.6,137.87,0.0,165
140.0,200,45,50,11.13,129.58,12.75,142.26,15.16,152.73,20.3,161.44,0.0,165
140.0,200,30,50,11.95,128.44,13.51,137.5,16.05,146.59,24.19,161.91,0.0,165
280.0,400,50,50,15.48,112.03,16.83,120.07,17.87,124.4,19.66,139.83,0.0,165
595.0,850,45,50,18.25,94.6,19.43,98.43,20.4,102.08,22.38,109.32,0.0,165
210.0,300,45,50,13.53,119.27,15.26,126.77,17.33,134.58,21.03,141.79,0.0,165
210.0,300,25,50,14.59,112.96,16.23,123.73,17.67,131.17,25.4,146.81,0.0,165
490.0,700,40,50,42.54,79.15,44.52,82.2,47.25,85.22,48.41,89.07,0.0,75
665.0,950,50,50,18.65,92.48,19.36,96.71,20.49,99.19,22.06,105.0,0.0,165
630.0,900,20,50,19.17,90.86,20.18,93.99,21.21,97.83,23.81,100.74,0.0,165
525.0,750,35,50,18.13,96.2,18.86,100.95,20.27,104.99,22.28,112.5,0.0,165
455.0,650,50,50,17.38,100.4,18.42,104.55,19.59,107.99,24.22,113.24,0.0,165
70.0,100,30,50,8.72,146.08,10.42,161.28,13.34,177.01,18.71,200.08,0.0,165
630.0,900,35,50,18.92,91.42,19.96,95.69,20.96,98.64,22.68,105.16,0.0,165
175.0,250,45,50,12.18,125.0,14.24,136.17,17.18,143.04,20.79,153.38,0.0,165
350.0,500,20,50,17.84,101.39,19.23,105.61,20.78,107.94,25.13,116.72,0.0,165
385.0,550,35,50,16.64,101.33,17.82,108.78,19.21,113.75,21.8,118.1,0.0,165
490.0,700,35,50,35.03,82.81,36.72,87.35,38.69,91.04,40.47,94.1,0.0,90
315.0,450,25,50,17.03,102.31,18.45,111.33,19.39,116.65,23.11,124.56,0.0,165
455.0,650,25,50,18.46,94.83,19.52,100.15,20.51,103.92,24.18,110.02,0.0,165
490.0,700,45,50,34.0,83.65,36.37,87.33,38.7,90.14,41.36,94.89,0.0,90
140.0,200,40,50,26.75,107.51,30.35,116.7,35.28,125.6,41.32,143.6,0.0,90
175.0,250,40,50,12.89,123.14,14.52,133.22,15.4,147.11,20.47,168.6,0.0,165
175.0,250,25,50,12.97,121.04,14.74,131.25,17.85,139.25,22.86,148.89,0.0,165
560.0,800,20,50,19.13,90.93,20.66,95.22,21.35,99.66,23.39,105.05,0.0,165
455.0,650,30,50,17.51,97.81,18.76,101.86,19.47,106.51,21.71,114.99,0.0,165
350.0,500,30,50,16.85,101.78,18.79,109.53,20.49,115.85,23.88,123.28,0.0,165
35.0,50,50,50,4.47,166.38,5.97,186.47,7.95,215.82,18.38,301.22,0.0,165
70.0,100,50,50,7.48,149.41,8.89,164.68,10.01,180.19,17.12,205.93,0.0,165
420.0,600,35,50,17.25,99.03,18.14,104.26,19.6,109.2,21.68,112.92,0.0,165
315.0,450,45,50,15.81,108.05,17.11,116.75,18.16,121.02,20.41,136.18,0.0,165
105.0,150,40,50,9.66,140.28,11.79,152.67,14.3,166.77,18.36,203.85,0.0,165
385.0,550,20,50,17.59,97.1,19.04,104.31,20.35,108.86,23.2,122.63,0.0,165
385.0,550,50,50,16.7,103.8,18.37,109.68,20.04,114.84,21.46,127.08,0.0,165
595.0,850,35,50,18.74,92.48,19.63,96.48,20.38,100.11,21.69,109.12,0.0,165
105.0,150,25,50,10.77,131.58,13.49,148.29,17.63,162.8,30.96,178.19,0.0,165
35.0,50,40,50,4.78,167.84,6.06,187.88,7.77,206.11,10.8,253.47,0.0,165
420.0,600,50,50,17.09,101.13,18.34,106.39,19.06,109.98,21.15,114.65,0.0,165
420.0,600,45,50,17.27,99.73,18.43,105.96,20.49,111.45,22.0,118.72,0.0,165
245.0,350,45,50,14.75,114.68,16.59,121.6,18.24,128.37,21.82,137.97,0.0,165
70.0,100,25,50,7.85,149.11,10.0,172.87,12.71,187.46,21.1,232.68,0.0,165
210.0,300,30,50,21.99,105.07,25.32,112.98,28.51,117.59,36.55,133.62,0.0,115
385.0,550,25,50,17.72,99.52,19.54,104.89,20.43,110.87,22.16,115.22,0.0,165
105.0,150,30,50,24.2,111.96,27.73,124.74,35.86,139.77,44.79,152.73,0.0,90
35.0,50,20,50,5.97,163.84,7.54,187.14,12.56,229.09,46.51,427.56,0.0,165
525.0,750,20,50,18.59,93.87,20.16,98.51,21.46,101.76,23.36,107.14,0.0,165
595.0,850,25,50,19.01,90.62,20.14,96.01,20.87,100.16,22.82,103.88,0.0,165
455.0,650,20,50,18.74,94.25,19.82,98.32,20.97,103.65,24.29,111.04,0.0,165
700.0,1000,25,50,19.49,88.58,20.46,93.45,21.53,95.92,22.72,99.5,0.0,165
70.0,100,40,50,7.66,153.65,9.28,164.27,11.54,181.44,16.7,206.83,0.0,165
175.0,250,50,50,12.58,125.53,14.61,136.3,16.25,144.63,21.69,157.81,0.0,165
665.0,950,25,50,18.98,88.66,20.09,93.65,20.97,97.49,23.21,100.92,0.0,165
490.0,700,25,50,18.33,95.01,19.32,100.06,20.18,103.59,22.83,111.41,0.0,165
490.0,700,20,50,18.87,91.87,19.72,96.95,21.16,102.49,22.89,108.64,0.0,165
280.0,400,25,50,15.9,107.17,17.76,113.81,20.71,117.77,26.36,126.43,0.0,165
140.0,200,25,50,12.48,128.08,14.02,139.49,17.22,149.71,22.37,164.04,0.0,165
525.0,750,30,50,18.71,94.19,19.71,98.24,20.73,103.67,22.62,112.35,0.0,165
245.0,350,30,50,31.24,91.75,35.46,101.89,38.15,107.8,43.54,113.28,0.0,90
35.0,50,45,50,4.27,168.24,5.2,182.35,6.79,199.42,9.43,260.72,0.0,165
560.0,800,50,50,26.64,85.75,28.23,91.51,29.88,94.31,32.93,100.29,0.0,115
35.0,50,30,50,5.03,169.31,6.58,182.66,9.71,200.05,21.13,312.4,0.0,165
700.0,1000,35,50,19.19,90.02,20.25,93.78,21.15,97.07,22.48,102.77,0.0,165
35.0,50,35,50,4.44,166.21,5.6,186.16,7.84,209.63,11.49,242.22,0.0,165
700.0,1000,50,50,18.91,92.84,19.67,96.34,20.32,98.94,21.9,104.12,0.0,165
665.0,950,40,50,18.96,92.76,19.94,96.93,21.1,99.82,24.07,104.15,0.0,165
315.0,450,40,50,15.77,106.23,17.57,113.37,18.97,120.44,25.08,126.82,0.0,165
630.0,900,25,50,19.01,91.52,19.86,94.5,20.96,97.04,22.65,102.51,0.0,165
455.0,650,35,50,17.49,97.91,18.54,102.44,19.87,107.01,21.59,113.93,0.0,165
700.0,1000,20,50,18.0,89.36,19.03,93.23,19.9,97.43,21.77,105.5,0.0,180
350.0,500,50,50,16.41,105.09,17.57,111.42,18.81,117.11,21.64,125.15,0.0,165
420.0,600,40,50,17.21,101.3,18.32,106.27,19.43,110.94,22.92,119.96,0.0,165
595.0,850,40,50,18.65,94.33,19.71,98.12,20.49,101.68,22.39,107.87,0.0,165
560.0,800,45,50,17.99,96.1,19.06,101.07,19.82,104.13,21.65,110.01,0.0,165
280.0,400,40,50,15.88,108.98,17.42,114.85,19.01,122.79,21.15,135.46,0.0,165
630.0,900,45,50,18.49,92.65,19.57,97.01,20.7,100.61,22.82,107.0,0.0,165
700.0,1000,45,50,19.13,92.64,19.9,95.87,20.63,97.97,21.74,102.81,0.0,165
385.0,550,30,50,17.19,100.38,18.97,105.26,20.0,111.11,21.21,118.45,0.0,165
280.0,400,45,50,15.31,111.8,16.81,117.18,18.18,121.15,22.69,134.36,0.0,165
280.0,400,20,50,16.53,104.43,18.13,113.65,20.71,119.56,26.08,128.23,0.0,165
280.0,400,35,50,15.79,111.1,17.4,118.32,19.17,124.08,25.18,139.69,0.0,165
455.0,650,45,50,17.54,99.61,18.62,103.9,20.03,108.55,21.78,112.74,0.0,165
525.0,750,25,50,18.82,93.87,20.04,98.42,21.24,101.88,23.3,109.62,0.0,165
175.0,250,35,50,13.29,122.36,15.17,136.49,17.34,143.91,24.73,162.59,0.0,165
315.0,450,20,50,17.05,102.23,18.3,107.23,19.61,113.87,24.49,122.95,0.0,165
105.0,150,35,50,24.47,110.04,30.29,125.23,35.42,137.65,53.96,155.34,1.0,90
350.0,500,40,50,16.12,104.07,17.64,110.69,19.21,114.39,21.37,125.59,0.0,165
595.0,850,20,50,19.62,88.71,20.62,92.51,21.67,96.63,23.22,103.96,0.0,165
210.0,300,50,50,13.79,119.95,14.9,128.0,15.97,136.15,17.54,152.5,0.0,165
700.0,1000,40,50,27.91,82.91,29.14,85.04,29.96,87.31,31.56,92.86,0.0,115
350.0,500,45,50,16.46,105.29,17.19,111.27,18.37,115.7,21.4,123.27,0.0,165
315.0,450,30,50,16.33,105.71,18.02,113.71,19.46,120.33,23.23,127.84,0.0,165
70.0,100,20,50,22.29,116.02,27.15,133.81,38.45,149.29,80.0,183.52,0.0,90
595.0,850,50,50,16.82,96.93,17.77,100.69,18.45,103.77,20.4,109.48,0.0,180
105.0,150,20,50,26.73,108.55,32.39,123.47,39.81,140.36,65.53,163.36,0.0,90
350.0,500,25,50,17.2,100.59,18.56,106.17,20.46,113.2,22.46,122.63,0.0,165
665.0,950,45,50,17.06,95.03,17.82,97.7,19.11,99.94,20.32,108.99,0.0,180
595.0,850,30,50,17.26,94.56,18.27,98.2,19.31,102.58,21.02,107.1,0.0,180
525.0,750,50,50,16.2,100.46,17.45,104.26,18.33,109.16,18.84,114.6,0.0,180
560.0,800,25,50,16.86,93.67,17.85,97.46,19.12,103.03,20.85,107.94,0.0,180
140.0,200,50,50,9.98,136.38,11.37,145.98,13.17,154.83,16.06,176.19,0.0,180
315.0,450,35,50,32.21,88.87,35.4,95.31,38.12,97.65,44.77,107.42,0.0,90
210.0,300,20,50,13.64,114.07,15.09,122.88,17.37,136.18,21.61,158.41,0.0,180
245.0,350,20,50,13.58,114.29,15.56,120.67,18.08,126.85,22.37,137.78,0.0,180
525.0,750,45,50,20.25,92.38,20.86,96.44,21.94,102.11,23.9,110.27,0.0,150
560.0,800,35,50,16.55,96.05,17.52,101.23,18.49,103.91,19.79,107.84,0.0,180
140.0,200,20,50,28.36,102.98,32.97,111.0,39.42,117.47,49.7,130.05,0.0,90
35.0,50,25,50,14.01,144.75,18.92,165.57,42.97,205.01,79.99,285.46,1.0,90
665.0,950,30,50,19.34,89.27,20.12,92.61,21.06,96.85,22.74,102.18,0.0,165
105.0,150,45,50,11.01,134.04,12.95,148.02,14.5,159.48,23.2,178.61,0.0,150
630.0,900,40,50,27.74,83.52,29.11,88.37,29.98,90.55,31.53,94.68,0.0,115
210.0,300,35,50,12.57,119.52,13.83,130.62,16.71,136.62,19.18,146.52,0.0,180
385.0,550,45,50,33.11,87.24,34.52,91.39,36.73,96.04,41.38,101.73,0.0,90
525.0,750,40,50,16.36,99.04,17.65,103.1,18.4,107.76,19.68,114.84,0.0,180
175.0,250,20,50,12.33,125.71,14.16,133.22,16.29,141.61,22.37,166.0,0.0,180
630.0,900,30,50,17.48,93.4,18.47,97.37,19.27,99.76,20.82,105.61,0.0,180
245.0,350,35,50,13.2,117.59,14.41,124.71,15.58,132.93,18.42,147.71,0.0,180
455.0,650,40,50,34.72,81.52,36.21,87.82,38.34,91.77,40.47,100.99,0.0,90
560.0,800,40,50,16.53,97.7,17.57,101.93,18.56,105.66,20.49,113.11,0.0,180
490.0,700,30,50,34.84,81.46,36.98,85.37,39.21,89.58,44.25,92.74,0.0,90
70.0,100,35,50,14.14,134.17,17.32,151.32,22.48,172.52,38.94,207.61,0.0,115
560.0,800,30,50,17.29,94.66,18.2,99.2,19.24,102.81,21.32,108.11,0.0,180
105.0,150,50,50,8.25,141.46,9.81,154.65,11.61,167.56,15.96,181.95,0.0,180
210.0,300,40,50,12.1,119.91,13.32,126.8,15.48,137.63,17.95,154.03,0.0,180
245.0,350,25,50,13.61,112.32,15.9,122.08,17.36,127.68,19.88,146.15,0.0,180
665.0,950,20,50,17.78,90.34,18.66,95.54,19.39,99.98,20.77,102.49,0.0,180
420.0,600,20,50,20.01,92.77,21.49,97.64,23.08,103.57,25.46,109.66,0.0,150
700.0,1000,30,50,17.76,90.04,18.52,95.38,19.27,98.43,20.99,101.04,0.0,180
490.0,700,50,50,15.81,102.26,16.63,106.42,17.58,110.08,19.36,115.4,0.0,180
175.0,250,30,50,11.41,127.6,13.05,138.19,14.17,146.58,17.6,154.56,0.0,180
140.0,200,35,50,10.39,129.48,11.57,143.96,14.76,152.33,27.8,175.59,0.0,180
420.0,600,25,50,16.01,99.63,17.51,105.64,18.56,108.84,20.7,116.38,0.0,180
630.0,900,20,50,17.92,90.55,19.06,95.35,20.15,100.38,22.93,106.23,0.0,180
245.0,350,40,50,13.01,114.34,14.4,123.63,16.68,132.08,21.14,140.02,0.0,180
630.0,900,50,50,17.07,96.62,17.74,100.78,18.7,104.85,20.24,108.07,0.0,180
70.0,100,45,50,6.86,153.66,8.23,169.23,10.38,193.51,15.31,224.48,0.0,180
350.0,500,35,50,33.4,87.25,36.03,92.04,37.58,96.99,43.45,99.92,0.0,90
315.0,450,50,50,14.15,112.24,15.14,116.96,16.3,123.12,19.44,134.76,0.0,180
280.0,400,50,50,13.82,115.0,15.24,122.21,16.76,126.48,18.51,139.33,0.0,180
420.0,600,30,50,17.7,97.19,18.85,103.78,20.23,108.8,24.09,117.27,0.0,165
140.0,200,45,50,9.76,135.12,11.11,145.77,12.68,154.6,21.94,174.6,0.0,180
665.0,950,35,50,17.3,93.18,18.22,97.41,19.12,98.93,20.24,102.24,0.0,180
385.0,550,40,50,17.11,101.26,18.19,107.41,19.68,112.16,22.51,118.97,0.0,165
595.0,850,45,50,16.79,97.28,17.69,100.22,18.49,103.65,19.54,108.1,0.0,180
280.0,400,30,50,17.89,104.24,19.19,110.28,21.23,116.39,24.0,127.39,0.0,150
210.0,300,45,50,12.55,120.03,14.37,128.28,15.79,133.35,18.81,145.71,0.0,180
210.0,300,25,50,12.79,117.08,14.4,125.19,15.65,136.65,19.38,148.69,0.0,180
70.0,100,30,50,7.15,157.07,9.49,171.05,11.95,195.79,15.61,236.75,0.0,180
245.0,350,50,50,30.58,94.44,33.08,100.38,36.89,108.23,42.28,116.6,0.0,90
315.0,450,25,50,15.12,107.68,16.3,112.89,18.46,117.8,22.83,129.3,0.0,180
455.0,650,50,50,15.63,102.01,16.58,107.68,17.68,111.69,19.52,117.4,0.0,180
175.0,250,45,50,11.13,127.61,12.55,137.89,14.29,146.32,17.78,165.14,0.0,180
665.0,950,50,50,17.32,94.33,17.84,97.77,18.52,101.29,19.96,105.09,0.0,180
630.0,900,35,50,17.29,94.72,18.0,98.89,19.03,101.73,20.04,106.12,0.0,180
140.0,200,30,50,10.15,129.25,12.32,138.51,15.54,152.58,20.86,169.06,0.0,180
525.0,750,35,50,16.6,97.0,17.47,102.0,18.44,106.44,19.64,109.61,0.0,180
350.0,500,20,50,15.51,100.63,16.84,106.38,18.82,112.23,23.17,119.57,0.0,180
175.0,250,25,50,12.58,122.9,13.75,132.69,15.15,145.9,22.76,164.64,0.0,180
385.0,550,35,50,15.41,105.15,16.4,111.84,17.51,117.04,20.02,121.46,0.0,180
175.0,250,40,50,12.04,127.23,13.92,133.13,15.89,142.5,21.68,159.42,0.0,180
105.0,150,25,50,9.66,138.75,11.97,153.52,14.48,166.78,20.76,196.99,0.0,180
70.0,100,50,50,6.25,156.77,8.02,173.69,9.86,185.98,12.81,199.69,0.0,180
560.0,800,20,50,17.4,93.37,18.25,99.03,19.2,100.71,20.99,107.82,0.0,180
35.0,50,50,50,3.81,167.49,4.77,190.36,6.1,218.13,10.47,323.2,0.0,180
455.0,650,25,50,16.52,99.59,17.84,104.99,18.7,108.0,22.39,118.28,0.0,180
70.0,100,25,50,7.64,143.43,9.25,165.91,11.67,186.56,14.66,222.09,0.0,180
455.0,650,30,50,16.3,99.21,17.29,103.39,18.15,108.06,19.65,119.84,0.0,180
105.0,150,40,50,8.74,143.77,10.05,155.75,12.47,163.53,18.29,189.65,0.0,180
420.0,600,50,50,15.59,104.14,16.61,110.43,17.96,113.58,19.17,123.8,0.0,180
35.0,50,40,50,3.91,168.37,5.07,193.12,6.41,216.48,10.39,283.09,0.0,180
420.0,600,35,50,15.74,102.16,17.22,109.38,19.18,113.24,20.06,120.06,0.0,180
315.0,450,45,50,14.42,110.78,15.82,119.41,17.29,127.85,19.09,137.33,0.0,180
35.0,50,20,50,4.84,167.09,6.61,197.4,9.17,248.83,14.78,309.44,0.0,180
140.0,200,40,50,18.27,113.48,20.81,122.7,24.74,129.76,32.87,144.89,0.0,115
245.0,350,45,50,13.04,116.27,14.73,124.17,15.95,129.68,18.83,148.25,0.0,180
350.0,500,30,50,15.42,105.8,16.93,111.29,18.45,116.18,21.96,123.67,0.0,180
595.0,850,35,50,17.26,94.6,18.03,101.12,18.91,104.32,20.58,110.51,0.0,180
385.0,550,25,50,15.67,101.6,17.21,107.82,17.94,115.11,20.73,122.05,0.0,180
490.0,700,45,50,26.09,87.75,27.69,93.29,28.51,97.48,32.37,104.14,0.0,115
385.0,550,20,50,16.04,100.06,17.58,104.64,19.06,112.12,21.83,118.1,0.0,180
420.0,600,45,50,15.14,103.07,16.58,109.17,17.35,113.31,19.69,116.04,0.0,180
385.0,550,50,50,14.82,107.42,16.29,112.91,17.35,119.53,19.24,124.2,0.0,180
455.0,650,20,50,16.61,96.62,17.8,100.94,18.54,104.26,22.11,117.07,0.0,180
490.0,700,35,50,27.01,86.82,28.5,90.93,29.66,93.28,32.28,99.68,0.0,115
490.0,700,40,50,35.25,82.2,36.91,86.25,39.3,89.51,41.64,93.33,0.0,90
595.0,850,25,50,17.76,92.8,18.48,97.83,19.19,102.7,20.33,106.38,0.0,180
525.0,750,20,50,17.17,94.06,18.17,99.92,19.55,103.83,23.12,113.94,0.0,180
700.0,1000,25,50,17.72,90.77,18.6,93.83,19.33,97.52,21.14,100.7,0.0,180
175.0,250,50,50,10.92,129.4,12.76,138.52,14.12,145.9,18.85,157.82,0.0,180
140.0,200,25,50,10.89,129.16,12.67,139.41,14.85,151.04,31.05,177.46,0.0,180
490.0,700,25,50,16.55,96.97,17.78,102.52,18.9,106.66,22.5,112.93,0.0,180
665.0,950,25,50,17.63,92.11,18.5,95.55,19.67,98.85,21.04,103.61,0.0,180
35.0,50,45,50,3.88,170.91,5.07,188.21,5.96,217.05,9.75,259.32,0.0,180
490.0,700,20,50,16.74,93.7,18.08,100.3,19.54,105.63,20.81,112.88,0.0,180
525.0,750,30,50,16.73,96.0,17.63,101.5,18.75,106.11,21.56,109.48,0.0,180
70.0,100,40,50,7.06,152.13,8.72,167.86,10.95,189.64,19.3,250.44,0.0,180
280.0,400,25,50,14.36,107.88,16.01,114.81,17.42,122.44,19.09,135.79,0.0,180
105.0,150,30,50,17.35,120.55,20.47,132.25,23.06,147.42,33.04,172.88,0.0,115
210.0,300,30,50,15.8,110.9,17.25,117.81,19.22,125.17,23.51,140.32,0.0,150
630.0,900,25,50,17.7,91.07,18.39,96.14,19.14,99.61,20.43,107.16,0.0,180
933.3333333333334,1000,20,50,79.99,35.71,80.0,41.57,80.0,45.58,80.0,51.64,0.0,45
245.0,350,30,50,23.58,98.25,25.33,108.53,27.65,114.98,36.67,120.23,0.0,115
700.0,1000,50,50,17.15,94.07,18.05,96.91,18.78,100.5,20.36,105.57,0.0,180
35.0,50,30,50,4.22,166.33,5.83,189.79,8.11,234.94,25.94,404.57,0.0,180
560.0,800,45,50,16.83,98.64,17.47,102.26,18.44,104.94,19.86,110.08,0.0,180
35.0,50,35,50,4.46,175.43,5.92,200.99,8.07,229.86,14.18,319.52,0.0,180
665.0,950,40,50,17.5,94.22,18.39,98.71,19.2,101.1,21.42,105.37,0.0,180
315.0,450,40,50,14.45,110.11,16.06,116.03,17.41,122.99,20.76,127.76,0.0,180
700.0,1000,35,50,17.27,92.3,18.21,96.35,19.09,99.14,20.15,107.37,0.0,180
280.0,400,20,50,15.03,108.44,16.5,115.29,18.05,124.2,20.15,133.82,0.0,180
455.0,650,35,50,16.37,99.71,17.17,103.58,18.38,109.22,19.65,114.39,0.0,180
560.0,800,50,50,20.19,92.25,21.05,97.2,21.83,100.69,25.76,107.18,0.0,150
175.0,250,35,50,11.41,128.37,13.07,137.88,15.42,144.47,18.66,157.77,0.0,180
595.0,850,40,50,16.91,96.4,18.13,99.76,18.78,104.87,21.13,110.03,0.0,180
630.0,900,45,50,17.13,95.24,18.13,98.61,18.53,102.93,20.88,109.3,0.0,180
420.0,600,40,50,15.51,100.36,16.75,106.92,18.65,112.57,22.69,121.08,0.0,180
280.0,400,40,50,14.11,115.17,15.28,121.39,17.09,128.47,19.43,134.45,0.0,180
350.0,500,50,50,14.34,109.14,15.61,115.12,17.23,120.85,18.23,126.75,0.0,180
525.0,750,25,50,17.13,96.06,18.06,100.8,19.04,105.32,20.72,111.22,0.0,180
700.0,1000,45,50,17.36,93.77,18.15,97.95,18.77,101.55,20.05,106.98,0.0,180
385.0,550,30,50,15.81,102.25,17.33,109.87,18.64,114.07,19.65,121.23,0.0,180
280.0,400,45,50,13.56,115.13,14.39,120.13,16.22,124.96,19.17,131.89,0.0,180
280.0,400,35,50,14.05,112.39,15.72,119.48,17.08,126.72,18.86,142.79,0.0,180
315.0,450,20,50,15.06,106.86,16.95,114.07,18.16,118.2,19.86,132.58,0.0,180
350.0,500,40,50,14.64,107.58,16.16,115.24,17.14,121.83,20.83,129.3,0.0,180
793.3333333333333,850,50,50,79.99,42.63,80.0,48.16,80.0,52.96,80.0,62.24,0.0,45
595.0,850,20,50,17.25,92.77,18.58,96.6,19.54,101.41,23.32,107.63,0.0,180
315.0,450,30,50,14.83,108.21,16.0,116.55,17.51,121.65,21.21,127.23,0.0,180
105.0,150,35,50,16.88,127.71,19.91,137.36,23.23,151.85,29.76,165.49,0.0,115
210.0,300,50,50,12.1,125.06,13.19,134.8,15.19,141.56,17.52,153.42,0.0,180
886.6666666666667,950,45,50,79.99,41.19,80.0,47.04,80.0,52.27,80.0,59.0,0.0,45
350.0,500,25,50,15.59,103.55,16.82,112.34,17.92,119.45,20.07,123.77,0.0,180
455.0,650,45,50,15.66,102.93,17.01,108.36,17.64,112.7,20.28,119.95,0.0,180
326.66666666666663,350,20,50,79.99,59.39,80.0,72.35,80.0,80.62,80.0,87.97,0.0,45
746.6666666666667,800,25,50,79.99,39.6,80.0,44.79,80.0,52.23,80.0,58.57,0.0,45
700.0,750,50,50,79.99,44.36,80.0,50.74,80.0,55.72,80.0,59.66,0.0,45
700.0,1000,40,50,21.09,87.03,22.17,92.16,23.55,94.12,24.57,98.95,0.0,150
186.66666666666669,200,50,50,79.31,83.34,79.99,90.79,80.0,97.03,80.0,106.97,1.0,45
280.0,300,20,50,79.99,59.86,80.0,75.99,80.0,82.7,80.0,94.54,0.0,45
70.0,100,20,50,14.67,135.13,19.52,151.61,24.73,162.56,38.87,202.96,1.0,115
746.6666666666667,800,35,50,79.99,41.78,80.0,49.44,80.0,54.26,80.0,59.58,0.0,45
793.3333333333333,850,30,50,79.99,39.96,80.0,45.62,80.0,49.54,80.0,55.3,0.0,45
105.0,150,20,50,18.19,117.87,21.8,129.83,25.35,142.48,57.08,170.68,0.0,115
350.0,500,45,50,14.51,107.79,15.89,114.92,17.03,121.69,19.4,131.7,0.0,180
233.33333333333334,250,20,50,79.99,68.46,80.0,83.18,80.0,89.09,80.0,99.5,0.0,45
840.0,900,30,50,79.99,41.59,80.0,46.35,80.0,52.0,80.0,58.16,0.0,45
280.0,300,35,50,79.98,61.92,79.99,78.29,80.0,85.28,80.0,92.71,0.0,45
700.0,750,40,50,79.99,42.44,80.0,49.93,80.0,55.57,80.0,61.75,0.0,45
746.6666666666667,800,40,50,79.99,42.48,80.0,47.28,80.0,51.76,80.0,71.22,0.0,45
665.0,950,30,50,17.67,92.07,18.62,95.95,19.4,99.46,20.54,105.45,0.0,180
326.66666666666663,350,35,50,79.99,56.88,80.0,74.12,80.0,84.94,80.0,91.37,1.0,45
140.0,150,50,50,76.23,90.15,79.99,101.4,80.0,108.87,80.0,118.37,0.0,45
280.0,300,40,50,79.99,65.02,79.99,77.58,80.0,85.47,80.0,92.52,1.0,45
886.6666666666667,950,20,50,79.99,36.28,80.0,41.82,80.0,47.61,80.0,52.72,0.0,45
140.0,200,20,50,19.95,110.38,24.33,121.01,28.5,133.69,33.57,144.75,0.0,115
385.0,550,45,50,25.64,91.58,27.67,96.28,29.47,101.13,31.91,106.78,0.0,115
746.6666666666667,800,30,50,79.99,40.51,80.0,44.64,80.0,51.31,80.0,59.57,0.0,45
35.0,50,25,50,8.87,150.97,11.78,173.22,16.65,196.53,57.0,292.47,0.0,115
315.0,450,35,50,24.87,95.07,26.7,99.72,28.61,105.72,31.23,112.81,0.0,115
233.33333333333334,250,30,50,79.99,67.69,80.0,80.59,80.0,88.37,80.0,99.73,3.0,45
933.3333333333334,1000,30,50,79.99,37.49,80.0,43.03,80.0,45.84,80.0,55.19,0.0,45
630.0,900,40,50,20.61,90.57,21.79,94.5,22.71,97.35,23.99,105.03,0.0,150
105.0,150,45,50,9.85,140.22,11.82,153.47,14.29,167.05,21.41,178.06,0.0,165
455.0,650,40,50,26.45,90.25,27.81,94.35,29.5,99.11,31.25,105.38,0.0,115
525.0,750,45,50,18.13,96.47,19.29,100.68,20.45,104.89,21.36,110.01,0.0,165
840.0,900,20,50,79.99,39.8,80.0,44.1,80.0,48.75,80.0,57.01,0.0,45
840.0,900,50,50,79.99,40.03,80.0,45.24,80.0,51.75,80.0,59.21,0.0,45
70.0,100,35,50,8.86,143.05,10.37,161.24,12.41,184.09,16.53,210.44,0.0,150
420.0,600,20,50,18.27,94.05,19.32,100.16,20.62,106.64,22.85,113.27,0.0,165
653.3333333333333,700,50,50,79.99,44.57,80.0,50.89,80.0,56.96,80.0,67.8,0.0,45
490.0,700,30,50,27.12,85.6,28.57,90.73,29.96,94.12,31.35,98.01,0.0,115
186.66666666666669,200,35,50,79.98,74.91,79.99,88.86,80.0,95.53,80.0,102.91,0.0,45
326.66666666666663,350,40,50,79.99,55.93,80.0,71.56,80.0,82.74,80.0,93.66,1.0,45
93.33333333333334,100,45,50,63.41,97.19,74.47,111.92,79.99,122.65,80.0,138.19,1.0,45
560.0,600,25,50,79.99,40.96,80.0,51.8,80.0,60.07,80.0,72.97,0.0,45
326.66666666666663,350,25,50,79.99,55.2,80.0,71.72,80.0,80.31,80.0,88.11,0.0,45
373.33333333333337,400,50,50,79.99,59.0,80.0,73.09,80.0,81.04,80.0,87.95,0.0,45
793.3333333333333,850,45,50,79.99,42.92,80.0,47.74,80.0,50.54,80.0,56.38,0.0,45
280.0,300,45,50,79.99,64.6,79.99,76.95,80.0,86.56,80.0,96.57,0.0,45
280.0,300,25,50,79.99,60.16,80.0,72.61,80.0,82.42,80.0,93.17,0.0,45
886.6666666666667,950,35,50,79.99,39.92,80.0,44.78,80.0,49.61,80.0,56.81,0.0,45
420.0,450,50,50,79.99,51.55,80.0,67.16,80.0,80.5,80.0,84.6,0.0,45
233.33333333333334,250,45,50,79.97,75.53,79.99,85.62,80.0,95.21,80.0,101.12,0.0,45
93.33333333333334,100,30,50,67.54,93.18,79.98,105.37,79.99,123.08,80.0,138.09,1.0,45
886.6666666666667,950,50,50,79.99,40.79,80.0,46.2,80.0,49.74,80.0,59.7,0.0,45
233.33333333333334,250,25,50,79.98,71.17,79.99,86.59,80.0,91.73,80.0,97.24,0.0,45
186.66666666666669,200,45,50,79.97,78.78,80.0,93.02,80.0,99.27,80.0,111.27,1.0,45
420.0,450,25,50,79.99,49.2,80.0,59.33,80.0,69.88,80.0,81.65,0.0,45
840.0,900,35,50,79.99,40.12,80.0,47.5,80.0,51.66,80.0,55.23,0.0,45
46.66666666666667,50,50,50,49.7,119.48,63.99,136.46,79.98,152.04,80.0,222.2,5.0,45
385.0,550,40,50,15.16,105.21,16.55,111.33,17.61,117.32,19.13,120.73,0.0,180
606.6666666666667,650,50,50,79.99,47.81,80.0,55.42,80.0,60.42,80.0,67.07,0.0,45
186.66666666666669,200,30,50,79.98,77.05,79.99,90.21,80.0,96.67,80.0,108.75,1.0,45
93.33333333333334,100,50,50,66.79,100.34,79.95,109.99,79.99,119.7,80.0,136.68,2.0,45
46.66666666666667,50,40,50,48.33,116.13,58.37,136.66,75.62,148.21,80.0,174.02,4.0,45
140.0,150,40,50,74.49,88.93,79.99,98.18,80.0,109.26,80.0,117.02,0.0,45
700.0,750,35,50,79.99,42.7,80.0,48.6,80.0,56.32,80.0,64.03,0.0,45
350.0,500,35,50,24.99,92.89,27.09,99.71,28.6,104.85,31.35,111.5,0.0,115
93.33333333333334,100,25,50,71.17,91.94,79.99,107.36,79.99,116.22,80.0,131.08,8.0,45
466.6666666666667,500,20,50,79.99,46.47,80.0,54.54,80.0,65.36,80.0,76.61,0.0,45
233.33333333333334,250,40,50,79.99,71.19,80.0,83.27,80.0,89.42,80.0,99.16,0.0,45
513.3333333333333,550,35,50,79.99,49.4,80.0,57.48,80.0,65.71,80.0,78.64,0.0,45
46.66666666666667,50,20,50,56.45,107.02,73.44,123.6,79.99,149.01,80.0,180.56,9.0,45
606.6666666666667,650,25,50,79.99,42.82,79.99,50.82,80.0,58.52,80.0,67.47,0.0,45
140.0,150,25,50,78.27,83.93,79.99,100.75,80.0,107.24,80.0,121.34,6.0,45
606.6666666666667,650,30,50,79.99,46.1,80.0,52.3,80.0,60.76,80.0,76.63,0.0,45
245.0,350,50,50,22.19,101.9,24.52,108.2,26.24,116.62,32.73,137.09,0.0,115
420.0,600,30,50,15.93,101.44,16.95,107.21,17.96,113.1,21.75,117.11,0.0,180
513.3333333333333,550,50,50,79.99,50.2,80.0,59.72,80.0,66.79,80.0,78.76,0.0,45
280.0,400,30,50,15.76,107.18,17.14,113.56,18.62,119.52,23.38,134.41,0.0,165
746.6666666666667,800,20,50,79.99,37.71,80.0,45.57,80.0,53.31,80.0,58.2,0.0,45
513.3333333333333,550,20,50,79.99,46.32,80.0,57.04,80.0,67.27,80.0,80.9,0.0,45
513.3333333333333,550,25,50,79.99,46.9,80.0,53.7,80.0,61.76,80.0,75.04,0.0,45
326.66666666666663,350,45,50,79.99,62.39,80.0,74.26,80.0,84.88,80.0,91.35,0.0,45
793.3333333333333,850,25,50,79.99,40.0,79.99,45.89,80.0,53.5,80.0,58.67,0.0,45
466.6666666666667,500,30,50,79.99,48.25,80.0,59.51,80.0,69.22,80.0,81.47,0.0,45
793.3333333333333,850,35,50,79.99,42.09,80.0,46.01,80.0,50.35,80.0,56.73,0.0,45
560.0,600,45,50,79.99,44.01,80.0,52.23,80.0,59.3,80.0,67.21,0.0,45
420.0,450,45,50,79.99,55.45,80.0,64.26,80.0,75.61,80.0,86.22,0.0,45
560.0,600,50,50,79.99,47.0,80.0,55.8,80.0,66.92,80.0,76.6,0.0,45
606.6666666666667,650,20,50,79.99,41.29,80.0,50.74,80.0,57.28,80.0,72.42,0.0,45
186.66666666666669,200,25,50,79.98,73.77,79.99,89.11,80.0,98.39,80.0,104.9,2.0,45
653.3333333333333,700,20,50,79.99,39.42,79.99,45.8,80.0,51.3,80.0,59.86,0.0,45
700.0,750,20,50,79.99,41.94,80.0,48.69,80.0,54.73,80.0,62.93,0.0,45
46.66666666666667,50,45,50,48.7,118.59,67.54,141.41,79.99,162.96,80.0,189.1,4.0,45
233.33333333333334,250,50,50,79.98,72.58,80.0,83.77,80.0,94.95,80.0,102.19,0.0,45
933.3333333333334,1000,25,50,79.99,38.66,80.0,42.66,80.0,46.93,80.0,52.61,0.0,45
140.0,200,40,50,13.1,125.58,15.17,135.21,17.45,141.37,23.56,158.35,0.0,150
490.0,700,45,50,19.51,95.08,21.19,99.22,22.18,102.53,23.6,109.18,0.0,150
653.3333333333333,700,25,50,79.99,39.22,79.99,48.43,80.0,53.95,80.0,58.61,0.0,45
46.66666666666667,50,35,50,50.76,115.48,63.17,135.0,79.97,148.85,80.0,172.79,4.0,45
560.0,600,35,50,79.99,47.19,80.0,53.59,80.0,59.9,80.0,69.28,0.0,45
933.3333333333334,1000,20,50,74.22,71.7,76.05,74.6,78.38,76.22,79.99,80.21,0.0,60
700.0,750,30,50,79.99,41.59,80.0,47.64,80.0,54.17,80.0,61.14,0.0,45
490.0,700,35,50,19.64,93.69,21.41,97.89,22.83,100.89,24.67,105.95,0.0,150
490.0,700,40,50,26.46,88.15,27.99,92.94,29.89,95.63,32.39,101.85,0.0,115
46.66666666666667,50,30,50,52.07,109.91,69.92,127.72,79.99,144.92,80.0,163.29,3.0,45
420.0,450,40,50,79.99,51.25,80.0,60.59,80.0,69.39,80.0,85.01,1.0,45
886.6666666666667,950,40,50,79.99,38.8,80.0,44.3,80.0,47.7,80.0,55.53,0.0,45
840.0,900,25,50,79.99,38.02,80.0,44.13,80.0,52.23,80.0,59.26,0.0,45
373.33333333333337,400,25,50,79.99,51.04,80.0,62.46,80.0,70.91,80.0,88.93,1.0,45
373.33333333333337,400,20,50,79.99,46.93,80.0,59.09,80.0,70.42,80.0,80.77,0.0,45
606.6666666666667,650,35,50,79.99,42.46,80.0,50.31,80.0,57.86,80.0,66.67,1.0,45
793.3333333333333,850,40,50,79.99,41.78,80.0,48.05,80.0,52.28,80.0,58.17,0.0,45
746.6666666666667,800,45,50,79.99,41.2,80.0,46.17,80.0,51.16,80.0,64.74,0.0,45
933.3333333333334,1000,35,50,79.99,39.64,80.0,43.24,80.0,47.38,80.0,56.26,0.0,45
933.3333333333334,1000,50,50,79.99,39.57,80.0,43.96,80.0,50.17,80.0,55.91,0.0,45
886.6666666666667,950,25,50,79.99,38.95,80.0,43.98,80.0,48.86,80.0,54.81,0.0,45
233.33333333333334,250,35,50,79.98,70.51,80.0,84.45,80.0,92.32,80.0,100.36,2.0,45
700.0,750,25,50,79.99,41.4,80.0,49.5,80.0,53.48,80.0,65.69,0.0,45
93.33333333333334,100,40,50,67.56,90.78,79.98,108.99,79.99,125.57,80.0,138.21,2.0,45
840.0,900,45,50,79.99,41.26,80.0,44.82,80.0,50.98,80.0,58.45,0.0,45
466.6666666666667,500,50,50,79.99,50.72,80.0,63.08,80.0,75.5,80.0,85.23,0.0,45
420.0,450,20,50,79.99,48.2,79.99,56.29,80.0,66.52,80.0,80.51,0.0,45
373.33333333333337,400,40,50,79.99,56.86,80.0,72.15,80.0,80.04,80.0,86.58,0.0,45
373.33333333333337,400,45,50,79.99,57.61,80.0,69.89,80.0,77.46,80.0,87.82,0.0,45
210.0,300,30,50,14.94,115.62,16.22,125.67,18.68,135.57,24.53,148.83,0.0,165
373.33333333333337,400,35,50,79.99,51.8,80.0,65.39,80.0,81.12,80.0,88.77,0.0,45
513.3333333333333,550,30,50,79.99,44.47,80.0,54.14,80.0,62.7,80.0,76.22,0.0,45
105.0,150,30,50,12.21,130.81,13.88,142.89,16.41,159.52,24.01,202.99,0.0,150
933.3333333333334,1000,45,50,79.99,38.78,80.0,43.37,80.0,48.94,80.0,54.75,0.0,45
245.0,350,30,50,16.89,106.93,18.51,115.86,20.46,121.8,25.78,129.52,0.0,150
560.0,600,40,50,79.99,43.97,80.0,53.87,80.0,62.24,80.0,73.97,0.0,45
793.3333333333333,850,50,50,71.92,74.21,74.75,78.1,77.0,80.32,79.11,83.87,0.0,60
466.6666666666667,500,40,50,79.99,54.52,80.0,63.88,80.0,73.12,80.0,83.59,0.0,45
793.3333333333333,850,20,50,79.99,39.85,80.0,45.87,80.0,49.57,80.0,57.31,0.0,45
886.6666666666667,950,45,50,72.5,74.02,75.45,76.43,78.12,78.53,79.99,81.49,0.0,60
420.0,450,30,50,79.99,52.86,79.99,63.72,80.0,68.26,80.0,81.57,0.0,45
560.0,800,50,50,18.24,94.2,19.08,99.48,20.12,102.83,21.21,110.77,0.0,165
186.66666666666669,200,50,50,57.02,93.63,63.32,103.15,71.86,109.34,79.98,119.91,0.0,60
466.6666666666667,500,25,50,79.99,48.79,80.0,56.71,80.0,63.31,80.0,77.71,0.0,45
280.0,300,50,50,79.99,67.12,79.99,81.98,80.0,89.34,80.0,97.38,1.0,45
326.66666666666663,350,20,50,67.76,80.63,73.05,87.23,79.98,91.66,80.0,98.17,0.0,60
746.6666666666667,800,25,50,72.96,73.51,76.12,76.43,78.42,79.7,79.99,84.29,0.0,60
700.0,750,50,50,72.12,74.95,75.02,78.66,77.27,82.03,79.99,84.2,0.0,60
746.6666666666667,800,35,50,71.97,74.26,75.29,77.24,78.8,79.03,80.0,83.27,0.0,60
280.0,300,20,50,65.37,83.34,74.71,91.32,79.98,95.36,80.0,101.45,0.0,60
793.3333333333333,850,30,50,72.48,73.63,75.62,75.8,78.45,78.59,80.0,84.56,0.0,60
233.33333333333334,250,20,50,63.85,86.73,72.25,95.52,79.97,102.37,80.0,120.34,0.0,60
840.0,900,30,50,71.92,74.2,75.22,76.69,78.08,79.73,79.99,82.18,0.0,60
606.6666666666667,650,45,50,79.99,42.14,80.0,51.43,80.0,58.03,80.0,69.18,0.0,45
700.0,750,40,50,71.65,75.12,74.6,79.25,77.66,81.86,79.99,88.54,0.0,60
280.0,300,35,50,63.69,86.96,67.13,93.0,78.36,98.48,80.0,108.1,0.0,60
746.6666666666667,800,40,50,72.38,74.33,75.96,76.98,78.03,79.04,80.0,83.88,0.0,60
105.0,150,35,50,11.38,131.57,13.28,146.11,15.83,160.02,19.22,169.38,0.0,150
140.0,150,50,50,53.1,103.39,62.89,113.35,68.98,120.47,79.98,135.84,0.0,60
70.0,100,20,50,10.74,139.33,13.48,154.45,18.0,187.89,28.65,223.14,0.0,150
280.0,300,40,50,62.03,85.21,68.67,92.53,73.14,97.8,79.96,110.91,0.0,60
886.6666666666667,950,30,50,79.99,37.5,80.0,43.59,80.0,47.77,80.0,50.89,0.0,45
700.0,1000,40,50,19.53,90.54,20.31,94.26,21.39,96.63,23.23,99.92,0.0,165
326.66666666666663,350,35,50,65.12,83.97,69.96,89.5,72.86,94.65,78.95,99.16,0.0,60
105.0,150,20,50,12.18,125.33,14.96,138.9,18.56,153.57,38.78,175.68,0.0,150
233.33333333333334,250,30,50,62.34,88.55,69.67,95.34,79.73,101.04,80.0,107.88,0.0,60
653.3333333333333,700,50,50,70.43,76.78,73.53,79.69,77.39,82.66,79.99,85.86,0.0,60
933.3333333333334,1000,30,50,74.07,72.02,76.88,74.03,77.85,76.43,80.0,80.85,0.0,60
840.0,900,50,50,72.21,74.31,75.35,77.63,77.69,80.04,79.99,83.06,0.0,60
373.33333333333337,400,50,50,66.24,82.98,70.01,89.59,73.66,94.29,80.0,98.79,0.0,60
886.6666666666667,950,20,50,73.88,72.12,75.97,74.96,77.64,76.9,79.44,80.56,0.0,60
840.0,900,20,50,73.54,71.54,75.54,74.76,79.01,77.06,79.99,80.76,0.0,60
466.6666666666667,500,45,50,79.99,50.37,80.0,61.9,80.0,69.06,80.0,84.51,0.0,45
186.66666666666669,200,35,50,56.1,91.37,64.44,100.65,72.5,109.8,79.99,133.33,0.0,60
326.66666666666663,350,40,50,65.26,85.65,69.58,91.72,76.74,97.54,80.0,105.26,0.0,60
93.33333333333334,100,45,50,43.59,109.06,54.72,118.38,69.25,135.11,80.0,143.66,1.0,60
746.6666666666667,800,30,50,71.89,75.41,74.15,78.35,77.73,81.04,80.0,83.87,0.0,60
560.0,600,25,50,71.24,76.28,74.94,79.35,78.52,82.56,80.0,86.95,0.0,60
793.3333333333333,850,45,50,72.92,74.39,75.54,77.45,77.84,79.69,79.99,85.7,0.0,60
385.0,550,45,50,18.7,99.91,20.19,105.93,21.7,110.82,23.68,116.09,0.0,150
420.0,600,20,50,16.52,96.87,18.17,103.45,18.82,108.79,20.49,118.33,0.0,180
280.0,300,25,50,65.09,84.15,70.65,91.02,78.17,96.54,79.99,106.37,0.0,60
455.0,650,40,50,19.7,96.16,20.89,100.45,22.85,104.47,27.57,109.96,0.0,150
93.33333333333334,100,30,50,50.93,111.45,58.36,126.49,76.85,141.68,80.0,152.89,2.0,60
886.6666666666667,950,35,50,73.4,73.23,75.41,76.05,78.18,78.41,79.99,81.81,0.0,60
186.66666666666669,200,45,50,56.72,97.03,65.73,104.61,73.72,109.36,79.99,117.22,0.0,60
140.0,200,20,50,14.33,121.08,16.62,131.32,19.17,137.67,25.64,151.58,0.0,150
280.0,300,45,50,63.05,84.76,70.17,93.97,75.48,101.38,80.0,112.88,0.0,60
233.33333333333334,250,25,50,62.63,86.87,69.11,96.02,75.52,103.21,79.99,108.27,0.0,60
420.0,450,25,50,68.95,78.55,74.61,84.95,79.12,87.86,80.0,91.69,0.0,60
420.0,450,50,50,67.01,81.69,71.31,86.45,79.58,91.47,80.0,96.78,0.0,60
630.0,900,40,50,18.43,93.22,19.45,97.66,20.5,102.01,22.55,105.69,0.0,165
233.33333333333334,250,45,50,62.65,91.86,69.79,96.97,76.54,103.03,80.0,112.29,0.0,60
886.6666666666667,950,50,50,72.97,74.03,75.47,76.68,77.0,78.63,79.99,82.18,0.0,60
490.0,700,30,50,19.87,92.92,21.35,97.06,23.2,101.88,26.25,109.04,0.0,150
93.33333333333334,100,50,50,44.85,117.35,55.01,129.99,68.22,143.92,80.0,158.76,1.0,60
525.0,750,45,50,16.43,99.61,17.49,104.0,18.76,105.91,20.02,111.07,0.0,180
105.0,150,45,50,8.51,146.25,10.62,156.78,12.41,169.16,15.94,197.16,0.0,180
606.6666666666667,650,50,50,69.77,78.23,73.41,80.57,77.54,83.4,79.99,85.7,0.0,60
140.0,150,40,50,52.35,100.02,61.11,113.45,71.41,123.96,79.99,139.29,0.0,60
326.66666666666663,350,25,50,64.95,84.02,71.58,89.76,79.98,95.35,80.0,99.45,0.0,60
46.66666666666667,50,50,50,35.49,133.58,43.13,155.07,66.56,178.32,79.99,252.43,1.0,60
233.33333333333334,250,40,50,60.35,92.03,67.27,98.97,74.6,104.43,79.99,112.8,0.0,60
140.0,150,25,50,55.35,99.42,64.77,107.76,79.98,119.25,80.0,131.48,0.0,60
186.66666666666669,200,30,50,60.4,91.85,68.01,101.44,78.5,107.78,80.0,117.24,0.0,60
93.33333333333334,100,25,50,46.56,106.88,58.73,119.17,79.66,128.86,80.0,145.98,1.0,60
315.0,450,35,50,18.36,105.08,20.24,111.64,21.04,116.27,23.3,124.81,0.0,150
700.0,750,35,50,72.42,75.64,75.95,78.73,77.43,82.08,80.0,86.63,0.0,60
46.66666666666667,50,40,50,33.53,132.69,45.11,152.23,57.88,174.64,80.0,193.72,0.0,60
513.3333333333333,550,35,50,68.48,78.35,71.95,82.35,76.87,86.61,80.0,89.58,0.0,60
70.0,100,35,50,7.89,144.35,9.35,165.28,11.99,184.91,19.47,217.96,0.0,165
513.3333333333333,550,40,50,79.99,45.73,80.0,54.71,80.0,61.52,80.0,71.37,0.0,45
606.6666666666667,650,30,50,71.64,76.19,74.18,79.52,78.63,83.22,79.99,89.04,0.0,60
840.0,900,35,50,72.48,74.07,74.93,76.76,77.5,78.8,79.99,84.98,0.0,60
46.66666666666667,50,20,50,34.48,120.0,48.56,144.61,57.58,162.25,80.0,204.39,1.0,60
466.6666666666667,500,20,50,71.15,76.66,75.66,82.01,79.96,85.32,80.0,88.83,0.0,60
35.0,50,25,50,5.87,162.65,7.79,186.55,10.59,223.82,17.21,295.08,0.0,150
466.6666666666667,500,30,50,67.95,80.06,72.08,84.02,78.53,87.09,79.99,92.92,0.0,60
606.6666666666667,650,25,50,72.81,76.34,74.91,78.83,77.66,82.2,80.0,86.16,0.0,60
746.6666666666667,800,20,50,72.35,74.15,75.9,76.93,78.45,79.38,79.99,81.2,0.0,60
513.3333333333333,550,50,50,68.37,78.36,71.59,83.65,74.78,86.95,79.99,90.49,0.0,60
793.3333333333333,850,25,50,73.15,72.49,75.67,75.38,78.2,77.86,80.0,81.16,0.0,60
513.3333333333333,550,20,50,69.33,76.83,74.05,80.47,79.97,84.02,80.0,89.41,0.0,60
560.0,600,50,50,69.92,77.21,73.66,80.15,77.11,84.04,79.99,88.48,0.0,60
420.0,450,45,50,67.29,79.88,71.12,85.35,77.5,90.45,79.99,97.86,0.0,60
653.3333333333333,700,20,50,72.54,73.82,77.09,76.64,79.98,80.17,80.0,85.19,0.0,60
793.3333333333333,850,35,50,72.58,75.03,74.6,76.99,78.07,79.4,80.0,82.71,0.0,60
560.0,600,45,50,69.95,77.69,73.09,81.07,75.78,85.16,80.0,90.83,0.0,60
606.6666666666667,650,20,50,71.33,76.14,75.51,79.44,78.88,84.31,79.99,86.94,0.0,60
46.66666666666667,50,45,50,32.73,129.42,43.56,152.83,69.47,172.24,80.0,220.84,0.0,60
513.3333333333333,550,25,50,69.98,77.44,74.51,81.57,79.41,85.09,79.99,93.65,0.0,60
245.0,350,50,50,16.1,110.44,18.35,118.73,20.54,128.74,28.08,137.64,0.0,150
326.66666666666663,350,45,50,63.77,82.82,69.21,88.91,76.81,94.86,80.0,100.99,0.0,60
933.3333333333334,1000,25,50,73.86,71.78,76.7,74.39,77.95,76.32,79.99,78.1,0.0,60
700.0,750,20,50,73.12,73.08,77.09,76.52,79.98,79.54,80.0,83.15,0.0,60
350.0,500,35,50,18.6,100.02,20.27,105.04,21.91,110.53,23.47,116.07,0.0,150
186.66666666666669,200,25,50,59.93,89.99,66.95,98.55,73.67,106.9,80.0,116.27,0.0,60
233.33333333333334,250,50,50,60.1,92.37,69.12,100.86,74.99,107.64,79.99,113.26,0.0,60
653.3333333333333,700,25,50,71.49,74.82,75.7,77.63,78.33,80.04,80.0,85.81,0.0,60
46.66666666666667,50,35,50,32.6,130.88,42.74,152.85,61.72,181.68,79.99,197.23,0.0,60
933.3333333333334,1000,20,50,58.78,74.57,60.91,77.25,63.57,80.1,66.14,83.44,0.0,75
420.0,450,40,50,69.15,80.66,73.32,85.41,77.37,91.3,80.0,96.66,0.0,60
560.0,600,30,50,79.99,45.71,80.0,54.02,80.0,59.45,80.0,69.51,0.0,45
46.66666666666667,50,30,50,32.07,133.83,51.43,160.51,73.82,181.0,80.0,231.38,3.0,60
700.0,750,30,50,72.43,74.4,75.21,77.32,77.8,81.15,79.98,84.25,0.0,60
280.0,400,30,50,14.18,111.16,15.73,118.7,16.94,126.98,20.43,136.94,0.0,180
886.6666666666667,950,40,50,73.13,73.29,75.25,75.86,77.15,78.51,80.0,81.9,0.0,60
840.0,900,25,50,73.26,72.69,75.72,75.66,78.7,78.34,80.0,82.34,0.0,60
606.6666666666667,650,35,50,70.3,76.58,74.15,79.49,78.57,83.02,79.99,86.52,0.0,60
933.3333333333334,1000,35,50,73.44,72.82,75.71,75.21,77.96,76.94,79.98,79.54,0.0,60
793.3333333333333,850,40,50,72.48,73.83,76.09,76.79,78.89,79.84,80.0,82.05,0.0,60
373.33333333333337,400,25,50,67.45,79.1,73.23,83.71,77.67,87.98,80.0,95.33,0.0,60
746.6666666666667,800,45,50,72.74,74.16,75.19,76.69,78.66,79.66,80.0,86.17,0.0,60
373.33333333333337,400,20,50,69.85,76.83,75.12,81.83,79.61,86.79,80.0,92.22,0.0,60
420.0,450,20,50,69.73,77.92,74.24,82.11,79.98,87.22,80.0,89.89,0.0,60
700.0,750,25,50,72.14,74.69,75.29,77.56,77.86,79.52,79.99,84.63,0.0,60
466.6666666666667,500,50,50,67.25,80.21,70.56,84.05,74.2,87.69,80.0,94.07,0.0,60
373.33333333333337,400,40,50,66.62,83.53,71.3,87.35,76.4,92.73,80.0,96.88,0.0,60
490.0,700,45,50,17.71,98.99,18.98,102.95,20.04,107.4,22.0,112.68,0.0,165
140.0,200,40,50,11.47,132.21,13.2,142.22,14.64,151.56,18.26,171.96,0.0,165
373.33333333333337,400,35,50,65.8,81.69,70.83,87.68,76.22,91.7,79.99,98.1,0.0,60
560.0,600,35,50,71.08,75.26,75.2,80.56,78.8,83.99,79.99,87.55,0.0,60
933.3333333333334,1000,50,50,72.12,73.94,74.33,76.36,76.55,77.39,78.57,82.78,0.0,60
490.0,700,40,50,19.76,94.72,21.12,100.58,22.39,102.76,24.44,107.65,0.0,150
840.0,900,45,50,72.46,74.49,75.06,76.61,76.92,78.43,80.0,81.45,0.0,60
933.3333333333334,1000,45,50,72.94,73.42,75.31,76.37,77.5,77.99,79.75,80.7,0.0,60
513.3333333333333,550,30,50,70.01,76.16,74.07,80.81,77.88,84.78,79.99,89.04,0.0,60
373.33333333333337,400,45,50,66.0,81.84,71.44,87.08,75.34,90.38,80.0,98.99,0.0,60
560.0,600,40,50,70.31,78.4,73.02,81.23,75.8,86.33,80.0,89.79,0.0,60
490.0,700,35,50,17.97,96.47,19.47,101.42,20.63,107.21,21.57,111.02,0.0,165
886.6666666666667,950,45,50,57.9,77.19,59.87,79.74,62.58,81.87,65.61,85.16,0.0,75
93.33333333333334,100,40,50,45.86,109.68,53.42,124.01,64.49,136.38,80.0,173.51,0.0,60
793.3333333333333,850,50,50,56.93,77.93,59.15,80.98,60.59,83.07,67.32,89.13,0.0,75
466.6666666666667,500,40,50,70.65,78.79,73.78,83.93,76.28,88.33,79.98,94.7,0.0,60
233.33333333333334,250,35,50,59.88,88.94,65.62,95.12,70.28,101.97,79.99,116.94,0.0,60
793.3333333333333,850,20,50,73.74,72.29,75.95,75.35,78.61,77.4,80.0,82.45,0.0,60
186.66666666666669,200,50,50,43.16,104.84,48.33,111.92,55.69,119.28,72.33,127.53,0.0,75
280.0,300,50,50,61.42,89.07,68.24,93.71,78.66,98.29,80.0,107.1,0.0,60
420.0,450,30,50,67.74,79.67,72.78,83.93,78.86,86.22,80.0,95.38,0.0,60
746.6666666666667,800,35,50,57.57,77.46,59.58,81.03,61.84,83.07,66.4,86.63,0.0,75
886.6666666666667,950,25,50,73.14,72.12,75.23,74.57,77.65,76.77,80.0,79.75,0.0,60
746.6666666666667,800,25,50,57.21,76.94,60.64,80.01,63.8,83.29,66.12,86.29,0.0,75
326.66666666666663,350,20,50,51.76,85.98,57.05,93.0,63.52,99.06,69.81,103.75,0.0,75
466.6666666666667,500,25,50,69.38,76.84,74.09,80.92,76.34,86.56,80.0,91.04,0.0,60
700.0,750,50,50,56.58,80.53,58.92,82.63,61.04,85.27,65.75,89.07,0.0,75
280.0,300,20,50,50.82,90.55,56.58,98.4,59.81,103.82,74.68,113.52,0.0,75
105.0,150,30,50,10.79,136.95,13.04,153.55,15.13,167.1,25.33,199.15,0.0,165
210.0,300,30,50,12.77,120.19,14.4,128.24,17.04,135.33,21.95,145.67,0.0,180
245.0,350,30,50,14.77,110.78,16.58,119.05,17.67,129.49,22.93,139.4,0.0,165
233.33333333333334,250,20,50,48.96,92.05,53.71,100.92,57.31,108.03,70.81,116.82,0.0,75
793.3333333333333,850,30,50,57.97,76.37,60.59,79.99,62.72,82.67,68.68,85.99,0.0,75
280.0,300,35,50,50.19,91.88,53.88,99.98,61.1,106.23,71.7,116.65,0.0,75
746.6666666666667,800,40,50,57.85,77.86,60.38,80.69,61.72,83.36,64.94,87.78,0.0,75
840.0,900,30,50,58.52,75.73,60.85,79.05,63.55,81.71,66.78,85.68,0.0,75
326.66666666666663,350,35,50,51.39,88.37,56.35,95.08,60.86,98.78,72.9,104.79,0.0,75
700.0,750,40,50,56.66,78.88,58.8,81.83,62.69,85.74,65.33,88.68,0.0,75
280.0,300,40,50,49.55,93.32,54.82,99.45,61.41,105.35,69.3,114.75,0.0,75
140.0,150,50,50,40.24,109.83,47.53,119.95,57.15,130.81,71.99,148.17,0.0,75
653.3333333333333,700,50,50,56.16,79.86,58.6,83.13,61.01,85.76,64.74,93.17,0.0,75
373.33333333333337,400,50,50,51.56,87.76,54.9,93.71,58.76,99.13,67.76,106.07,0.0,75
886.6666666666667,950,30,50,72.91,73.02,75.59,75.58,78.7,78.34,79.99,79.81,0.0,60
933.3333333333334,1000,30,50,59.2,74.84,60.61,77.59,62.4,78.88,66.56,84.48,0.0,75
840.0,900,20,50,58.35,74.92,60.28,78.82,63.91,82.11,65.69,84.27,0.0,75
93.33333333333334,100,45,50,32.54,118.39,40.91,132.71,54.99,153.21,79.99,198.97,1.0,75
560.0,800,50,50,16.4,98.63,17.09,101.91,18.44,105.56,20.37,118.04,0.0,180
886.6666666666667,950,20,50,59.12,74.3,61.13,77.41,63.77,80.18,66.62,84.1,0.0,75
606.6666666666667,650,45,50,69.94,75.81,74.17,81.13,77.19,83.34,79.99,87.56,0.0,60
840.0,900,50,50,57.06,77.52,59.05,80.24,61.2,82.45,63.85,86.36,0.0,75
326.66666666666663,350,40,50,50.79,89.3,55.43,95.82,59.68,100.4,68.3,107.07,0.0,75
186.66666666666669,200,35,50,44.91,100.35,51.83,106.52,60.52,118.77,79.99,133.81,0.0,75
233.33333333333334,250,30,50,48.15,93.59,53.51,100.13,61.28,108.34,67.98,120.4,0.0,75
746.6666666666667,800,30,50,57.9,77.03,60.62,79.77,62.06,82.01,66.06,87.63,0.0,75
186.66666666666669,200,45,50,42.24,103.16,50.41,112.3,58.75,121.09,78.91,130.3,0.0,75
560.0,600,20,50,79.99,45.42,80.0,52.19,80.0,58.17,80.0,76.84,0.0,45
280.0,300,25,50,49.64,90.75,54.25,98.44,58.89,104.69,76.34,115.37,0.0,75
700.0,1000,40,50,17.06,93.64,18.0,97.7,19.03,99.83,20.83,104.98,0.0,180
233.33333333333334,250,45,50,48.49,97.4,53.67,104.66,59.17,112.43,66.58,122.67,0.0,75
560.0,600,25,50,55.0,80.33,58.92,84.0,61.86,87.85,65.05,91.75,0.0,75
420.0,450,25,50,53.43,82.62,56.78,88.1,62.31,93.19,73.48,101.18,0.0,75
105.0,150,35,50,10.73,138.65,12.56,152.26,17.02,167.34,26.64,199.61,0.0,165
70.0,100,20,50,8.59,145.15,10.12,163.31,14.15,177.7,18.9,227.49,0.0,165
793.3333333333333,850,45,50,57.29,77.42,59.64,80.24,61.67,82.6,65.9,88.16,0.0,75
233.33333333333334,250,25,50,49.22,92.95,53.77,101.51,62.29,108.43,72.22,119.63,0.0,75
420.0,450,50,50,53.6,87.33,57.18,92.14,59.86,97.46,71.92,101.99,0.0,75
886.6666666666667,950,35,50,58.72,75.87,60.52,78.66,62.43,81.07,64.78,83.7,0.0,75
105.0,150,20,50,11.35,132.59,14.09,143.7,18.67,153.73,32.55,196.49,0.0,165
513.3333333333333,550,35,50,54.33,83.41,57.22,87.69,60.88,92.07,64.36,95.85,0.0,75
140.0,150,40,50,40.13,109.27,47.12,120.75,58.42,129.46,69.54,157.86,0.0,75
93.33333333333334,100,30,50,33.4,115.3,43.21,128.85,57.4,138.4,80.0,161.61,0.0,75
606.6666666666667,650,50,50,55.85,80.43,58.25,85.01,61.66,87.6,65.51,92.34,0.0,75
140.0,150,25,50,40.11,106.4,51.35,115.32,64.39,126.04,80.0,140.17,1.0,75
280.0,300,45,50,48.66,94.67,52.97,98.97,59.37,104.54,66.72,112.72,0.0,75
93.33333333333334,100,50,50,32.44,126.49,40.68,137.69,49.33,151.38,71.38,191.62,1.0,75
46.66666666666667,50,50,50,22.84,140.58,27.68,160.19,42.58,182.12,79.99,238.06,1.0,75
466.6666666666667,500,45,50,69.33,79.51,72.96,83.38,78.2,86.37,80.0,95.5,0.0,60
233.33333333333334,250,40,50,46.92,96.56,50.69,105.27,58.02,114.63,69.18,127.75,0.0,75
700.0,750,35,50,56.71,78.39,59.34,81.12,61.61,85.58,67.09,88.93,0.0,75
186.66666666666669,200,30,50,44.94,102.33,50.49,112.34,58.34,119.8,69.75,131.46,0.0,75
886.6666666666667,950,50,50,58.1,77.07,59.63,80.24,61.27,82.79,62.91,84.52,0.0,75
93.33333333333334,100,25,50,36.47,113.0,47.24,128.99,65.89,141.83,79.99,174.93,1.0,75
466.6666666666667,500,20,50,56.16,80.68,59.43,84.89,62.11,90.7,69.57,98.5,0.0,75
46.66666666666667,50,20,50,27.68,136.78,36.03,162.34,56.81,182.83,80.0,214.31,2.0,75
606.6666666666667,650,30,50,56.34,78.58,60.5,82.44,63.36,88.01,67.69,91.07,0.0,75
385.0,550,45,50,16.61,104.81,17.85,109.21,19.58,115.55,23.55,122.98,0.0,165
606.6666666666667,650,25,50,56.36,79.28,59.78,83.45,63.01,86.28,67.64,91.09,0.0,75
840.0,900,35,50,57.47,76.76,60.06,80.07,61.86,83.31,64.37,87.14,0.0,75
793.3333333333333,850,25,50,57.86,75.36,60.63,79.34,63.04,83.15,65.0,88.11,0.0,75
46.66666666666667,50,40,50,23.58,141.3,29.53,160.41,44.97,184.03,80.0,223.65,0.0,75
513.3333333333333,550,40,50,68.0,78.79,73.58,82.52,76.16,86.63,80.0,89.5,0.0,60
466.6666666666667,500,30,50,54.75,83.07,57.84,86.91,60.08,91.26,65.01,98.87,0.0,75
746.6666666666667,800,20,50,58.0,75.25,60.13,78.57,62.41,81.79,65.8,87.3,0.0,75
513.3333333333333,550,25,50,55.95,81.98,59.6,86.66,62.66,90.27,70.04,98.93,0.0,75
455.0,650,40,50,17.67,98.06,18.85,102.74,19.97,106.0,21.35,114.44,0.0,165
420.0,450,45,50,53.46,86.94,56.91,91.97,61.45,96.28,66.67,105.57,0.0,75
513.3333333333333,550,50,50,55.29,82.5,58.55,87.16,63.51,90.35,67.71,95.56,0.0,75
560.0,600,50,50,55.16,82.29,58.89,85.93,61.17,89.33,65.65,97.88,0.0,75
513.3333333333333,550,20,50,56.55,79.85,58.91,84.21,61.26,87.96,72.13,93.71,0.0,75
653.3333333333333,700,20,50,57.32,77.36,59.85,80.69,63.09,83.7,65.9,87.53,0.0,75
140.0,200,20,50,12.73,121.06,14.68,134.5,19.54,146.75,28.35,178.59,0.0,165
490.0,700,30,50,17.85,95.9,19.41,100.78,20.44,104.73,22.77,107.67,0.0,165
700.0,750,20,50,57.39,76.58,60.26,79.92,62.77,82.21,68.45,86.9,0.0,75
46.66666666666667,50,45,50,20.7,136.17,26.64,159.39,38.66,181.46,55.68,238.08,0.0,75
560.0,600,45,50,54.32,81.79,58.1,86.4,60.59,88.87,64.72,95.7,0.0,75
326.66666666666663,350,45,50,49.46,91.42,53.38,97.01,57.15,101.53,68.36,113.69,0.0,75
793.3333333333333,850,35,50,57.44,77.14,59.58,80.3,62.09,82.6,65.51,86.2,0.0,75
630.0,900,40,50,16.99,95.61,17.89,98.65,18.89,104.31,19.83,108.14,0.0,180
326.66666666666663,350,25,50,52.39,86.92,57.31,93.72,63.53,100.3,71.91,108.49,0.0,75
606.6666666666667,650,20,50,56.95,78.52,59.52,82.77,63.09,86.66,66.75,91.57,0.0,75
140.0,150,45,50,76.0,83.81,79.98,99.15,80.0,105.74,80.0,118.46,4.0,45
700.0,750,45,50,79.99,46.06,80.0,52.39,80.0,56.45,80.0,63.44,0.0,45
653.3333333333333,700,25,50,56.4,78.68,60.36,82.1,64.28,85.51,67.07,88.88,0.0,75
186.66666666666669,200,25,50,47.0,95.55,52.73,105.48,63.87,113.35,71.81,128.99,0.0,75
933.3333333333334,1000,25,50,58.94,74.98,61.41,77.59,64.05,80.88,66.75,82.71,0.0,75
315.0,450,35,50,16.74,106.65,17.76,113.88,19.62,118.64,21.93,126.58,0.0,165
46.66666666666667,50,35,50,23.14,138.2,31.36,157.65,44.6,186.14,79.99,218.28,1.0,75
70.0,100,35,50,6.96,156.46,8.12,168.0,10.51,176.24,17.47,250.1,0.0,180
35.0,50,25,50,5.65,164.31,7.18,189.4,9.35,239.11,30.85,364.01,0.0,165
233.33333333333334,250,50,50,46.8,96.47,50.9,104.02,58.88,109.48,74.07,120.61,0.0,75
933.3333333333334,1000,20,50,48.83,76.87,51.1,80.45,52.94,82.6,57.05,86.54,0.0,90
840.0,900,25,50,59.32,74.69,61.09,77.94,64.17,81.56,67.01,85.09,0.0,75
606.6666666666667,650,35,50,55.74,80.82,59.03,83.87,60.87,87.09,69.43,90.8,0.0,75
245.0,350,50,50,14.48,113.77,15.57,123.35,17.38,129.3,20.18,135.49,0.0,165
933.3333333333334,1000,35,50,58.5,75.96,60.68,78.88,62.53,81.61,65.51,84.45,0.0,75
886.6666666666667,950,40,50,58.59,76.11,60.03,78.69,61.51,81.08,67.27,83.99,0.0,75
420.0,450,40,50,51.79,85.78,55.89,90.55,59.49,94.2,73.26,98.62,0.0,75
46.66666666666667,50,30,50,25.67,135.48,36.66,155.23,48.11,175.61,79.98,218.33,0.0,75
700.0,750,30,50,57.43,78.14,59.14,81.97,62.1,84.41,66.34,90.81,0.0,75
350.0,500,35,50,16.9,102.16,18.3,108.31,20.15,114.87,23.25,122.42,0.0,165
373.33333333333337,400,20,50,53.35,84.71,59.4,91.11,62.51,97.06,69.26,100.76,0.0,75
373.33333333333337,400,25,50,52.91,84.73,58.17,90.62,61.64,94.9,72.1,101.51,0.0,75
420.0,450,20,50,53.25,82.58,57.37,88.25,62.15,93.56,74.32,97.25,0.0,75
373.33333333333337,400,40,50,51.84,86.44,56.31,93.37,61.16,98.39,71.55,102.49,0.0,75
793.3333333333333,850,40,50,57.85,76.7,59.96,80.5,61.6,82.35,66.22,88.74,0.0,75
746.6666666666667,800,45,50,56.66,78.28,58.78,81.49,61.48,84.92,65.46,88.06,0.0,75
466.6666666666667,500,50,50,54.18,83.15,57.87,86.98,61.49,93.29,73.96,99.23,0.0,75
700.0,750,25,50,58.13,78.84,59.75,82.09,62.78,83.96,67.12,89.26,0.0,75
560.0,600,40,50,54.81,82.12,57.9,86.17,60.63,89.89,67.91,93.82,0.0,75
840.0,900,45,50,58.12,76.48,59.87,79.67,62.09,82.49,64.71,84.97,0.0,75
933.3333333333334,1000,50,50,58.88,76.68,60.4,79.67,62.14,81.6,64.8,84.77,0.0,75
373.33333333333337,400,45,50,52.19,87.63,56.13,93.33,59.73,96.8,67.8,101.09,0.0,75
373.33333333333337,400,35,50,52.26,87.09,56.44,92.3,61.44,97.31,73.18,101.35,0.0,75
513.3333333333333,550,30,50,55.04,81.58,58.58,85.72,62.36,89.23,69.99,97.52,0.0,75
933.3333333333334,1000,45,50,58.3,75.57,60.15,78.03,62.2,80.16,64.53,82.55,0.0,75
793.3333333333333,850,20,50,59.05,75.54,60.65,78.48,62.9,80.75,66.68,84.38,0.0,75
466.6666666666667,500,40,50,54.31,84.39,58.64,89.74,61.9,92.51,68.75,97.25,0.0,75
560.0,600,30,50,70.88,76.49,74.01,81.1,76.84,84.07,80.0,90.14,0.0,60
793.3333333333333,850,50,50,47.27,80.99,49.05,84.59,51.71,87.24,54.42,93.4,0.0,90
746.6666666666667,800,35,50,48.06,80.59,49.81,84.21,52.15,86.98,55.96,93.07,0.0,90
420.0,450,30,50,54.51,83.48,57.63,90.19,61.58,94.14,64.89,100.09,0.0,75
326.66666666666663,350,20,50,43.7,90.11,46.72,98.17,54.11,102.52,60.3,107.57,0.0,90
886.6666666666667,950,45,50,47.88,80.4,49.96,82.68,51.64,84.35,54.03,86.81,0.0,90
490.0,700,45,50,16.49,97.11,17.27,102.83,18.15,109.02,21.04,113.47,0.0,180
560.0,600,35,50,56.49,81.0,59.13,85.45,61.76,88.19,65.52,92.31,0.0,75
373.33333333333337,400,30,50,79.99,53.64,80.0,63.79,80.0,76.27,80.0,86.22,0.0,45
186.66666666666669,200,50,50,35.16,109.35,40.72,118.54,46.46,131.83,58.59,150.08,0.0,90
93.33333333333334,100,40,50,33.48,122.54,40.29,136.47,53.3,144.65,74.4,167.3,0.0,75
140.0,200,40,50,10.59,138.28,12.11,148.42,14.23,155.72,20.37,175.85,0.0,180
280.0,300,50,50,46.68,94.98,51.59,100.97,55.58,105.84,65.78,115.22,0.0,75
746.6666666666667,800,25,50,47.56,79.02,49.3,82.61,51.92,87.38,55.52,96.58,0.0,90
793.3333333333333,850,30,50,47.46,79.91,49.28,82.29,51.0,85.21,55.72,91.75,0.0,90
280.0,300,20,50,40.43,95.66,45.16,104.25,50.82,110.02,61.49,118.26,0.0,90
466.6666666666667,500,25,50,54.32,81.46,59.14,86.92,61.53,92.45,70.77,97.96,0.0,75
490.0,700,40,50,17.97,96.58,19.72,101.13,20.54,104.16,21.95,111.43,0.0,165
233.33333333333334,250,20,50,40.53,98.02,44.47,106.94,51.64,115.83,59.96,125.44,0.0,90
700.0,750,50,50,46.04,83.77,48.12,86.72,50.09,88.89,53.45,96.16,0.0,90
233.33333333333334,250,35,50,45.51,97.58,50.16,106.43,58.78,112.99,71.19,121.28,0.0,75
700.0,750,40,50,46.83,80.91,49.71,85.54,51.54,88.47,54.38,91.28,0.0,90
490.0,700,35,50,16.28,99.42,17.3,103.83,18.73,106.04,20.14,117.67,0.0,180
280.0,300,35,50,39.15,97.77,42.65,104.33,46.0,112.57,51.91,119.95,0.0,90
326.66666666666663,350,35,50,41.33,93.7,44.01,99.92,47.03,105.06,57.92,114.3,0.0,90
746.6666666666667,800,40,50,47.68,80.08,49.61,84.2,52.58,88.05,55.14,91.45,0.0,90
886.6666666666667,950,25,50,59.17,75.27,61.28,77.9,63.15,79.82,66.64,84.38,0.0,75
840.0,900,30,50,48.25,78.3,50.49,81.56,53.43,84.1,55.54,89.17,0.0,90
140.0,150,50,50,31.34,115.69,37.02,129.54,42.33,145.88,64.36,168.76,0.0,90
653.3333333333333,700,50,50,45.86,83.34,47.99,86.73,50.43,90.26,55.44,94.36,0.0,90
373.33333333333337,400,50,50,41.91,92.21,44.28,98.15,48.76,104.42,52.85,111.0,0.0,90
933.3333333333334,1000,30,50,48.67,77.98,50.37,81.03,51.68,83.42,53.56,88.68,0.0,90
105.0,150,30,50,9.43,140.9,10.86,153.93,14.2,167.42,20.1,181.16,0.0,180
280.0,300,40,50,39.71,98.48,44.45,105.67,49.75,113.55,53.58,118.29,0.0,90
93.33333333333334,100,45,50,24.46,126.6,30.25,140.86,38.16,154.6,64.04,165.96,0.0,90
886.6666666666667,950,20,50,48.74,76.56,51.02,80.63,52.79,84.39,55.55,87.09,0.0,90
245.0,350,30,50,13.58,114.46,14.77,122.6,16.42,128.69,19.08,145.65,0.0,180
186.66666666666669,200,35,50,35.2,105.44,42.45,114.34,49.68,124.28,56.15,152.4,0.0,90
886.6666666666667,950,30,50,58.28,75.1,60.27,78.14,62.72,80.73,64.68,84.42,0.0,75
840.0,900,50,50,47.23,80.24,49.17,84.15,51.45,87.08,56.01,89.23,0.0,90
746.6666666666667,800,30,50,47.39,80.37,49.61,83.65,52.46,86.46,54.75,91.84,0.0,90
840.0,900,20,50,49.28,77.9,51.26,81.41,52.71,84.63,55.55,88.81,0.0,90
326.66666666666663,350,40,50,40.81,93.43,46.27,101.55,51.48,107.61,58.89,116.58,0.0,90
186.66666666666669,200,45,50,34.26,108.22,40.15,116.52,44.66,122.38,52.43,143.41,0.0,90
560.0,600,25,50,45.26,83.18,47.93,87.13,51.42,89.98,54.11,96.72,0.0,90
233.33333333333334,250,30,50,39.1,100.27,44.04,110.2,50.2,119.07,69.86,127.56,0.0,90
420.0,450,25,50,44.11,87.41,47.32,92.5,51.92,97.53,60.57,102.53,0.0,90
233.33333333333334,250,45,50,38.53,101.71,42.46,108.51,48.39,112.97,60.87,122.98,0.0,90
280.0,300,25,50,40.31,95.24,44.14,101.6,49.17,108.38,61.71,118.06,0.0,90
280.0,300,30,50,79.99,59.7,80.0,71.98,80.0,81.07,80.0,87.56,1.0,45
233.33333333333334,250,25,50,38.61,101.25,42.29,109.64,47.37,116.36,57.7,127.6,0.0,90
793.3333333333333,850,45,50,47.37,80.38,48.85,83.83,50.53,86.98,54.19,89.55,0.0,90
560.0,600,20,50,71.96,75.43,75.53,78.53,79.82,82.75,80.0,87.71,0.0,60
280.0,300,45,50,38.61,100.09,42.85,108.02,48.22,112.32,54.8,124.17,0.0,90
140.0,150,40,50,30.07,115.58,36.16,128.88,42.24,136.93,73.97,161.38,0.0,90
93.33333333333334,100,30,50,26.54,120.33,35.09,140.54,45.63,155.44,76.77,185.82,0.0,90
46.66666666666667,50,50,50,16.0,144.81,20.05,172.85,31.68,199.8,68.61,290.61,0.0,90
606.6666666666667,650,50,50,45.54,84.83,48.13,88.33,50.95,91.2,55.52,97.53,0.0,90
886.6666666666667,950,35,50,48.02,79.04,49.76,82.04,51.81,84.52,55.61,86.97,0.0,90
606.6666666666667,650,45,50,54.98,80.19,58.12,84.68,61.18,87.3,69.05,90.62,0.0,75
233.33333333333334,250,40,50,36.82,100.75,39.92,108.04,47.1,114.14,59.45,125.38,0.0,90
700.0,750,35,50,46.79,82.86,48.62,86.38,50.88,89.62,52.85,92.58,0.0,90
93.33333333333334,100,50,50,25.78,126.43,31.48,142.47,45.81,161.19,79.98,193.2,0.0,90
513.3333333333333,550,35,50,45.66,84.21,48.8,88.32,51.17,92.01,60.05,97.17,0.0,90
140.0,150,25,50,33.79,110.2,39.94,121.85,50.57,133.51,74.86,143.53,0.0,90
186.66666666666669,200,30,50,35.09,107.95,41.46,118.83,46.48,125.1,62.74,137.75,0.0,90
93.33333333333334,100,25,50,26.92,122.46,35.43,141.92,44.13,158.7,75.3,200.57,0.0,90
886.6666666666667,950,50,50,47.74,79.59,49.88,83.55,51.03,85.99,53.13,88.68,0.0,90
793.3333333333333,850,25,50,48.99,77.27,51.41,82.08,53.14,85.84,57.76,88.64,0.0,90
840.0,900,35,50,48.03,79.21,50.72,82.73,52.37,84.97,55.18,88.21,0.0,90
46.66666666666667,50,20,50,20.6,143.64,29.33,166.6,44.51,200.9,80.0,305.81,0.0,90
46.66666666666667,50,40,50,17.3,147.08,23.16,164.94,31.51,200.65,79.99,266.39,0.0,90
606.6666666666667,650,25,50,46.74,82.07,49.95,85.59,52.51,88.79,56.67,91.56,0.0,90
560.0,600,50,50,44.39,86.8,47.4,90.8,50.1,94.63,53.19,99.21,0.0,90
746.6666666666667,800,50,50,79.99,42.48,80.0,50.21,80.0,54.0,80.0,66.48,0.0,45
466.6666666666667,500,20,50,46.08,83.47,49.2,89.75,52.09,94.04,59.89,100.08,0.0,90
746.6666666666667,800,20,50,47.37,78.5,49.61,81.67,51.93,84.16,54.81,88.83,0.0,90
606.6666666666667,650,30,50,46.72,82.25,49.25,85.5,51.94,89.14,55.7,93.75,0.0,90
466.6666666666667,500,30,50,44.7,86.26,48.53,91.49,51.74,98.86,56.69,102.28,0.0,90
420.0,450,45,50,42.49,90.31,44.58,96.92,49.79,102.96,56.61,108.28,0.0,90
513.3333333333333,550,25,50,45.98,82.83,48.64,88.58,50.65,93.86,54.24,97.64,0.0,90
933.3333333333334,1000,40,50,79.99,38.39,80.0,43.62,80.0,46.34,80.0,53.2,0.0,45
513.3333333333333,550,40,50,53.76,80.63,57.26,88.01,61.43,91.35,66.56,97.24,0.0,75
513.3333333333333,550,50,50,44.08,87.41,45.93,90.86,49.59,97.54,53.18,102.67,0.0,90
420.0,450,50,50,42.58,90.1,45.64,94.34,48.69,99.2,56.24,105.27,0.0,90
46.66666666666667,50,45,50,19.08,147.7,24.17,173.14,41.39,204.18,79.98,354.08,1.0,90
70.0,100,20,50,7.96,147.6,10.0,170.36,12.04,195.9,22.1,255.11,0.0,180
653.3333333333333,700,20,50,47.69,79.47,49.68,83.15,51.07,87.94,54.53,91.37,0.0,90
326.66666666666663,350,45,50,40.9,92.96,43.83,99.96,48.77,107.75,55.86,118.2,0.0,90
513.3333333333333,550,20,50,45.93,83.28,48.68,89.38,51.86,93.03,56.3,97.86,0.0,90
606.6666666666667,650,20,50,47.96,81.42,50.07,84.51,52.7,88.04,56.86,94.5,0.0,90
466.6666666666667,500,45,50,53.31,85.06,56.68,88.5,59.8,93.04,64.86,97.53,0.0,75
105.0,150,35,50,8.89,146.22,10.5,155.34,12.42,168.81,20.82,197.17,0.0,180
700.0,750,20,50,48.55,78.51,50.66,82.77,53.55,86.17,56.37,91.42,0.0,90
560.0,600,45,50,45.21,84.81,47.51,88.97,49.8,93.05,54.01,96.22,0.0,90
105.0,150,20,50,10.29,138.21,12.41,155.53,14.82,175.97,21.63,196.5,0.0,180
46.66666666666667,50,35,50,17.19,146.29,25.61,169.1,37.31,199.94,79.99,310.91,2.0,90
186.66666666666669,200,25,50,36.36,101.56,43.69,112.72,51.59,121.3,63.86,129.78,0.0,90
793.3333333333333,850,35,50,47.26,81.03,49.16,84.38,51.3,86.73,53.23,90.54,0.0,90
653.3333333333333,700,25,50,47.17,81.41,49.74,85.16,51.39,88.28,57.28,93.26,0.0,90
933.3333333333334,1000,20,50,38.49,78.37,40.39,82.97,41.68,85.11,44.97,87.71,0.0,115
840.0,900,25,50,48.07,78.15,50.37,81.92,52.25,85.6,54.58,89.79,0.0,90
606.6666666666667,650,35,50,46.45,82.95,49.63,87.32,51.7,91.33,57.89,94.68,0.0,90
933.3333333333334,1000,25,50,49.37,77.35,50.71,79.83,52.18,82.6,53.66,86.38,0.0,90
455.0,650,40,50,15.95,102.87,16.99,108.7,18.04,112.89,20.63,121.0,0.0,180
326.66666666666663,350,25,50,42.45,91.09,45.26,97.28,51.13,102.6,65.64,108.37,0.0,90
385.0,550,45,50,15.03,105.32,16.39,110.19,17.59,115.02,19.36,119.9,0.0,180
933.3333333333334,1000,35,50,48.28,79.12,49.79,81.59,50.94,84.27,53.77,87.71,0.0,90
700.0,750,30,50,46.48,79.69,49.44,83.53,52.39,87.55,55.65,94.58,0.0,90
140.0,150,45,50,52.54,104.97,60.1,113.32,69.6,119.39,80.0,131.42,0.0,60
840.0,900,40,50,79.99,41.17,80.0,45.8,80.0,50.26,80.0,56.66,0.0,45
466.6666666666667,500,50,50,44.53,87.15,47.74,92.01,50.5,96.81,53.94,102.46,0.0,90
233.33333333333334,250,50,50,37.01,104.23,41.57,112.7,48.15,118.45,58.11,130.93,0.0,90
373.33333333333337,400,40,50,42.82,93.06,46.12,96.84,48.85,105.04,56.17,117.97,0.0,90
373.33333333333337,400,20,50,44.07,87.21,47.48,92.41,52.67,96.42,60.28,106.43,0.0,90
420.0,450,40,50,43.38,89.99,46.75,95.84,50.03,99.37,57.88,105.91,0.0,90
490.0,700,30,50,16.77,97.01,17.68,102.73,18.6,107.4,20.06,112.67,0.0,180
746.6666666666667,800,45,50,46.47,81.81,48.43,84.43,49.58,88.17,55.51,91.05,0.0,90
886.6666666666667,950,40,50,48.32,79.64,50.1,82.35,52.24,84.97,54.28,90.1,0.0,90
46.66666666666667,50,30,50,18.23,134.89,24.46,161.21,42.38,181.96,80.0,287.32,2.0,90
420.0,450,20,50,44.57,87.17,48.66,91.96,54.52,95.74,59.45,101.91,0.0,90
700.0,750,45,50,71.79,75.2,74.29,78.95,77.05,81.96,79.99,85.47,0.0,60
700.0,750,25,50,46.86,80.45,48.92,84.5,51.08,89.18,57.01,94.87,0.0,90
560.0,600,40,50,45.29,85.14,46.92,89.98,50.32,92.69,55.33,97.11,0.0,90
140.0,200,20,50,11.35,131.31,12.54,143.28,15.43,157.79,19.28,179.15,0.0,180
793.3333333333333,850,40,50,47.85,79.81,50.03,83.04,51.15,86.31,55.21,90.14,0.0,90
373.33333333333337,400,35,50,42.63,90.37,46.24,95.57,49.83,102.53,62.82,109.17,0.0,90
373.33333333333337,400,25,50,43.06,90.18,48.06,95.92,51.33,99.48,56.93,112.17,0.0,90
93.33333333333334,100,35,50,66.04,94.85,79.98,113.61,79.99,124.66,80.0,132.3,3.0,45
315.0,450,35,50,14.58,110.51,15.71,116.5,17.11,122.09,19.82,132.75,0.0,180
35.0,50,25,50,4.34,161.71,5.37,183.97,6.89,217.39,12.46,287.5,0.0,180
466.6666666666667,500,40,50,43.62,87.52,46.38,92.28,50.05,97.14,58.28,102.07,0.0,90
933.3333333333334,1000,50,50,47.96,79.67,50.06,82.29,51.5,84.23,54.02,89.09,0.0,90
420.0,450,30,50,43.72,87.72,47.58,94.05,52.6,99.56,66.8,103.54,0.0,90
840.0,900,45,50,47.94,79.82,49.74,82.33,51.69,84.62,55.93,88.94,0.0,90
933.3333333333334,1000,45,50,48.86,77.74,50.98,80.29,52.58,83.19,54.3,87.43,0.0,90
793.3333333333333,850,20,50,48.71,78.02,50.26,81.82,51.77,84.23,59.64,88.01,0.0,90
513.3333333333333,550,30,50,44.98,85.58,49.4,89.42,52.36,92.16,62.84,95.58,0.0,90
373.33333333333337,400,45,50,41.59,91.07,45.06,97.85,48.52,104.53,55.13,112.63,0.0,90
793.3333333333333,850,50,50,36.28,85.04,38.41,89.44,39.74,92.28,43.64,95.9,0.0,115
746.6666666666667,800,35,50,35.76,86.06,37.76,90.21,39.72,92.94,42.94,97.05,0.0,115
186.66666666666669,200,50,50,25.2,116.79,28.19,128.12,32.2,134.88,47.24,156.47,0.0,115
326.66666666666663,350,20,50,31.79,97.21,35.0,101.96,38.83,108.41,44.76,117.26,0.0,115
886.6666666666667,950,45,50,36.96,84.57,38.3,87.14,39.96,89.65,41.64,95.91,0.0,115
746.6666666666667,800,25,50,37.02,83.38,38.8,86.79,40.54,90.64,42.9,96.87,0.0,115
245.0,350,50,50,12.98,118.94,13.76,125.68,15.46,131.14,17.92,146.52,0.0,180
793.3333333333333,850,30,50,37.21,84.85,38.7,87.63,40.48,90.17,44.19,99.97,0.0,115
350.0,500,35,50,14.66,105.28,15.81,113.34,17.02,117.76,20.3,126.12,0.0,180
233.33333333333334,250,20,50,29.48,107.32,33.7,114.7,36.46,124.78,40.06,138.58,0.0,115
280.0,300,20,50,31.3,100.34,35.46,108.55,40.75,115.92,56.72,121.74,0.0,115
280.0,300,50,50,38.46,100.58,42.02,106.6,46.11,112.65,54.19,121.68,0.0,90
560.0,600,30,50,55.35,79.39,58.16,83.37,62.19,86.72,69.27,91.14,0.0,75
700.0,750,50,50,36.15,88.5,37.73,92.26,39.71,97.37,40.78,99.63,0.0,115
466.6666666666667,500,25,50,45.05,85.37,48.36,91.51,50.62,96.5,55.34,100.46,0.0,90
746.6666666666667,800,40,50,36.34,84.77,38.28,90.12,40.94,92.98,42.64,96.29,0.0,115
700.0,750,40,50,35.75,87.19,37.76,89.79,39.17,94.34,42.93,100.29,0.0,115
93.33333333333334,100,40,50,25.21,126.27,32.6,139.93,39.28,150.72,55.58,184.77,0.0,90
280.0,300,35,50,30.15,103.39,33.49,111.67,39.06,118.04,46.63,124.87,0.0,115
653.3333333333333,700,45,50,79.99,42.88,80.0,51.32,80.0,56.62,80.0,66.28,0.0,45
560.0,600,35,50,45.94,82.64,48.55,88.62,51.61,93.92,56.18,99.93,0.0,90
933.3333333333334,1000,30,50,38.13,80.68,39.97,84.7,40.82,87.61,42.64,92.34,0.0,115
326.66666666666663,350,35,50,30.44,98.63,33.28,105.22,37.54,112.39,44.71,122.46,0.0,115
840.0,900,30,50,37.16,83.57,38.77,86.7,40.08,89.53,44.26,92.85,0.0,115
653.3333333333333,700,50,50,35.35,85.97,37.54,91.39,39.44,95.78,42.48,100.16,0.0,115
140.0,150,50,50,21.73,129.07,25.2,141.11,29.27,152.63,46.25,188.64,0.0,115
233.33333333333334,250,35,50,37.55,100.58,42.69,108.18,46.93,113.9,53.5,126.92,0.0,90
280.0,300,40,50,30.18,107.17,32.95,115.22,37.03,121.3,43.07,133.74,0.0,115
373.33333333333337,400,30,50,69.98,81.42,74.2,86.68,78.66,90.18,80.0,99.65,0.0,60
186.66666666666669,200,40,50,79.98,73.55,79.99,87.48,80.0,93.04,80.0,107.3,1.0,45
93.33333333333334,100,45,50,18.76,137.47,23.4,151.67,31.94,175.73,46.13,237.46,0.0,115
373.33333333333337,400,50,50,31.21,99.01,33.89,104.89,37.04,110.51,40.66,117.19,0.0,115
326.66666666666663,350,40,50,29.61,102.08,32.49,109.71,36.0,116.71,44.41,121.77,0.0,115
840.0,900,50,50,36.52,85.15,37.88,88.5,40.01,91.47,41.03,94.42,0.0,115
886.6666666666667,950,20,50,37.85,80.76,39.49,83.5,40.52,86.29,42.07,90.8,0.0,115
233.33333333333334,250,30,50,28.57,106.15,31.9,115.88,37.13,126.19,43.14,137.28,0.0,115
746.6666666666667,800,30,50,36.51,86.02,38.3,88.99,39.35,91.59,42.16,96.0,0.0,115
886.6666666666667,950,25,50,49.14,77.53,51.34,80.84,53.07,83.13,55.52,85.9,0.0,90
420.0,450,25,50,33.92,94.2,36.32,99.83,38.76,105.27,43.42,113.82,0.0,115
186.66666666666669,200,35,50,24.79,113.09,31.49,121.44,37.76,133.73,52.74,156.23,0.0,115
886.6666666666667,950,30,50,48.71,79.22,50.62,81.26,52.27,83.7,54.76,85.63,0.0,90
560.0,600,25,50,35.4,88.38,37.75,94.33,39.57,97.06,44.5,102.87,0.0,115
490.0,700,40,50,15.91,98.43,17.35,103.68,18.52,108.27,19.99,116.05,0.0,180
280.0,300,25,50,31.15,100.5,34.46,107.64,36.49,115.18,50.63,129.12,0.0,115
840.0,900,20,50,37.84,80.26,39.62,85.59,42.18,88.26,43.95,92.57,0.0,115
653.3333333333333,700,35,50,79.99,44.05,80.0,52.14,80.0,55.35,80.0,61.03,0.0,45
186.66666666666669,200,45,50,25.12,113.67,28.92,124.56,34.29,136.17,42.2,159.76,0.0,115
233.33333333333334,250,45,50,27.45,110.13,29.72,119.35,32.69,128.05,43.27,139.56,0.0,115
560.0,600,20,50,57.96,78.85,61.16,83.02,64.12,87.37,68.76,90.49,0.0,75
793.3333333333333,850,45,50,36.63,86.27,38.47,89.27,40.79,92.25,43.48,97.18,0.0,115
326.66666666666663,350,30,50,79.99,53.84,79.99,72.29,80.0,80.68,80.0,88.61,0.0,45
140.0,150,40,50,21.6,123.81,27.06,136.46,28.97,146.73,40.77,154.27,0.0,115
140.0,150,30,50,77.81,86.81,79.99,97.89,80.0,106.2,80.0,112.45,3.0,45
280.0,300,45,50,28.76,104.83,32.25,113.42,37.4,117.74,45.48,128.86,0.0,115
93.33333333333334,100,30,50,18.37,134.84,24.15,149.1,32.42,162.28,63.9,228.78,0.0,115
606.6666666666667,650,50,50,35.03,89.32,36.74,93.85,38.93,99.38,41.65,102.54,0.0,115
700.0,750,35,50,35.85,86.85,37.89,90.53,39.9,94.19,45.37,97.28,0.0,115
46.66666666666667,50,50,50,10.46,159.72,14.7,177.74,19.57,199.36,27.23,252.51,0.0,115
93.33333333333334,100,50,50,16.89,139.85,22.23,153.79,27.52,169.75,46.24,211.28,0.0,115
233.33333333333334,250,40,50,27.29,108.51,29.92,115.93,33.83,123.37,49.85,132.58,0.0,115
233.33333333333334,250,25,50,29.29,103.99,33.08,113.66,37.91,122.16,43.97,133.9,0.0,115
513.3333333333333,550,35,50,34.46,91.29,36.93,98.0,38.95,101.73,41.46,106.25,0.0,115
560.0,600,50,50,33.72,92.9,36.6,97.8,38.63,101.64,41.86,107.18,0.0,115
186.66666666666669,200,30,50,25.54,115.26,29.65,123.71,35.01,131.45,45.98,145.58,0.0,115
886.6666666666667,950,35,50,37.09,82.34,38.38,86.36,39.93,89.2,41.21,93.0,0.0,115
46.66666666666667,50,20,50,14.84,148.73,20.0,174.24,31.03,210.47,79.98,319.15,0.0,115
140.0,150,25,50,23.95,122.34,29.78,137.37,37.93,153.44,52.55,172.03,0.0,115
46.66666666666667,50,40,50,11.0,154.95,14.14,184.21,21.62,207.49,40.14,292.01,0.0,115
93.33333333333334,100,25,50,18.95,131.16,22.25,146.31,30.29,160.76,69.66,204.56,0.0,115
840.0,900,35,50,36.75,84.56,38.67,87.88,39.86,89.85,42.24,96.18,0.0,115
513.3333333333333,550,25,50,35.02,89.74,38.18,94.66,40.35,99.49,44.16,106.07,0.0,115
466.6666666666667,500,20,50,34.85,88.41,37.03,94.36,39.85,99.43,44.19,106.81,0.0,115
606.6666666666667,650,30,50,35.28,87.08,37.76,92.38,39.98,96.68,43.29,101.86,0.0,115
793.3333333333333,850,25,50,37.58,83.05,39.44,87.84,41.23,91.17,44.39,95.03,0.0,115
746.6666666666667,800,20,50,37.35,82.39,39.22,86.58,41.07,91.13,44.02,94.7,0.0,115
886.6666666666667,950,50,50,36.94,82.71,38.38,85.0,40.8,88.83,44.07,94.38,0.0,115
280.0,300,30,50,64.09,85.01,70.54,91.34,76.05,97.57,79.99,103.2,0.0,60
513.3333333333333,550,40,50,45.21,85.23,48.38,90.3,51.4,94.06,57.85,98.76,0.0,90
466.6666666666667,500,30,50,34.21,89.76,36.89,96.92,39.29,103.29,42.75,110.92,0.0,115
46.66666666666667,50,45,50,10.77,155.79,14.87,175.64,21.45,209.34,78.21,339.33,1.0,115
653.3333333333333,700,20,50,36.81,84.99,38.48,88.78,40.68,93.3,44.6,97.71,0.0,115
606.6666666666667,650,20,50,36.14,86.86,38.51,91.17,41.13,95.34,45.87,101.78,0.0,115
420.0,450,45,50,31.74,96.4,34.72,102.35,37.96,110.29,45.77,116.87,0.0,115
513.3333333333333,550,50,50,34.08,91.27,36.75,96.03,39.38,101.52,42.15,109.29,0.0,115
700.0,750,20,50,37.1,83.62,39.3,87.67,40.6,90.8,43.42,94.92,0.0,115
513.3333333333333,550,20,50,34.78,87.07,38.13,92.94,41.38,99.8,44.7,102.95,0.0,115
186.66666666666669,200,25,50,25.36,115.71,29.89,126.99,36.3,135.24,42.46,146.17,0.0,115
653.3333333333333,700,25,50,36.52,85.24,38.67,90.01,40.66,93.76,43.54,96.63,0.0,115
326.66666666666663,350,45,50,30.58,102.98,33.93,109.82,36.45,116.78,42.47,126.75,0.0,115
46.66666666666667,50,35,50,11.57,146.74,15.85,166.86,21.49,206.17,61.36,289.86,0.0,115
793.3333333333333,850,35,50,37.01,84.77,38.37,88.42,40.06,91.48,43.48,94.86,0.0,115
606.6666666666667,650,45,50,45.47,84.88,47.82,88.04,49.81,89.81,52.01,96.58,0.0,90
606.6666666666667,650,25,50,36.5,86.41,38.77,91.86,40.71,96.67,43.79,102.23,0.0,115
93.33333333333334,100,20,50,68.8,92.69,79.98,107.25,79.99,116.73,80.0,125.33,5.0,45
560.0,600,45,50,34.2,91.9,36.41,96.55,38.82,100.41,46.24,107.63,0.0,115
933.3333333333334,1000,20,50,28.99,84.41,30.74,88.91,31.95,91.84,34.74,96.26,0.0,150
466.6666666666667,500,45,50,43.02,89.11,45.86,93.44,47.94,97.09,52.75,104.39,0.0,90
420.0,450,50,50,32.06,98.1,34.27,104.51,36.91,110.83,41.69,116.45,0.0,115
840.0,900,25,50,37.77,82.22,39.69,85.27,41.15,88.83,44.23,93.59,0.0,115
933.3333333333334,1000,40,50,73.29,72.67,75.51,75.78,78.08,77.13,79.99,79.55,0.0,60
933.3333333333334,1000,35,50,37.57,81.55,39.55,84.59,40.71,87.83,43.9,91.49,0.0,115
606.6666666666667,650,35,50,35.08,88.41,37.6,93.96,40.35,98.06,43.26,106.36,0.0,115
700.0,750,30,50,36.69,85.4,38.02,89.71,39.59,93.94,44.02,100.8,0.0,115
140.0,150,35,50,79.77,84.01,79.99,96.4,80.0,102.12,80.0,113.4,5.0,45
746.6666666666667,800,50,50,71.46,74.6,74.07,77.77,76.52,78.96,79.98,85.67,0.0,60
933.3333333333334,1000,25,50,38.45,81.89,40.14,84.97,41.44,88.48,44.6,93.52,0.0,115
373.33333333333337,400,20,50,33.56,93.03,37.39,100.31,41.0,107.48,46.9,121.88,0.0,115
140.0,150,20,50,78.69,79.59,79.99,92.22,80.0,99.62,80.0,117.62,5.0,45
746.6666666666667,800,45,50,35.99,85.25,37.99,90.29,40.37,93.36,42.06,97.0,0.0,115
466.6666666666667,500,50,50,33.05,93.84,34.94,98.96,36.28,106.02,39.87,111.61,0.0,115
700.0,750,25,50,36.85,84.17,38.52,88.14,41.2,92.21,45.83,95.96,0.0,115
420.0,450,20,50,33.33,94.65,36.96,100.91,39.73,109.65,43.82,115.52,0.0,115
793.3333333333333,850,40,50,35.61,86.13,37.99,90.11,39.65,94.17,44.18,97.46,0.0,115
420.0,450,40,50,32.5,95.55,35.44,100.22,38.4,104.73,42.21,113.98,0.0,115
326.66666666666663,350,25,50,31.0,99.11,34.16,106.43,37.56,112.26,42.26,123.38,0.0,115
233.33333333333334,250,50,50,26.96,111.8,30.24,121.3,35.7,128.57,42.52,137.91,0.0,115
606.6666666666667,650,40,50,79.99,43.23,80.0,52.12,80.0,57.97,80.0,67.44,0.0,45
886.6666666666667,950,40,50,36.96,83.2,38.66,86.35,40.35,90.15,44.43,93.91,0.0,115
46.66666666666667,50,30,50,12.27,147.14,15.52,167.59,25.61,197.79,51.22,297.57,0.0,115
560.0,600,40,50,34.22,89.73,36.91,94.04,39.43,98.54,43.96,103.94,0.0,115
373.33333333333337,400,35,50,31.6,98.73,35.12,105.79,38.44,112.09,47.22,122.48,0.0,115
840.0,900,40,50,73.34,73.83,75.63,76.76,77.12,79.22,79.98,81.27,0.0,60
140.0,150,45,50,39.16,112.39,45.84,122.31,53.72,132.54,65.34,147.23,0.0,75
933.3333333333334,1000,45,50,37.0,84.21,38.46,87.42,39.9,90.23,43.88,96.22,0.0,115
373.33333333333337,400,25,50,33.03,94.97,36.07,102.1,38.7,109.99,45.2,113.98,0.0,115
513.3333333333333,550,30,50,34.68,88.86,36.89,95.38,39.32,100.2,42.0,104.92,0.0,115
373.33333333333337,400,40,50,31.73,96.41,34.49,102.25,37.55,107.32,46.02,117.86,0.0,115
420.0,450,30,50,32.67,93.88,35.16,101.8,36.95,106.0,41.21,111.24,0.0,115
513.3333333333333,550,45,50,79.99,48.09,80.0,57.05,80.0,66.57,80.0,78.61,0.0,45
793.3333333333333,850,20,50,37.22,81.14,38.62,85.24,40.81,88.25,46.82,93.43,0.0,115
466.6666666666667,500,40,50,33.56,92.36,35.98,99.13,37.94,102.46,45.06,114.31,0.0,115
700.0,750,45,50,56.36,79.11,58.63,83.19,60.71,86.33,62.89,91.79,0.0,75
746.6666666666667,800,35,50,27.43,90.08,28.71,95.14,29.95,98.96,31.38,105.43,0.0,150
933.3333333333334,1000,50,50,37.29,82.95,38.47,85.55,39.54,88.56,42.0,93.03,0.0,115
326.66666666666663,350,20,50,23.18,105.03,26.58,112.69,29.59,116.89,35.98,129.78,0.0,150
793.3333333333333,850,50,50,27.17,92.72,28.46,96.0,30.75,98.71,33.51,102.69,0.0,150
840.0,900,45,50,37.28,84.43,38.44,88.08,40.57,91.78,43.48,95.09,0.0,115
746.6666666666667,800,25,50,27.65,87.91,28.92,92.54,30.72,96.88,33.46,101.76,0.0,150
793.3333333333333,850,30,50,27.34,90.15,29.01,93.44,30.72,97.85,32.67,107.41,0.0,150
46.66666666666667,50,25,50,52.26,114.04,73.86,129.79,79.99,155.14,80.0,187.18,5.0,45
233.33333333333334,250,20,50,21.25,113.89,24.13,125.05,27.05,131.42,36.99,146.16,0.0,150
373.33333333333337,400,45,50,31.53,98.49,34.6,104.35,37.94,108.17,45.84,116.88,0.0,115
280.0,300,50,50,28.05,105.78,30.91,114.94,34.64,123.08,45.31,136.89,0.0,115
186.66666666666669,200,50,50,16.9,129.64,19.97,140.17,23.62,153.96,30.28,186.54,0.0,150
653.3333333333333,700,30,50,79.99,42.92,80.0,49.34,80.0,53.29,80.0,62.71,0.0,45
93.33333333333334,100,35,50,44.42,109.72,57.59,124.32,74.07,135.53,80.0,166.94,2.0,60
280.0,300,20,50,22.35,109.35,25.37,120.13,28.69,125.66,34.84,134.13,0.0,150
886.6666666666667,950,45,50,27.84,89.04,29.07,93.62,29.99,96.09,31.31,99.85,0.0,150
186.66666666666669,200,20,50,79.98,78.31,79.99,88.64,80.0,96.51,80.0,106.01,3.0,45
746.6666666666667,800,40,50,26.95,92.45,28.68,96.55,29.64,100.64,32.08,104.95,0.0,150
420.0,450,35,50,79.99,52.54,80.0,60.98,80.0,68.12,80.0,73.05,0.0,45
700.0,750,40,50,26.62,94.02,28.16,98.47,29.73,103.36,32.22,109.14,0.0,150
700.0,750,50,50,26.52,93.55,27.84,98.12,29.87,102.96,30.73,107.06,0.0,150
326.66666666666663,350,50,50,79.99,62.86,80.0,74.34,80.0,84.5,80.0,88.62,0.0,45
466.6666666666667,500,25,50,33.74,92.2,36.33,96.74,39.22,103.46,46.36,108.08,0.0,115
933.3333333333334,1000,30,50,28.63,87.24,30.17,91.02,31.12,93.41,34.66,97.25,0.0,150
326.66666666666663,350,35,50,21.05,110.82,23.86,120.01,26.68,126.85,29.27,130.35,0.0,150
560.0,600,30,50,46.5,82.81,49.15,87.23,52.05,91.4,57.05,97.47,0.0,90
280.0,300,35,50,21.12,116.01,23.88,123.31,26.83,129.9,36.21,140.5,0.0,150
280.0,300,40,50,21.06,114.82,23.2,122.87,25.59,134.03,31.33,142.93,0.0,150
140.0,150,50,50,14.47,134.68,17.29,146.38,21.71,154.91,34.69,179.05,0.0,150
840.0,900,30,50,28.23,88.96,29.4,92.91,30.9,95.87,32.2,101.19,0.0,150
326.66666666666663,350,40,50,22.04,108.85,24.35,117.03,27.42,123.73,32.03,131.97,0.0,150
653.3333333333333,700,50,50,26.28,95.1,27.81,99.55,29.61,103.78,32.22,110.39,0.0,150
466.6666666666667,500,35,50,79.99,50.2,80.0,60.24,80.0,72.5,80.0,78.9,0.0,45
840.0,900,50,50,27.37,91.6,28.65,94.4,30.94,97.87,32.57,103.36,0.0,150
233.33333333333334,250,30,50,20.16,117.51,22.66,127.14,28.21,134.09,32.65,150.72,0.0,150
93.33333333333334,100,45,50,11.17,151.49,13.99,164.28,16.03,176.06,21.0,208.27,0.0,150
746.6666666666667,800,30,50,27.18,92.09,29.06,95.39,29.96,98.01,32.48,103.78,0.0,150
93.33333333333334,100,40,50,18.43,135.47,23.64,152.91,29.05,169.81,62.03,196.6,0.0,115
886.6666666666667,950,20,50,28.78,86.08,30.49,90.73,31.58,94.07,33.92,97.65,0.0,150
280.0,300,25,50,21.42,112.39,24.55,120.58,29.88,128.68,38.89,139.18,0.0,150
560.0,600,25,50,26.77,94.71,28.34,100.24,30.86,104.31,34.19,110.48,0.0,150
373.33333333333337,400,50,50,22.91,108.6,25.05,115.39,26.76,120.96,33.04,130.78,0.0,150
840.0,900,20,50,28.51,86.21,30.3,91.02,32.31,94.37,33.78,102.77,0.0,150
233.33333333333334,250,35,50,28.55,108.66,32.84,115.13,36.54,124.08,44.01,134.98,0.0,115
560.0,600,35,50,34.89,92.2,36.91,95.92,39.3,99.2,42.13,104.31,0.0,115
653.3333333333333,700,45,50,70.9,76.59,75.09,79.41,78.34,81.97,80.0,85.18,0.0,60
186.66666666666669,200,35,50,16.86,126.35,20.09,135.13,24.86,147.03,33.89,166.04,0.0,150
886.6666666666667,950,30,50,37.71,83.08,39.26,86.44,40.51,88.87,44.69,92.99,0.0,115
233.33333333333334,250,45,50,19.38,117.09,22.44,126.86,25.04,136.34,35.44,145.76,0.0,150
420.0,450,25,50,25.11,101.55,26.98,107.25,29.21,113.36,33.31,121.49,0.0,150
886.6666666666667,950,25,50,37.54,81.67,39.06,85.44,40.84,88.85,42.89,91.91,0.0,115
793.3333333333333,850,45,50,26.97,92.43,28.17,96.64,29.59,100.23,33.71,103.77,0.0,150
280.0,300,45,50,20.09,115.81,23.18,123.73,25.08,132.09,32.67,140.22,0.0,150
373.33333333333337,400,30,50,51.87,85.26,56.25,91.91,61.2,96.0,71.29,102.83,0.0,75
186.66666666666669,200,40,50,59.3,93.0,67.43,104.47,79.72,112.9,80.0,121.51,0.0,60
186.66666666666669,200,45,50,18.16,123.61,20.82,135.65,23.45,149.0,35.75,160.67,0.0,150
140.0,150,40,50,15.11,139.49,17.54,153.99,20.45,165.67,43.76,175.75,0.0,150
233.33333333333334,250,40,50,19.95,119.19,22.96,128.83,25.35,134.97,30.61,143.74,0.0,150
700.0,750,35,50,26.96,92.68,28.61,96.88,29.83,100.43,32.1,106.49,0.0,150
560.0,600,20,50,46.51,81.27,49.56,86.26,52.81,91.69,56.63,96.94,0.0,90
46.66666666666667,50,50,50,6.91,163.11,8.63,181.61,12.64,217.63,34.67,356.45,0.0,150
233.33333333333334,250,25,50,20.0,117.57,22.38,125.04,26.71,135.97,33.87,149.86,0.0,150
93.33333333333334,100,50,50,11.61,150.45,13.74,162.14,16.72,177.06,25.63,218.49,0.0,150
513.3333333333333,550,25,50,26.2,95.8,27.63,99.23,28.98,104.45,33.93,113.73,0.0,150
513.3333333333333,550,35,50,25.63,98.6,27.2,104.05,29.06,108.12,32.93,115.4,0.0,150
93.33333333333334,100,30,50,12.1,146.52,15.79,164.93,22.0,179.1,55.09,222.68,0.0,150
606.6666666666667,650,50,50,26.19,96.42,27.46,101.6,29.01,105.14,31.46,111.75,0.0,150
560.0,600,50,50,25.1,98.86,26.68,102.91,27.78,108.85,30.3,116.56,0.0,150
466.6666666666667,500,20,50,25.42,97.74,27.71,102.28,29.96,108.51,33.58,119.32,0.0,150
186.66666666666669,200,30,50,17.9,123.11,21.76,134.12,24.6,144.92,34.36,155.28,0.0,150
140.0,150,30,50,55.29,98.41,63.66,110.02,74.25,120.84,80.0,133.33,0.0,60
886.6666666666667,950,35,50,28.14,88.82,29.38,92.73,30.47,95.4,32.64,101.44,0.0,150
653.3333333333333,700,35,50,71.28,74.92,75.57,78.13,78.99,80.88,79.99,84.69,0.0,60
653.3333333333333,700,20,50,27.67,89.71,29.37,94.77,31.02,98.58,36.61,106.05,0.0,150
46.66666666666667,50,40,50,7.33,162.52,8.72,178.96,11.64,194.43,31.26,314.24,0.0,150
886.6666666666667,950,50,50,27.59,90.19,28.98,94.21,29.86,97.09,32.07,102.65,0.0,150
46.66666666666667,50,20,50,8.74,164.37,11.13,185.0,15.52,210.52,34.75,296.09,0.0,150
653.3333333333333,700,40,50,79.99,45.52,80.0,53.84,80.0,58.07,80.0,65.54,0.0,45
93.33333333333334,100,25,50,13.09,147.49,17.37,165.09,21.89,180.38,26.86,216.71,0.0,150
793.3333333333333,850,25,50,27.84,89.05,29.1,92.9,30.29,95.33,32.66,102.23,0.0,150
606.6666666666667,650,30,50,26.58,93.89,28.6,99.56,30.05,105.41,33.36,108.78,0.0,150
840.0,900,35,50,28.12,88.9,29.7,92.05,30.6,96.34,33.38,99.31,0.0,150
513.3333333333333,550,40,50,34.01,92.48,36.45,98.12,39.27,103.2,45.6,109.2,0.0,115
46.66666666666667,50,45,50,7.38,168.9,9.19,194.05,14.84,224.39,46.98,317.68,0.0,150
326.66666666666663,350,30,50,66.66,81.62,72.18,87.37,78.43,95.2,79.99,102.14,0.0,60
746.6666666666667,800,20,50,28.67,86.4,30.18,90.93,31.93,94.76,37.5,100.12,0.0,150
840.0,900,25,50,28.52,86.72,29.96,91.92,31.32,94.86,33.93,100.54,0.0,150
326.66666666666663,350,45,50,22.27,108.13,24.03,117.71,26.93,123.64,36.41,129.3,0.0,150
793.3333333333333,850,35,50,27.32,89.5,28.64,93.47,29.94,97.8,33.02,101.47,0.0,150
606.6666666666667,650,20,50,27.29,91.07,29.14,96.62,30.85,101.95,35.2,107.18,0.0,150
186.66666666666669,200,25,50,18.58,120.32,22.25,132.52,26.83,142.73,34.72,157.99,0.0,150
466.6666666666667,500,30,50,25.24,96.64,28.0,103.05,29.84,108.06,33.38,118.73,0.0,150
280.0,300,30,50,49.76,92.1,55.74,99.38,61.29,104.6,72.46,108.5,0.0,75
420.0,450,45,50,23.34,106.5,25.39,113.47,27.75,117.68,31.02,122.6,0.0,150
513.3333333333333,550,50,50,24.33,99.58,26.48,105.27,28.04,109.6,30.53,115.48,0.0,150
140.0,150,25,50,15.46,129.44,19.36,139.16,22.88,151.4,44.09,178.47,0.0,150
513.3333333333333,550,20,50,26.17,96.49,28.8,102.02,30.91,107.47,36.46,115.86,0.0,150
653.3333333333333,700,25,50,27.15,91.67,28.8,96.15,30.28,101.18,32.41,105.57,0.0,150
700.0,750,20,50,27.43,90.39,28.96,94.72,30.69,97.33,33.61,105.08,0.0,150
46.66666666666667,50,35,50,7.37,162.17,10.35,193.17,12.16,223.05,21.09,278.79,0.0,150
933.3333333333334,1000,20,50,26.49,86.38,27.79,90.98,28.73,94.38,30.29,99.49,0.0,165
933.3333333333334,1000,35,50,28.28,88.4,29.55,91.97,30.61,94.77,33.84,102.02,0.0,150
606.6666666666667,650,35,50,26.46,95.08,28.22,99.99,29.73,106.51,32.03,112.82,0.0,150
560.0,600,45,50,25.32,99.33,26.77,103.68,29.79,108.85,33.99,118.08,0.0,150
746.6666666666667,800,45,50,27.03,92.5,28.63,96.27,29.73,100.73,32.28,106.35,0.0,150
700.0,750,30,50,26.92,91.29,28.14,95.78,30.45,100.94,33.02,105.24,0.0,150
466.6666666666667,500,50,50,23.68,104.5,26.44,111.07,28.2,116.29,30.68,122.56,0.0,150
373.33333333333337,400,20,50,24.2,102.8,26.82,111.11,30.94,116.13,36.25,125.86,0.0,150
933.3333333333334,1000,25,50,29.09,86.73,30.05,90.15,31.3,92.75,32.92,98.28,0.0,150
606.6666666666667,650,45,50,35.02,88.61,36.79,95.12,39.58,98.96,42.45,107.55,0.0,115
93.33333333333334,100,20,50,48.71,104.41,66.23,118.98,79.99,125.64,80.0,161.65,1.0,60
420.0,450,20,50,24.73,99.64,27.03,105.72,30.02,112.39,34.32,126.0,0.0,150
140.0,150,35,50,56.07,93.55,62.9,103.99,77.47,118.76,80.0,137.22,1.0,60
700.0,750,25,50,27.54,91.61,28.99,96.91,30.54,98.83,35.38,103.42,0.0,150
466.6666666666667,500,45,50,33.77,95.51,35.66,101.44,38.59,105.68,42.13,109.48,0.0,115
606.6666666666667,650,25,50,26.59,93.01,28.0,99.08,30.61,103.48,31.38,107.7,0.0,150
140.0,150,20,50,56.16,94.1,66.85,105.03,79.96,113.23,80.0,119.96,1.0,60
933.3333333333334,1000,40,50,58.53,75.23,60.42,78.05,62.32,80.98,64.35,85.18,0.0,75
46.66666666666667,50,30,50,7.57,157.01,9.86,179.73,13.65,211.12,20.98,316.47,0.0,150
793.3333333333333,850,40,50,27.46,90.11,28.55,94.04,30.59,97.21,33.31,103.57,0.0,150
233.33333333333334,250,50,50,18.57,122.11,21.19,130.64,24.81,141.16,28.31,150.14,0.0,150
420.0,450,50,50,23.32,103.16,25.1,108.73,28.0,115.12,32.21,126.48,0.0,150
420.0,450,40,50,23.6,103.96,26.25,108.63,28.79,114.32,31.83,121.7,0.0,150
560.0,600,40,50,25.88,98.11,27.86,101.98,30.23,106.46,35.62,116.36,0.0,150
886.6666666666667,950,40,50,28.06,89.35,29.44,92.68,31.01,95.41,33.33,99.69,0.0,150
373.33333333333337,400,35,50,23.51,103.78,26.19,112.35,28.2,118.51,33.97,124.69,0.0,150
933.3333333333334,1000,45,50,27.93,89.51,29.53,93.08,30.8,94.86,32.36,99.7,0.0,150
746.6666666666667,800,35,50,24.87,92.39,25.77,96.98,26.88,100.31,27.98,106.69,0.0,165
793.3333333333333,850,50,50,24.4,94.97,25.61,99.17,27.25,104.71,30.01,111.39,0.0,165
373.33333333333337,400,25,50,23.83,103.99,25.72,110.62,29.02,115.87,37.08,134.35,0.0,150
326.66666666666663,350,20,50,20.08,109.97,22.77,118.44,25.33,125.06,31.12,136.02,0.0,165
793.3333333333333,850,20,50,28.31,86.65,29.55,92.97,30.51,97.06,32.36,103.21,0.0,150
466.6666666666667,500,40,50,24.58,100.16,27.02,106.65,29.19,113.06,33.56,118.82,0.0,150
746.6666666666667,800,50,50,57.39,78.71,60.05,81.61,62.78,84.23,67.34,88.05,0.0,75
513.3333333333333,550,30,50,25.56,99.02,27.76,103.36,29.63,108.41,33.38,113.61,0.0,150
933.3333333333334,1000,50,50,28.05,89.36,29.23,93.68,30.48,95.72,32.39,99.51,0.0,150
746.6666666666667,800,25,50,25.0,91.07,26.87,95.43,28.0,100.76,30.1,105.44,0.0,165
606.6666666666667,650,40,50,70.65,76.94,73.38,81.03,76.49,82.79,80.0,85.86,0.0,60
840.0,900,45,50,28.21,90.91,29.38,94.42,31.02,97.37,33.78,103.3,0.0,150
420.0,450,30,50,24.38,101.17,26.99,107.62,28.7,113.45,31.51,123.43,0.0,150
793.3333333333333,850,30,50,25.38,90.43,26.74,95.04,27.9,98.72,30.09,105.0,0.0,165
326.66666666666663,350,25,50,23.13,107.77,25.23,114.39,28.17,122.16,33.86,130.94,0.0,150
840.0,900,40,50,58.42,76.95,60.24,80.53,61.58,82.68,63.34,84.36,0.0,75
233.33333333333334,250,20,50,18.41,117.73,20.99,126.75,24.28,138.75,37.77,153.74,0.0,165
186.66666666666669,200,50,50,15.38,130.99,17.63,142.9,20.95,152.3,26.29,166.64,0.0,165
746.6666666666667,800,40,50,24.59,95.08,25.68,98.88,27.3,102.28,28.82,108.07,0.0,165
513.3333333333333,550,45,50,68.2,78.42,71.92,83.99,76.48,88.17,79.79,90.86,0.0,60
140.0,150,45,50,30.46,115.45,38.51,128.41,46.11,140.18,79.99,152.64,0.0,90
373.33333333333337,400,45,50,22.4,106.29,24.48,114.03,26.66,120.69,30.46,125.59,0.0,150
700.0,750,40,50,23.93,94.79,25.24,99.48,26.74,103.84,28.65,110.56,0.0,165
886.6666666666667,950,45,50,25.28,92.05,26.52,95.77,27.98,98.12,29.58,101.66,0.0,165
280.0,300,50,50,20.31,118.59,23.28,127.1,26.44,135.86,30.81,148.11,0.0,150
280.0,300,20,50,21.08,113.64,23.33,121.48,25.67,127.93,32.79,149.98,0.0,165
700.0,750,50,50,23.49,96.64,25.05,102.72,26.22,106.84,28.24,113.41,0.0,165
46.66666666666667,50,25,50,38.66,121.92,55.17,142.0,71.49,161.03,80.0,232.21,1.0,60
700.0,750,45,50,46.58,82.08,49.27,85.6,51.72,88.64,55.86,94.77,0.0,90
373.33333333333337,400,40,50,23.08,107.62,25.63,113.5,27.05,118.43,29.23,124.36,0.0,150
933.3333333333334,1000,30,50,26.1,87.27,27.57,90.57,28.3,96.29,29.96,100.47,0.0,165
466.6666666666667,500,25,50,25.88,95.68,28.11,102.27,30.02,107.4,34.12,114.1,0.0,150
326.66666666666663,350,40,50,19.78,115.76,21.26,121.17,23.47,127.0,31.11,134.56,0.0,165
840.0,900,30,50,25.25,91.27,27.09,95.63,28.12,98.06,29.89,100.09,0.0,165
653.3333333333333,700,30,50,70.93,76.24,74.99,79.65,77.94,83.12,79.99,87.2,0.0,60
280.0,300,40,50,18.34,118.15,20.69,129.67,23.78,136.04,29.1,148.03,0.0,165
93.33333333333334,100,35,50,33.01,114.0,42.9,129.57,52.68,151.22,78.05,175.48,0.0,75
840.0,900,50,50,25.04,92.77,26.24,96.41,27.34,100.98,29.87,108.22,0.0,165
140.0,150,50,50,12.53,142.06,15.46,153.82,20.28,164.25,34.62,178.12,0.0,165
233.33333333333334,250,30,50,17.44,120.85,20.0,131.67,23.77,140.13,29.5,163.33,0.0,165
326.66666666666663,350,35,50,20.53,114.86,22.69,121.19,24.51,128.43,27.66,137.21,0.0,165
420.0,450,35,50,66.08,80.54,70.34,84.9,75.62,88.36,79.99,94.25,0.0,60
280.0,300,35,50,19.04,115.67,21.95,124.94,24.74,134.28,31.39,151.02,0.0,165
326.66666666666663,350,50,50,63.13,84.88,68.87,91.1,73.69,97.11,79.2,104.83,0.0,60
746.6666666666667,800,30,50,24.64,93.14,26.24,97.75,27.64,101.81,29.52,108.26,0.0,165
93.33333333333334,100,45,50,9.79,151.19,12.17,167.46,16.14,180.93,32.3,231.73,0.0,165
560.0,600,30,50,35.4,90.0,37.49,94.09,39.52,97.59,42.23,108.02,0.0,115
653.3333333333333,700,50,50,23.0,100.61,24.63,104.38,26.06,108.27,28.4,115.07,0.0,165
560.0,600,25,50,23.83,96.02,25.39,101.82,27.19,105.83,31.3,116.68,0.0,165
186.66666666666669,200,20,50,59.96,88.94,68.27,97.59,79.98,105.58,80.0,113.89,2.0,60
280.0,300,25,50,18.87,111.7,22.06,121.71,23.55,131.09,27.4,141.1,0.0,165
840.0,900,20,50,25.62,88.88,26.52,94.58,28.29,97.79,31.6,103.8,0.0,165
886.6666666666667,950,20,50,26.13,87.54,27.34,93.04,28.46,96.85,30.66,103.56,0.0,165
233.33333333333334,250,45,50,17.74,127.06,20.36,135.95,23.46,146.79,28.55,154.16,0.0,165
466.6666666666667,500,35,50,69.91,78.41,74.01,82.56,78.18,86.21,80.0,90.69,0.0,60
186.66666666666669,200,35,50,15.02,127.98,17.46,143.18,23.25,152.35,26.82,191.69,0.0,165
793.3333333333333,850,45,50,24.38,94.5,25.5,98.61,26.88,100.21,28.6,104.5,0.0,165
373.33333333333337,400,50,50,19.39,112.77,20.87,121.92,22.55,126.79,27.7,138.13,0.0,165
886.6666666666667,950,30,50,28.24,86.69,29.42,91.38,30.7,94.85,33.15,99.25,0.0,150
280.0,300,45,50,17.61,119.3,20.33,128.01,22.08,133.84,24.98,144.37,0.0,165
186.66666666666669,200,45,50,15.31,131.15,16.74,145.6,18.92,154.0,21.86,164.81,0.0,165
140.0,150,40,50,12.98,135.83,15.11,144.24,17.55,158.96,23.47,180.39,0.0,165
420.0,450,25,50,21.97,105.3,24.69,110.51,26.69,115.13,32.4,127.1,0.0,165
93.33333333333334,100,40,50,12.02,143.93,14.18,155.04,17.53,169.75,33.46,215.76,0.0,150
653.3333333333333,700,45,50,56.0,80.15,58.51,83.51,60.39,86.25,66.57,91.95,0.0,75
233.33333333333334,250,40,50,17.06,125.86,19.26,132.36,21.48,137.47,29.92,149.83,0.0,165
513.3333333333333,550,25,50,22.8,100.04,24.74,104.96,26.63,111.68,29.21,121.4,0.0,165
466.6666666666667,500,20,50,23.4,97.18,25.55,105.57,27.75,108.77,31.05,117.24,0.0,165
560.0,600,20,50,36.16,86.95,38.62,91.8,41.41,97.1,44.62,103.07,0.0,115
233.33333333333334,250,35,50,20.29,120.36,22.48,130.13,26.43,139.96,31.36,168.69,0.0,150
560.0,600,35,50,25.51,97.0,27.87,102.34,29.35,106.26,32.55,114.9,0.0,150
46.66666666666667,50,50,50,6.66,174.2,7.88,192.89,10.84,216.83,14.44,261.84,0.0,165
233.33333333333334,250,25,50,18.33,121.24,20.31,129.96,22.94,140.81,28.81,154.36,0.0,165
93.33333333333334,100,30,50,10.53,144.62,11.81,161.14,16.58,176.8,21.18,223.79,0.0,165
186.66666666666669,200,40,50,43.09,101.22,48.02,111.92,54.69,121.41,73.36,140.33,0.0,75
606.6666666666667,650,50,50,22.88,100.52,24.65,104.89,25.7,108.71,30.19,114.58,0.0,165
93.33333333333334,100,50,50,9.56,152.85,11.65,162.82,14.63,186.77,25.97,213.63,0.0,165
886.6666666666667,950,50,50,24.96,92.83,26.34,96.03,27.92,99.15,29.39,104.61,0.0,165
886.6666666666667,950,35,50,25.25,91.06,26.28,94.53,27.37,99.58,30.11,103.01,0.0,165
700.0,750,35,50,24.12,97.12,25.69,101.02,27.85,104.68,29.6,107.12,0.0,165
373.33333333333337,400,30,50,42.51,90.48,46.07,95.87,50.86,100.73,56.51,110.1,0.0,90
46.66666666666667,50,40,50,6.14,167.8,8.13,190.62,10.41,218.03,21.47,360.03,0.0,165
560.0,600,50,50,22.76,101.91,24.21,107.75,25.91,113.11,27.56,120.92,0.0,165
886.6666666666667,950,25,50,28.8,87.05,30.06,90.34,32.08,93.55,34.97,98.9,0.0,150
46.66666666666667,50,20,50,7.36,159.31,9.37,186.53,12.5,214.75,22.8,279.95,0.0,165
513.3333333333333,550,35,50,22.99,100.38,24.35,105.4,25.48,110.86,28.66,119.29,0.0,165
653.3333333333333,700,20,50,25.48,92.4,26.88,97.51,28.23,101.2,30.37,106.21,0.0,165
606.6666666666667,650,30,50,23.65,96.3,25.35,101.19,27.84,105.65,30.81,115.7,0.0,165
186.66666666666669,200,30,50,15.69,125.84,17.74,137.12,21.11,142.16,24.66,155.94,0.0,165
840.0,900,35,50,24.95,91.62,26.25,95.13,27.22,98.73,29.93,104.45,0.0,165
793.3333333333333,850,35,50,24.94,92.55,26.11,99.18,27.64,101.82,28.54,106.07,0.0,165
140.0,150,30,50,43.2,107.11,51.66,116.07,59.71,130.94,66.47,153.63,0.0,75
93.33333333333334,100,25,50,11.75,148.21,14.55,162.4,18.87,177.56,37.86,224.58,0.0,165
513.3333333333333,550,40,50,25.7,101.26,27.6,105.58,29.44,108.61,31.38,120.17,0.0,150
513.3333333333333,550,50,50,22.09,103.45,23.72,108.7,24.74,111.22,27.4,118.78,0.0,165
793.3333333333333,850,25,50,25.47,90.98,26.81,95.37,27.87,98.9,29.5,104.88,0.0,165
840.0,900,25,50,25.93,89.59,26.98,92.94,28.33,96.78,31.09,99.92,0.0,165
326.66666666666663,350,45,50,19.29,113.93,21.62,122.92,23.64,128.05,28.11,148.09,0.0,165
653.3333333333333,700,35,50,56.73,79.07,59.29,85.47,61.82,88.96,63.61,93.99,0.0,75
746.6666666666667,800,20,50,25.26,90.68,27.11,94.01,29.55,98.3,30.37,105.67,0.0,165
513.3333333333333,550,20,50,23.63,98.28,25.46,104.32,27.63,109.12,30.68,112.89,0.0,165
653.3333333333333,700,25,50,24.85,94.89,26.09,101.04,27.87,104.36,30.46,108.18,0.0,165
46.66666666666667,50,45,50,5.9,166.27,7.84,193.28,10.11,214.77,25.76,281.09,0.0,165
186.66666666666669,200,25,50,16.12,126.17,19.77,137.41,24.02,144.81,31.6,153.81,0.0,165
326.66666666666663,350,30,50,51.42,88.72,57.08,95.12,60.58,102.18,72.25,108.86,0.0,75
466.6666666666667,500,30,50,22.68,102.08,23.97,107.06,26.27,117.35,28.54,126.23,0.0,165
420.0,450,45,50,20.48,108.12,22.9,113.71,26.54,119.79,30.3,125.39,0.0,165
933.3333333333334,1000,20,50,23.88,89.95,25.21,93.65,26.43,96.81,27.92,99.89,0.0,180
46.66666666666667,50,35,50,6.63,164.68,8.36,182.08,10.57,200.97,20.9,260.63,0.0,165
466.6666666666667,500,50,50,21.46,107.34,23.02,113.83,24.51,118.6,28.23,128.36,0.0,165
606.6666666666667,650,20,50,25.16,92.41,26.87,99.69,28.42,103.03,32.87,112.25,0.0,165
700.0,750,20,50,25.45,91.22,27.09,97.07,29.46,101.87,32.35,108.6,0.0,165
606.6666666666667,650,35,50,23.71,96.19,25.18,101.87,26.75,106.82,30.92,111.35,0.0,165
933.3333333333334,1000,35,50,25.35,89.51,26.91,94.59,27.92,97.13,30.46,99.54,0.0,165
746.6666666666667,800,45,50,24.34,95.37,25.62,100.44,26.82,103.14,28.91,109.89,0.0,165
373.33333333333337,400,20,50,21.62,105.59,24.4,112.09,26.2,119.34,30.15,132.1,0.0,165
653.3333333333333,700,40,50,71.25,76.16,74.0,79.88,76.99,82.27,80.0,85.79,0.0,60
560.0,600,45,50,22.21,103.37,24.24,107.51,26.5,112.45,29.03,115.93,0.0,165
700.0,750,30,50,24.76,93.37,26.07,98.34,28.22,103.62,29.43,108.28,0.0,165
280.0,300,30,50,41.21,95.08,46.62,102.01,49.75,108.65,58.05,118.11,0.0,90
933.3333333333334,1000,25,50,25.56,88.83,26.97,92.19,27.86,94.96,29.98,97.1,0.0,165
140.0,150,25,50,13.87,130.37,16.57,147.22,19.4,154.65,24.42,190.79,0.0,165
420.0,450,20,50,22.48,102.59,24.97,109.44,27.11,115.32,29.94,125.36,0.0,165
46.66666666666667,50,30,50,6.83,163.45,9.52,184.81,12.11,209.73,18.25,281.76,0.0,165
700.0,750,25,50,24.85,92.95,25.93,98.47,27.53,101.67,31.27,108.49,0.0,165
933.3333333333334,1000,40,50,48.26,78.41,49.69,80.84,52.02,84.58,55.85,87.46,0.0,90
793.3333333333333,850,40,50,24.43,93.69,25.74,98.4,27.66,101.44,29.29,105.6,0.0,165
140.0,150,35,50,40.4,108.33,46.69,117.27,57.17,123.94,74.86,151.59,0.0,75
793.3333333333333,850,50,50,22.1,97.68,23.32,102.63,24.22,105.82,26.11,112.85,0.0,180
233.33333333333334,250,50,50,15.86,125.71,17.43,133.93,19.42,146.7,24.0,162.56,0.0,165
746.6666666666667,800,35,50,22.07,94.81,23.81,99.2,24.87,105.79,25.89,108.49,0.0,180
420.0,450,40,50,21.27,107.31,22.88,113.97,24.87,119.16,30.49,123.6,0.0,165
606.6666666666667,650,45,50,25.7,96.52,27.14,101.87,29.15,106.27,31.46,115.71,0.0,150
466.6666666666667,500,40,50,21.61,104.96,23.79,110.74,25.32,115.48,28.65,123.61,0.0,165
933.3333333333334,1000,45,50,25.27,91.45,26.62,94.35,27.61,97.94,28.85,102.02,0.0,165
93.33333333333334,100,20,50,39.7,115.83,49.31,129.38,79.98,145.32,80.0,165.95,2.0,75
560.0,600,40,50,23.11,100.46,24.99,104.97,26.34,109.41,29.98,120.33,0.0,165
326.66666666666663,350,20,50,19.38,113.57,22.08,119.49,24.08,124.37,27.2,133.22,0.0,180
886.6666666666667,950,40,50,25.21,91.38,26.03,94.19,27.32,97.99,29.33,101.7,0.0,165
373.33333333333337,400,35,50,20.57,109.8,22.77,117.18,24.97,123.38,31.01,131.31,0.0,165
140.0,150,20,50,43.12,104.01,49.83,114.88,57.33,122.61,80.0,142.93,0.0,75
513.3333333333333,550,30,50,22.84,99.85,25.05,106.34,27.02,110.4,30.8,124.03,0.0,165
793.3333333333333,850,20,50,25.46,90.25,26.85,96.43,28.09,99.82,31.18,105.47,0.0,165
933.3333333333334,1000,50,50,25.39,91.65,26.5,95.46,27.6,99.2,29.04,101.42,0.0,165
373.33333333333337,400,25,50,21.01,106.31,23.61,112.84,25.24,118.58,29.97,127.48,0.0,165
420.0,450,30,50,21.58,105.21,23.85,111.97,25.04,117.26,27.78,125.47,0.0,165
746.6666666666667,800,25,50,22.45,95.3,24.25,99.49,25.19,104.89,28.77,108.66,0.0,180
840.0,900,45,50,25.01,93.24,26.26,97.32,27.99,100.81,28.91,106.81,0.0,165
466.6666666666667,500,45,50,24.3,102.41,26.16,109.02,28.35,111.81,34.15,118.63,0.0,150
606.6666666666667,650,25,50,23.7,95.89,25.93,99.9,27.26,104.43,29.36,114.92,0.0,165
420.0,450,50,50,20.29,108.34,23.31,114.3,24.72,119.34,26.66,128.57,0.0,165
746.6666666666667,800,40,50,21.96,98.46,23.1,101.94,24.34,106.35,25.66,113.38,0.0,180
280.0,300,20,50,17.82,113.71,20.22,120.82,22.5,132.22,28.08,145.6,0.0,180
186.66666666666669,200,50,50,12.84,137.02,15.14,146.55,18.09,160.55,27.24,183.03,0.0,180
233.33333333333334,250,20,50,17.28,123.69,19.17,131.68,22.1,138.18,28.54,159.6,0.0,180
793.3333333333333,850,30,50,22.55,93.91,24.21,98.61,25.49,102.5,26.8,106.05,0.0,180
933.3333333333334,1000,30,50,23.45,91.54,24.4,96.05,26.2,99.61,27.36,104.78,0.0,180
700.0,750,40,50,21.96,100.26,23.13,104.22,24.42,109.44,26.47,112.74,0.0,180
280.0,300,50,50,17.6,121.06,19.36,128.36,22.07,132.57,27.57,138.14,0.0,165
700.0,750,50,50,21.8,99.07,23.38,103.42,24.31,108.0,25.98,119.6,0.0,180
606.6666666666667,650,40,50,56.42,80.87,59.2,84.41,60.91,88.01,65.92,92.28,0.0,75
886.6666666666667,950,45,50,22.83,94.57,24.07,98.28,25.24,100.99,27.37,106.7,0.0,180
466.6666666666667,500,25,50,22.95,104.05,25.4,109.42,26.96,113.77,29.95,119.8,0.0,165
840.0,900,40,50,47.6,79.35,49.72,83.03,51.05,86.5,53.44,89.07,0.0,90
373.33333333333337,400,45,50,20.18,112.49,21.51,119.86,23.46,126.96,28.54,135.47,0.0,165
326.66666666666663,350,40,50,17.49,116.73,18.72,124.64,20.88,130.51,27.01,141.22,0.0,180
840.0,900,30,50,23.11,92.92,24.48,96.9,25.81,101.63,27.57,106.47,0.0,180
746.6666666666667,800,50,50,46.71,82.32,49.02,84.57,51.09,87.48,53.62,91.74,0.0,90
840.0,900,50,50,22.58,95.97,23.7,100.97,24.48,103.56,27.13,105.86,0.0,180
326.66666666666663,350,25,50,20.15,111.37,22.41,118.2,26.83,123.32,29.6,132.3,0.0,165
280.0,300,40,50,16.36,121.44,18.72,132.31,21.19,139.68,25.5,150.98,0.0,180
140.0,150,50,50,11.16,144.34,12.58,157.22,14.28,166.22,16.51,189.99,0.0,180
46.66666666666667,50,25,50,23.24,135.64,33.92,154.25,59.05,169.34,80.0,211.81,0.0,75
140.0,150,45,50,21.45,118.92,25.63,134.9,31.08,150.66,50.53,190.64,0.0,115
513.3333333333333,550,45,50,55.22,83.27,58.1,87.08,60.17,91.1,64.37,93.23,0.0,75
233.33333333333334,250,30,50,15.74,121.77,17.81,131.05,19.91,141.34,27.85,151.86,0.0,180
700.0,750,45,50,36.34,87.27,38.3,90.96,40.07,94.33,42.56,98.04,0.0,115
373.33333333333337,400,40,50,20.28,109.48,22.55,116.52,24.68,122.65,29.83,133.45,0.0,165
280.0,300,35,50,16.04,124.58,17.86,130.35,19.66,138.44,24.65,149.76,0.0,180
746.6666666666667,800,30,50,22.55,94.78,23.63,100.8,25.03,103.39,28.39,107.26,0.0,180
653.3333333333333,700,50,50,20.88,101.51,21.98,106.53,23.19,109.96,25.27,113.77,0.0,180
560.0,600,25,50,21.61,98.05,23.17,104.2,24.64,110.55,29.37,120.56,0.0,180
326.66666666666663,350,35,50,17.51,116.28,20.16,124.88,21.22,129.72,26.0,138.95,0.0,180
280.0,300,25,50,17.16,117.31,19.62,125.53,22.45,133.13,25.39,150.89,0.0,180
93.33333333333334,100,45,50,9.1,155.78,11.04,167.16,13.05,191.14,16.81,222.05,0.0,180
93.33333333333334,100,35,50,26.92,123.99,32.65,139.99,45.24,152.32,79.96,178.08,0.0,90
793.3333333333333,850,45,50,22.14,96.02,23.44,99.7,24.3,101.85,26.89,106.21,0.0,180
653.3333333333333,700,30,50,56.48,79.13,60.2,82.23,62.64,86.36,66.68,92.03,0.0,75
886.6666666666667,950,30,50,25.91,89.94,27.08,94.12,27.84,97.94,29.57,100.77,0.0,165
420.0,450,35,50,54.6,84.97,57.44,91.2,62.38,96.11,69.52,100.28,0.0,75
233.33333333333334,250,45,50,15.03,129.44,17.1,137.6,20.28,146.92,24.85,159.27,0.0,180
186.66666666666669,200,35,50,14.12,132.53,15.61,146.74,18.89,153.3,25.36,185.84,0.0,180
886.6666666666667,950,20,50,24.2,90.09,25.08,94.54,26.45,98.13,27.57,102.64,0.0,180
233.33333333333334,250,40,50,14.75,128.03,16.29,136.13,19.51,145.52,23.2,155.72,0.0,180
840.0,900,20,50,23.95,89.65,25.12,94.99,26.41,98.31,28.48,103.73,0.0,180
560.0,600,20,50,27.24,92.78,29.59,98.11,32.45,102.12,38.63,110.85,0.0,150
373.33333333333337,400,50,50,18.32,113.25,19.81,120.18,22.03,125.75,25.13,139.62,0.0,180
280.0,300,45,50,16.24,123.21,17.58,131.77,19.76,137.62,26.97,147.27,0.0,180
326.66666666666663,350,50,50,49.95,90.73,53.34,95.58,57.8,100.48,65.64,105.65,0.0,75
140.0,150,40,50,11.94,145.58,14.46,160.18,16.47,176.52,28.61,213.26,0.0,180
560.0,600,30,50,26.07,96.04,28.28,101.53,31.1,105.93,32.45,110.44,0.0,150
186.66666666666669,200,45,50,12.93,133.88,14.67,148.35,17.09,159.65,26.14,183.81,0.0,180
513.3333333333333,550,25,50,20.91,102.66,22.55,109.81,24.67,112.83,27.84,125.55,0.0,180
233.33333333333334,250,25,50,16.62,124.54,19.07,136.01,21.19,143.12,26.75,160.1,0.0,180
466.6666666666667,500,20,50,20.6,103.85,22.24,109.92,23.61,117.82,26.97,125.06,0.0,180
420.0,450,25,50,19.39,107.36,20.59,115.73,22.58,120.92,26.48,131.86,0.0,180
46.66666666666667,50,50,50,5.45,168.7,6.92,194.59,9.58,229.9,13.25,255.43,0.0,180
886.6666666666667,950,50,50,22.79,95.29,23.78,97.8,25.19,101.12,25.81,105.62,0.0,180
186.66666666666669,200,20,50,46.65,98.85,55.48,108.95,64.22,116.03,80.0,134.01,0.0,75
653.3333333333333,700,20,50,22.55,94.52,24.36,99.45,26.62,104.27,28.38,110.1,0.0,180
466.6666666666667,500,35,50,55.42,83.45,57.73,88.66,60.81,92.73,71.93,96.63,0.0,75
93.33333333333334,100,30,50,9.34,152.35,11.36,166.03,14.93,198.58,26.08,255.59,0.0,180
93.33333333333334,100,50,50,9.59,159.5,11.15,174.65,12.82,194.36,18.8,213.28,0.0,180
886.6666666666667,950,35,50,23.43,92.32,24.73,97.89,25.39,101.37,28.15,104.96,0.0,180
653.3333333333333,700,45,50,46.01,82.44,48.94,86.64,51.78,89.92,56.63,94.99,0.0,90
93.33333333333334,100,40,50,9.77,146.1,11.79,160.3,14.43,182.17,22.62,219.48,0.0,165
560.0,600,50,50,20.31,104.54,21.76,110.28,23.07,114.75,26.39,124.99,0.0,180
606.6666666666667,650,30,50,21.62,99.45,23.05,104.34,25.2,109.0,28.18,113.29,0.0,180
46.66666666666667,50,20,50,6.21,164.72,8.17,181.91,11.16,220.47,24.72,284.7,0.0,180
700.0,750,35,50,22.31,97.48,23.4,101.76,24.89,106.08,27.74,109.67,0.0,180
793.3333333333333,850,35,50,22.77,95.01,24.39,99.48,25.8,102.9,27.72,109.36,0.0,180
233.33333333333334,250,35,50,17.39,121.07,19.7,132.48,24.07,141.68,31.59,156.01,0.0,165
513.3333333333333,550,35,50,20.53,105.47,22.43,110.66,24.26,115.99,27.4,122.34,0.0,180
186.66666666666669,200,30,50,14.33,127.61,15.5,138.4,19.42,150.84,27.73,180.49,0.0,180
606.6666666666667,650,50,50,20.87,101.64,22.57,106.5,25.14,111.78,27.4,117.27,0.0,180
840.0,900,35,50,22.79,93.84,24.4,98.89,25.51,102.01,28.74,108.36,0.0,180
93.33333333333334,100,25,50,9.61,151.64,11.82,167.62,15.07,193.78,22.93,215.57,0.0,180
513.3333333333333,550,50,50,19.63,107.11,21.26,112.01,23.06,118.75,24.88,123.97,0.0,180
186.66666666666669,200,40,50,33.55,106.83,37.86,118.47,45.94,124.89,62.38,130.65,0.0,90
513.3333333333333,550,40,50,22.16,104.1,23.55,110.65,25.72,113.12,27.88,120.74,0.0,165
326.66666666666663,350,45,50,17.13,119.59,18.92,127.78,20.81,135.16,27.14,143.28,0.0,180
840.0,900,25,50,23.27,93.6,24.61,97.12,25.88,99.69,28.4,104.39,0.0,180
653.3333333333333,700,25,50,22.03,97.61,23.7,101.51,24.99,105.48,27.18,110.83,0.0,180
140.0,150,30,50,34.06,110.92,42.34,125.22,50.64,139.64,62.37,164.79,0.0,90
186.66666666666669,200,25,50,15.01,132.03,17.96,142.43,22.33,157.72,27.32,166.9,0.0,180
560.0,600,35,50,23.45,98.65,24.5,105.3,25.86,111.09,29.05,122.49,0.0,165
746.6666666666667,800,20,50,23.29,92.23,24.73,97.59,26.99,102.17,31.66,106.93,0.0,180
793.3333333333333,850,25,50,23.05,93.38,24.36,98.17,26.06,101.04,29.54,108.91,0.0,180
373.33333333333337,400,30,50,32.16,96.9,34.89,101.03,38.87,107.38,41.9,116.09,0.0,115
606.6666666666667,650,35,50,21.9,100.09,23.22,105.82,24.91,109.71,27.96,121.73,0.0,180
46.66666666666667,50,45,50,5.49,172.38,6.64,191.49,9.01,205.66,12.82,276.76,0.0,180
513.3333333333333,550,20,50,21.83,101.2,23.22,107.88,25.61,111.81,28.67,120.22,0.0,180
466.6666666666667,500,30,50,20.33,106.2,22.09,111.54,24.73,118.13,26.83,124.86,0.0,180
46.66666666666667,50,35,50,5.45,170.88,7.48,200.72,10.21,230.35,23.23,338.86,0.0,180
466.6666666666667,500,50,50,19.24,109.43,20.78,116.31,22.39,122.06,27.32,129.7,0.0,180
46.66666666666667,50,40,50,5.74,173.41,7.28,203.11,9.74,225.14,19.63,375.98,0.0,180
886.6666666666667,950,25,50,25.98,89.4,27.07,92.6,28.47,95.85,30.1,98.95,0.0,165
420.0,450,45,50,18.91,111.76,21.56,118.08,23.21,122.85,25.63,127.35,0.0,180
1166.6666666666667,1000,20,50,79.99,10.04,80.0,13.1,80.0,17.63,80.0,23.93,2.0,45
746.6666666666667,800,45,50,22.11,98.94,23.55,103.11,24.7,106.51,26.87,110.76,0.0,180
326.66666666666663,350,30,50,41.67,90.79,45.01,97.28,49.52,105.47,75.87,112.01,0.0,90
653.3333333333333,700,40,50,56.18,80.16,58.54,84.44,60.82,86.34,67.38,90.1,0.0,75
933.3333333333334,1000,35,50,23.19,92.75,24.23,96.9,25.01,99.9,26.41,103.16,0.0,180
606.6666666666667,650,20,50,22.49,97.39,24.34,103.2,25.54,108.0,28.89,117.3,0.0,180
653.3333333333333,700,35,50,46.25,82.86,49.22,87.47,50.65,92.25,55.37,94.88,0.0,90
700.0,750,20,50,22.71,93.36,24.0,98.92,25.8,103.6,28.1,115.04,0.0,180
373.33333333333337,400,20,50,19.8,107.3,21.64,116.44,24.05,120.9,28.09,130.22,0.0,180
420.0,450,20,50,20.72,104.8,22.46,113.41,24.74,118.89,29.88,128.17,0.0,180
700.0,750,30,50,21.88,98.18,23.56,102.53,24.81,105.58,27.31,112.37,0.0,180
46.66666666666667,50,30,50,5.54,170.78,7.67,189.77,10.15,218.19,15.41,302.22,0.0,180
560.0,600,45,50,20.98,103.87,22.33,111.26,24.27,114.6,26.74,123.13,0.0,180
933.3333333333334,1000,25,50,23.56,90.0,24.79,94.74,25.79,98.48,27.51,103.22,0.0,180
700.0,750,25,50,22.4,94.8,24.12,100.28,25.24,103.72,28.09,111.35,0.0,180
793.3333333333333,850,40,50,22.39,96.49,23.48,100.54,24.61,103.57,27.33,109.65,0.0,180
233.33333333333334,250,50,50,14.83,128.6,16.2,137.6,18.66,146.81,22.8,152.17,0.0,180
933.3333333333334,800,35,50,79.99,15.06,80.0,20.26,80.0,24.16,80.0,31.37,3.0,45
420.0,450,40,50,19.45,110.73,21.11,119.78,22.72,125.3,27.23,129.06,0.0,180
933.3333333333334,1000,45,50,22.95,94.02,23.92,98.58,25.1,101.68,26.34,105.14,0.0,180
991.6666666666666,850,50,50,79.99,12.08,80.0,17.5,80.0,20.67,80.0,27.3,2.0,45
466.6666666666667,500,40,50,19.41,106.89,21.07,113.22,22.37,118.12,23.97,127.97,0.0,180
408.3333333333333,350,20,50,79.99,21.47,80.0,32.71,80.0,42.9,80.0,59.97,9.0,45
513.3333333333333,550,30,50,20.98,102.35,22.98,107.08,23.87,111.51,27.18,118.11,0.0,180
373.33333333333337,400,25,50,19.6,110.04,21.5,117.48,23.4,122.65,29.6,135.58,0.0,180
886.6666666666667,950,40,50,23.14,93.18,24.06,97.79,25.71,101.44,29.08,108.12,0.0,180
933.3333333333334,800,25,50,79.99,11.24,80.0,19.07,80.0,21.57,80.0,28.21,7.0,45
420.0,450,30,50,20.07,108.09,21.85,116.7,24.22,123.03,28.47,132.68,0.0,180
560.0,600,40,50,21.01,102.67,22.75,108.56,24.42,114.85,26.02,120.57,0.0,180
373.33333333333337,400,35,50,18.51,111.23,20.35,117.89,22.09,126.85,24.96,140.94,0.0,180
140.0,150,25,50,12.67,136.75,15.72,147.99,18.13,167.85,22.13,192.82,0.0,180
933.3333333333334,1000,50,50,22.79,93.94,24.13,97.83,25.28,100.61,26.3,103.64,0.0,180
93.33333333333334,100,20,50,29.13,119.73,37.58,134.13,44.81,146.53,74.17,178.34,0.0,90
280.0,300,30,50,29.67,105.81,32.67,113.82,36.34,120.45,39.77,132.92,0.0,115
933.3333333333334,1000,40,50,37.04,83.21,38.83,85.71,40.44,89.86,42.52,92.51,0.0,115
140.0,150,35,50,32.74,111.51,40.9,126.05,47.19,134.9,58.45,147.7,0.0,90
933.3333333333334,800,40,50,79.99,13.85,80.0,17.99,80.0,24.28,80.0,28.59,0.0,45
793.3333333333333,850,20,50,23.27,92.45,24.89,97.96,26.3,101.84,28.12,108.98,0.0,180
840.0,900,45,50,22.6,94.95,23.94,98.68,25.16,102.85,27.64,109.02,0.0,180
991.6666666666666,850,30,50,79.99,13.74,80.0,18.6,80.0,22.01,80.0,33.87,3.0,45
280.0,300,50,50,15.73,122.67,17.78,132.04,20.61,138.96,23.92,149.0,0.0,180
350.0,300,20,50,79.99,28.15,80.0,40.13,80.0,54.14,80.0,77.83,10.0,45
233.33333333333334,200,50,50,79.99,51.64,80.0,71.69,80.0,85.82,80.0,100.53,10.0,45
875.0,750,40,50,79.99,14.08,80.0,19.41,80.0,25.12,80.0,28.6,2.0,45
291.6666666666667,250,20,50,79.99,33.98,80.0,49.53,80.0,62.99,80.0,82.92,7.0,45
140.0,150,20,50,33.58,108.56,41.52,120.09,47.4,137.93,79.29,149.23,0.0,90
1166.6666666666667,1000,30,50,79.99,11.6,80.0,15.55,80.0,19.66,80.0,26.52,3.0,45
606.6666666666667,650,45,50,23.0,99.48,24.65,104.59,26.22,109.01,29.44,115.48,0.0,165
875.0,750,50,50,79.99,16.32,80.0,22.0,80.0,26.06,80.0,31.18,5.0,45
466.6666666666667,500,45,50,21.69,105.4,24.42,112.75,26.29,118.36,29.46,121.83,0.0,165
350.0,300,40,50,79.99,32.31,80.0,47.22,80.0,53.15,80.0,73.1,7.0,45
466.6666666666667,500,25,50,20.67,104.05,23.19,113.52,25.01,117.83,27.89,123.84,0.0,180
1050.0,900,50,50,79.99,12.86,80.0,18.37,80.0,22.06,80.0,29.54,5.0,45
408.3333333333333,350,40,50,79.99,31.18,80.0,40.84,80.0,46.88,80.0,65.77,3.0,45
1050.0,900,30,50,79.99,11.84,80.0,16.77,80.0,20.89,80.0,26.36,2.0,45
1108.3333333333335,950,45,50,79.99,12.0,80.0,15.62,80.0,19.31,80.0,23.9,1.0,45
373.33333333333337,400,45,50,18.15,116.84,20.02,123.69,21.96,130.04,26.35,139.4,0.0,180
606.6666666666667,650,25,50,21.82,98.79,23.33,104.54,24.83,109.41,28.73,118.12,0.0,180
350.0,300,35,50,79.99,30.99,80.0,45.67,80.0,56.23,80.0,65.15,4.0,45
420.0,450,50,50,18.62,113.06,20.23,121.78,21.88,128.23,29.38,134.25,0.0,180
175.0,150,50,50,79.99,61.18,80.0,82.84,80.0,99.29,80.0,112.42,4.0,45
291.6666666666667,250,30,50,79.99,36.06,80.0,51.38,80.0,60.99,80.0,93.7,12.0,45
840.0,900,40,50,36.9,84.12,38.84,87.84,40.32,90.14,43.46,95.87,0.0,115
933.3333333333334,800,30,50,79.99,11.9,80.0,17.72,80.0,22.2,80.0,32.05,5.0,45
816.6666666666666,700,50,50,79.99,16.04,80.0,19.67,80.0,26.29,80.0,29.14,5.0,45
46.66666666666667,50,25,50,17.88,139.57,27.11,162.24,32.82,196.43,79.99,286.49,3.0,90
326.66666666666663,350,25,50,18.64,113.83,20.99,120.35,23.69,128.31,31.47,136.45,0.0,180
746.6666666666667,800,50,50,35.49,87.09,38.05,91.66,39.21,94.58,41.94,100.17,0.0,115
606.6666666666667,650,40,50,45.87,83.85,47.97,88.15,50.19,91.97,53.84,98.34,0.0,90
991.6666666666666,850,45,50,79.99,14.92,80.0,18.91,80.0,23.76,80.0,30.89,0.0,45
886.6666666666667,950,30,50,23.11,90.87,24.04,95.55,25.18,99.52,26.32,107.51,0.0,180
700.0,600,25,50,79.99,18.01,80.0,23.71,80.0,29.44,80.0,37.06,2.0,45
116.66666666666667,100,45,50,79.97,87.97,79.99,103.47,80.0,115.8,80.0,135.01,7.0,45
560.0,600,20,50,24.1,95.85,26.27,101.59,27.89,104.55,30.42,111.25,0.0,165
350.0,300,25,50,79.99,24.21,80.0,38.89,80.0,58.78,80.0,81.13,7.0,45
373.33333333333337,400,40,50,18.83,112.27,21.18,121.36,23.7,127.8,26.49,137.62,0.0,180
291.6666666666667,250,45,50,79.99,41.0,80.0,57.04,80.0,73.98,80.0,88.09,1.0,45
513.3333333333333,550,45,50,44.11,86.89,47.11,91.47,49.59,96.32,56.21,99.95,0.0,90
1108.3333333333335,950,20,50,79.99,9.77,80.0,14.74,80.0,18.28,80.0,27.1,4.0,45
408.3333333333333,350,35,50,79.99,28.33,80.0,36.49,80.0,46.35,80.0,54.56,5.0,45
233.33333333333334,200,35,50,79.99,44.1,80.0,58.86,80.0,71.24,80.0,94.47,6.0,45
291.6666666666667,250,40,50,79.99,41.43,80.0,52.52,80.0,63.3,80.0,92.54,1.0,45
525.0,450,25,50,79.99,17.95,79.99,26.24,80.0,36.49,80.0,54.27,12.0,45
700.0,750,45,50,27.06,93.98,28.66,97.99,29.98,101.26,33.08,107.51,0.0,150
140.0,150,45,50,14.49,135.93,17.14,147.25,19.78,163.2,29.31,184.14,0.0,150
466.6666666666667,400,50,50,79.99,26.45,80.0,34.37,80.0,45.05,80.0,61.13,3.0,45
175.0,150,40,50,79.98,58.62,79.99,82.97,80.0,98.06,80.0,122.87,7.0,45
350.0,300,45,50,79.99,32.47,80.0,44.21,80.0,57.47,80.0,70.59,7.0,45
58.333333333333336,50,50,50,57.78,111.34,74.26,130.47,79.99,150.18,80.0,179.74,3.0,45
93.33333333333334,100,35,50,19.6,136.91,23.2,152.62,30.61,161.4,47.72,191.41,0.0,115
653.3333333333333,700,30,50,46.64,82.1,48.83,85.36,50.1,89.73,53.31,93.59,0.0,90
1050.0,900,20,50,79.99,11.04,80.0,15.84,80.0,19.58,80.0,28.27,6.0,45
233.33333333333334,200,45,50,79.99,48.66,80.0,66.8,80.0,76.96,80.0,89.24,4.0,45
116.66666666666667,100,30,50,79.97,83.9,79.99,103.87,80.0,117.35,80.0,130.08,8.0,45
291.6666666666667,250,25,50,79.99,37.2,80.0,49.91,80.0,63.72,80.0,78.52,6.0,45
816.6666666666666,700,20,50,79.99,14.01,80.0,19.53,80.0,24.68,80.0,33.5,7.0,45
420.0,450,35,50,44.17,87.26,48.03,92.3,52.94,98.24,62.62,107.92,0.0,90
641.6666666666666,550,25,50,79.99,16.88,80.0,21.83,80.0,28.46,80.0,56.54,6.0,45
116.66666666666667,100,50,50,79.97,84.46,79.99,104.05,80.0,117.45,80.0,129.1,3.0,45
1108.3333333333335,950,50,50,79.99,12.93,80.0,17.28,80.0,19.93,80.0,23.96,1.0,45
583.3333333333334,500,20,50,79.99,16.34,80.0,23.94,80.0,31.14,80.0,50.2,12.0,45
58.333333333333336,50,20,50,67.69,94.34,79.99,125.43,80.0,145.88,80.0,178.69,12.0,45
1108.3333333333335,950,35,50,79.99,11.42,80.0,16.65,80.0,20.45,80.0,25.33,4.0,45
326.66666666666663,350,50,50,41.29,95.05,44.7,100.36,49.42,106.36,55.22,111.19,0.0,90
700.0,600,50,50,79.99,18.4,80.0,24.68,80.0,31.53,80.0,46.03,3.0,45
758.3333333333334,650,30,50,79.99,16.8,80.0,22.26,80.0,24.61,80.0,36.74,2.0,45
641.6666666666666,550,35,50,79.99,20.24,80.0,26.75,80.0,31.87,80.0,48.21,4.0,45
758.3333333333334,650,50,50,79.99,17.41,80.0,22.14,80.0,28.45,80.0,35.73,3.0,45
116.66666666666667,100,25,50,79.98,74.15,79.99,96.28,80.0,112.11,80.0,121.93,10.0,45
991.6666666666666,850,35,50,79.99,12.62,80.0,17.33,80.0,22.14,80.0,25.12,1.0,45
1050.0,900,25,50,79.99,12.35,80.0,15.87,80.0,20.42,80.0,23.43,6.0,45
233.33333333333334,200,25,50,79.99,38.76,80.0,56.76,80.0,81.36,80.0,105.26,7.0,45
1050.0,900,35,50,79.99,12.19,80.0,16.84,80.0,20.24,80.0,26.56,7.0,45
875.0,750,35,50,79.99,13.87,80.0,18.34,80.0,22.77,80.0,31.64,1.0,45
233.33333333333334,200,30,50,79.99,44.54,80.0,67.89,80.0,81.23,80.0,97.68,4.0,45
408.3333333333333,350,45,50,79.99,22.78,80.0,33.65,80.0,48.96,80.0,65.09,11.0,45
560.0,600,30,50,23.24,101.83,24.79,104.81,26.73,109.83,29.48,117.03,0.0,165
466.6666666666667,500,35,50,44.37,87.09,47.8,92.29,50.92,95.37,56.19,102.51,0.0,90
186.66666666666669,200,20,50,39.77,99.54,45.34,108.54,52.43,119.53,63.65,135.9,0.0,90
641.6666666666666,550,50,50,79.99,20.54,80.0,28.46,80.0,33.73,80.0,44.39,3.0,45
653.3333333333333,700,45,50,35.21,86.73,37.85,91.37,39.18,96.17,41.62,103.74,0.0,115
816.6666666666666,700,25,50,79.99,11.87,80.0,18.73,80.0,22.87,80.0,29.04,0.0,45
58.333333333333336,50,45,50,58.83,109.88,79.98,126.17,79.99,143.65,80.0,173.06,4.0,45
58.333333333333336,50,35,50,62.67,107.11,79.97,129.46,80.0,143.17,80.0,170.18,4.0,45
513.3333333333333,550,40,50,20.35,103.14,21.67,110.02,23.62,116.4,27.28,123.96,0.0,180
93.33333333333334,100,40,50,9.06,154.84,10.86,169.63,12.88,184.79,17.99,229.07,0.0,180
58.333333333333336,50,40,50,61.22,111.0,79.98,126.15,79.99,143.36,80.0,184.9,3.0,45
583.3333333333334,500,30,50,79.99,15.83,80.0,25.09,80.0,34.83,80.0,40.12,3.0,45
186.66666666666669,200,40,50,25.68,114.17,29.7,126.96,34.36,135.53,49.61,151.56,0.0,115
933.3333333333334,800,20,50,79.99,9.37,80.0,15.05,80.0,21.91,80.0,28.73,4.0,45
991.6666666666666,850,25,50,79.99,12.8,80.0,16.79,80.0,19.46,80.0,25.17,2.0,45
758.3333333333334,650,35,50,79.99,17.15,80.0,24.16,80.0,28.82,80.0,36.35,5.0,45
233.33333333333334,250,35,50,15.65,127.24,18.21,135.69,20.35,146.13,26.31,160.69,0.0,180
641.6666666666666,550,20,50,79.99,15.84,80.0,22.47,80.0,29.27,80.0,45.84,7.0,45
1166.6666666666667,1000,20,50,79.99,48.97,80.0,52.85,80.0,59.98,80.0,67.97,0.0,60
140.0,150,30,50,23.02,123.8,27.29,135.31,30.87,145.21,48.03,177.39,0.0,115
653.3333333333333,700,40,50,46.16,83.23,49.87,86.32,51.47,89.67,56.18,94.21,0.0,90
1166.6666666666667,1000,35,50,79.99,11.63,80.0,14.84,80.0,18.04,80.0,29.92,1.0,45
583.3333333333334,500,50,50,79.99,20.63,80.0,28.25,80.0,35.61,80.0,46.12,4.0,45
933.3333333333334,800,45,50,79.99,13.39,80.0,18.28,80.0,23.78,80.0,30.04,0.0,45
466.6666666666667,400,20,50,79.99,22.52,80.0,31.85,80.0,40.86,80.0,58.72,8.0,45
525.0,450,45,50,79.99,24.44,80.0,34.31,80.0,39.31,80.0,48.13,4.0,45
326.66666666666663,350,30,50,30.94,100.82,34.5,110.45,36.74,116.05,43.32,124.87,0.0,115
560.0,600,35,50,20.7,102.27,22.17,107.46,23.99,114.28,27.1,119.66,0.0,180
373.33333333333337,400,30,50,24.21,102.19,26.51,109.77,28.75,118.15,36.0,126.8,0.0,150
875.0,750,20,50,79.99,12.0,80.0,18.18,80.0,23.47,80.0,33.5,2.0,45
758.3333333333334,650,20,50,79.99,12.79,79.99,20.07,80.0,23.89,80.0,32.0,7.0,45
875.0,750,30,50,79.99,15.11,80.0,19.9,80.0,22.87,80.0,30.95,3.0,45
58.333333333333336,50,30,50,67.07,101.99,79.98,125.05,79.99,140.79,80.0,178.59,5.0,45
700.0,600,45,50,79.99,16.03,80.0,21.3,80.0,31.19,80.0,39.87,5.0,45
525.0,450,20,50,79.99,18.93,80.0,27.19,80.0,34.17,80.0,46.97,6.0,45
886.6666666666667,950,25,50,23.73,92.34,25.02,95.87,26.39,98.66,27.41,103.78,0.0,180
1166.6666666666667,1000,25,50,79.99,10.98,80.0,14.69,80.0,17.4,80.0,24.12,7.0,45
933.3333333333334,800,35,50,79.99,54.0,80.0,61.29,80.0,69.86,80.0,78.34,0.0,60
991.6666666666666,850,40,50,79.99,13.95,80.0,18.56,80.0,22.43,80.0,25.02,2.0,45
583.3333333333334,500,40,50,79.99,19.22,80.0,27.69,80.0,33.03,80.0,42.91,7.0,45
991.6666666666666,850,50,50,79.99,57.5,80.0,64.01,80.0,70.55,80.0,79.9,0.0,60
653.3333333333333,700,35,50,35.87,85.68,38.41,89.9,40.41,94.1,45.57,98.67,0.0,115
875.0,750,25,50,79.99,14.78,80.0,19.72,80.0,24.47,80.0,30.99,3.0,45
525.0,450,40,50,79.99,23.15,80.0,32.7,80.0,38.73,80.0,58.44,2.0,45
291.6666666666667,250,50,50,79.99,37.4,80.0,54.06,80.0,69.49,80.0,93.36,1.0,45
408.3333333333333,350,20,50,79.98,74.44,80.0,85.15,80.0,90.78,80.0,97.31,0.0,60
466.6666666666667,400,35,50,79.99,23.76,80.0,34.66,80.0,40.87,80.0,54.79,6.0,45
1108.3333333333335,950,40,50,79.99,13.15,80.0,17.2,80.0,20.22,80.0,24.52,1.0,45
933.3333333333334,1000,40,50,27.79,87.75,28.65,91.86,30.36,95.71,32.24,101.79,0.0,150
1166.6666666666667,1000,45,50,79.99,12.74,80.0,17.04,80.0,20.45,80.0,24.16,2.0,45
641.6666666666666,550,30,50,79.99,16.8,80.0,26.21,80.0,31.17,80.0,38.17,3.0,45
466.6666666666667,400,25,50,79.99,21.31,80.0,34.32,80.0,43.35,80.0,53.99,7.0,45
933.3333333333334,800,40,50,79.99,56.03,80.0,63.13,80.0,69.02,80.0,81.99,0.0,60
933.3333333333334,800,25,50,79.99,52.74,80.0,59.66,80.0,63.88,80.0,73.36,0.0,60
1166.6666666666667,1000,50,50,79.99,12.31,80.0,16.5,80.0,18.38,80.0,23.72,1.0,45
700.0,600,40,50,79.99,16.21,80.0,24.87,80.0,29.88,80.0,38.32,4.0,45
350.0,300,20,50,79.98,77.56,79.99,88.98,80.0,93.47,80.0,107.1,1.0,60
350.0,300,50,50,79.99,33.86,80.0,43.67,80.0,56.35,80.0,72.92,3.0,45
875.0,750,40,50,79.99,55.45,80.0,62.94,80.0,68.38,80.0,79.91,0.0,60
291.6666666666667,250,20,50,79.53,78.76,79.99,91.01,80.0,95.93,80.0,109.66,2.0,60
233.33333333333334,200,50,50,70.27,94.69,79.97,102.74,80.0,109.36,80.0,115.8,0.0,60
525.0,450,30,50,79.99,19.6,80.0,28.57,80.0,37.61,80.0,45.2,4.0,45
991.6666666666666,850,30,50,79.99,51.81,80.0,58.25,80.0,65.32,80.0,70.39,0.0,60
93.33333333333334,100,20,50,19.62,130.89,24.19,144.68,31.83,162.3,54.15,197.33,0.0,115
1166.6666666666667,1000,30,50,79.99,51.24,80.0,55.54,80.0,61.08,80.0,75.98,0.0,60
991.6666666666666,850,20,50,79.99,11.36,80.0,15.05,80.0,18.7,80.0,27.2,3.0,45
1050.0,900,45,50,79.99,13.61,80.0,18.12,80.0,21.6,80.0,27.23,2.0,45
140.0,150,35,50,22.8,126.23,27.67,138.17,33.15,151.09,47.92,171.5,0.0,115
1050.0,900,30,50,79.99,52.52,80.0,59.79,80.0,65.05,80.0,70.83,0.0,60
408.3333333333333,350,40,50,79.96,80.54,79.99,89.71,80.0,97.08,80.0,103.14,0.0,60
350.0,300,40,50,79.97,79.44,79.99,89.81,80.0,94.84,80.0,103.32,0.0,60
875.0,750,50,50,79.99,55.85,80.0,67.24,80.0,74.92,80.0,87.33,0.0,60
583.3333333333334,500,25,50,79.99,19.49,80.0,25.73,80.0,33.79,80.0,38.17,7.0,45
1050.0,900,50,50,79.99,53.67,80.0,60.85,80.0,68.25,80.0,74.09,0.0,60
280.0,300,30,50,21.35,111.23,23.76,121.02,26.55,127.59,34.77,142.44,0.0,150
350.0,300,35,50,79.86,82.12,79.99,91.3,80.0,96.34,80.0,105.47,0.0,60
175.0,150,25,50,79.99,59.31,80.0,78.64,80.0,96.84,80.0,108.35,9.0,45
140.0,150,20,50,23.89,119.85,28.16,132.29,32.56,143.21,46.84,153.84,0.0,115
291.6666666666667,250,30,50,75.44,87.86,79.98,95.71,79.99,100.04,80.0,112.96,0.0,60
606.6666666666667,650,45,50,20.96,103.52,22.64,108.63,24.06,113.37,26.83,120.73,0.0,180
466.6666666666667,400,45,50,79.99,23.88,80.0,37.28,80.0,46.69,80.0,56.4,4.0,45
175.0,150,50,50,67.47,94.09,79.43,105.64,79.99,115.94,80.0,120.63,1.0,60
1108.3333333333335,950,45,50,79.99,50.79,80.0,56.76,80.0,63.77,80.0,71.45,0.0,60
933.3333333333334,800,30,50,79.99,53.45,80.0,59.73,80.0,66.64,80.0,75.67,0.0,60
116.66666666666667,100,45,50,55.19,103.63,65.2,118.62,79.98,126.46,80.0,150.99,1.0,60
816.6666666666666,700,50,50,79.99,59.36,80.0,66.63,80.0,74.34,80.0,83.64,0.0,60
991.6666666666666,850,45,50,79.99,54.98,80.0,61.2,80.0,69.76,80.0,74.76,0.0,60
1108.3333333333335,950,30,50,79.99,11.29,80.0,14.72,80.0,20.2,80.0,25.68,3.0,45
350.0,300,25,50,79.98,76.91,79.99,87.64,80.0,94.59,80.0,105.19,0.0,60
700.0,600,25,50,79.99,60.97,79.99,71.34,80.0,80.71,80.0,85.75,0.0,60
1108.3333333333335,950,20,50,79.99,48.89,80.0,55.45,80.0,59.35,80.0,65.81,0.0,60
233.33333333333334,200,35,50,75.61,87.01,79.99,98.97,80.0,106.83,80.0,120.9,1.0,60
291.6666666666667,250,40,50,77.06,82.98,79.99,92.86,80.0,101.28,80.0,117.9,1.0,60
560.0,600,20,50,22.28,100.08,23.74,104.83,25.53,109.63,27.2,115.95,0.0,180
291.6666666666667,250,45,50,76.9,87.62,79.99,94.62,80.0,102.43,80.0,112.0,0.0,60
350.0,300,45,50,77.74,83.61,79.99,89.32,80.0,97.28,80.0,102.43,0.0,60
175.0,150,40,50,64.6,97.94,76.82,113.5,79.99,125.15,80.0,135.92,0.0,60
466.6666666666667,500,45,50,19.88,110.36,21.06,116.06,22.29,120.96,25.05,130.54,0.0,180
758.3333333333334,650,25,50,79.99,13.36,80.0,22.7,80.0,28.09,80.0,33.96,3.0,45
466.6666666666667,400,50,50,79.98,75.03,79.99,86.52,80.0,93.17,80.0,99.63,0.0,60
525.0,450,50,50,79.99,23.29,80.0,32.0,80.0,40.37,80.0,46.3,4.0,45
840.0,900,40,50,28.32,89.29,29.56,93.58,30.94,96.65,31.72,101.97,0.0,150
408.3333333333333,350,35,50,79.98,75.97,79.99,87.48,80.0,91.93,80.0,100.81,0.0,60
525.0,450,25,50,79.99,63.1,80.0,75.01,80.0,83.16,80.0,96.55,0.0,60
58.333333333333336,50,20,50,43.11,116.48,60.74,139.84,80.0,153.87,80.0,190.04,5.0,60
116.66666666666667,100,30,50,57.05,105.45,72.62,119.96,79.99,128.61,80.0,146.4,1.0,60
291.6666666666667,250,25,50,77.43,85.32,79.99,94.38,80.0,100.63,80.0,109.85,0.0,60
408.3333333333333,350,25,50,79.99,27.09,80.0,38.71,80.0,49.75,80.0,58.6,10.0,45
58.333333333333336,50,50,50,38.35,130.19,50.72,147.56,65.93,174.42,80.0,225.52,1.0,60
46.66666666666667,50,25,50,11.88,152.31,17.38,167.39,25.64,204.55,79.99,300.65,0.0,115
116.66666666666667,100,50,50,56.8,111.49,75.46,122.86,79.99,133.76,80.0,165.37,3.0,60
746.6666666666667,800,50,50,26.94,91.87,28.29,98.08,29.53,101.12,31.31,107.69,0.0,150
641.6666666666666,550,25,50,79.99,61.38,80.0,70.23,80.0,79.3,80.0,87.41,0.0,60
1050.0,900,20,50,79.99,49.11,80.0,55.25,80.0,61.99,80.0,73.22,0.0,60
466.6666666666667,400,40,50,79.99,24.02,80.0,35.09,80.0,44.46,80.0,56.4,8.0,45
816.6666666666666,700,20,50,79.99,56.22,80.0,63.12,80.0,72.47,80.0,84.12,0.0,60
233.33333333333334,200,45,50,74.16,92.74,79.98,101.97,80.0,109.44,80.0,118.12,2.0,60
513.3333333333333,550,45,50,33.58,94.3,35.12,98.6,38.42,104.41,42.99,108.2,0.0,115
1108.3333333333335,950,50,50,79.99,54.78,80.0,61.57,80.0,65.76,80.0,81.07,0.0,60
606.6666666666667,650,40,50,35.27,87.81,37.36,92.09,39.7,97.03,41.98,102.62,0.0,115
700.0,750,45,50,24.09,97.6,25.43,101.51,26.89,105.78,29.37,110.56,0.0,165
583.3333333333334,500,20,50,79.99,58.46,79.99,75.12,80.0,80.54,80.0,88.51,0.0,60
758.3333333333334,650,30,50,79.99,56.37,80.0,66.07,80.0,74.11,80.0,85.34,0.0,60
700.0,600,50,50,79.99,65.06,80.0,75.46,80.0,79.74,80.0,84.89,0.0,60
140.0,150,45,50,12.69,138.27,15.49,150.58,18.15,169.29,24.39,193.83,0.0,165
1108.3333333333335,950,35,50,79.99,52.74,80.0,56.24,80.0,60.58,80.0,71.95,0.0,60
116.66666666666667,100,25,50,65.23,102.58,78.53,120.57,79.99,133.15,80.0,165.41,2.0,60
420.0,450,35,50,32.56,93.98,35.47,102.77,39.61,107.63,43.72,111.25,0.0,115
758.3333333333334,650,50,50,79.99,62.32,80.0,71.78,80.0,79.41,80.0,84.66,0.0,60
653.3333333333333,700,30,50,36.35,85.97,38.01,91.35,39.91,95.05,45.84,98.55,0.0,115
641.6666666666666,550,35,50,79.99,63.14,79.99,76.61,80.0,83.54,80.0,92.18,0.0,60
58.333333333333336,50,45,50,39.32,126.06,53.03,144.57,79.95,171.28,80.0,208.93,3.0,60
1050.0,900,35,50,79.99,52.23,80.0,58.47,80.0,63.06,80.0,70.4,0.0,60
93.33333333333334,100,35,50,13.29,146.73,15.75,164.6,19.81,186.51,38.41,271.05,1.0,150
233.33333333333334,200,25,50,75.28,88.13,79.98,100.37,79.99,107.65,80.0,116.16,0.0,60
58.333333333333336,50,35,50,48.0,121.32,63.15,138.58,79.99,160.32,80.0,217.39,6.0,60
1050.0,900,25,50,79.99,51.52,80.0,57.35,80.0,64.92,80.0,75.3,0.0,60
583.3333333333334,500,30,50,79.99,67.13,79.99,78.39,80.0,85.49,80.0,92.66,0.0,60
641.6666666666666,550,50,50,79.99,64.54,80.0,76.27,80.0,80.5,80.0,88.15,0.0,60
58.333333333333336,50,40,50,37.47,126.97,52.52,144.0,73.78,163.16,80.0,190.21,0.0,60
408.3333333333333,350,45,50,79.98,80.8,79.99,88.1,80.0,94.37,80.0,100.94,0.0,60
233.33333333333334,200,30,50,73.86,90.68,79.99,102.74,79.99,108.73,80.0,115.43,1.0,60
875.0,750,35,50,79.99,55.58,80.0,62.75,80.0,70.51,80.0,80.69,0.0,60
991.6666666666666,850,35,50,79.99,53.19,80.0,60.27,80.0,67.09,80.0,73.82,0.0,60
816.6666666666666,700,25,50,79.99,55.64,80.0,64.64,80.0,70.58,80.0,76.9,0.0,60
326.66666666666663,350,50,50,29.76,104.01,33.39,110.1,36.02,115.86,42.32,122.07,0.0,115
933.3333333333334,800,20,50,79.99,51.1,80.0,56.36,80.0,63.04,80.0,71.6,0.0,60
641.6666666666666,550,40,50,79.99,19.81,79.99,25.55,80.0,32.6,80.0,37.83,2.0,45
1166.6666666666667,1000,35,50,79.99,48.38,80.0,55.83,80.0,61.22,80.0,75.73,0.0,60
758.3333333333334,650,35,50,79.99,57.31,80.0,66.26,80.0,73.66,80.0,81.81,0.0,60
653.3333333333333,700,40,50,34.76,87.95,37.2,91.79,39.1,94.95,44.46,96.64,0.0,115
991.6666666666666,850,25,50,79.99,52.58,80.0,60.07,80.0,68.7,80.0,78.17,0.0,60
186.66666666666669,200,20,50,27.39,109.19,31.75,119.22,34.67,128.37,42.56,164.46,0.0,115
466.6666666666667,400,20,50,79.98,72.7,79.99,81.32,80.0,87.33,80.0,93.49,1.0,60
933.3333333333334,800,45,50,79.99,54.86,80.0,61.82,80.0,67.76,80.0,79.62,0.0,60
758.3333333333334,650,20,50,79.99,57.67,80.0,66.61,80.0,75.17,80.0,82.87,0.0,60
653.3333333333333,700,45,50,26.45,94.97,27.96,99.66,30.34,105.64,32.14,112.92,0.0,150
525.0,450,45,50,79.99,71.47,79.99,82.22,80.0,86.82,80.0,90.48,0.0,60
641.6666666666666,550,20,50,79.99,54.63,80.0,66.75,80.0,72.88,80.0,85.77,0.0,60
1166.6666666666667,1000,20,50,74.3,74.22,76.43,77.26,79.16,79.28,80.0,83.18,0.0,75
466.6666666666667,500,35,50,33.21,91.32,36.35,96.19,39.0,103.43,43.78,108.53,0.0,115
186.66666666666669,200,40,50,18.22,126.13,21.42,137.24,25.16,146.86,31.23,173.3,0.0,150
583.3333333333334,500,50,50,79.98,70.85,79.99,81.7,80.0,86.07,80.0,91.25,0.0,60
140.0,150,30,50,16.61,133.49,19.69,144.41,23.79,160.1,36.94,178.46,0.0,150
875.0,750,20,50,79.99,54.99,80.0,60.59,80.0,65.98,80.0,77.21,0.0,60
116.66666666666667,100,40,50,79.98,87.89,79.99,101.49,80.0,114.07,80.0,135.75,5.0,45
560.0,600,30,50,21.12,102.02,23.22,107.32,24.51,111.93,28.54,117.93,0.0,180
700.0,600,45,50,79.99,60.67,80.0,72.73,80.0,81.39,80.0,88.74,0.0,60
875.0,750,30,50,79.99,53.21,80.0,61.64,80.0,67.91,80.0,73.74,0.0,60
58.333333333333336,50,30,50,40.96,120.26,55.14,146.41,77.4,169.47,80.0,196.82,2.0,60
291.6666666666667,250,35,50,79.99,38.2,80.0,59.15,80.0,68.67,80.0,86.27,5.0,45
991.6666666666666,850,50,50,70.16,79.28,73.97,81.95,77.17,84.91,80.0,87.45,0.0,75
326.66666666666663,350,30,50,23.28,111.58,25.75,118.72,27.66,124.67,29.57,131.8,0.0,150
933.3333333333334,800,35,50,72.54,76.49,75.25,80.37,78.01,83.0,79.99,86.5,0.0,75
991.6666666666666,850,40,50,79.99,55.06,80.0,63.03,80.0,69.35,80.0,79.02,0.0,60
583.3333333333334,500,40,50,79.99,64.99,80.0,79.9,80.0,85.69,80.0,91.76,0.0,60
875.0,750,25,50,79.99,55.43,80.0,60.87,80.0,66.37,80.0,76.78,0.0,60
525.0,450,20,50,79.99,63.36,80.0,79.22,80.0,83.73,80.0,88.04,0.0,60
525.0,450,40,50,79.99,71.8,80.0,81.82,80.0,85.36,80.0,93.96,0.0,60
1166.6666666666667,1000,25,50,79.99,48.14,80.0,54.4,80.0,59.16,80.0,66.39,0.0,60
408.3333333333333,350,20,50,66.03,86.36,70.48,92.93,76.79,99.56,80.0,108.13,0.0,75
466.6666666666667,400,35,50,79.97,76.45,79.99,85.99,80.0,91.56,80.0,96.96,1.0,60
291.6666666666667,250,50,50,74.98,89.57,79.99,98.71,80.0,105.88,80.0,119.7,1.0,60
1166.6666666666667,1000,45,50,79.99,51.9,80.0,58.18,80.0,65.28,80.0,77.71,0.0,60
933.3333333333334,1000,40,50,25.59,89.71,26.75,93.95,27.53,97.19,29.78,103.24,0.0,165
933.3333333333334,800,40,50,71.11,78.01,73.67,82.4,76.98,85.75,79.99,92.29,0.0,75
350.0,300,50,50,77.24,83.9,79.99,90.64,80.0,97.28,80.0,104.48,0.0,60
700.0,600,35,50,79.99,14.69,80.0,22.34,80.0,30.51,80.0,42.86,3.0,45
933.3333333333334,800,25,50,72.44,76.92,75.89,80.09,77.53,83.74,80.0,86.42,0.0,75
466.6666666666667,400,25,50,79.99,70.7,79.99,82.75,80.0,89.46,80.0,96.08,0.0,60
373.33333333333337,400,30,50,20.81,106.18,23.24,113.04,26.62,121.54,30.43,135.46,0.0,165
233.33333333333334,200,50,50,53.26,104.35,62.23,112.65,69.35,119.52,79.98,135.8,0.0,75
350.0,300,20,50,64.69,89.84,70.82,94.69,78.66,100.96,79.99,111.72,0.0,75
641.6666666666666,550,30,50,79.99,60.88,80.0,69.88,80.0,79.1,80.0,85.7,0.0,60
700.0,600,40,50,79.99,61.99,80.0,71.85,80.0,78.74,80.0,87.42,0.0,60
991.6666666666666,850,30,50,72.1,76.66,75.49,80.61,77.67,82.41,79.99,88.94,0.0,75
291.6666666666667,250,20,50,60.77,93.05,67.29,100.79,75.42,110.0,80.0,124.25,0.0,75
1108.3333333333335,950,40,50,79.99,52.53,80.0,59.14,80.0,63.72,80.0,75.31,0.0,60
875.0,750,40,50,70.5,79.18,73.33,82.04,77.0,85.92,79.99,88.34,0.0,75
1166.6666666666667,1000,30,50,73.23,74.47,75.51,77.12,78.45,79.7,79.97,83.36,0.0,75
1166.6666666666667,1000,50,50,79.99,52.68,79.99,58.26,80.0,63.24,80.0,72.7,0.0,60
653.3333333333333,700,35,50,26.85,94.24,28.82,97.99,30.0,103.5,33.15,108.27,0.0,150
1050.0,900,30,50,72.98,76.05,76.01,80.19,79.19,81.96,80.0,86.94,0.0,75
1108.3333333333335,950,25,50,79.99,10.89,80.0,15.08,80.0,20.08,80.0,25.9,2.0,45
350.0,300,40,50,62.84,92.03,69.23,98.71,74.32,103.61,79.99,115.19,0.0,75
991.6666666666666,850,20,50,79.99,51.36,80.0,56.31,80.0,62.53,80.0,73.02,0.0,60
408.3333333333333,350,40,50,62.72,90.65,69.17,96.96,75.14,100.75,79.98,110.96,0.0,75
1050.0,900,45,50,79.99,53.46,80.0,59.54,80.0,65.87,80.0,78.23,0.0,60
525.0,450,30,50,79.99,68.43,80.0,78.98,80.0,83.37,80.0,89.84,0.0,60
350.0,300,35,50,59.91,90.89,67.91,97.61,77.68,104.47,80.0,118.34,0.0,75
175.0,150,50,50,50.29,109.1,57.16,120.59,71.51,130.17,80.0,148.28,0.0,75
583.3333333333334,500,25,50,79.99,64.23,79.99,74.78,80.0,83.0,80.0,91.08,0.0,60
291.6666666666667,250,30,50,62.44,92.37,67.15,101.86,75.0,109.51,80.0,125.61,0.0,75
875.0,750,50,50,71.13,78.41,74.46,83.26,77.37,85.92,79.97,88.34,0.0,75
1050.0,900,50,50,72.06,76.67,74.99,79.65,76.68,82.25,79.99,86.3,0.0,75
466.6666666666667,400,45,50,79.98,72.61,79.99,83.89,80.0,90.75,80.0,98.37,0.0,60
1108.3333333333335,950,30,50,79.99,52.4,80.0,58.14,80.0,63.07,80.0,69.37,0.0,60
1108.3333333333335,950,45,50,71.58,77.15,74.28,79.77,77.13,81.71,80.0,84.17,0.0,75
93.33333333333334,100,20,50,14.44,145.69,18.76,160.16,22.95,184.25,53.28,259.06,0.0,150
933.3333333333334,800,30,50,72.3,77.16,75.2,79.67,78.2,82.74,80.0,88.49,0.0,75
116.66666666666667,100,45,50,39.75,117.89,49.19,130.98,60.2,142.72,79.99,170.11,0.0,75
140.0,150,35,50,14.8,134.17,18.18,144.33,21.42,154.17,27.92,175.07,0.0,150
816.6666666666666,700,50,50,69.47,80.02,73.51,82.89,75.75,86.07,80.0,88.99,0.0,75
350.0,300,25,50,62.17,88.24,67.38,97.76,76.51,104.14,79.99,113.35,0.0,75
291.6666666666667,250,45,50,59.54,97.03,64.98,102.91,78.1,110.86,80.0,123.56,0.0,75
700.0,600,25,50,70.17,79.81,72.39,83.78,77.51,88.56,80.0,92.94,0.0,75
291.6666666666667,250,40,50,57.51,97.03,63.11,104.39,67.68,109.03,79.97,115.91,0.0,75
140.0,150,20,50,17.71,132.93,20.36,142.48,23.48,156.01,33.0,196.52,0.0,150
991.6666666666666,850,45,50,71.18,76.42,74.18,80.05,77.06,83.48,79.99,86.57,0.0,75
175.0,150,40,50,50.74,108.16,61.28,116.63,66.85,124.61,78.46,140.13,0.0,75
350.0,300,45,50,61.38,92.16,66.5,100.26,72.05,107.73,80.0,115.39,0.0,75
233.33333333333334,200,35,50,57.54,102.2,65.11,109.67,75.82,118.77,80.0,139.58,0.0,75
175.0,150,25,50,68.13,90.37,79.98,104.4,80.0,115.89,80.0,135.39,0.0,60
280.0,300,30,50,18.35,119.96,21.05,126.11,23.78,134.75,28.68,141.84,0.0,165
1108.3333333333335,950,20,50,73.1,75.87,75.82,78.29,78.02,81.33,79.99,85.88,0.0,75
466.6666666666667,400,50,50,63.99,89.83,68.81,94.11,76.03,98.28,80.0,105.28,0.0,75
700.0,600,20,50,79.99,15.55,80.0,22.03,80.0,30.82,80.0,38.92,5.0,45
291.6666666666667,250,25,50,60.5,92.74,67.42,100.73,75.34,107.84,80.0,115.26,0.0,75
116.66666666666667,100,50,50,41.47,121.67,50.27,133.72,58.77,145.7,69.49,176.14,0.0,75
408.3333333333333,350,35,50,62.14,89.18,66.67,94.74,73.18,100.15,79.98,108.13,0.0,75
116.66666666666667,100,30,50,42.33,117.41,54.01,131.25,76.34,146.85,79.99,168.94,0.0,75
525.0,450,25,50,67.82,82.74,71.71,87.47,76.32,92.05,80.0,101.52,0.0,75
758.3333333333334,650,45,50,79.99,16.65,80.0,23.93,80.0,28.12,80.0,36.21,1.0,45
58.333333333333336,50,20,50,28.89,123.95,45.5,142.82,79.99,166.91,80.0,233.67,4.0,75
58.333333333333336,50,50,50,26.18,132.42,37.93,152.54,54.36,170.96,79.98,267.76,1.0,75
840.0,900,40,50,24.77,93.5,25.95,97.5,27.17,100.69,28.77,105.99,0.0,165
641.6666666666666,550,25,50,69.6,79.41,74.23,83.79,79.62,88.36,80.0,95.67,0.0,75
1050.0,900,20,50,73.46,74.98,76.12,77.81,78.97,79.35,80.0,85.97,0.0,75
816.6666666666666,700,20,50,72.4,76.64,75.81,80.22,79.96,83.18,80.0,88.61,0.0,75
758.3333333333334,650,30,50,70.22,79.39,73.97,82.96,77.85,85.64,79.99,90.41,0.0,75
1108.3333333333335,950,50,50,71.71,76.81,74.02,79.59,76.52,82.08,80.0,86.55,0.0,75
525.0,450,50,50,79.98,71.22,79.99,83.05,80.0,88.92,80.0,96.19,0.0,60
583.3333333333334,500,45,50,79.99,24.43,80.0,29.27,80.0,35.02,80.0,42.94,1.0,45
116.66666666666667,100,25,50,46.37,112.67,56.16,127.78,72.38,143.28,80.0,171.13,1.0,75
700.0,600,50,50,67.91,82.6,72.47,85.7,76.51,88.37,79.99,93.24,0.0,75
233.33333333333334,200,45,50,55.22,99.71,63.99,105.89,70.26,116.64,79.98,143.72,0.0,75
583.3333333333334,500,20,50,67.71,81.36,72.02,86.41,77.43,90.97,80.0,95.61,0.0,75
758.3333333333334,650,25,50,79.99,60.1,80.0,69.55,80.0,74.78,80.0,84.13,0.0,60
1050.0,900,35,50,72.04,77.31,75.49,81.0,77.47,82.73,79.99,87.33,0.0,75
58.333333333333336,50,35,50,30.48,132.5,40.1,152.55,60.26,172.4,79.99,222.66,1.0,75
46.66666666666667,50,25,50,7.65,156.06,10.75,182.03,17.39,205.38,32.68,307.89,0.0,150
233.33333333333334,200,25,50,59.41,96.32,66.41,105.9,78.99,115.45,80.0,127.32,0.0,75
58.333333333333336,50,45,50,27.19,138.46,34.54,154.86,44.16,177.76,71.37,253.69,0.0,75
408.3333333333333,350,25,50,79.98,74.59,79.99,86.05,80.0,92.47,80.0,100.32,0.0,60
746.6666666666667,800,50,50,24.54,95.84,25.57,99.62,26.77,103.99,28.85,106.69,0.0,165
758.3333333333334,650,50,50,68.58,81.49,71.53,85.82,73.79,89.11,79.98,94.93,0.0,75
1108.3333333333335,950,35,50,72.23,76.56,75.3,79.55,77.53,81.4,79.99,83.39,0.0,75
641.6666666666666,550,50,50,68.71,84.26,72.41,88.33,75.17,92.54,79.99,97.57,0.0,75
1050.0,900,25,50,72.7,75.87,76.17,79.23,78.17,82.01,79.99,87.47,0.0,75
408.3333333333333,350,45,50,62.41,90.09,68.1,94.97,74.74,101.27,80.0,109.35,0.0,75
641.6666666666666,550,35,50,68.2,81.92,72.54,85.97,75.79,88.52,79.99,93.09,0.0,75
583.3333333333334,500,30,50,69.68,81.07,73.47,86.04,79.31,90.31,80.0,93.11,0.0,75
233.33333333333334,200,30,50,56.94,98.71,66.69,107.5,78.22,118.96,80.0,126.96,0.0,75
58.333333333333336,50,40,50,27.94,136.11,38.22,157.99,53.3,177.68,80.0,202.74,2.0,75
466.6666666666667,400,40,50,79.98,78.26,80.0,84.9,80.0,90.98,80.0,95.73,0.0,60
1166.6666666666667,1000,35,50,73.57,74.84,76.24,77.35,78.07,79.29,79.99,83.42,0.0,75
875.0,750,35,50,72.27,77.89,75.61,81.85,78.69,86.29,80.0,90.86,0.0,75
653.3333333333333,700,30,50,26.96,93.65,28.61,97.73,30.1,101.28,34.42,105.07,0.0,150
991.6666666666666,850,35,50,71.75,77.2,74.13,81.42,77.21,83.76,80.0,89.59,0.0,75
606.6666666666667,650,40,50,26.22,95.6,27.84,102.11,29.94,105.88,31.7,111.73,0.0,150
513.3333333333333,550,45,50,24.98,100.37,26.91,106.39,28.98,109.58,32.5,118.24,0.0,150
700.0,750,45,50,21.62,98.06,23.06,103.2,24.28,106.65,26.33,111.73,0.0,180
140.0,150,45,50,11.38,145.24,13.24,157.89,15.23,169.29,26.23,195.16,0.0,180
758.3333333333334,650,35,50,70.26,79.58,74.21,83.52,77.68,86.62,80.0,92.72,0.0,75
641.6666666666666,550,40,50,79.99,62.45,80.0,75.96,80.0,83.69,80.0,91.33,0.0,60
816.6666666666666,700,25,50,71.83,77.43,75.47,81.42,78.75,84.7,80.0,87.88,0.0,75
466.6666666666667,400,20,50,66.6,84.7,72.99,90.09,79.12,94.84,80.0,100.5,0.0,75
653.3333333333333,700,40,50,26.31,94.73,27.65,98.84,29.33,102.29,32.23,109.53,0.0,150
933.3333333333334,800,20,50,73.74,75.17,76.77,77.74,79.81,80.53,80.0,85.46,0.0,75
93.33333333333334,100,35,50,9.82,147.45,12.28,166.55,15.71,181.48,30.74,218.12,0.0,165
420.0,450,35,50,23.91,104.74,25.74,111.54,28.32,117.1,33.39,124.35,0.0,150
758.3333333333334,650,20,50,72.52,76.38,75.63,80.84,79.95,85.04,80.0,90.21,0.0,75
933.3333333333334,800,45,50,70.72,79.35,73.63,82.42,77.9,85.71,80.0,89.9,0.0,75
641.6666666666666,550,20,50,68.82,80.37,73.28,84.52,76.98,88.28,80.0,94.55,0.0,75
583.3333333333334,500,50,50,65.73,84.57,70.35,91.43,74.85,93.9,79.99,98.55,0.0,75
991.6666666666666,850,25,50,72.42,76.19,75.4,79.39,78.41,82.41,80.0,84.85,0.0,75
875.0,750,20,50,73.16,76.82,76.55,81.03,79.48,83.69,80.0,91.63,0.0,75
991.6666666666666,850,40,50,71.79,77.31,74.24,79.81,77.15,84.25,79.99,88.58,0.0,75
525.0,450,45,50,66.52,86.72,72.1,92.42,75.27,97.6,79.98,102.07,0.0,75
1166.6666666666667,1000,20,50,61.11,76.67,64.38,79.39,66.32,83.28,72.45,87.31,0.0,90
875.0,750,30,50,70.62,78.98,74.66,81.62,76.91,85.13,80.0,90.19,0.0,75
58.333333333333336,50,30,50,27.73,136.41,38.89,157.95,58.85,179.53,80.0,212.92,2.0,75
991.6666666666666,850,50,50,58.96,80.26,60.92,84.3,64.04,86.97,70.54,90.04,0.0,90
933.3333333333334,800,35,50,59.56,80.22,61.99,83.49,64.59,86.77,69.55,90.85,0.0,90
583.3333333333334,500,40,50,67.97,82.34,71.64,88.23,75.29,92.41,79.99,99.79,0.0,75
408.3333333333333,350,20,50,53.43,89.65,57.55,96.27,63.57,105.21,72.77,116.62,0.0,90
700.0,600,45,50,68.77,81.89,72.49,86.03,76.33,89.15,80.0,95.12,0.0,75
326.66666666666663,350,50,50,20.8,109.94,23.04,114.71,25.63,122.66,30.66,128.81,0.0,150
350.0,300,50,50,59.78,93.87,66.31,101.31,73.69,106.78,80.0,113.02,0.0,75
653.3333333333333,700,45,50,23.86,98.28,25.21,102.91,26.66,106.76,31.78,115.29,0.0,165
525.0,450,20,50,67.52,82.69,72.88,88.12,77.33,93.51,79.99,99.67,0.0,75
525.0,450,40,50,66.72,84.22,71.59,89.7,77.08,93.25,80.0,97.95,0.0,75
186.66666666666669,200,40,50,15.8,128.01,18.04,140.08,21.53,149.31,26.52,167.77,0.0,165
291.6666666666667,250,50,50,58.09,95.88,63.03,101.36,73.75,110.33,80.0,123.99,0.0,75
186.66666666666669,200,20,50,19.12,118.52,23.05,129.21,28.54,140.46,38.81,168.49,0.0,150
140.0,150,30,50,13.65,137.01,16.65,149.03,19.94,160.42,27.72,175.02,0.0,165
933.3333333333334,800,40,50,59.12,81.91,62.08,85.55,64.35,88.56,67.66,93.36,0.0,90
466.6666666666667,400,35,50,65.21,85.55,70.98,91.63,78.36,94.99,79.99,104.82,0.0,75
933.3333333333334,1000,40,50,23.69,92.14,24.67,95.71,25.7,100.26,27.48,107.6,0.0,180
875.0,750,25,50,72.91,76.21,75.86,80.21,78.29,82.84,80.0,86.02,0.0,75
933.3333333333334,800,25,50,59.43,80.37,62.25,83.36,64.66,87.4,70.88,90.09,0.0,90
466.6666666666667,500,35,50,24.67,99.92,26.69,106.91,28.87,114.39,32.9,123.01,0.0,150
116.66666666666667,100,40,50,57.18,109.46,68.97,123.37,79.99,132.98,80.0,162.03,3.0,60
291.6666666666667,250,20,50,48.85,100.76,53.05,107.52,61.46,114.65,79.98,128.09,0.0,90
233.33333333333334,200,50,50,44.73,106.82,51.36,115.19,58.13,125.84,71.24,139.93,0.0,90
350.0,300,20,50,54.52,92.94,59.3,101.74,63.55,106.87,71.12,114.41,0.0,90
291.6666666666667,250,35,50,75.59,83.88,79.99,95.1,80.0,101.23,80.0,108.95,1.0,60
875.0,750,40,50,58.81,81.85,61.14,85.13,64.79,87.1,70.78,93.52,0.0,90
466.6666666666667,400,25,50,66.76,84.28,71.65,91.0,77.49,98.88,80.0,108.01,0.0,75
700.0,600,30,50,79.99,16.43,80.0,22.89,80.0,27.66,80.0,40.82,4.0,45
326.66666666666663,350,30,50,19.73,111.54,21.58,120.64,24.14,128.1,27.78,133.1,0.0,165
641.6666666666666,550,30,50,69.55,80.9,73.76,85.01,77.73,88.14,80.0,94.4,0.0,75
1166.6666666666667,1000,45,50,72.67,76.08,75.04,79.46,77.32,81.93,80.0,86.58,0.0,75
991.6666666666666,850,30,50,59.41,78.57,62.3,82.41,64.67,87.24,69.7,89.38,0.0,90
700.0,600,40,50,69.65,79.61,73.65,84.41,78.14,89.35,80.0,94.7,0.0,75
350.0,300,40,50,50.47,96.57,55.18,104.98,60.04,112.61,67.89,120.5,0.0,90
1166.6666666666667,1000,30,50,60.88,78.34,62.86,81.7,65.17,84.12,67.2,87.95,0.0,90
408.3333333333333,350,40,50,50.6,92.88,57.37,98.49,61.75,104.29,74.5,112.74,0.0,90
1166.6666666666667,1000,25,50,72.88,76.03,75.59,77.66,78.2,79.73,79.99,83.94,0.0,75
1108.3333333333335,950,40,50,72.55,77.04,75.57,79.28,77.93,81.85,80.0,85.94,0.0,75
1166.6666666666667,1000,50,50,72.94,75.87,75.74,79.07,77.94,82.47,79.99,86.21,0.0,75
175.0,150,50,50,38.33,117.11,46.88,126.68,54.67,138.7,80.0,151.95,0.0,90
525.0,450,30,50,66.23,85.22,71.34,89.9,76.81,94.51,80.0,102.36,0.0,75
1050.0,900,30,50,59.67,78.94,62.57,82.07,64.28,84.85,67.67,87.08,0.0,90
350.0,300,35,50,49.54,95.81,53.67,104.89,56.01,111.12,78.97,124.41,0.0,90
291.6666666666667,250,30,50,48.44,97.79,54.29,103.83,60.69,113.43,70.82,122.6,0.0,90
466.6666666666667,400,45,50,65.0,86.2,71.16,91.96,73.86,97.34,78.34,98.77,0.0,75
1050.0,900,45,50,71.82,78.34,74.54,81.13,77.11,83.36,79.98,86.96,0.0,75
991.6666666666666,850,20,50,73.72,73.78,76.6,76.77,79.34,79.86,80.0,85.18,0.0,75
700.0,600,35,50,79.99,60.9,80.0,70.95,80.0,76.25,80.0,83.52,0.0,60
1050.0,900,50,50,59.61,80.6,61.8,83.37,64.94,85.51,67.56,90.48,0.0,90
875.0,750,50,50,57.45,83.72,59.76,86.92,62.15,90.47,68.64,95.82,0.0,90
653.3333333333333,700,35,50,24.11,94.72,25.93,100.44,27.46,104.89,29.5,111.2,0.0,165
583.3333333333334,500,25,50,69.27,81.3,73.12,87.51,76.63,91.48,80.0,101.8,0.0,75
1108.3333333333335,950,45,50,60.09,79.88,62.69,83.26,64.99,86.5,67.95,91.91,0.0,90
373.33333333333337,400,30,50,18.51,112.2,20.54,119.74,22.35,126.05,25.87,136.17,0.0,180
116.66666666666667,100,45,50,29.87,124.67,36.51,138.15,45.48,158.98,69.43,192.13,0.0,90
816.6666666666666,700,50,50,57.47,83.99,60.26,87.08,64.05,89.7,68.16,96.68,0.0,90
933.3333333333334,800,30,50,59.3,80.89,62.22,83.81,65.62,86.68,69.37,90.27,0.0,90
700.0,600,25,50,57.44,82.78,60.9,86.86,65.03,90.34,68.31,95.9,0.0,90
291.6666666666667,250,45,50,45.35,103.04,49.66,108.99,58.25,117.91,74.04,126.47,0.0,90
350.0,300,25,50,52.07,90.25,59.0,99.82,64.95,108.25,76.71,111.17,0.0,90
1108.3333333333335,950,30,50,73.32,75.24,75.94,77.88,77.93,81.92,80.0,84.6,0.0,75
291.6666666666667,250,40,50,46.19,102.1,51.6,110.94,60.49,117.29,79.98,136.23,0.0,90
233.33333333333334,200,35,50,43.93,104.45,52.36,114.44,60.69,126.02,75.23,139.69,0.0,90
1108.3333333333335,950,25,50,79.99,52.54,80.0,57.73,80.0,65.11,80.0,72.34,0.0,60
350.0,300,45,50,48.79,97.92,55.73,107.54,63.43,113.64,71.79,120.57,0.0,90
991.6666666666666,850,45,50,58.72,80.66,61.44,84.49,63.69,86.61,68.2,90.87,0.0,90
175.0,150,40,50,41.14,109.86,50.14,122.37,58.82,134.26,79.99,158.93,0.0,90
291.6666666666667,250,25,50,49.41,99.88,57.18,108.13,66.14,117.86,80.0,125.62,1.0,90
525.0,450,25,50,56.58,86.83,60.55,92.07,64.06,98.21,72.05,104.06,0.0,90
700.0,600,20,50,79.99,61.11,79.99,71.13,80.0,78.49,80.0,86.8,0.0,60
140.0,150,35,50,13.66,137.8,16.48,151.93,18.82,168.69,25.79,177.62,0.0,165
1108.3333333333335,950,20,50,60.77,77.56,63.39,81.96,66.64,84.65,72.47,87.39,0.0,90
116.66666666666667,100,50,50,31.38,124.91,40.73,141.07,52.06,156.1,79.99,177.1,0.0,90
466.6666666666667,400,50,50,51.91,92.83,56.53,98.95,61.87,103.74,66.43,111.03,0.0,90
116.66666666666667,100,30,50,32.43,126.99,40.39,139.37,56.17,156.91,79.97,188.92,0.0,90
93.33333333333334,100,20,50,11.96,149.92,15.15,170.94,19.67,196.68,30.7,247.24,0.0,165
408.3333333333333,350,35,50,53.47,91.17,57.69,97.56,62.04,104.75,76.5,113.61,0.0,90
58.333333333333336,50,20,50,24.05,144.44,35.05,165.53,47.63,182.39,72.25,211.95,1.0,90
58.333333333333336,50,50,50,23.45,155.92,30.46,178.13,38.93,213.96,58.23,259.06,0.0,90
140.0,150,20,50,14.28,132.04,17.1,147.83,21.1,161.43,28.48,180.09,0.0,165
1050.0,900,20,50,61.64,76.54,64.1,80.95,67.89,84.89,69.43,88.32,0.0,90
280.0,300,30,50,16.4,116.99,19.31,126.57,21.44,136.86,26.95,147.57,0.0,180
641.6666666666666,550,25,50,57.85,84.22,61.89,88.86,64.62,92.66,72.18,96.47,0.0,90
758.3333333333334,650,30,50,57.56,83.34,60.55,87.63,64.2,90.74,71.78,96.96,0.0,90
1108.3333333333335,950,50,50,60.08,79.19,62.65,81.95,64.5,85.11,69.21,88.08,0.0,90
700.0,600,50,50,56.14,85.11,59.28,89.12,63.27,93.23,71.66,100.65,0.0,90
816.6666666666666,700,20,50,59.7,81.07,62.75,85.33,65.32,88.25,69.66,91.11,0.0,90
233.33333333333334,200,25,50,45.64,97.91,53.48,110.01,59.14,119.88,76.31,133.34,0.0,90
116.66666666666667,100,25,50,33.74,116.26,40.87,128.96,47.87,141.86,79.99,162.88,2.0,90
1050.0,900,35,50,60.19,79.63,62.72,82.64,65.17,84.68,68.25,90.27,0.0,90
583.3333333333334,500,20,50,56.59,83.88,60.65,88.69,65.53,92.6,74.49,101.54,0.0,90
175.0,150,25,50,52.83,103.61,64.08,115.32,79.01,125.5,80.0,143.3,2.0,75
233.33333333333334,200,45,50,43.53,105.94,51.35,117.27,58.52,124.37,76.52,137.02,0.0,90
758.3333333333334,650,45,50,79.99,63.12,80.0,70.96,80.0,77.45,80.0,80.9,0.0,60
58.333333333333336,50,35,50,21.27,142.42,27.03,163.89,35.67,185.32,75.14,233.63,0.0,90
58.333333333333336,50,45,50,19.02,143.71,25.14,167.63,34.95,214.5,78.06,269.56,0.0,90
641.6666666666666,550,50,50,55.06,87.67,59.15,92.56,62.22,97.94,67.23,100.38,0.0,90
525.0,450,50,50,66.78,83.58,70.61,88.47,76.85,93.3,80.0,98.94,0.0,75
1166.6666666666667,1000,35,50,60.77,78.84,62.39,81.67,63.86,83.82,68.0,89.12,0.0,90
840.0,900,40,50,22.42,95.87,24.04,98.57,25.53,102.91,26.7,108.68,0.0,180
758.3333333333334,650,50,50,57.28,85.23,60.22,89.36,62.57,92.56,66.78,96.42,0.0,90
1108.3333333333335,950,35,50,59.68,77.71,62.23,82.43,64.94,86.23,68.3,90.47,0.0,90
1050.0,900,25,50,60.44,78.74,63.25,82.42,65.92,85.06,69.31,87.94,0.0,90
58.333333333333336,50,40,50,21.44,142.42,26.28,159.63,32.76,180.18,80.0,214.13,0.0,90
233.33333333333334,200,30,50,43.85,103.8,50.22,110.64,58.85,120.57,75.1,131.42,0.0,90
408.3333333333333,350,45,50,50.07,94.28,57.12,100.61,61.86,105.29,70.68,112.77,0.0,90
641.6666666666666,550,35,50,55.49,85.56,58.82,91.95,63.21,94.88,68.4,99.56,0.0,90
991.6666666666666,850,35,50,59.74,79.77,62.1,82.94,64.17,85.1,68.07,90.06,0.0,90
583.3333333333334,500,30,50,56.48,82.7,61.63,91.51,65.37,95.07,69.62,102.73,0.0,90
816.6666666666666,700,25,50,58.39,80.64,60.51,84.44,63.25,89.02,67.16,93.13,0.0,90
583.3333333333334,500,45,50,79.99,66.91,79.99,80.53,80.0,84.57,80.0,90.21,0.0,60
933.3333333333334,800,20,50,60.88,78.34,63.11,81.45,65.46,85.61,68.02,90.14,0.0,90
653.3333333333333,700,40,50,24.22,96.93,25.66,102.11,26.97,106.02,29.21,115.28,0.0,165
583.3333333333334,500,50,50,54.87,89.05,58.92,95.72,62.22,99.4,69.13,105.39,0.0,90
758.3333333333334,650,25,50,70.69,78.9,74.32,81.92,78.12,85.99,79.99,92.21,0.0,75
875.0,750,35,50,58.51,82.55,61.11,85.61,63.52,87.97,67.86,90.6,0.0,90
466.6666666666667,400,20,50,55.8,88.11,59.83,94.56,65.72,99.57,79.94,109.57,0.0,90
408.3333333333333,350,25,50,63.91,86.79,71.25,95.1,76.49,100.71,80.0,105.0,0.0,75
641.6666666666666,550,40,50,68.08,82.05,71.66,86.63,75.48,90.07,79.99,93.93,0.0,75
46.66666666666667,50,25,50,7.06,160.27,9.13,189.29,13.24,205.62,29.54,273.23,0.0,165
758.3333333333334,650,35,50,56.15,83.34,59.21,88.26,62.39,90.65,68.66,95.0,0.0,90
933.3333333333334,800,45,50,58.69,81.62,61.53,84.97,63.57,88.93,72.04,94.71,0.0,90
466.6666666666667,400,40,50,65.07,86.13,70.22,91.91,74.27,97.56,79.99,103.88,0.0,75
746.6666666666667,800,50,50,21.54,97.94,22.89,103.82,24.56,106.48,26.12,113.26,0.0,180
758.3333333333334,650,20,50,58.83,80.88,62.6,84.42,65.06,87.95,70.09,94.46,0.0,90
991.6666666666666,850,25,50,59.94,79.53,62.65,82.73,65.93,85.47,68.4,90.87,0.0,90
1166.6666666666667,1000,20,50,48.09,78.84,50.37,84.03,52.32,85.81,54.14,88.54,0.0,115
653.3333333333333,700,30,50,23.95,94.74,26.15,100.61,27.7,105.69,31.09,111.36,0.0,165
933.3333333333334,800,35,50,45.47,84.93,47.88,89.23,51.51,93.45,53.9,96.39,0.0,115
641.6666666666666,550,20,50,58.19,84.15,62.95,89.57,66.52,92.93,71.21,97.15,0.0,90
875.0,750,30,50,59.53,81.73,62.33,84.72,65.48,87.56,70.17,94.4,0.0,90
991.6666666666666,850,50,50,44.6,85.82,47.64,89.41,50.22,92.7,55.17,95.72,0.0,115
583.3333333333334,500,40,50,55.68,88.37,58.56,93.42,61.73,97.71,65.84,102.45,0.0,90
525.0,450,45,50,53.72,90.08,57.93,94.82,62.47,100.29,67.04,104.06,0.0,90
606.6666666666667,650,40,50,23.68,97.63,25.14,104.72,26.51,107.26,30.05,112.44,0.0,165
991.6666666666666,850,40,50,58.92,81.92,61.2,84.84,62.94,86.16,73.24,90.34,0.0,90
350.0,300,50,50,48.38,97.6,53.04,104.31,60.69,111.26,73.65,116.48,0.0,90
408.3333333333333,350,20,50,40.39,97.87,45.19,104.39,49.61,111.55,53.21,119.1,0.0,115
875.0,750,20,50,60.24,79.01,62.43,83.3,64.93,87.11,69.32,95.02,0.0,90
58.333333333333336,50,30,50,20.35,139.39,29.92,163.02,41.14,191.04,79.99,264.15,0.0,90
175.0,150,45,50,79.99,65.43,80.0,90.86,80.0,101.42,80.0,114.33,7.0,45
525.0,450,20,50,54.47,86.07,60.03,94.0,65.68,97.91,70.35,103.56,0.0,90
933.3333333333334,800,25,50,46.01,84.35,48.45,89.08,51.2,91.18,56.51,93.89,0.0,115
875.0,750,45,50,79.99,15.6,80.0,20.65,80.0,25.24,80.0,32.73,2.0,45
291.6666666666667,250,20,50,36.34,105.05,41.39,111.69,50.19,123.52,60.01,128.68,0.0,115
291.6666666666667,250,50,50,45.69,103.97,51.54,110.24,58.74,118.22,75.05,126.96,0.0,90
700.0,600,45,50,57.06,83.86,59.2,87.61,63.95,91.41,73.29,97.75,0.0,90
525.0,450,40,50,54.42,88.06,58.72,95.74,63.27,99.59,70.93,107.42,0.0,90
1166.6666666666667,1000,40,50,79.99,13.52,80.0,16.55,80.0,20.67,80.0,28.27,1.0,45
513.3333333333333,550,45,50,22.67,101.07,25.03,106.78,26.85,111.77,30.68,119.14,0.0,165
933.3333333333334,800,40,50,45.36,85.44,47.43,89.11,50.67,92.38,54.37,98.3,0.0,115
875.0,750,25,50,59.5,80.19,62.4,84.8,65.52,88.18,68.49,95.61,0.0,90
875.0,750,40,50,44.85,85.83,47.98,92.13,49.89,95.45,53.39,99.64,0.0,115
93.33333333333334,100,35,50,8.74,149.23,10.43,167.65,12.57,179.01,23.4,200.48,0.0,180
233.33333333333334,200,50,50,29.57,118.38,34.13,127.47,41.1,139.04,49.36,149.02,0.0,115
420.0,450,35,50,21.26,106.48,23.37,114.39,25.89,119.12,28.47,125.49,0.0,165
466.6666666666667,400,35,50,53.79,89.96,57.5,95.88,62.54,102.15,72.52,107.86,0.0,90
1166.6666666666667,1000,45,50,59.95,78.93,62.9,82.53,64.58,84.64,70.07,87.7,0.0,90
350.0,300,20,50,37.41,98.75,42.58,106.98,49.07,114.07,55.94,131.24,0.0,115
991.6666666666666,850,30,50,45.46,83.28,47.29,88.0,49.5,92.36,55.45,98.62,0.0,115
641.6666666666666,550,30,50,56.25,84.49,60.46,89.62,66.05,91.96,72.3,99.44,0.0,90
1166.6666666666667,1000,30,50,46.7,81.92,48.39,85.22,50.14,88.43,54.15,93.3,0.0,115
466.6666666666667,400,25,50,54.78,88.77,57.69,95.36,64.89,97.82,71.96,109.17,0.0,90
350.0,300,40,50,35.78,105.4,40.71,114.89,45.18,120.3,53.04,124.05,0.0,115
326.66666666666663,350,50,50,18.87,118.14,20.95,124.51,23.78,131.31,26.34,138.49,0.0,165
653.3333333333333,700,45,50,21.35,100.77,23.16,105.07,24.53,109.18,26.9,114.52,0.0,180
186.66666666666669,200,40,50,13.38,137.62,15.87,148.89,17.89,157.75,26.17,181.35,0.0,180
408.3333333333333,350,40,50,37.59,101.32,42.07,110.69,44.85,119.6,47.83,127.34,0.0,115
700.0,600,40,50,57.09,84.44,60.01,90.08,63.41,94.73,69.69,97.79,0.0,90
116.66666666666667,100,40,50,43.11,117.41,58.78,128.03,72.45,139.5,80.0,179.88,1.0,75
1108.3333333333335,950,40,50,60.31,78.89,62.22,81.57,64.9,84.34,69.98,88.37,0.0,90
1050.0,900,30,50,46.77,83.71,48.35,86.37,49.58,90.49,53.12,95.89,0.0,115
175.0,150,50,50,27.63,123.64,30.85,132.85,35.83,154.49,47.85,168.08,0.0,115
186.66666666666669,200,20,50,17.48,121.49,20.36,136.53,24.24,147.32,39.35,169.1,0.0,165
350.0,300,35,50,36.87,103.06,39.86,111.22,45.2,119.4,58.59,136.68,0.0,115
291.6666666666667,250,30,50,35.42,105.0,41.37,116.58,46.43,120.16,59.31,132.67,0.0,115
291.6666666666667,250,35,50,58.47,94.97,64.6,101.42,72.66,108.43,79.99,115.39,0.0,75
140.0,150,30,50,11.84,139.26,14.13,148.23,17.94,163.45,25.64,197.17,0.0,180
1050.0,900,50,50,46.25,86.39,48.64,88.7,49.72,92.51,53.74,96.26,0.0,115
1166.6666666666667,1000,50,50,59.67,79.01,61.83,82.56,63.96,84.95,67.02,87.96,0.0,90
466.6666666666667,500,35,50,22.09,103.33,24.09,110.52,27.2,115.18,30.24,120.55,0.0,165
525.0,450,30,50,53.32,89.06,60.78,93.88,63.26,98.23,77.61,105.59,0.0,90
875.0,750,50,50,44.44,86.63,46.55,91.44,48.93,94.62,57.53,98.78,0.0,115
326.66666666666663,350,30,50,18.11,113.73,20.4,123.29,22.26,130.33,25.24,138.51,0.0,180
1050.0,900,45,50,59.71,79.99,61.69,82.37,64.43,86.8,66.6,90.19,0.0,90
991.6666666666666,850,20,50,61.43,77.36,63.89,80.11,65.7,84.05,68.31,89.48,0.0,90
466.6666666666667,400,45,50,52.63,91.22,56.74,97.58,59.33,102.58,67.54,113.18,0.0,90
700.0,600,30,50,79.99,54.25,80.0,65.3,80.0,74.83,80.0,86.23,0.0,60
116.66666666666667,100,45,50,22.88,135.01,28.76,155.74,36.4,173.29,54.35,195.41,0.0,115
1108.3333333333335,950,45,50,46.2,84.15,47.74,87.42,50.36,90.09,52.3,93.31,0.0,115
1166.6666666666667,1000,25,50,60.48,77.36,63.63,80.04,65.75,82.58,68.37,85.45,0.0,90
933.3333333333334,800,30,50,45.49,83.66,48.28,87.81,50.74,89.88,53.52,94.86,0.0,115
291.6666666666667,250,45,50,35.51,110.6,39.94,118.24,43.32,125.66,65.17,138.06,0.0,115
291.6666666666667,250,40,50,33.67,109.33,38.94,119.98,44.73,127.64,55.9,141.48,0.0,115
700.0,600,25,50,44.37,87.34,48.46,92.09,51.59,96.04,58.59,104.76,0.0,115
350.0,300,25,50,37.67,100.63,41.35,109.83,45.63,116.16,59.42,128.19,0.0,115
350.0,300,45,50,36.14,105.64,40.15,111.5,43.64,118.66,51.06,133.9,0.0,115
583.3333333333334,500,25,50,56.19,86.08,59.62,89.19,62.07,94.73,68.72,100.0,0.0,90
816.6666666666666,700,50,50,43.98,89.55,46.65,93.92,48.13,96.16,50.88,105.36,0.0,115
233.33333333333334,200,35,50,32.69,115.14,37.38,123.93,42.83,132.2,51.06,152.16,0.0,115
175.0,150,40,50,26.64,124.9,31.4,135.97,35.45,146.61,54.24,171.76,1.0,115
291.6666666666667,250,25,50,36.61,104.11,42.82,113.83,50.11,126.64,65.73,143.56,0.0,115
700.0,600,35,50,70.25,80.91,73.37,84.85,76.97,88.13,79.99,92.76,0.0,75
1108.3333333333335,950,30,50,60.12,77.69,62.31,81.25,65.32,84.49,68.72,88.36,0.0,90
991.6666666666666,850,45,50,44.8,85.57,47.43,89.45,50.46,92.58,54.07,96.18,0.0,115
525.0,450,25,50,41.78,91.26,45.8,98.71,50.39,104.5,53.96,116.39,0.0,115
700.0,600,20,50,70.47,79.12,74.84,83.82,78.44,88.3,80.0,93.07,0.0,75
466.6666666666667,400,50,50,38.66,100.18,42.71,106.95,46.22,114.24,53.88,117.19,0.0,115
116.66666666666667,100,30,50,23.25,132.62,27.82,148.36,34.19,165.11,57.01,190.52,0.0,115
116.66666666666667,100,50,50,22.99,141.36,28.13,156.67,34.65,177.2,43.78,228.06,0.0,115
1108.3333333333335,950,20,50,47.21,81.61,48.86,84.61,50.73,87.81,54.06,92.14,0.0,115
653.3333333333333,700,35,50,22.03,97.04,23.26,102.18,24.28,107.35,27.59,114.22,0.0,180
58.333333333333336,50,20,50,15.98,145.46,21.78,163.8,31.46,186.49,80.0,257.52,0.0,115
466.6666666666667,400,30,50,79.99,23.66,80.0,34.47,80.0,42.98,80.0,53.96,7.0,45
408.3333333333333,350,35,50,38.86,99.84,43.13,107.4,47.22,114.0,53.3,125.0,0.0,115
58.333333333333336,50,50,50,13.22,157.11,16.8,175.06,22.02,199.86,38.78,246.33,0.0,115
641.6666666666666,550,25,50,44.62,89.1,47.12,93.74,50.63,98.27,56.29,105.45,0.0,115
700.0,600,50,50,42.42,91.28,45.38,94.84,48.07,99.99,56.54,108.74,0.0,115
816.6666666666666,700,20,50,45.98,85.23,48.23,89.53,50.56,92.79,57.35,99.27,0.0,115
1108.3333333333335,950,50,50,46.68,84.04,48.42,87.69,49.96,89.37,53.01,93.85,0.0,115
1050.0,900,20,50,47.63,81.38,49.51,85.19,51.76,89.98,54.48,95.45,0.0,115
116.66666666666667,100,25,50,23.11,130.41,27.99,143.07,41.79,160.22,79.98,189.39,0.0,115
233.33333333333334,200,25,50,34.62,112.38,39.0,125.64,46.21,131.44,60.08,152.31,0.0,115
758.3333333333334,650,30,50,45.4,85.7,48.31,90.19,51.14,94.52,54.86,102.6,0.0,115
1050.0,900,35,50,46.78,82.58,49.1,85.86,50.79,90.25,53.99,92.66,0.0,115
140.0,150,35,50,12.6,138.45,15.15,156.33,18.11,172.55,29.94,199.44,0.0,180
1108.3333333333335,950,25,50,73.02,74.75,75.29,77.74,77.59,81.02,79.02,83.51,0.0,75
233.33333333333334,200,45,50,31.64,116.52,36.8,127.27,42.46,134.21,60.13,151.44,0.0,115
93.33333333333334,100,20,50,9.98,149.81,11.78,164.88,14.52,188.06,24.72,220.42,0.0,180
758.3333333333334,650,50,50,42.44,91.22,45.29,95.97,49.78,100.88,53.39,103.36,0.0,115
58.333333333333336,50,35,50,14.56,156.84,19.08,169.73,27.73,187.44,79.93,300.15,0.0,115
583.3333333333334,500,20,50,43.68,90.03,47.01,95.25,48.54,102.31,52.74,106.44,0.0,115
991.6666666666666,850,35,50,45.49,83.85,48.36,87.81,49.71,91.03,51.66,95.12,0.0,115
58.333333333333336,50,45,50,13.68,153.61,16.7,174.68,21.79,205.92,62.04,298.18,0.0,115
1166.6666666666667,1000,35,50,47.15,81.81,49.25,86.03,51.29,88.15,54.9,91.9,0.0,115
641.6666666666666,550,50,50,42.26,92.1,44.84,96.67,48.97,101.43,53.75,106.99,0.0,115
58.333333333333336,50,40,50,14.44,152.25,18.64,175.71,28.4,207.37,79.99,247.5,0.0,115
140.0,150,20,50,13.57,134.34,15.91,146.53,20.36,159.7,26.02,200.57,0.0,180
1050.0,900,25,50,46.96,83.3,49.21,85.88,49.91,89.35,57.26,95.13,0.0,115
408.3333333333333,350,45,50,37.03,99.57,41.61,108.67,45.55,113.72,50.98,122.77,0.0,115
233.33333333333334,200,30,50,33.64,109.68,38.83,120.86,45.51,132.35,51.59,147.87,0.0,115
350.0,300,30,50,79.99,31.27,80.0,42.81,80.0,55.6,80.0,73.27,3.0,45
1108.3333333333335,950,35,50,46.3,83.72,48.63,86.89,50.37,89.59,52.23,93.76,0.0,115
758.3333333333334,650,45,50,69.32,82.22,73.11,86.07,77.36,88.76,79.99,91.29,0.0,75
583.3333333333334,500,50,50,41.66,95.15,44.63,102.26,47.28,106.55,52.38,111.87,0.0,115
583.3333333333334,500,30,50,42.4,93.5,46.09,99.01,48.0,102.68,54.05,107.96,0.0,115
175.0,150,25,50,41.95,110.04,48.58,123.69,59.41,133.93,72.61,146.73,1.0,90
641.6666666666666,550,40,50,56.93,85.67,60.35,88.87,63.82,93.51,71.68,99.84,0.0,90
466.6666666666667,400,20,50,41.88,94.65,46.59,101.33,53.27,105.12,68.78,119.92,0.0,115
641.6666666666666,550,35,50,42.57,92.35,45.73,98.19,48.28,102.01,54.9,109.65,0.0,115
653.3333333333333,700,40,50,21.82,99.77,22.82,104.26,24.34,107.99,28.07,112.16,0.0,180
816.6666666666666,700,25,50,45.95,85.43,49.34,89.53,51.48,93.45,58.26,96.48,0.0,115
933.3333333333334,800,20,50,47.0,84.14,49.57,87.37,51.72,91.0,55.17,96.89,0.0,115
875.0,750,35,50,45.13,86.62,48.14,91.1,49.64,93.92,53.07,98.46,0.0,115
758.3333333333334,650,35,50,43.72,89.96,47.33,95.12,49.1,98.77,51.72,106.51,0.0,115
525.0,450,50,50,54.37,89.43,58.31,94.18,63.14,99.91,72.12,110.92,0.0,90
933.3333333333334,800,45,50,44.96,86.75,46.97,90.53,49.34,95.13,52.6,98.73,0.0,115
991.6666666666666,850,25,50,46.28,84.21,48.19,88.58,50.52,92.75,56.94,95.62,0.0,115
1166.6666666666667,1000,20,50,36.26,86.68,38.03,89.36,40.23,92.77,43.62,99.29,0.0,150
641.6666666666666,550,20,50,44.16,88.96,47.39,94.76,51.43,98.14,57.63,105.71,0.0,115
1050.0,900,40,50,79.99,13.85,80.0,17.45,80.0,20.19,80.0,25.18,2.0,45
991.6666666666666,850,40,50,45.07,84.87,47.13,89.17,49.75,93.48,52.86,98.32,0.0,115
933.3333333333334,800,35,50,34.13,91.24,36.14,95.53,37.76,99.04,41.32,105.94,0.0,150
583.3333333333334,500,45,50,68.75,83.41,73.13,89.57,78.33,94.56,80.0,98.03,0.0,75
758.3333333333334,650,25,50,59.03,81.38,62.47,85.36,65.94,88.68,69.5,92.37,0.0,90
758.3333333333334,650,20,50,45.79,86.71,48.39,90.87,51.63,93.58,55.31,97.61,0.0,115
525.0,450,45,50,40.72,96.93,44.05,101.13,45.88,107.67,54.25,114.32,0.0,115
583.3333333333334,500,40,50,41.86,92.69,45.55,99.49,48.19,103.53,53.94,108.88,0.0,115
991.6666666666666,850,50,50,33.82,91.58,35.9,97.16,36.57,101.81,38.27,106.4,0.0,150
875.0,750,20,50,45.92,84.45,48.22,89.77,51.79,91.48,54.87,99.28,0.0,115
525.0,450,20,50,42.15,94.19,46.48,98.3,50.68,103.44,55.86,108.92,0.0,115
408.3333333333333,350,25,50,53.5,93.66,58.53,100.75,67.93,107.04,76.31,112.95,0.0,90
408.3333333333333,350,20,50,29.97,103.98,33.18,113.42,35.39,122.01,46.64,128.57,0.0,150
875.0,750,30,50,45.37,85.34,47.62,90.49,50.99,93.98,53.92,99.08,0.0,115
58.333333333333336,50,30,50,15.85,150.81,21.09,178.38,37.06,204.47,80.0,292.3,0.0,115
933.3333333333334,800,25,50,35.14,89.74,36.38,94.33,38.23,99.27,40.22,102.74,0.0,150
350.0,300,50,50,35.56,106.19,39.92,114.2,42.39,122.29,60.01,136.26,0.0,115
46.66666666666667,50,25,50,5.99,170.25,7.73,188.94,9.11,227.41,12.97,277.09,0.0,180
525.0,450,40,50,40.22,94.38,44.81,101.6,47.83,107.43,55.83,112.13,0.0,115
933.3333333333334,800,50,50,79.99,14.21,80.0,19.29,80.0,24.19,80.0,30.46,2.0,45
933.3333333333334,800,40,50,33.32,92.25,35.57,96.91,37.25,100.72,40.46,105.13,0.0,150
291.6666666666667,250,20,50,24.95,110.55,30.74,121.16,34.78,129.9,50.77,151.35,0.0,150
875.0,750,25,50,46.08,83.06,47.96,87.72,49.94,91.71,52.62,96.75,0.0,115
1166.6666666666667,1000,40,50,79.99,51.2,80.0,57.46,80.0,65.33,80.0,69.14,0.0,60
291.6666666666667,250,50,50,33.8,114.18,37.54,121.05,42.47,128.33,48.31,142.29,0.0,115
466.6666666666667,400,35,50,40.57,95.6,44.37,102.06,47.64,107.68,53.15,119.81,0.0,115
700.0,600,45,50,42.01,90.48,44.45,94.67,46.92,99.33,51.67,105.38,0.0,115
875.0,750,40,50,33.86,94.02,35.6,99.23,37.39,103.8,40.76,109.04,0.0,150
233.33333333333334,200,50,50,22.07,127.05,25.31,138.55,30.71,148.81,41.98,160.44,0.0,150
350.0,300,20,50,27.9,109.79,32.26,121.67,39.65,127.09,50.25,139.28,0.0,150
1166.6666666666667,1000,45,50,46.49,82.96,48.48,87.6,50.69,90.78,54.78,98.28,0.0,115
606.6666666666667,650,40,50,21.13,102.43,22.53,108.03,24.17,111.51,26.75,115.27,0.0,180
653.3333333333333,700,30,50,21.45,98.43,23.06,104.41,25.07,108.88,28.57,113.98,0.0,180
641.6666666666666,550,30,50,43.95,89.95,46.35,97.45,49.05,99.75,52.28,106.74,0.0,115
991.6666666666666,850,30,50,34.77,89.43,36.5,93.22,38.61,95.88,41.24,99.09,0.0,150
466.6666666666667,400,40,50,53.15,88.72,57.56,96.09,62.54,101.02,69.13,111.64,0.0,90
175.0,150,45,50,64.83,100.63,73.4,112.08,79.98,121.5,80.0,141.24,2.0,60
1166.6666666666667,1000,30,50,35.38,87.05,37.15,90.46,39.44,93.96,41.76,99.92,0.0,150
350.0,300,40,50,25.75,112.84,29.42,120.6,33.3,130.17,45.17,138.01,0.0,150
175.0,150,50,50,18.91,135.25,21.66,148.39,29.31,160.15,38.11,182.07,0.0,150
466.6666666666667,400,25,50,41.3,96.48,45.85,102.33,49.54,108.11,60.48,116.46,0.0,115
1050.0,900,50,50,34.18,90.61,36.64,96.59,38.28,99.19,41.27,105.88,0.0,150
1108.3333333333335,950,40,50,46.3,83.76,48.63,86.21,50.73,89.52,54.24,93.9,0.0,115
408.3333333333333,350,40,50,27.58,112.71,30.62,117.25,33.9,124.3,42.43,134.39,0.0,150
700.0,600,40,50,43.3,91.12,46.52,96.01,48.64,99.62,53.71,107.3,0.0,115
1050.0,900,30,50,35.46,88.38,36.78,91.15,38.56,94.37,40.87,99.16,0.0,150
875.0,750,50,50,32.88,93.44,34.9,98.28,36.71,101.24,39.45,105.57,0.0,150
875.0,750,45,50,79.99,56.81,80.0,66.76,80.0,71.14,80.0,77.49,0.0,60
1050.0,900,45,50,46.43,84.71,48.37,87.52,49.62,91.35,53.65,94.94,0.0,115
350.0,300,35,50,25.81,115.64,30.08,123.44,33.52,129.87,37.72,142.23,0.0,150
116.66666666666667,100,35,50,79.98,84.78,79.99,100.83,80.0,112.9,80.0,130.31,2.0,45
291.6666666666667,250,30,50,26.58,117.23,30.57,128.11,35.07,138.81,41.7,155.2,0.0,150
513.3333333333333,550,45,50,19.64,106.05,21.3,110.76,22.95,114.76,27.57,123.81,0.0,180
420.0,450,35,50,19.16,109.72,21.91,116.17,23.78,121.4,25.31,131.79,0.0,180
1166.6666666666667,1000,50,50,46.35,83.49,49.19,86.4,50.49,89.08,54.13,93.77,0.0,115
991.6666666666666,850,20,50,47.43,82.42,49.59,85.54,52.33,89.01,56.81,94.15,0.0,115
525.0,450,30,50,41.78,94.24,45.21,98.51,47.86,104.93,56.14,111.11,0.0,115
233.33333333333334,200,40,50,79.99,52.67,80.0,67.95,80.0,86.77,80.0,104.51,3.0,45
116.66666666666667,100,45,50,14.9,144.05,18.97,165.05,23.87,188.49,48.92,252.96,0.0,150
1108.3333333333335,950,45,50,34.98,90.09,36.52,92.73,38.28,96.92,40.63,103.19,0.0,150
291.6666666666667,250,40,50,24.34,120.92,27.02,126.62,32.94,134.6,40.19,142.25,0.0,150
700.0,600,25,50,33.38,94.95,35.44,100.08,37.97,102.96,41.81,111.33,0.0,150
816.6666666666666,700,45,50,79.99,15.59,80.0,21.78,80.0,25.48,80.0,33.01,4.0,45
816.6666666666666,700,50,50,31.99,95.51,34.5,100.88,36.31,104.98,39.51,112.24,0.0,150
116.66666666666667,100,40,50,31.45,123.32,40.73,138.56,54.7,151.11,80.0,171.48,1.0,90
291.6666666666667,250,45,50,24.5,120.15,27.07,129.76,34.37,140.72,42.33,157.27,0.0,150
326.66666666666663,350,50,50,17.28,121.5,19.2,126.2,21.19,134.59,26.49,145.58,0.0,180
350.0,300,25,50,27.63,110.95,31.35,119.81,36.04,129.4,40.49,139.79,0.0,150
291.6666666666667,250,35,50,45.4,101.26,53.23,109.31,58.67,117.57,69.11,129.25,0.0,90
583.3333333333334,500,25,50,42.83,90.52,47.47,95.33,50.95,99.64,59.9,106.7,0.0,115
933.3333333333334,800,30,50,34.79,89.54,36.37,94.06,38.07,97.78,42.11,102.35,0.0,150
233.33333333333334,200,35,50,22.42,127.54,26.31,136.06,31.81,145.69,50.73,166.01,0.0,150
350.0,300,45,50,26.67,115.67,28.96,121.8,30.48,128.84,35.68,140.52,0.0,150
175.0,150,40,50,17.38,136.97,20.85,154.87,25.04,162.8,32.69,195.74,0.0,150
186.66666666666669,200,20,50,15.06,129.91,18.2,139.46,20.39,148.24,32.73,190.5,0.0,180
408.3333333333333,350,30,50,79.99,25.5,80.0,36.66,80.0,44.86,80.0,61.43,6.0,45
466.6666666666667,400,50,50,28.22,107.89,30.87,114.11,34.57,121.36,39.56,129.78,0.0,150
525.0,450,25,50,31.53,100.78,34.37,107.49,37.95,110.65,41.64,118.47,0.0,150
291.6666666666667,250,25,50,26.21,118.34,29.54,129.25,34.48,134.33,42.19,155.42,0.0,150
700.0,600,20,50,58.28,82.22,63.7,86.73,67.83,90.31,72.69,96.05,0.0,90
1108.3333333333335,950,30,50,47.14,81.59,49.08,86.03,51.32,88.26,54.77,92.18,0.0,115
1166.6666666666667,1000,25,50,47.3,80.65,48.9,85.42,50.37,87.51,53.91,89.68,0.0,115
466.6666666666667,500,35,50,20.23,106.05,21.74,111.28,23.47,119.8,26.17,126.68,0.0,180
1108.3333333333335,950,20,50,35.98,85.22,38.21,89.67,40.66,93.0,43.15,98.94,0.0,150
408.3333333333333,350,35,50,28.12,109.24,31.1,116.73,34.92,124.12,39.33,134.29,0.0,150
991.6666666666666,850,45,50,34.32,89.59,36.0,94.93,38.0,97.9,40.11,104.48,0.0,150
116.66666666666667,100,30,50,15.84,140.99,18.77,160.51,23.29,175.27,33.33,217.67,0.0,150
466.6666666666667,400,45,50,39.55,100.75,43.34,104.58,46.65,110.75,51.17,116.92,0.0,115
116.66666666666667,100,50,50,14.1,147.95,16.58,166.58,22.15,178.5,28.97,214.5,0.0,150
700.0,600,30,50,71.18,78.23,74.08,83.34,77.09,87.9,79.99,94.11,0.0,75
58.333333333333336,50,20,50,11.52,168.51,18.03,196.34,25.46,247.83,47.73,307.61,0.0,150
175.0,150,30,50,79.99,62.66,80.0,83.79,80.0,95.32,80.0,109.6,6.0,45
816.6666666666666,700,20,50,34.28,90.61,36.29,94.73,39.24,101.61,42.59,111.98,0.0,150
58.333333333333336,50,50,50,9.44,166.39,12.55,187.82,16.28,232.12,26.83,297.25,0.0,150
641.6666666666666,550,25,50,31.65,95.91,35.18,101.06,37.82,104.4,41.46,115.51,0.0,150
700.0,600,35,50,56.78,84.55,59.01,89.45,64.39,94.28,71.78,99.33,0.0,90
233.33333333333334,200,25,50,23.13,121.85,26.72,133.43,30.08,147.37,39.39,159.09,0.0,150
1050.0,900,35,50,34.74,89.01,36.67,93.22,39.18,96.08,41.19,101.51,0.0,150
1108.3333333333335,950,50,50,34.47,89.51,35.86,94.28,37.73,96.83,39.58,101.14,0.0,150
1050.0,900,20,50,35.54,87.17,37.24,91.85,38.1,96.48,40.7,100.04,0.0,150
116.66666666666667,100,25,50,16.93,146.4,20.39,166.93,27.96,186.16,68.79,236.37,0.0,150
466.6666666666667,400,30,50,79.98,71.53,79.99,83.48,80.0,90.57,80.0,93.81,0.0,60
233.33333333333334,200,45,50,21.55,130.92,25.82,139.86,29.51,145.3,37.88,153.91,0.0,150
758.3333333333334,650,30,50,32.89,93.31,34.9,98.52,37.45,101.29,41.65,113.14,0.0,150
991.6666666666666,850,35,50,35.24,91.69,36.86,95.53,38.38,97.9,41.43,102.16,0.0,150
583.3333333333334,500,50,50,30.38,103.63,32.47,109.8,35.1,112.71,41.41,120.13,0.0,150
58.333333333333336,50,35,50,9.41,162.55,12.44,182.87,18.62,203.7,45.45,297.88,1.0,150
758.3333333333334,650,50,50,32.88,97.08,34.62,101.3,37.2,108.0,40.61,113.48,0.0,150
583.3333333333334,500,20,50,31.48,98.3,34.6,105.25,36.68,111.09,41.69,118.01,0.0,150
700.0,600,50,50,32.43,98.54,34.45,102.2,35.38,107.09,42.47,112.84,0.0,150
816.6666666666666,700,35,50,79.99,15.32,80.0,20.48,80.0,27.01,80.0,40.47,7.0,45
641.6666666666666,550,50,50,30.78,102.07,32.69,108.55,36.21,111.63,47.38,118.34,0.0,150
1166.6666666666667,1000,35,50,35.18,88.1,36.37,91.36,37.65,94.67,40.17,99.46,0.0,150
1050.0,900,25,50,35.53,87.29,37.13,91.55,38.62,93.98,42.55,98.34,0.0,150
233.33333333333334,200,30,50,22.86,124.21,26.54,134.92,30.75,145.38,39.27,161.49,0.0,150
58.333333333333336,50,45,50,8.79,164.34,11.27,182.74,15.23,200.22,41.07,286.22,0.0,150
1108.3333333333335,950,35,50,34.88,88.94,36.7,93.04,38.45,96.4,43.08,103.44,0.0,150
408.3333333333333,350,45,50,26.71,112.59,29.29,120.17,32.63,124.65,41.65,132.84,0.0,150
583.3333333333334,500,30,50,30.55,99.52,33.86,107.01,37.38,110.61,40.88,117.53,0.0,150
1108.3333333333335,950,25,50,61.06,77.39,63.37,80.93,65.52,84.62,67.92,88.61,0.0,90
175.0,150,35,50,79.99,60.55,80.0,79.13,80.0,94.92,80.0,106.87,8.0,45
58.333333333333336,50,40,50,8.79,163.39,11.08,184.25,15.1,201.11,27.49,238.78,0.0,150
466.6666666666667,400,20,50,30.8,103.82,32.9,110.45,36.29,115.07,41.46,120.33,0.0,150
641.6666666666666,550,40,50,42.98,91.68,45.52,96.2,47.83,101.54,50.68,105.15,0.0,115
991.6666666666666,850,40,50,33.94,89.9,35.59,94.46,36.98,97.29,38.37,101.18,0.0,150
816.6666666666666,700,25,50,33.55,91.89,36.06,96.71,38.29,100.81,41.68,105.25,0.0,150
933.3333333333334,800,20,50,34.86,89.23,36.75,95.43,38.99,98.66,43.94,102.32,0.0,150
641.6666666666666,550,35,50,31.58,98.02,34.23,104.26,36.41,107.88,38.33,114.72,0.0,150
116.66666666666667,100,20,50,79.98,67.07,79.99,101.44,80.0,110.33,80.0,131.32,11.0,45
758.3333333333334,650,35,50,33.95,95.42,35.85,101.37,37.6,106.46,40.65,111.29,0.0,150
1166.6666666666667,1000,20,50,33.34,86.38,34.18,90.66,35.42,96.87,37.37,99.99,0.0,165
933.3333333333334,800,45,50,33.43,92.48,35.45,96.44,36.71,102.39,40.67,108.61,0.0,150
875.0,750,35,50,33.45,93.61,35.85,97.32,37.64,100.54,41.19,105.81,0.0,150
816.6666666666666,700,40,50,79.99,16.43,80.0,21.85,80.0,26.64,80.0,32.74,1.0,45
350.0,300,30,50,79.84,79.53,79.99,87.07,80.0,95.84,80.0,108.73,0.0,60
641.6666666666666,550,20,50,32.55,97.09,35.44,103.13,37.52,108.61,42.98,117.56,0.0,150
525.0,450,20,50,31.6,100.52,34.26,109.44,37.16,113.99,43.97,123.32,0.0,150
991.6666666666666,850,25,50,35.13,88.14,37.3,93.84,39.8,98.24,43.38,104.33,0.0,150
583.3333333333334,500,40,50,31.15,100.16,34.11,104.77,35.95,111.35,40.71,116.62,0.0,150
933.3333333333334,800,35,50,30.86,94.52,32.61,98.88,34.27,102.27,36.91,107.05,0.0,165
525.0,450,45,50,28.74,106.03,31.08,112.38,33.78,116.04,36.78,124.81,0.0,150
175.0,150,20,50,79.99,45.18,79.99,72.01,80.0,97.15,80.0,116.7,10.0,45
758.3333333333334,650,45,50,56.84,85.72,59.69,89.79,63.72,92.54,68.07,97.34,0.0,90
408.3333333333333,350,20,50,26.63,109.09,29.6,115.76,32.72,121.61,43.98,133.23,0.0,165
875.0,750,20,50,35.84,88.29,37.83,94.18,39.68,97.86,43.61,101.95,0.0,150
583.3333333333334,500,45,50,54.71,87.38,59.46,92.91,62.46,95.9,72.06,102.51,0.0,90
758.3333333333334,650,20,50,34.18,91.51,36.07,96.85,38.22,103.05,43.24,108.98,0.0,150
175.0,150,25,50,31.07,120.61,36.32,132.28,44.42,141.28,57.41,157.38,0.0,115
875.0,750,30,50,33.76,90.65,36.03,95.18,38.68,100.42,42.21,105.15,0.0,150
933.3333333333334,800,25,50,31.2,91.56,32.99,97.42,35.43,99.64,37.46,105.14,0.0,165
991.6666666666666,850,50,50,31.17,94.01,32.78,98.92,34.02,102.65,36.94,107.58,0.0,165
525.0,450,40,50,30.0,102.4,32.53,108.96,35.45,115.38,43.91,124.63,0.0,150
525.0,450,50,50,40.0,96.99,42.57,102.4,47.15,107.44,53.91,115.65,0.0,115
350.0,300,50,50,25.18,116.48,28.35,123.84,31.19,129.91,40.75,139.83,0.0,150
933.3333333333334,800,40,50,30.36,95.48,32.57,99.7,34.56,104.16,37.37,106.47,0.0,165
758.3333333333334,650,25,50,44.36,87.29,48.12,91.25,50.38,93.79,55.0,99.75,0.0,115
291.6666666666667,250,20,50,23.3,116.52,27.17,127.33,30.96,135.3,40.19,150.93,0.0,165
58.333333333333336,50,30,50,9.81,163.07,13.21,186.95,17.88,213.57,58.48,263.6,0.0,150
291.6666666666667,250,50,50,22.99,119.62,26.8,126.72,29.45,135.62,35.87,153.55,0.0,150
466.6666666666667,400,35,50,29.42,105.95,33.28,111.83,36.14,119.2,40.07,132.56,0.0,150
875.0,750,25,50,34.51,90.47,36.54,93.98,38.31,97.98,41.75,107.52,0.0,150
700.0,600,45,50,32.47,97.58,34.61,103.55,36.86,107.69,42.59,115.88,0.0,150
1050.0,900,40,50,79.99,51.58,80.0,60.5,80.0,66.26,80.0,74.89,0.0,60
875.0,750,40,50,29.93,94.68,31.65,99.18,32.67,103.5,35.7,109.61,0.0,165
233.33333333333334,200,50,50,18.78,130.8,21.94,144.97,24.79,155.42,36.58,167.07,0.0,165
1166.6666666666667,1000,40,50,72.12,77.06,74.9,80.33,77.65,82.5,79.99,84.87,0.0,75
350.0,300,20,50,25.49,111.97,27.8,122.31,31.82,128.2,35.66,143.75,0.0,165
408.3333333333333,350,25,50,38.97,98.29,43.38,106.51,47.8,112.11,55.08,120.92,0.0,115
1166.6666666666667,1000,45,50,35.18,88.67,36.84,91.5,39.59,93.47,43.7,99.64,0.0,150
350.0,300,40,50,22.5,118.08,25.39,124.8,30.2,131.53,33.13,149.27,0.0,165
1166.6666666666667,1000,30,50,32.6,90.11,34.24,93.35,34.95,96.11,37.18,104.33,0.0,165
641.6666666666666,550,30,50,31.48,98.0,34.48,102.22,37.28,106.17,42.57,112.02,0.0,150
991.6666666666666,850,30,50,31.18,89.82,33.06,95.44,35.77,100.43,38.33,106.17,0.0,165
58.333333333333336,50,25,50,63.12,108.96,79.97,124.39,80.0,140.8,80.0,165.02,1.0,45
1108.3333333333335,950,40,50,35.59,88.85,37.47,92.83,38.76,94.86,40.53,99.67,0.0,150
466.6666666666667,400,25,50,30.44,103.59,33.67,109.97,35.81,115.14,41.69,124.3,0.0,150
1050.0,900,50,50,31.06,94.9,32.28,99.66,34.21,102.03,36.27,105.29,0.0,165
408.3333333333333,350,40,50,24.81,112.08,27.46,119.72,29.32,125.75,34.62,132.77,0.0,165
933.3333333333334,800,50,50,79.99,56.56,80.0,64.44,80.0,74.59,80.0,84.93,0.0,60
991.6666666666666,850,20,50,35.59,87.51,37.31,90.47,39.35,94.59,41.37,99.58,0.0,150
1050.0,900,30,50,32.15,89.91,33.94,93.23,36.0,98.6,39.32,101.76,0.0,165
700.0,600,40,50,32.21,95.85,33.95,101.86,35.81,105.41,38.76,114.59,0.0,150
758.3333333333334,650,40,50,79.99,17.35,80.0,22.51,80.0,27.25,80.0,32.39,2.0,45
875.0,750,50,50,30.21,95.85,31.55,101.07,33.12,105.22,35.94,110.55,0.0,165
175.0,150,50,50,15.61,140.61,19.01,153.97,22.42,162.06,30.85,187.47,0.0,165
350.0,300,35,50,22.89,117.34,25.78,126.58,27.5,133.16,34.48,149.18,0.0,165
1166.6666666666667,1000,50,50,35.25,88.73,36.74,92.88,38.43,96.81,41.21,99.3,0.0,150
1050.0,900,45,50,34.58,89.45,35.91,93.48,37.45,96.43,40.38,102.17,0.0,150
291.6666666666667,250,30,50,22.12,122.15,25.56,129.83,29.16,136.04,36.03,157.56,0.0,165
175.0,150,45,50,51.2,109.1,59.33,122.62,71.57,132.58,80.0,142.67,1.0,75
525.0,450,30,50,30.33,100.66,33.49,108.15,35.91,111.42,40.11,117.51,0.0,150
116.66666666666667,100,45,50,12.8,147.27,14.98,165.69,17.66,183.34,32.22,218.59,0.0,165
816.6666666666666,700,30,50,79.99,14.87,80.0,21.01,80.0,24.1,80.0,33.06,7.0,45
875.0,750,45,50,71.22,78.53,74.34,82.48,77.8,85.11,80.0,87.8,0.0,75
700.0,600,25,50,29.08,97.53,32.13,101.1,33.9,104.95,39.04,113.49,0.0,165
291.6666666666667,250,45,50,21.27,122.45,23.82,133.84,27.65,145.15,33.59,158.92,0.0,165
525.0,450,25,50,26.99,101.09,29.77,109.32,32.78,117.11,38.57,124.9,0.0,165
466.6666666666667,400,40,50,40.42,98.69,43.39,104.87,45.94,110.7,51.15,114.03,0.0,115
933.3333333333334,800,30,50,31.76,92.25,33.45,98.46,35.79,102.76,38.61,109.48,0.0,165
1108.3333333333335,950,45,50,31.37,91.1,32.93,96.99,34.46,100.02,35.53,105.2,0.0,165
583.3333333333334,500,25,50,31.05,98.91,33.6,103.97,37.34,110.48,42.42,114.83,0.0,150
525.0,450,35,50,79.99,20.41,80.0,28.92,80.0,34.61,80.0,48.1,9.0,45
816.6666666666666,700,50,50,29.41,97.51,31.05,102.51,32.96,106.81,35.98,113.82,0.0,165
291.6666666666667,250,40,50,21.06,125.96,23.92,135.5,28.52,142.18,35.76,152.92,0.0,165
233.33333333333334,200,35,50,20.6,133.56,24.66,148.61,27.97,160.59,38.1,175.69,0.0,165
350.0,300,25,50,24.34,113.91,28.28,121.88,31.67,130.1,38.44,140.85,0.0,165
175.0,150,40,50,16.32,135.97,19.0,146.93,22.56,156.09,49.77,188.68,0.0,165
350.0,300,45,50,22.95,119.62,25.49,125.93,29.59,131.99,34.65,149.49,0.0,165
700.0,600,20,50,44.58,88.82,48.61,93.29,50.71,96.07,56.29,101.02,0.0,115
466.6666666666667,400,50,50,25.56,110.87,27.72,117.12,31.54,123.17,35.78,134.82,0.0,165
116.66666666666667,100,35,50,55.46,108.6,68.43,119.23,79.98,131.24,80.0,160.41,2.0,60
1108.3333333333335,950,30,50,35.51,87.39,37.29,91.22,39.19,93.55,41.47,96.86,0.0,150
641.6666666666666,550,45,50,79.99,22.04,79.99,28.54,80.0,35.03,80.0,43.74,3.0,45
291.6666666666667,250,25,50,22.47,118.94,26.97,127.87,29.88,135.89,37.29,149.03,0.0,165
408.3333333333333,350,35,50,25.23,113.24,28.32,118.99,31.67,126.9,40.25,131.91,0.0,165
233.33333333333334,200,40,50,70.02,92.47,78.06,101.94,80.0,108.34,80.0,121.23,0.0,60
116.66666666666667,100,40,50,24.0,136.47,31.03,146.35,36.98,161.3,79.99,181.2,1.0,115
116.66666666666667,100,30,50,13.67,148.11,17.21,162.8,21.11,184.82,36.65,204.63,0.0,165
991.6666666666666,850,45,50,30.98,94.07,32.66,98.76,34.48,101.66,38.31,107.29,0.0,165
408.3333333333333,350,50,50,79.99,29.6,80.0,43.29,80.0,55.48,80.0,67.23,4.0,45
1108.3333333333335,950,20,50,32.91,87.34,34.47,91.47,35.76,95.16,37.81,100.15,0.0,165
816.6666666666666,700,45,50,79.99,58.06,80.0,70.44,80.0,75.66,80.0,85.23,0.0,60
291.6666666666667,250,35,50,35.79,108.44,39.68,116.8,42.41,124.32,50.23,132.0,0.0,115
58.333333333333336,50,20,50,9.05,160.15,10.76,182.63,21.67,216.21,43.46,308.36,0.0,165
116.66666666666667,100,50,50,12.09,153.94,14.24,169.11,18.11,189.74,27.98,220.83,0.0,165
816.6666666666666,700,20,50,31.14,93.22,33.0,98.69,34.79,102.97,39.72,110.03,0.0,165
408.3333333333333,350,30,50,79.98,72.36,79.99,85.75,80.0,93.08,80.0,104.66,0.0,60
1166.6666666666667,1000,25,50,35.94,85.84,38.19,89.63,39.81,93.63,41.69,96.42,0.0,150
1108.3333333333335,950,50,50,31.72,92.85,33.16,95.71,34.84,100.25,37.32,102.8,0.0,165
233.33333333333334,200,25,50,21.07,124.26,25.52,138.54,29.19,147.7,36.69,173.63,0.0,165
233.33333333333334,200,20,50,79.99,35.69,80.0,58.64,80.0,77.16,80.0,97.84,12.0,45
1050.0,900,35,50,31.8,91.8,33.39,95.67,35.02,99.67,41.01,103.28,0.0,165
641.6666666666666,550,25,50,28.75,100.17,30.75,105.66,33.15,110.09,37.37,114.58,0.0,165
58.333333333333336,50,50,50,7.44,168.03,8.85,185.89,11.44,206.79,22.41,322.59,0.0,165
1050.0,900,20,50,32.22,87.41,34.34,93.09,36.51,97.89,38.15,103.48,0.0,165
233.33333333333334,200,45,50,18.82,131.29,22.26,143.09,25.26,154.94,32.69,161.28,0.0,165
116.66666666666667,100,25,50,14.44,146.28,17.69,164.79,23.37,177.46,46.58,198.45,0.0,165
758.3333333333334,650,30,50,29.36,97.06,31.27,101.96,33.44,107.15,38.89,115.15,0.0,165
233.33333333333334,200,30,50,21.45,127.93,24.92,138.22,28.99,148.2,33.48,162.31,0.0,165
1050.0,900,25,50,31.65,90.24,33.28,93.78,35.39,98.17,37.89,102.78,0.0,165
583.3333333333334,500,35,50,79.99,18.72,80.0,27.14,80.0,32.83,80.0,42.63,3.0,45
758.3333333333334,650,50,50,28.95,98.8,30.99,103.81,32.56,109.27,36.4,117.5,0.0,165
641.6666666666666,550,50,50,27.44,104.23,29.47,110.2,31.48,114.52,34.12,122.9,0.0,165
700.0,600,30,50,56.82,83.59,59.91,87.95,63.07,92.68,71.75,97.37,0.0,90
583.3333333333334,500,20,50,29.21,101.77,31.35,108.52,33.72,112.91,39.73,122.89,0.0,165
175.0,150,30,50,65.3,97.0,79.61,108.12,79.99,118.4,80.0,136.24,2.0,60
700.0,600,35,50,42.66,90.61,45.66,95.8,47.96,99.34,55.31,104.63,0.0,115
466.6666666666667,400,45,50,28.31,108.68,30.94,115.39,33.5,122.5,39.88,127.4,0.0,150
58.333333333333336,50,35,50,7.89,167.4,10.03,190.25,13.68,211.27,47.02,292.27,0.0,165
1166.6666666666667,1000,35,50,31.78,90.11,33.84,93.63,34.84,96.65,37.31,100.7,0.0,165
583.3333333333334,500,50,50,26.69,108.7,29.85,114.44,33.28,119.37,35.53,121.47,0.0,165
466.6666666666667,400,30,50,66.21,83.46,71.09,90.03,78.35,93.92,80.0,103.92,0.0,75
1108.3333333333335,950,35,50,32.07,91.19,33.56,95.76,35.0,99.93,36.68,104.76,0.0,165
408.3333333333333,350,45,50,24.42,114.73,27.25,121.53,30.33,127.96,32.65,140.29,0.0,165
583.3333333333334,500,30,50,28.05,102.47,30.45,108.59,32.65,114.12,38.81,124.53,0.0,165
991.6666666666666,850,35,50,31.16,91.69,32.89,95.51,34.69,98.97,37.17,106.33,0.0,165
466.6666666666667,400,20,50,26.87,105.54,30.04,112.07,32.21,117.3,37.43,122.98,0.0,165
58.333333333333336,50,45,50,7.53,171.24,9.44,186.55,11.79,206.21,19.43,237.33,0.0,165
58.333333333333336,50,40,50,7.39,164.85,9.58,184.59,13.82,211.79,22.85,274.02,0.0,165
700.0,600,50,50,28.14,101.33,30.13,105.13,32.91,110.42,34.63,119.4,0.0,165
816.6666666666666,700,25,50,30.42,95.13,32.11,97.93,34.52,103.25,36.84,109.25,0.0,165
1166.6666666666667,1000,20,50,30.14,88.79,31.22,91.03,32.14,94.92,34.51,102.46,0.0,180
758.3333333333334,650,35,50,29.85,98.86,31.52,103.48,33.07,107.63,37.24,119.71,0.0,165
933.3333333333334,800,45,50,30.65,94.58,32.22,101.77,34.09,106.12,36.08,109.5,0.0,165
641.6666666666666,550,20,50,29.64,96.24,32.14,103.13,34.82,108.98,40.69,113.97,0.0,165
991.6666666666666,850,40,50,30.94,93.67,32.41,98.38,33.88,100.78,34.85,104.17,0.0,165
816.6666666666666,700,35,50,79.99,58.31,80.0,66.85,80.0,74.63,80.0,85.59,0.0,60
875.0,750,35,50,30.61,93.99,32.32,98.51,33.69,103.81,36.87,108.19,0.0,165
641.6666666666666,550,40,50,31.12,99.06,33.61,103.04,35.58,107.71,38.61,114.32,0.0,150
175.0,150,35,50,67.42,98.8,77.97,107.72,79.99,116.79,80.0,135.45,0.0,60
933.3333333333334,800,20,50,31.57,90.11,33.82,94.5,35.47,98.63,37.84,105.83,0.0,165
816.6666666666666,700,40,50,79.99,57.93,80.0,68.02,80.0,77.16,80.0,83.33,0.0,60
991.6666666666666,850,25,50,31.95,90.92,33.13,95.71,35.77,97.91,39.17,106.53,0.0,165
583.3333333333334,500,40,50,27.1,105.79,29.27,110.22,31.54,115.59,35.5,128.88,0.0,165
641.6666666666666,550,35,50,28.34,100.82,30.84,105.92,33.17,110.54,38.89,118.95,0.0,165
1108.3333333333335,950,25,50,46.99,82.07,49.1,85.83,51.84,88.35,53.92,90.76,0.0,115
525.0,450,45,50,26.09,109.89,28.8,115.12,31.44,122.62,40.53,128.32,0.0,165
525.0,450,20,50,28.81,101.26,31.19,109.06,34.08,114.75,51.61,120.84,0.0,165
758.3333333333334,650,20,50,30.61,96.62,32.57,100.8,34.66,104.44,39.14,113.31,0.0,165
933.3333333333334,800,35,50,27.84,95.46,29.32,99.57,31.64,104.8,34.49,108.57,0.0,180
875.0,750,20,50,31.28,92.47,33.38,97.48,35.05,100.02,38.69,105.31,0.0,165
408.3333333333333,350,20,50,23.31,113.91,25.59,125.17,31.03,132.07,38.3,141.13,0.0,180
933.3333333333334,800,25,50,28.31,95.51,30.28,99.13,32.59,102.12,35.22,107.73,0.0,180
991.6666666666666,850,50,50,27.95,98.04,29.53,101.64,31.52,103.71,34.17,110.43,0.0,180
116.66666666666667,100,20,50,64.42,104.43,79.3,115.16,79.99,127.14,80.0,150.39,1.0,60
525.0,450,40,50,26.5,108.06,29.5,116.36,31.46,120.82,34.34,125.16,0.0,165
350.0,300,30,50,60.18,89.46,66.45,96.88,78.01,101.77,80.0,113.41,0.0,75
1166.6666666666667,1000,40,50,60.32,77.87,62.27,80.6,64.63,84.59,67.89,88.36,0.0,90
875.0,750,30,50,30.46,94.18,32.41,99.63,34.6,103.48,38.99,107.1,0.0,165
466.6666666666667,400,35,50,25.66,109.56,29.0,117.32,33.02,123.48,39.18,129.09,0.0,165
933.3333333333334,800,40,50,27.67,97.11,29.08,101.08,30.28,105.04,31.73,111.21,0.0,180
233.33333333333334,200,50,50,16.59,133.09,18.47,144.04,21.56,154.82,26.84,167.76,0.0,180
758.3333333333334,650,45,50,44.59,88.7,47.3,92.97,49.16,97.49,52.27,102.23,0.0,115
583.3333333333334,500,45,50,41.4,94.19,44.44,99.24,47.28,103.89,55.22,110.66,0.0,115
175.0,150,20,50,68.75,86.65,79.98,104.69,80.0,112.56,80.0,120.88,1.0,60
58.333333333333336,50,30,50,8.09,165.36,11.54,188.51,15.04,222.25,31.56,340.06,0.0,165
875.0,750,40,50,27.25,97.08,28.82,102.46,30.22,107.41,34.27,112.26,0.0,180
875.0,750,25,50,30.6,92.76,32.53,97.32,34.11,100.98,37.37,105.81,0.0,165
350.0,300,50,50,22.55,119.21,24.36,126.76,27.83,136.77,34.15,150.91,0.0,165
700.0,600,45,50,28.83,99.41,30.6,104.94,33.0,109.57,39.66,116.0,0.0,165
291.6666666666667,250,20,50,20.46,120.52,23.18,131.27,26.16,142.73,43.23,158.28,0.0,180
291.6666666666667,250,50,50,21.32,127.02,24.19,135.1,27.13,145.08,31.45,161.26,0.0,165
641.6666666666666,550,30,50,29.34,98.19,32.02,106.41,35.12,114.57,38.66,123.21,0.0,165
991.6666666666666,850,30,50,28.76,94.23,30.36,98.21,32.6,102.51,35.76,110.82,0.0,180
175.0,150,25,50,19.98,132.22,23.71,144.51,27.02,161.98,44.54,190.82,0.0,150
350.0,300,40,50,20.58,121.18,23.79,130.91,26.22,139.15,31.16,155.37,0.0,180
350.0,300,20,50,23.34,117.19,27.05,125.13,29.2,130.84,33.47,151.93,0.0,180
1166.6666666666667,1000,45,50,31.84,89.12,33.77,92.42,36.08,97.88,38.33,101.46,0.0,165
1166.6666666666667,1000,30,50,29.83,90.22,31.45,94.67,32.32,100.11,33.7,104.49,0.0,180
525.0,450,50,50,29.84,105.73,32.42,111.16,35.09,117.95,40.77,124.0,0.0,150
1108.3333333333335,950,40,50,31.49,91.55,33.12,95.58,34.2,99.16,36.83,103.33,0.0,165
408.3333333333333,350,25,50,28.13,105.41,31.33,114.87,33.85,123.65,39.47,134.01,0.0,150
1050.0,900,30,50,28.94,93.69,30.29,97.32,32.44,100.8,35.02,104.08,0.0,180
408.3333333333333,350,40,50,21.43,115.9,24.28,125.54,26.85,132.2,34.84,136.96,0.0,180
1050.0,900,40,50,72.72,76.96,75.31,79.66,77.45,81.37,80.0,86.29,0.0,75
758.3333333333334,650,25,50,33.32,93.48,36.02,97.89,38.17,102.48,40.7,105.83,0.0,150
466.6666666666667,400,25,50,25.91,106.57,29.15,114.92,32.74,120.69,42.35,133.04,0.0,165
58.333333333333336,50,25,50,44.91,123.63,63.69,146.84,79.98,166.36,80.0,187.44,4.0,60
1050.0,900,50,50,27.9,97.47,29.28,100.25,30.63,104.3,33.52,108.82,0.0,180
1166.6666666666667,1000,50,50,31.31,92.3,32.56,95.39,34.42,98.71,35.56,106.55,0.0,165
991.6666666666666,850,20,50,31.97,89.87,34.12,93.42,36.07,96.07,37.69,104.31,0.0,165
875.0,750,50,50,27.01,98.64,28.25,104.89,29.72,107.25,32.35,113.11,0.0,180
1050.0,900,45,50,31.41,92.43,33.01,96.31,34.58,99.19,37.65,104.02,0.0,165
175.0,150,50,50,13.86,144.37,17.11,155.79,22.25,167.3,27.22,181.91,0.0,180
350.0,300,35,50,20.79,124.34,23.07,130.29,25.71,138.86,31.15,155.33,0.0,180
700.0,600,40,50,29.43,98.51,31.46,104.58,33.87,108.11,36.45,113.45,0.0,165
525.0,450,30,50,26.76,104.02,29.77,112.65,33.53,121.98,38.9,137.19,0.0,165
116.66666666666667,100,45,50,11.68,158.87,14.15,180.52,16.57,193.56,29.57,249.76,0.0,180
291.6666666666667,250,30,50,20.12,124.88,23.56,132.4,25.89,141.79,30.33,154.58,0.0,180
700.0,600,25,50,26.87,99.91,29.3,106.2,31.73,110.45,34.35,117.37,0.0,180
175.0,150,45,50,39.85,117.3,45.19,128.77,53.83,134.55,71.82,144.15,0.0,90
291.6666666666667,250,45,50,17.92,129.69,20.38,138.89,22.95,147.39,28.28,161.67,0.0,180
933.3333333333334,800,30,50,28.2,94.85,29.61,98.58,30.75,103.03,33.44,109.25,0.0,180
1108.3333333333335,950,45,50,28.65,94.18,30.24,97.25,31.45,102.15,34.19,107.04,0.0,180
933.3333333333334,800,50,50,70.76,77.67,73.49,82.06,76.72,85.76,80.0,89.71,0.0,75
583.3333333333334,500,25,50,29.07,101.33,31.37,107.15,34.52,113.61,39.46,121.71,0.0,165
350.0,300,25,50,22.25,116.11,24.35,122.42,26.36,129.63,40.06,149.07,0.0,180
758.3333333333334,650,40,50,79.99,59.34,79.99,70.68,80.0,80.25,80.0,85.46,0.0,60
700.0,600,20,50,33.73,93.96,35.67,100.13,38.78,105.62,44.09,112.91,0.0,150
525.0,450,25,50,24.79,106.15,26.98,113.57,30.01,118.06,33.46,129.49,0.0,180
291.6666666666667,250,40,50,19.11,128.37,21.74,137.97,23.15,145.79,27.55,157.37,0.0,180
350.0,300,45,50,20.4,119.08,23.26,132.55,25.87,139.65,28.83,151.94,0.0,180
875.0,750,45,50,58.6,81.11,61.13,86.44,63.21,89.31,67.97,94.27,0.0,90
233.33333333333334,200,35,50,17.17,134.23,20.28,144.85,22.59,152.17,26.57,171.42,0.0,180
816.6666666666666,700,50,50,26.77,101.06,28.05,105.84,29.54,109.51,32.01,115.24,0.0,180
1108.3333333333335,950,30,50,32.3,89.85,33.7,94.48,34.56,96.4,36.99,100.96,0.0,165
291.6666666666667,250,25,50,20.08,120.89,22.35,131.68,25.67,141.88,38.34,156.61,0.0,180
816.6666666666666,700,30,50,79.99,56.58,80.0,63.74,80.0,70.68,80.0,84.03,0.0,60
466.6666666666667,400,40,50,28.12,106.08,30.98,112.4,34.52,118.89,40.98,127.02,0.0,150
1108.3333333333335,950,20,50,29.65,90.2,31.6,94.6,32.95,98.78,35.57,101.35,0.0,180
175.0,150,40,50,14.52,143.46,17.29,155.7,20.35,166.65,25.72,200.77,0.0,180
408.3333333333333,350,35,50,22.06,116.77,24.53,122.14,27.76,129.44,34.68,139.79,0.0,180
116.66666666666667,100,40,50,14.87,147.91,18.82,158.61,22.71,178.62,32.92,236.36,0.0,150
641.6666666666666,550,45,50,79.99,64.75,80.0,73.72,80.0,83.05,80.0,87.13,0.0,60
466.6666666666667,400,50,50,22.43,114.66,24.3,123.77,26.49,130.8,33.2,138.99,0.0,180
991.6666666666666,850,45,50,27.89,96.38,29.13,100.48,30.53,103.14,35.98,112.28,0.0,180
116.66666666666667,100,35,50,43.14,121.66,57.27,134.88,71.55,150.35,80.0,157.34,0.0,75
525.0,450,35,50,79.98,72.11,79.99,82.01,80.0,87.4,80.0,93.91,0.0,60
291.6666666666667,250,35,50,24.23,118.53,28.66,133.18,31.55,142.34,39.91,150.84,0.0,150
58.333333333333336,50,20,50,7.37,161.56,9.65,184.93,13.65,203.19,23.47,266.46,0.0,180
1108.3333333333335,950,50,50,28.39,93.36,29.78,96.6,31.46,100.35,33.43,105.24,0.0,180
233.33333333333334,200,40,50,54.01,102.37,62.46,111.5,74.18,117.87,80.0,125.5,0.0,75
116.66666666666667,100,50,50,10.85,156.0,13.22,173.46,16.74,201.93,19.95,221.26,0.0,180
116.66666666666667,100,30,50,12.39,150.4,15.33,174.18,20.28,192.66,32.95,287.45,0.0,180
816.6666666666666,700,20,50,27.74,95.84,29.86,100.81,31.48,104.25,37.52,112.55,0.0,180
408.3333333333333,350,30,50,66.67,86.81,73.32,93.12,79.98,99.24,80.0,110.75,0.0,75
816.6666666666666,700,45,50,69.41,81.31,72.8,84.29,75.34,87.14,79.58,91.39,0.0,75
233.33333333333334,200,25,50,18.11,133.22,21.05,144.2,25.37,156.13,37.92,175.3,0.0,180
641.6666666666666,550,25,50,26.25,103.87,28.11,110.24,29.39,112.98,34.19,116.92,0.0,180
58.333333333333336,50,50,50,6.24,172.3,8.19,191.18,10.59,219.28,16.24,274.63,0.0,180
408.3333333333333,350,50,50,79.98,77.6,79.99,87.51,80.0,94.43,80.0,101.25,0.0,60
1050.0,900,35,50,28.44,94.07,30.13,97.23,32.56,99.85,35.32,109.6,0.0,180
1166.6666666666667,1000,25,50,32.5,88.14,33.93,91.37,35.47,94.94,37.98,103.33,0.0,165
116.66666666666667,100,25,50,11.81,150.85,14.94,165.96,18.74,178.5,27.33,221.32,0.0,180
758.3333333333334,650,30,50,27.14,100.37,28.4,104.05,30.51,108.19,32.85,112.43,0.0,180
233.33333333333334,200,30,50,17.57,131.74,19.79,142.1,23.09,152.57,28.85,169.94,0.0,180
1050.0,900,20,50,29.56,91.14,31.21,94.74,33.53,98.71,35.45,105.1,0.0,180
583.3333333333334,500,20,50,26.32,103.52,28.43,109.48,30.9,116.21,34.57,126.38,0.0,180
758.3333333333334,650,50,50,26.02,102.96,27.84,106.91,29.32,110.4,31.65,118.63,0.0,180
58.333333333333336,50,35,50,6.56,166.74,8.69,186.83,10.42,203.89,16.81,251.0,0.0,180
175.0,150,30,50,52.51,106.51,62.58,117.56,72.92,130.75,80.0,143.69,0.0,75
233.33333333333334,200,20,50,76.16,86.64,79.99,93.48,80.0,105.9,80.0,118.58,0.0,60
1050.0,900,25,50,28.58,92.16,30.36,95.9,31.69,99.74,35.35,105.12,0.0,180
641.6666666666666,550,50,50,25.36,106.64,27.35,113.81,29.02,117.24,31.49,120.31,0.0,180
466.6666666666667,400,45,50,25.68,112.56,27.65,117.89,29.28,123.0,35.48,135.24,0.0,165
700.0,600,30,50,43.36,89.29,45.7,93.18,48.6,96.32,59.65,102.49,0.0,115
1166.6666666666667,1000,35,50,29.31,92.36,30.46,95.81,31.82,98.18,34.48,101.36,0.0,180
408.3333333333333,350,45,50,21.63,120.07,23.98,128.31,26.37,133.82,33.41,139.41,0.0,180
583.3333333333334,500,50,50,25.65,107.96,26.96,116.18,29.78,121.02,32.68,124.56,0.0,180
233.33333333333334,200,45,50,16.33,138.02,18.23,146.56,24.35,156.41,32.62,171.8,0.0,180
758.3333333333334,650,35,50,26.93,101.47,28.33,106.67,31.33,110.84,33.38,119.02,0.0,180
1108.3333333333335,950,35,50,28.76,92.92,30.01,97.77,31.48,101.69,36.21,104.57,0.0,180
583.3333333333334,500,35,50,79.99,72.0,79.99,80.22,80.0,84.54,80.0,89.71,0.0,60
466.6666666666667,400,20,50,24.08,108.96,26.71,116.78,30.15,124.11,36.61,131.69,0.0,180
700.0,600,35,50,32.2,94.55,33.97,101.13,36.09,105.68,40.66,110.89,0.0,150
58.333333333333336,50,45,50,6.6,177.03,7.86,194.62,10.02,217.47,14.29,259.78,0.0,180
583.3333333333334,500,30,50,24.62,107.16,27.23,112.85,29.47,119.1,34.39,130.13,0.0,180
933.3333333333334,800,45,50,27.79,97.4,29.34,100.75,30.96,106.92,33.22,111.57,0.0,180
466.6666666666667,400,30,50,52.63,89.96,58.78,96.94,63.79,100.63,71.26,109.08,0.0,90
816.6666666666666,700,25,50,28.29,96.63,30.16,101.92,31.29,104.91,35.89,109.78,0.0,180
816.6666666666666,700,40,50,71.11,78.63,74.99,80.81,77.04,84.54,79.99,88.02,0.0,75
58.333333333333336,50,40,50,6.76,171.17,8.01,195.38,10.32,216.98,17.59,254.52,0.0,180
175.0,150,35,50,53.78,104.86,64.85,117.77,76.32,129.44,80.0,158.21,0.0,75
991.6666666666666,850,35,50,28.47,95.82,30.07,100.65,31.6,105.64,33.43,110.23,0.0,180
991.6666666666666,850,40,50,27.97,94.94,29.7,100.69,31.61,105.4,37.06,113.09,0.0,180
583.3333333333334,500,40,50,24.19,107.74,25.93,114.37,29.3,120.45,32.85,127.07,0.0,180
758.3333333333334,650,20,50,28.14,97.91,29.94,102.82,32.24,105.68,38.34,111.27,0.0,180
641.6666666666666,550,35,50,25.47,104.47,27.26,112.37,29.86,118.14,33.91,126.6,0.0,180
875.0,750,35,50,27.71,97.56,29.13,101.91,31.57,106.47,32.88,112.88,0.0,180
641.6666666666666,550,20,50,27.15,98.85,29.37,105.49,31.38,110.81,36.27,119.19,0.0,180
641.6666666666666,550,40,50,27.53,103.55,29.76,109.54,32.92,114.04,38.47,122.84,0.0,165
700.0,600,50,50,25.08,106.13,27.17,111.9,29.59,116.98,31.23,120.13,0.0,180
933.3333333333334,800,20,50,29.02,92.99,31.25,96.44,33.49,101.75,35.49,107.92,0.0,180
291.6666666666667,250,50,50,18.64,129.26,21.32,137.37,23.62,147.44,27.71,166.74,0.0,180
816.6666666666666,700,35,50,70.0,78.9,73.6,82.35,76.2,85.05,79.99,90.28,0.0,75
525.0,450,20,50,24.97,107.07,27.99,114.83,29.5,120.57,34.55,130.53,0.0,180
991.6666666666666,850,25,50,28.96,93.83,30.27,97.67,31.42,101.4,33.82,107.02,0.0,180
116.66666666666667,100,20,50,49.03,108.35,62.63,127.71,79.98,139.1,80.0,157.94,1.0,75
525.0,450,45,50,23.43,111.92,26.4,118.7,29.77,124.35,31.85,136.3,0.0,180
1166.6666666666667,1000,40,50,47.25,83.07,48.88,86.13,50.11,89.93,53.64,93.71,0.0,115
758.3333333333334,650,45,50,32.46,95.56,34.38,101.01,37.16,105.81,41.14,113.92,0.0,150
1108.3333333333335,950,40,50,28.67,93.45,30.58,96.34,31.96,99.55,34.01,105.66,0.0,180
350.0,300,30,50,50.06,95.57,55.25,103.23,59.7,107.83,69.51,117.57,0.0,90
583.3333333333334,500,45,50,29.75,101.59,32.59,108.04,36.08,113.51,38.23,122.73,0.0,150
875.0,750,30,50,27.82,95.4,29.58,100.97,31.31,104.87,36.78,112.55,0.0,180
58.333333333333336,50,25,50,28.22,127.07,42.42,147.02,58.69,169.41,80.0,207.44,1.0,75
1166.6666666666667,1000,45,50,29.06,93.44,30.4,96.74,31.97,98.97,33.4,104.5,0.0,180
1108.3333333333335,950,25,50,35.58,88.02,37.37,90.75,38.75,93.89,40.0,96.97,0.0,150
875.0,750,20,50,28.53,92.48,30.05,98.58,31.61,104.44,36.94,114.32,0.0,180
525.0,450,40,50,23.11,111.87,24.84,119.17,26.12,124.92,30.05,134.59,0.0,180
875.0,750,25,50,28.25,93.82,30.05,99.75,31.1,102.97,35.41,108.49,0.0,180
175.0,150,20,50,52.29,100.58,62.72,112.21,74.28,122.53,79.99,134.39,0.0,75
700.0,600,40,50,26.31,101.82,28.64,106.48,30.21,111.78,33.16,117.13,0.0,180
175.0,150,25,50,17.48,128.8,21.78,140.56,24.27,153.96,27.19,184.45,0.0,165
641.6666666666666,550,30,50,25.92,102.08,27.93,108.09,30.29,112.67,33.5,119.66,0.0,180
350.0,300,50,50,19.98,124.32,22.71,132.61,25.58,138.48,31.76,148.28,0.0,180
466.6666666666667,400,35,50,23.13,113.2,25.14,120.5,27.69,125.58,31.56,130.0,0.0,180
700.0,600,45,50,25.18,104.68,27.14,109.66,29.35,114.78,32.37,117.64,0.0,180
58.333333333333336,50,30,50,6.96,168.0,8.84,196.23,13.93,227.05,30.09,287.84,0.0,180
525.0,450,30,50,24.65,111.56,26.79,117.51,29.36,123.37,35.62,129.3,0.0,180
1050.0,900,40,50,59.36,80.59,61.5,83.84,64.23,86.14,70.48,89.43,0.0,90
466.6666666666667,400,25,50,24.0,111.14,26.15,117.25,29.02,123.6,37.55,133.46,0.0,180
933.3333333333334,800,50,50,58.6,82.19,61.45,84.75,63.15,90.55,65.93,95.66,0.0,90
525.0,450,50,50,26.8,108.22,28.95,113.36,30.82,119.33,34.81,125.52,0.0,165
816.6666666666666,700,30,50,70.19,78.56,74.33,82.15,77.49,86.02,79.99,92.14,0.0,75
1050.0,900,45,50,28.53,95.09,29.7,99.42,31.19,104.99,32.65,109.3,0.0,180
758.3333333333334,650,25,50,30.47,95.56,32.42,100.28,34.39,104.12,37.86,113.28,0.0,165
991.6666666666666,850,20,50,29.03,91.73,31.15,95.44,32.73,101.14,34.07,107.22,0.0,180
758.3333333333334,650,40,50,69.26,80.25,73.5,84.28,76.36,87.68,79.99,92.13,0.0,75
466.6666666666667,400,40,50,25.6,108.32,28.42,117.25,31.57,122.61,36.18,134.1,0.0,165
408.3333333333333,350,25,50,25.05,109.96,27.85,119.01,31.85,123.47,35.72,137.07,0.0,165
1166.6666666666667,1000,50,50,29.06,92.91,30.51,96.94,31.5,100.83,33.49,104.31,0.0,180
641.6666666666666,550,45,50,68.55,83.6,72.82,87.08,76.38,90.29,80.0,96.23,0.0,75
116.66666666666667,100,35,50,35.17,129.16,48.17,150.64,61.85,165.09,79.99,183.39,0.0,90
175.0,150,45,50,27.34,126.49,31.74,137.79,38.15,153.18,51.08,169.29,0.0,115
233.33333333333334,200,40,50,43.59,104.49,49.9,115.37,57.36,123.04,68.0,131.55,0.0,90
700.0,600,20,50,30.26,96.62,32.16,101.68,34.66,106.6,39.74,113.94,0.0,165
1108.3333333333335,950,30,50,28.79,92.55,30.43,97.22,31.8,101.77,35.96,104.55,0.0,180
583.3333333333334,500,25,50,25.91,105.13,28.6,111.6,30.9,116.44,37.05,127.41,0.0,180
875.0,750,45,50,45.01,88.03,47.65,91.65,49.57,95.6,52.9,100.47,0.0,115
408.3333333333333,350,30,50,52.09,91.18,56.74,98.46,61.32,106.13,80.0,114.57,0.0,90
291.6666666666667,250,35,50,21.45,121.86,24.43,131.57,29.65,140.95,40.63,155.8,0.0,165
233.33333333333334,200,20,50,58.37,95.85,64.6,102.13,72.66,113.62,79.99,123.08,0.0,75
816.6666666666666,700,45,50,56.97,82.93,59.3,87.56,63.29,90.74,70.4,93.5,0.0,90
408.3333333333333,350,50,50,62.23,92.57,67.33,97.47,74.92,102.73,79.98,108.59,0.0,75
116.66666666666667,100,40,50,12.77,150.86,14.86,167.63,18.62,182.29,31.72,222.07,0.0,165
525.0,450,35,50,66.22,85.24,72.07,89.73,76.15,95.01,79.98,106.41,0.0,75
466.6666666666667,400,45,50,22.93,111.98,25.73,119.41,27.16,126.61,32.26,139.65,0.0,180
700.0,600,30,50,33.05,95.04,35.48,100.18,38.11,104.14,41.73,107.69,0.0,150
1166.6666666666667,1000,25,50,29.59,91.02,31.57,94.66,32.9,98.84,35.45,104.0,0.0,180
583.3333333333334,500,35,50,68.3,83.16,70.97,86.94,75.2,91.8,79.99,97.71,0.0,75
175.0,150,30,50,41.95,110.71,50.26,122.43,60.09,130.66,80.0,150.83,0.0,90
175.0,150,35,50,39.31,114.52,45.48,127.44,57.0,144.62,73.13,161.1,0.0,90
816.6666666666666,700,40,50,59.22,81.57,62.72,86.2,64.94,89.48,68.92,94.79,0.0,90
466.6666666666667,400,30,50,39.73,95.85,44.21,102.02,48.24,108.43,57.09,115.13,0.0,115
700.0,600,35,50,29.1,99.31,30.65,105.41,33.12,110.81,34.84,117.81,0.0,165
116.66666666666667,100,20,50,33.77,120.66,41.17,138.24,65.35,157.42,80.0,181.53,2.0,90
641.6666666666666,550,40,50,24.75,104.55,26.97,110.8,28.54,115.29,31.97,124.99,0.0,180
758.3333333333334,650,45,50,29.4,100.68,31.08,105.02,32.79,111.92,36.23,116.78,0.0,165
350.0,300,30,50,36.97,104.28,42.24,111.76,49.84,118.49,60.88,132.31,0.0,115
816.6666666666666,700,35,50,57.72,82.9,59.86,86.92,64.05,89.85,68.36,94.09,0.0,90
583.3333333333334,500,45,50,27.05,104.31,28.96,111.22,30.31,116.86,32.72,125.99,0.0,165
175.0,150,20,50,41.12,107.64,48.4,119.51,55.89,134.35,76.57,164.21,0.0,90
1166.6666666666667,1000,40,50,35.29,88.11,36.54,92.55,38.83,96.85,41.46,100.14,0.0,150
58.333333333333336,50,25,50,21.77,147.55,27.78,173.02,39.09,189.91,79.99,223.47,0.0,90
1108.3333333333335,950,25,50,32.48,88.93,33.85,92.97,35.62,95.88,37.82,101.43,0.0,165
175.0,150,25,50,15.53,140.9,19.72,150.94,24.16,165.11,32.42,197.34,0.0,180
933.3333333333334,800,50,50,45.26,86.58,47.4,90.96,48.5,93.63,55.12,99.61,0.0,115
1050.0,900,40,50,45.5,84.0,47.95,86.67,49.94,90.79,54.73,94.65,0.0,115
525.0,450,50,50,23.35,110.85,25.14,119.12,26.6,125.81,33.89,134.15,0.0,180
816.6666666666666,700,30,50,58.34,81.52,61.95,85.17,65.28,87.31,68.94,89.7,0.0,90
758.3333333333334,650,40,50,58.22,83.02,61.32,86.87,64.07,91.83,69.14,100.71,0.0,90
758.3333333333334,650,25,50,27.65,97.63,29.19,104.16,30.85,109.13,36.64,115.23,0.0,180
466.6666666666667,400,40,50,23.08,115.25,25.05,122.27,27.46,127.95,32.78,136.14,0.0,180
408.3333333333333,350,25,50,23.48,113.92,26.62,123.12,29.91,129.43,39.3,139.51,0.0,180
641.6666666666666,550,45,50,55.3,87.27,57.79,92.95,60.95,96.62,68.46,102.78,0.0,90
116.66666666666667,100,35,50,25.33,129.83,31.78,146.97,38.8,163.67,55.1,210.33,0.0,115
233.33333333333334,200,40,50,31.6,116.35,38.58,124.86,43.86,133.52,49.57,154.39,0.0,115
175.0,150,45,50,19.14,137.17,22.64,149.66,27.37,165.85,36.3,179.0,0.0,150
700.0,600,20,50,27.1,98.48,29.06,104.18,31.03,109.29,35.46,116.41,0.0,180
816.6666666666666,700,45,50,44.01,87.54,47.01,92.33,49.23,95.73,52.66,102.91,0.0,115
408.3333333333333,350,30,50,38.28,101.48,42.07,107.86,48.05,113.54,58.75,121.94,0.0,115
875.0,750,45,50,33.48,94.46,35.44,99.61,37.67,104.0,41.62,108.38,0.0,150
291.6666666666667,250,35,50,18.87,127.05,22.37,135.46,25.46,145.72,31.92,162.17,0.0,180
233.33333333333334,200,20,50,48.29,98.95,54.62,109.11,63.35,117.78,79.99,130.85,0.0,90
408.3333333333333,350,50,50,51.33,94.95,57.77,101.74,63.05,105.97,74.21,114.09,0.0,90
525.0,450,35,50,55.16,87.36,59.02,92.08,62.49,96.45,73.04,105.8,0.0,90
116.66666666666667,100,40,50,11.44,160.37,14.16,177.23,16.94,197.68,23.11,227.56,0.0,180
700.0,600,30,50,29.09,98.32,31.3,104.05,34.1,111.11,39.65,115.9,0.0,165
175.0,150,30,50,32.28,117.58,37.32,133.28,45.4,148.95,64.26,164.14,0.0,115
583.3333333333334,500,35,50,54.93,86.81,59.05,92.88,61.86,97.99,72.08,102.97,0.0,90
175.0,150,35,50,28.48,123.58,34.36,133.16,43.46,147.52,65.34,155.15,0.0,115
466.6666666666667,400,30,50,29.0,103.97,32.55,110.45,34.99,116.83,44.65,127.53,0.0,150
700.0,600,35,50,26.17,103.84,28.32,108.81,30.09,112.16,33.09,119.83,0.0,180
816.6666666666666,700,40,50,43.97,86.91,46.58,91.49,48.37,96.19,51.13,99.75,0.0,115
116.66666666666667,100,20,50,27.34,133.9,33.4,149.11,42.57,161.23,61.08,191.1,0.0,115
816.6666666666666,700,35,50,44.88,86.41,47.19,90.71,49.01,94.98,52.82,101.07,0.0,115
350.0,300,30,50,26.44,112.46,30.39,123.73,34.31,130.09,43.24,142.32,0.0,150
583.3333333333334,500,45,50,24.5,109.63,26.53,115.33,28.32,120.82,32.07,127.8,0.0,180
758.3333333333334,650,45,50,26.33,101.95,28.34,108.91,30.09,114.04,31.76,120.93,0.0,180
175.0,150,20,50,31.59,115.63,37.48,133.86,42.43,143.22,61.75,177.41,1.0,115
58.333333333333336,50,25,50,15.4,152.16,20.09,171.86,33.72,189.97,69.41,244.25,1.0,115
1166.6666666666667,1000,40,50,31.71,91.52,32.94,94.22,35.01,97.57,37.54,100.86,0.0,165
1108.3333333333335,950,25,50,29.52,92.01,31.02,95.06,32.17,98.16,34.84,101.37,0.0,180
933.3333333333334,800,50,50,34.07,93.05,35.8,97.96,36.97,102.03,40.59,105.29,0.0,150
1050.0,900,40,50,35.52,89.81,37.14,94.38,38.19,98.32,40.28,101.4,0.0,150
816.6666666666666,700,30,50,44.97,86.13,47.36,91.54,49.28,94.26,52.39,98.61,0.0,115
758.3333333333334,650,40,50,43.77,88.63,45.99,93.12,47.85,97.06,54.83,105.47,0.0,115
641.6666666666666,550,45,50,42.26,93.03,45.09,97.24,48.26,101.39,52.51,108.22,0.0,115
116.66666666666667,100,35,50,15.07,143.96,17.41,156.01,21.38,164.02,33.88,180.99,0.0,150
233.33333333333334,200,40,50,23.3,130.73,27.4,140.0,30.93,152.53,40.25,162.07,0.0,150
175.0,150,45,50,16.4,140.99,18.44,154.13,23.26,163.94,35.76,186.83,0.0,165
816.6666666666666,700,45,50,33.29,95.44,34.66,100.29,37.51,104.04,41.53,109.02,0.0,150
408.3333333333333,350,30,50,29.32,106.85,32.48,114.3,39.76,121.73,47.67,133.48,0.0,150
875.0,750,45,50,29.35,97.2,31.79,100.91,33.12,104.48,36.21,108.34,0.0,165
408.3333333333333,350,50,50,36.61,103.44,41.75,111.56,44.95,119.11,53.29,123.07,0.0,115
233.33333333333334,200,20,50,35.19,107.68,40.81,118.63,48.64,126.96,80.0,142.79,0.0,115
525.0,450,35,50,40.11,95.39,44.77,100.49,48.03,105.04,56.46,110.61,0.0,115
700.0,600,30,50,26.47,100.9,28.1,107.66,30.25,111.92,36.98,118.24,0.0,180
175.0,150,30,50,19.75,135.5,24.42,147.98,34.37,162.57,73.57,212.62,0.0,150
583.3333333333334,500,35,50,42.11,93.59,45.53,100.47,49.42,104.4,51.58,109.07,0.0,115
175.0,150,35,50,18.87,134.51,23.64,148.27,26.58,159.62,33.95,184.52,0.0,150
466.6666666666667,400,30,50,26.28,109.37,29.31,115.24,32.29,122.96,38.69,130.29,0.0,165
816.6666666666666,700,40,50,33.04,93.79,34.66,98.05,35.92,102.14,40.04,107.63,0.0,150
116.66666666666667,100,20,50,16.56,139.24,21.37,155.77,30.9,182.76,45.11,214.63,1.0,150
816.6666666666666,700,35,50,33.13,94.42,34.77,99.83,36.85,102.72,40.65,108.35,0.0,150
350.0,300,30,50,24.21,117.63,27.02,124.68,30.99,130.01,37.84,161.26,0.0,165
175.0,150,20,50,21.07,132.02,24.66,144.56,31.72,162.48,55.43,206.55,0.0,150
58.333333333333336,50,25,50,9.8,154.95,12.68,179.89,17.49,221.35,55.63,389.39,0.0,150
1166.6666666666667,1000,40,50,28.74,92.37,29.92,95.78,31.31,99.24,33.14,104.19,0.0,180
933.3333333333334,800,50,50,30.03,96.31,31.59,99.3,33.67,102.42,35.72,108.24,0.0,165
816.6666666666666,700,30,50,33.01,93.44,35.6,98.26,38.33,102.54,41.14,105.36,0.0,150
1050.0,900,40,50,31.19,93.01,32.63,97.52,34.1,101.31,36.75,104.76,0.0,165
758.3333333333334,650,40,50,32.28,97.76,34.15,101.97,38.04,104.79,39.75,108.35,0.0,150
641.6666666666666,550,45,50,31.0,99.51,32.64,104.53,34.58,109.01,38.04,115.89,0.0,150
116.66666666666667,100,35,50,13.81,142.59,15.55,163.71,17.64,178.39,22.14,212.0,0.0,165
233.33333333333334,200,40,50,19.01,132.51,21.39,139.32,25.98,152.1,33.08,161.97,0.0,165
175.0,150,45,50,13.65,146.05,15.65,159.59,18.92,166.72,22.87,183.45,0.0,180
816.6666666666666,700,45,50,29.84,96.4,32.17,101.38,34.17,104.67,35.52,109.84,0.0,165
408.3333333333333,350,30,50,24.97,113.23,27.66,119.67,30.34,128.94,35.38,137.18,0.0,165
408.3333333333333,350,50,50,26.21,113.84,29.59,122.88,31.91,128.99,35.18,138.17,0.0,150
875.0,750,45,50,27.39,98.84,28.83,105.86,30.58,110.11,33.46,114.1,0.0,180
233.33333333333334,200,20,50,24.49,120.18,27.81,131.74,37.05,142.36,49.98,153.62,0.0,150
525.0,450,35,50,29.17,104.78,31.72,111.61,33.82,116.38,39.42,128.8,0.0,150
175.0,150,30,50,18.05,134.3,21.34,154.08,28.34,170.9,43.54,215.16,0.0,165
583.3333333333334,500,35,50,30.92,99.44,33.94,105.61,36.7,112.78,44.32,121.84,0.0,150
175.0,150,35,50,16.87,138.85,19.53,152.85,23.31,166.62,36.94,185.02,0.0,165
466.6666666666667,400,30,50,23.83,109.45,26.06,117.14,28.82,127.39,34.83,136.8,0.0,180
116.66666666666667,100,20,50,15.05,151.8,18.24,169.35,23.86,184.22,37.7,229.67,0.0,165
816.6666666666666,700,35,50,29.84,95.87,31.77,99.68,33.06,103.46,34.82,109.59,0.0,165
350.0,300,30,50,21.24,119.97,22.85,127.16,24.99,133.43,28.96,142.86,0.0,180
816.6666666666666,700,40,50,29.7,97.86,32.01,102.31,33.62,106.51,39.08,112.22,0.0,165
175.0,150,20,50,18.55,132.49,23.04,144.33,27.86,153.24,47.26,173.44,0.0,165
58.333333333333336,50,25,50,8.45,165.2,10.75,182.68,15.43,209.34,47.71,277.03,0.0,165
933.3333333333334,800,50,50,27.63,97.26,29.04,101.9,30.55,109.73,33.02,116.82,0.0,180
816.6666666666666,700,30,50,30.18,95.75,32.24,101.39,34.0,105.45,39.37,112.47,0.0,165
1050.0,900,40,50,28.48,94.8,30.15,99.06,30.98,102.69,33.2,107.56,0.0,180
758.3333333333334,650,40,50,29.44,100.17,30.99,104.53,32.6,108.15,37.97,114.88,0.0,165
641.6666666666666,550,45,50,27.64,102.75,30.29,108.32,31.52,113.58,37.54,116.63,0.0,165
116.66666666666667,100,35,50,11.92,154.46,14.82,175.61,18.68,198.64,22.77,244.52,0.0,180
233.33333333333334,200,40,50,17.48,133.07,20.77,143.93,22.4,153.21,25.68,166.97,0.0,180
816.6666666666666,700,45,50,27.22,100.03,28.77,105.11,30.3,108.81,32.54,117.04,0.0,180
408.3333333333333,350,50,50,23.87,117.72,26.26,125.7,28.82,131.73,33.75,139.78,0.0,165
408.3333333333333,350,30,50,21.87,113.04,24.81,124.99,27.81,130.93,32.4,137.61,0.0,180
525.0,450,35,50,27.26,107.44,28.98,114.47,31.87,120.63,39.52,129.36,0.0,165
233.33333333333334,200,20,50,20.4,121.74,24.63,133.75,27.67,145.98,44.82,157.45,0.0,165
583.3333333333334,500,35,50,26.64,105.28,28.8,111.17,30.92,115.63,34.31,127.11,0.0,165
175.0,150,30,50,16.23,140.46,18.89,161.07,24.48,169.49,39.52,185.51,0.0,180
175.0,150,35,50,14.99,139.47,18.78,150.76,21.96,167.57,40.18,198.0,0.0,180
116.66666666666667,100,20,50,13.08,148.97,17.02,165.63,19.96,187.64,27.23,211.92,0.0,180
816.6666666666666,700,35,50,27.2,99.5,28.23,104.08,29.86,107.1,33.85,113.08,0.0,180
175.0,150,20,50,16.14,139.05,19.67,154.09,22.81,171.75,34.35,217.98,0.0,180
58.333333333333336,50,25,50,6.9,170.93,10.64,195.84,13.89,225.86,63.09,336.61,0.0,180
816.6666666666666,700,40,50,26.8,99.62,28.57,104.22,30.53,108.9,34.53,113.83,0.0,180
816.6666666666666,700,30,50,27.1,96.92,29.01,101.25,30.78,104.66,34.49,111.0,0.0,180
758.3333333333334,650,40,50,26.64,101.22,28.43,106.51,29.67,111.7,34.32,119.22,0.0,180
641.6666666666666,550,45,50,25.16,105.43,27.0,111.75,28.68,116.91,31.66,125.31,0.0,180
408.3333333333333,350,50,50,21.22,117.81,23.27,124.24,26.72,130.15,33.42,144.94,0.0,180
525.0,450,35,50,24.21,110.44,26.4,115.21,28.54,120.12,30.06,126.96,0.0,180
233.33333333333334,200,20,50,18.68,128.4,21.99,140.95,26.27,149.03,34.46,171.6,0.0,180
583.3333333333334,500,35,50,24.99,107.08,27.2,114.44,29.52,120.47,31.21,133.13,0.0,180
`.trim()

const computed: PreComputed[] = data.split(/\r?\n/).map(line => {
  const [ask,mrr,growth,volatility,pledge_median,
        pledge_trapped,pledge_75,trapped_75,
        pedge_90,trapped_90,pledge_99,trapped_99,
        pd, tenure
        ] = line.split(",")
  return {
    ask: parseFloat(ask),
    mrr: parseFloat(mrr),
    growth: parseFloat(growth),
    volatility: parseFloat(volatility),
    pledge: parseFloat(pedge_90),
    trapped: parseFloat(trapped_90),
    pd: parseFloat(pd),
    tenure: parseFloat(tenure),
  }
})

export { computed }
